import { DocumentReference } from "@firebase/firestore";
import React,{CSSProperties, FC} from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { QnA } from "../../classes/QnA";
import { UserWithId } from "../../classes/User";
import { QnABox } from "./QnABox";

export const QnAPage : FC<{
    style? : CSSProperties
    isManage? : boolean,
    qnaList : DocumentReference<QnA>[],
    page: number,
    qnaPerPage? : number,
    onClick? : (ref : DocumentReference<QnA>) => void
}> = ({
    style,
    isManage = false,
    qnaList,
    page,
    qnaPerPage = 10,
    onClick
}) => {
    const { user } = useSelector((state: { user: UserWithId }) => state)

    useEffect(()=>{
        console.log("QnAPage : ",qnaList)
    },[])
    return (
        <div style={{
            ...style,
            padding: "0px 2.5%", margin: "8px 0px",
            display: "flex", flexDirection: "column", overflow: "scroll"
        }}>
            {qnaList.slice(page * qnaPerPage, (page + 1) * qnaPerPage).map((ref, idx) => {
                const index = qnaList.length - page * qnaPerPage - idx
                return (
                    <QnABox
                        key={`index_${index}`}
                        isManage={isManage}
                        qnaRef={ref}
                        index={index}
                        userId={user.id}
                        onClick={() => onClick && onClick(ref)} />
                )
            })}
        </div>
    )
}