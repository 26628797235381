import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { Dispatch, useEffect, useRef } from "react";
import { bannerConverter, BannerWithId } from "../classes/Banner";
import { Notice, NoticeWithId } from "../classes/Notice";
import { HTU } from "../classes/HTU";
import { fireStorage, fireStore } from "../fireConfig";
import {chart,overflow} from "../income.json"
import { saveBase64 } from "../redux/reducer/banners/banners";
import { Tax } from "../redux/reducer/tax/tax";
export const b64toBlob = (b64Data : string , contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

// export const dataURLtoFile = (dataurl : string = " , ", fileName : string) => {
 
//     var arr = dataurl.split(',')
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = Buffer.from(arr[1], 'base64').toString(), 
    //     n = bstr.length, 
    //     u8arr = new Uint8Array(n);
        
    // while(n--){
    //     u8arr[n] = bstr.charCodeAt(n);
    // }
    
    // return new File([u8arr], fileName, {type:mime});
// }
export const isValidPhone = (text: string | undefined) => {
    if (text !== undefined) {
        var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        return regPhone.test(text)
    }
    return false
}
export const isValidEmail = (text: string | undefined) => {
    if (text !== undefined) {
        var regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return regEmail.test(text)
    }
    return false
}
export const isValidPassword = (text: string | undefined) => {
    if (text !== undefined) {
        var regEmail = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;
        return regEmail.test(text)
    }
    return false
}
export const isValidSSN = (text? : string) => {
    if(text){
        var regSSN = /([0-9]{6})-([0-9]{1})$/;
        return regSSN.test(text)
    }
    return false
}
export const commaString2Number = (stringNumber: string): number => {
    const removeComma = stringNumber.replace(/[^0-9]/g, "")
    if (removeComma.length > 0) {
        return parseFloat(removeComma)
    } else
        return 0
}
export const cutOffFuntion = (ratio: number, input: string, cutOffUnit : number): string => {
    //ratio % unit
    const removeComma: string = input.replace(/[^0-9.]/g, "")
    if (removeComma.length > 0) {
        const numberInput: number = parseFloat(removeComma)
        const adjustRatio: number = (numberInput * ratio * 1000) / (1000 * 100)
        const cutOffNumber: number = Math.floor(adjustRatio / cutOffUnit) * cutOffUnit
        const pushComma: string = cutOffNumber.toLocaleString()
        return pushComma
    } else
        return "0"
}

export const parseLocale = (value: string | number): string => {
    if (typeof value === "string") {
        const input: string = value
        const removeComma: string = input.replace(/[^0-9]/g, "")
        if (removeComma.length > 0) {
            const numberInput: number = parseInt(removeComma)
            const pushComma: string = numberInput.toLocaleString()
            return pushComma
        } else
            return ""
    } else {
        if (value > 0) {
            return value.toLocaleString()
        } else {
            return "0"
        }
    }
}
export const calIncomeTax = (normal:string,targetDeduction : number,cutOffUnit:number ) :string => {
    var str : string
    const removeComma: string = normal.replace(/[^0-9]/g, "")
    if (removeComma.length > 0) {
        const numberInput: number = parseFloat(removeComma)
        var taxNumber: number = 0;
        if (numberInput <= 10_000_000) {
            for (var i = 0; i < chart.length; i++) {
                const { more, deduction } = chart[i]
                const realMoreValue = more * 1_000
                if (numberInput <= realMoreValue) {
                    if(numberInput < realMoreValue){
                        taxNumber = chart[i-1]?.deduction[targetDeduction > 0 ? targetDeduction - 1 : 0] ?? 0
                    }
                    else  { //(numberInput === realMoreValue)
                        taxNumber = deduction[targetDeduction > 0 ? targetDeduction - 1 : 0]
                    }
                    break;
                }
            }
        }
        else {
            const { deduction } = chart[chart.length - 1]
            //10_000천원인 경우의 해당 세액
            const baseTaxNumber: number = deduction[targetDeduction > 0 ? targetDeduction : 1]
            var add: number = 0, alot: number = 0
            // console.log(numberInput)

            var check = true;
            overflow.forEach((value,index)=>{
                const {over,below,per1,per2,add:addValue} = value
                // console.log(index)
                if(((numberInput <= below) || (index === overflow.length-1))&& check){
                    // console.log(value)
                    check = false
                    add = addValue
                    alot = ((((numberInput - over) * per1 * 1000) / (1000 * 100)) * per2 * 1000) / (1000 * 100)
                }
            })
            taxNumber = baseTaxNumber + add + alot
        }
        const cutOffNumber: number = Math.floor(taxNumber / cutOffUnit) * cutOffUnit
        const pushComma: string = cutOffNumber.toLocaleString()
        str = pushComma
    } else
        str = "0"
    return str
}

export const getBannerList = async (): Promise<BannerWithId[]> => {
    try {
        const colRef = collection(fireStore, "banners").withConverter(bannerConverter)
        const colQuery = query(colRef, orderBy("createTime", "asc"))
        const bannerDocs = await getDocs(colQuery)
        // alert("bannerDocs Size : " + bannerDocs.size)
        return Promise.all(
            bannerDocs.docs.map(async (value, index) => {
                var banner = value.data()
                return {
                    banner: banner,
                    id: value.id,
                    index: index
                }
            })
        )
    } catch (error) {
        alert(error)
        return []
    }
}
export const getHowToUses = async () : Promise<HTU[]> => {
    const colRef = collection(fireStore,"howtouse")
    const htus = await getDocs(colRef)
    return Promise.all(
        htus.docs.map(async(value,index)=>{
            var htu = value.data()
            return {
                question : htu.question,
                answer : htu.answer
            }
        })
    )
}
export const getNoticeList = async () : Promise<NoticeWithId[]> => {
    const colRef = collection(fireStore,"notices")
    const colQuery = query(colRef,orderBy("createTime","desc"))
    const notices = await getDocs(colQuery)
    return Promise.all(
        notices.docs.map(async(value,index)=>{
            var notice = value.data()
            return {
                notice: {
                    createTime: new Timestamp(notice.createTime.seconds, notice.createTime.nanoseconds),
                    title: notice.title,
                    content: notice.content
                },
                id : value.id,
                index : index
            }
        })
    )
}
export const getTaxValue = async() : Promise<Tax>=>{
    const taxDoc = doc(fireStore,"tax/value")
    console.log("Start get TaxValue")
    const taxValue = await getDoc(taxDoc)
    console.log("Finish get TaxValue")
    return taxValue.data() as Tax
}
export const getQuestionAnswer = async () => {
    const colRef = collection(fireStore,"qna")
    const colQuery = query(colRef,orderBy("createTime","desc"))
    const qnaList = await getDocs(colQuery)
    return Promise.all(
        qnaList.docs.map(async(value,index)=>{

        })
    )
}
export const getImageUrl = async (
    reduxDispatch: Dispatch<any>,destination : string,index : number,
    onGetImageSource : (src : string) => void) : Promise<void> => {
    const imgRef = ref(fireStorage,destination)
    const imageUrl = await getDownloadURL(imgRef)
    toDataUrl(imageUrl,(result)=>{
        var resultString = result as string
        onGetImageSource(resultString)
        reduxDispatch(saveBase64({
            base64 : resultString,
            index : index
        }))
    })
    onGetImageSource(imageUrl)
}
const toDataUrl = (url:string, callback : ( result :string | ArrayBuffer | null) => void) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const useInterval = (callback : () => void, delay : number) => {
    const savedCallback = useRef<()=>void>(()=>{});

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        }

        const timerId = setInterval(tick, delay);
        return () => clearInterval(timerId);
    }, [delay]);
}

export const pickUpRandom = (array : BannerWithId[], ) =>{
    return array[Math.floor(Math.random()*array.length)]
}
/**
 * 
 * @param array 오리지날 배열
 * @param containerArray array에서 랜덤한 값을 쌓아나갈 배열
 * @returns 
 */
export const bannerWithOutDuplication = (array : BannerWithId[], containerArray : BannerWithId[]) : BannerWithId[] => {
    try {
        if (array.length > 0) {
            const ran = pickUpRandom(array)
            if (array.length > containerArray.length) {
                if (containerArray.findIndex((item) => { return item.id === ran.id }) < 0)
                    return containerArray.concat(ran)
                else return bannerWithOutDuplication(array, containerArray)
            } else {
                if (array.length > 1) {
                    if (containerArray[containerArray.length - 1].id !== ran.id)
                        return [ran]
                    else return bannerWithOutDuplication(array, containerArray)
                } return [ran]
            }
        } else return []
    } catch (error) {
        console.log(array.length,containerArray.length)
        console.log(error)
        return []
    }
}