import React, { FC } from "react"
import { Route, Routes } from "react-router"
import { AdminQnAList } from "./AdminQnA/AdminQnAList"
import { AdminQnAView } from "./AdminQnA/AdminQnAView"

export const AdminQnARouter : FC = () => {
    return(
        <Routes>
            <Route path=":page" element={<AdminQnAList/>}/>
            <Route path="view/:id" element={<AdminQnAView/>}/>
        </Routes>
    )
}