import { Dialog } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, {FC, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fireStore } from "../fireConfig";
import { AccessKey, saveAccessKeyList } from "../redux/reducer/accesskey/accesskey";
import { THEME_YELLOW } from "../values/Colors";

export const ChangeKeyModal: FC<{
    open: boolean
    onClose: () => void
}> = ({
    open,
    onClose
}) => {
    const reduxDispatch = useDispatch()
    const { accessKey } = useSelector((state: { accessKey: AccessKey[] }) => state)
    const [targetIdx, setTargetIdx] = useState<number | undefined>(undefined)

    const [id, setId] = useState<string>("")
    const [oldPw, setOldPw] = useState<string>("")
    const [pw, setPw] = useState<string>("")

    const TITLE_WIDTH = "120px"
    const INPUT_WIDTH = "320px"


    const isFullFill = (): boolean => {
        var state = true
        if (id.length < 1) {
            state = false
        }
        if (oldPw.length < 1) {
            state = false
        }
        if (pw.length < 1) {
            state = false
        }
        return state
    }

    const changeAccessKey = async() : Promise<void> => {
        try {
            // throw new Error("new Error")
            const newCode = accessKey.slice()
            var currentTargetItem = newCode[targetIdx!]
            currentTargetItem = {
                ...currentTargetItem,
                title: id,
                value: pw
            }
            newCode[targetIdx!] = currentTargetItem
            console.log(newCode,currentTargetItem)
            const docRef = doc(fireStore, "accessCode/access_code")
            await updateDoc(docRef, {
                code: newCode
            })
            reduxDispatch(saveAccessKeyList(newCode))
            alert("수정되었습니다.")
            onClose()
            initialize()
        } catch (error) {
            alert("오류가 발생하였습니다.")
        }

    }
    const checkPassword = () : boolean => {
        const {value : originKey} = accessKey[targetIdx!]
        return originKey === oldPw
    }
    const initialize = ()=>{
        setTargetIdx(undefined)
        setId("")
        setOldPw("")
        setPw("")
    }

    const onChangeTargetIdx = async () => {
        if (targetIdx !== undefined) {
            var targetItem: AccessKey;
            if (accessKey.length < 1) {
                const docRef = doc(fireStore, "accessCode/access_code")
                const docGet = (await getDoc(docRef)).data()
                const list = docGet!.code as AccessKey[]
                reduxDispatch(saveAccessKeyList(list))
                targetItem = list[targetIdx]
            } else
                targetItem = accessKey[targetIdx]
            setId(targetItem.title)
            setOldPw("")
            setPw("")
        }
    }
    
    useEffect(() => {
        onChangeTargetIdx()
    }, [targetIdx])

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none", borderRadius: "0px",
                    flexDirection: "row", justifyContent: "center",
                    boxSizing:"border-box"
                }
            }}
            fullWidth={true}
            open={open}
            onClose={()=>{
                initialize()
                onClose()
            }}>

            <div style={{
                    // border:"1px solid black",
                borderRadius: "4px",
                margin: "8px",
                padding: `32px 36px`,
                boxSizing: "border-box",
                width:"100%",
                maxWidth :"600px",
                display: "flex", flexDirection: "column",
                fontFamily: "NotoSans",
                backgroundColor: "white"
            }}>
                <div style={{
                    // border:"1px solid black",
                    display: "flex",justifyContent:"space-evenly",
                }}>
                    <div className="pointer-cursor"
                        style={{
                            backgroundColor : targetIdx !== 0 ? undefined : THEME_YELLOW,
                            border: targetIdx !== 0 ? "1px solid #D0D0D0" : undefined, 
                            color: targetIdx !== 0 ? "#9B9B9B" : "white",
                            fontWeight: targetIdx !== 0 ? undefined : 500,
                            padding:"12px 18px",boxSizing:"border-box",borderRadius:"4px"
                        }}
                        onClick={()=>setTargetIdx(0)}>총관리자</div>
                    <div className="pointer-cursor"
                        style={{
                            backgroundColor : targetIdx !== 1 ? undefined : THEME_YELLOW,
                            border: targetIdx !== 1 ? "1px solid #D0D0D0" : undefined, 
                            color: targetIdx !== 1 ? "#9B9B9B" : "white",
                            fontWeight: targetIdx !== 1 ? undefined : 500,
                            padding:"12px 18px",boxSizing:"border-box",borderRadius:"4px"
                        }}
                        onClick={()=>setTargetIdx(1)}>부관리자</div>
                </div>
                {targetIdx !== undefined
                    && <div style={{
                        display: "flex", flexDirection: "column",
                        fontSize: "14px", marginTop: "36px", boxSizing:"border-box"
                    }}>
                        <div
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center",
                                color: "#161616", marginBottom: "24px"
                            }}>
                            <span style={{ marginRight: "20px", width: TITLE_WIDTH }}>아이디</span>
                            <input style={{
                                flex:1,
                                width: INPUT_WIDTH,
                                padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                                outlineWidth: "0px"
                            }}
                                placeholder="배너제목을 입력해 주세요."
                                value={id}
                                onChange={(e) => { setId(e.target.value) }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center",
                                color: "#161616", marginBottom: "24px"
                            }}>
                            <span style={{ marginRight: "20px", width: TITLE_WIDTH }}>기존 비밀번호</span>
                            <input style={{
                                flex:1,
                                width: INPUT_WIDTH,
                                padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                                outlineWidth: "0px", whiteSpace: "nowrap", wordBreak: "keep-all",
                            }}
                                type="password"
                                placeholder="기존 비밀번호를 입력해 주세요."
                                value={oldPw}
                                onChange={(e) => { setOldPw(e.target.value) }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center",
                                color: "#161616", marginBottom: "24px"
                            }}>
                            <span style={{ marginRight: "20px", width: TITLE_WIDTH }}>새로운 비밀번호</span>
                            <input style={{
                                flex:1,
                                width: INPUT_WIDTH,
                                padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                                outlineWidth: "0px"
                            }}
                                type="password"
                                placeholder="새로운 비밀번호를 입력해 주세요."
                                value={pw}
                                onChange={(e) => { setPw(e.target.value) }}
                            />
                        </div>
                        <div className="pointer-cursor" style={{
                            display:"flex",justifyContent:"center",
                            padding : "12px", 
                            backgroundColor : !isFullFill()? undefined : THEME_YELLOW,
                            border: `1px solid ${!isFullFill()? "#D0D0D0" : THEME_YELLOW}`, 
                            borderRadius:"9px",
                            color: !isFullFill()? "#9B9B9B" : "white",
                            fontWeight: !isFullFill()? undefined : 500,
                            boxSizing:"border-box",
                        }}
                            onClick={()=>{
                                if(isFullFill()){
                                    if(checkPassword()){
                                        changeAccessKey()
                                    }else
                                        alert("기존 비밀번호가 일치하지 않습니다.")
                                }
                            }}>
                            <span>수정</span>
                        </div>
                    </div>
                }
            </div>
        </Dialog>
    )
    }