import React, {FC} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { img_people_pc, img_text_pc, landing_mobile1, landing_pc1, landing_pc2 } from "../../images/images";
import { DARK_GRAY, THEME_YELLOW } from "../../values/Colors";
import { FIVE_PER } from "../../values/strings";

export const LandingInfo: FC = () => {
    const isMobile = useSelector((state : {isMobile: boolean})=> state.isMobile)
    const navigate = useNavigate();

    return isMobile
        ?
        <div style={{
            overflowY:"hidden",
            display: "flex", flexDirection: "column",
            boxSizing:"border-box"
        }}>
            <div style={{ overflowY: "scroll", flex: 1 }}>
                <img src={landing_mobile1} style={{ width: "100%" }} />
                <footer style={{
                    width: "100%",
                    padding: `12px ${FIVE_PER} 80px ${FIVE_PER}`, boxSizing: "border-box",
                    backgroundColor: "rgba(245, 244, 241, 0.85)",
                }}>
                    <p style={{ fontFamily: "Notosans", fontSize: "12px", color: DARK_GRAY, fontWeight: 500 }}>
                        노랑봉투<br />
                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                            {`주식회사 플로션`}<br />
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br />
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br />
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br />
                        </span>
                    </p>
                </footer>
            </div>
            <div className="pointer-cursor"
                style={{
                    position:"absolute",
                    bottom:0,left:0,right:0,
                    borderRadius:"4px",
                    boxSizing: "border-box",
                    display: "flex", justifyContent: "center",
                    margin:"16px",
                    padding: "16px",
                    fontWeight: 500, color: "white", backgroundColor: THEME_YELLOW
                }}
                onClick={() => {
                    navigate("../../login")
                }}>노랑봉투 서비스 바로가기
            </div>
        </div>
        :
        <div style={{
            overflowY: "scroll"
        }}>
            <div style={{
                margin: "64px 0px",
                display: "flex", boxSizing: "border-box", justifyContent: "center"
            }}>
                <div style={{
                    marginRight: "5%",
                    width: "40%", maxWidth: "400px",
                    display: "flex", flexDirection: "column",
                    alignItems: "flex-start", justifyContent: "flex-end",
                }}>
                    <img src={img_text_pc} style={{ width: "80%" }} />
                    <p style={{ fontSize: "18px" }}>
                        <span>노랑봉투 서비스를 통해</span><br />
                        <span><span style={{ fontWeight: 500 }}>스마트하고 빠르게 급여명세서를 발송</span>해보세요!</span>
                    </p>
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            display: "flex", justifyContent: "center",
                            width: "100%",
                            padding: "16px",
                            fontWeight: 500, color: "white", backgroundColor: THEME_YELLOW
                        }}
                        onClick={() => {
                            navigate("../../login")
                        }}>노랑봉투 서비스 바로가기
                    </div>
                </div>
                <img src={img_people_pc}
                    style={{ width: "60%", maxWidth: "600px" }} />
            </div>
            <img src={landing_pc1} style={{ width: "100%" }} />
        </div>
}