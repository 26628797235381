import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { MonthItem } from "../../classes/Month";
import { EmpPs, PartPs, PayStub } from "../../classes/PayStub";
import { StaffWithId } from "../../classes/Staff";
import { User, UserWithId } from "../../classes/User";
import { Emp } from "../../components/ViewPageComp/Emp";
import styles from '../../css/PayViewPage.module.css'
import { back, logo } from "../../images/images";
import { birthCalculator } from "../../values/BirthCalculator";

export const EmployeeView :FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {user : reduxUserState,isMobile} = useSelector((state: { user: UserWithId,isMobile:boolean }) => state);
    const { staff, payStub, targetMonth, staffIndex, readonly } =
        location.state as {
            staff: StaffWithId,
            payStub?: PayStub,
            targetMonth: MonthItem,
            staffIndex: number,
            readonly: boolean
        }
    const birthDate =
        birthCalculator(parseInt(staff.data.ssn[1][0]) ) + staff.data.ssn[0].substring(0, 2) + "-" + staff.data.ssn[0].substring(2, 4) + "-" + staff.data.ssn[0].substring(4, 6)
    const payStubData = payStub?.data as EmpPs
    // const [isOpenDeduct,setIsOpenDeduct] = useState<boolean>(false)
    // const [isOpenMothlyPayment,setIsOpenMothlyPayment] = useState<boolean>(false)
    // const [isOpenIrregular,setIsOpenIrregular] = useState<boolean>(false)
    // useEffect(() => {
    //     console.log(payStubData)
    // }, [])
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={!isMobile ? {
                display: "flex", justifyContent:"center"
            } : undefined}>
            <div className={styles.main_ctn}
                style={!isMobile ? {
                    maxWidth: "800px"
                } : undefined}>
                <div className={styles.header_ctn}>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                        onClick={goBack} >
                        <img
                            src={back} />
                    </div>
                    <div className={styles.header_center}>
                        <img src={logo} />
                    </div>
                </div>
                <div className={styles.body_ctn}>
                    <div className={`side_margin_5per`} style={{display: "flex",boxSizing: "border-box",alignItems: "flex-end"}}>
                        <span className="name_deco">{staff.data.name}</span>
                        <span style={{ marginLeft: "4px", fontSize: "18px", fontWeight: "bold" }}>님</span>
                    </div>
                    {/* topper */}
                    <div className={`side_margin_5per`}
                        style={{marginTop:"12px",display: "flex", flexDirection: "column",boxSizing: "border-box",}}>
                        <div style={{display: "flex", justifyContent: "space-between",fontSize: "12px"}}>
                            <span>
                                <span style={{ fontWeight: 500, marginRight: "8px" }}>{`부서`}</span>
                                <span>{payStub?.staffInfo.part}</span>
                            </span>
                            <span>{reduxUserState.data.companyName}</span>
                        </div>

                        <div style={{marginTop: "6px",display: "flex", justifyContent: "space-between",fontSize: "12px"}}>
                            <span>
                                <span style={{ fontWeight: 500, marginRight: "8px" }}>{`직급`}</span>
                                <span>{payStub?.staffInfo.rank}</span>
                            </span>
                            <span>
                                <span style={{ fontWeight: 500, marginRight: "8px" }}>지급일</span>
                                {moment.unix(payStubData.paymentDate.seconds).format("YYYY-MM-DD")}</span>
                        </div>

                        <div style={{marginTop: "6px",display: "flex", justifyContent: "space-between",fontSize: "12px"}}>
                        <span>
                            <span style={{ fontWeight: 500, marginRight: "8px" }}>{`생년월일(사번)`}</span>
                            <span>{birthDate}</span>
                        </span>
                        </div>
                    </div>
                    <div style={{ minHeight: 5, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />

                    <Emp payStubData={payStubData}/>
                    {/* <div className={`side_margin_5per`} style={{display: "flex", flexDirection: "column",marginBottom: "16px"}}>
                        <TitleInputUnit
                            title="근로일수"
                            titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                            value={payStubData.workingDays}
                            removeBorder={true}
                            unit="일"
                            readonly={true} />
                        <TitleInputUnit
                            title="근로시간"
                            titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                            value={payStubData.workingHours}
                            removeBorder={true}
                            unit="시간"
                            readonly={true} />
                    </div>
                    <div
                        className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                        <PayInput
                            // PaymentAmount
                            style={{ borderWidth: "0px" }}
                            readonly={true}
                            title={"지급액계"}
                            value={payStubData.paymentAmount} />
                        <PayInput
                        // DeductionAmount
                            style={{ borderWidth: "0px" }}
                            readonly={true}
                            title={"공제액계"}
                            value={payStubData.deductionAmount}
                            onChange={() => { }} />
                        <div style={{ minHeight: 1, backgroundColor: "#F5F5F5" }} />
                        <PayInput
                        // Amount
                            style={{ borderWidth: "0px" }}
                            titleStyle={{ fontWeight: 500 }}
                            readonly={true}
                            title={"실수령액"}
                            value={payStubData.amount} />
                    </div>
                    {!isOpenMothlyPayment
                        ?
                        <div className="side_margin_5per"
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                border: "1px solid #F5F5F5",marginBottom:"18px",
                                padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenMothlyPayment(!isOpenMothlyPayment)
                            }}>
                            <span>매월지급</span>
                            <img src={ic_arrow_down} style={{ height: "20px" }} />
                        </div>
                        :
                        <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                            <div className={`${styles.deduct_ctn}`}
                                style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                                <div
                                    style={{
                                        display: "flex", justifyContent: "space-between", alignItems: "center",
                                        fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                                    }}
                                    onClick={() => {
                                        setIsOpenMothlyPayment(!isOpenMothlyPayment)
                                    }}>
                                    <span>매월지급</span>
                                    <img src={ic_arrow_up} style={{ height: "20px" }} />
                                </div>
                                {payStubData.monthlyPayments.map((value, index) => {
                                    return (
                                        <MonthlyPaymentInput
                                            key={`${value.title}_${index}`}
                                            styles={{
                                                titleStyle: {
                                                    fontWeight: 500
                                                }
                                            }}
                                            readonly={true}
                                            values={value} />
                                    )
                                })}
                                <PayInput
                                    readonly={true}
                                    titleStyle={{fontWeight:500}}
                                    title={payStubData.additionalWorkAllowance.title}
                                    value={payStubData.additionalWorkAllowance.value} />
                            </div>
                        </div>
                    }
                    {payStubData.irregularPayments.length > 0 && !isOpenIrregular
                        ?
                        <div className="side_margin_5per"
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                border: "1px solid #F5F5F5",marginBottom:"18px",
                                padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenIrregular(!isOpenIrregular)
                            }}>
                            <span>부정기지급</span>
                            <img src={ic_arrow_down} style={{ height: "20px" }} />
                        </div>
                        :
                        <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                            <div className={`${styles.deduct_ctn}`}
                                style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                                <div
                                    style={{
                                        display: "flex", justifyContent: "space-between", alignItems: "center",
                                        fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                                    }}
                                    onClick={() => {
                                        setIsOpenIrregular(!isOpenIrregular)
                                    }}>
                                    <span>부정기지급</span>
                                    <img src={ic_arrow_up} style={{ height: "20px" }} />
                                </div>
                                {payStubData.irregularPayments.map((value, index) => {
                                    return (
                                        <MonthlyPaymentInput
                                            key={`${value.title}_${index}`}
                                            styles={{
                                                titleStyle: {
                                                    fontWeight: 500
                                                }
                                            }}
                                            readonly={true}
                                            values={value} />
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {!isOpenDeduct
                        ?
                        <div className="side_margin_5per"
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                border: "1px solid #F5F5F5",marginBottom:"18px",
                                padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenDeduct(!isOpenDeduct)
                            }}>
                            <span>공제항목</span>
                            <img src={ic_arrow_down} style={{ height: "20px" }} />
                        </div>
                        :
                        <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                            <div className={`${styles.deduct_ctn}`}
                                style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                                <div
                                    style={{
                                        display: "flex", justifyContent: "space-between", alignItems: "center",
                                        fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                                    }}
                                    onClick={() => {
                                        setIsOpenDeduct(!isOpenDeduct)
                                    }}>
                                    <span>공제항목</span>
                                    <img src={ic_arrow_up} style={{ height: "20px" }} />
                                </div>
                                {payStubData.deductionItems.map((value, index) => {
                                    return (
                                        <MonthlyPaymentInput
                                            key={`${value.title}_${index}`}
                                            styles={{
                                                titleStyle: {
                                                    fontWeight: 500
                                                }
                                            }}
                                            readonly={true}
                                            values={value} />
                                    )
                                })}
                            </div>
                        </div>
                    }
                    <div className="side_margin_5per" style={{ minHeight: 1, backgroundColor: "#F5F5F5", marginBottom: "18px" }} />
                    <div className="side_margin_5per" style={{
                        display: "flex", flexDirection: "column",
                        boxSizing: "border-box", padding: "0px 4px",
                        marginBottom:"18px"
                    }}>
                        <span style={{
                            wordBreak: "keep-all", whiteSpace: "nowrap",
                            fontSize: "14px", fontWeight: 500
                        }}>공제항목</span>
                        {payStubData.additionWorks.map((value, index) =>
                            <div style={{
                                marginTop: "6px",
                                display: "flex", justifyContent: "space-between",
                                fontSize: "12px"
                            }}>
                                <span style={{ color: "#767676", marginRight: "8px" }}>{`${value.title}`}</span>
                                <span
                                    style={{ fontWeight: 500 }}>
                                    {`${value.value.hour}시간 (*${value.value.ratio})`}</span>
                            </div>
                        )}
                    </div> */}
                </div>
                {/* <ServiceBanner type={1}/> */}
            </div>
        </div>
    )
}