import React, { useEffect } from 'react';
import './App.css';
import {
	Routes,
	Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Login } from './pages/Login';
import { Join } from './pages/Join';
import { StaffManage } from './pages/StaffManage';
import { MemberAdd } from './pages/MemberAdd';
import { MyDialog } from './pages/MyDialog';
import { Drawer } from './pages/Drawer';
import { Info } from './pages/Info';
import { PayManage } from './pages/PayManage';
import { HowToUse } from './pages/HowToUse';
import { PayRouter } from './pages/PayRouter';
import { PayStubList } from './pages/PayStubList';
import { StaffLogin } from './pages/StaffLogin';
import { Main } from './pages/Main';
import { onAuthStateChanged } from 'firebase/auth';
import { fireAuth } from './fireConfig';
import { GetInitValue } from './components/GetInitValue';
import { useDispatch, useSelector } from 'react-redux';
import { User, UserWithId } from './classes/User';
import { StaffWithId } from './classes/Staff';
import { MonthSet } from './classes/Month';
import { PayViewRouter } from './pages/PayViewRouter';
import { Notice } from './pages/Notice';
import { InfoModify } from './pages/InfoModify';
import { AdminRouter } from './pages/Admin/AdminRouter';
import { bannerWithOutDuplication, getBannerList, getHowToUses, getTaxValue, pickUpRandom, useInterval } from './components/Tools';
import { saveBanners } from './redux/reducer/banners/banners';
import { BannerWithId } from './classes/Banner';
import { setTimeout } from 'timers';
import { changeCurrentBanner, ServeBannerObj } from './redux/reducer/serve-banner/serveBanners';
import { saveTaxValue } from './redux/reducer/tax/tax';
import { LandingRouter } from './pages/Landing/LandingRouter';
import { useMediaQuery } from 'react-responsive';
import { changeViewSizeState } from './redux/reducer/is-mobile/isMobile';
import { saveHtu } from './redux/reducer/howtouse/howtouse';
import { QnAList } from './pages/QnA/QnAList';
import { QnARouter } from './pages/QnARouter';

declare global {
  interface Window {IMP : any,native : {
    shareLink : (url:string) => void
  }}
}
function App() {
  const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location[] };
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const reduxState = useSelector((state: { 
    user: UserWithId, staff: StaffWithId[], monthSet: MonthSet,
    banners : BannerWithId[],serveBanner : ServeBannerObj }) => state);
  useEffect(()=>{
    reduxDispatch(changeViewSizeState(isMobile))
  },[isMobile])
  useEffect(() => {
    const _onAuthStateChanged = onAuthStateChanged(fireAuth,
      async (user) => {
        // console.log(user, location)
        if (user === null) {
          const availablePathes: string[] = ["main", "manage-staff", "manage-pay", "info", "how-to-use", "notice", "pay", "pay-view","qna"]
          const pathName = location.pathname
          console.log(pathName)
          if (pathName.includes("admin")) {
            console.log("include admin")
            return
          }
          if (pathName.includes("landing")) {
            console.log("include landing")
            return
          }
          if (availablePathes.some(el => pathName.includes(el))) {
            console.log(pathName)
            navigate("/login", { replace: true })
            return
          }
        } else {
          const blockUrlList: string[] = ["/join","/main/info-modi","info-modi","/admin","landing"]
          const isBlocked = blockUrlList.some(el=> location.pathname.includes(el))
          console.log(`User !== null & isBlocked ${isBlocked}`)
          if (!isBlocked && !(reduxState.user.data.email === user.email)) {
            console.log("GetInitValue")
            await GetInitValue(user.uid, reduxDispatch, reduxState)
          }
        }
      })
    return _onAuthStateChanged
  }, [location])

  useEffect(() => {
    saveBannerList()
    saveTax()
    saveQnAList()
  }, [])

  const getNextBanner = () : ServeBannerObj => {
    const type0Banner = reduxState.banners.filter(({ banner }) => banner.type === 0)
    const newListType0 = bannerWithOutDuplication(type0Banner, reduxState.serveBanner[0])

    const type1Banner = reduxState.banners.filter(({ banner }) => banner.type === 1)
    const newListType1 = bannerWithOutDuplication(type1Banner, reduxState.serveBanner[1])

    const newServeBannerObj : ServeBannerObj = {
      0: newListType0,
      1: newListType1,
    }
    return newServeBannerObj
  }
  useInterval(() => {
    reduxDispatch(changeCurrentBanner(getNextBanner()))
  }, 5000)

  const saveTax = async ()=>{
    const taxValue = await getTaxValue()
    reduxDispatch(saveTaxValue(taxValue))
}
  const saveBannerList = async () => {
    const bannerList = await getBannerList()
    reduxDispatch(saveBanners(bannerList))
  }

  const saveQnAList = async () => {
    const qnaList = await getHowToUses()
    reduxDispatch(saveHtu(qnaList))
}
  return (
    <div id="app">
      <Routes location={state?.backgroundLocation &&
        state.backgroundLocation.length > 0
        ? state.backgroundLocation[0]
        : location}>
        {/* <Route path="/home" element={}/> */}
        {/* <Route path="/*" element={<Navigate replace to=""/>} /> */}
        <Route path="/*" element={<LandingRouter/>}/>
        <Route path="login" element={<Login/>} />
        <Route path="join" element={<Join />} />
        <Route path="main/*" element={<Main/>}/>
        <Route path={"manage-staff"} element={<StaffManage />} />
        <Route path="manage-pay" element={<PayManage />} />
        <Route path="info" element={<Info />} />
        <Route path="info-modi" element={<InfoModify />} />
        <Route path="how-to-use" element={<HowToUse />} />
        <Route path="notice" element={<Notice/>} />
        <Route path="pay" element={<PayRouter />} />
        <Route path="qna/*" element={<QnARouter />} />
        <Route path="pay-view" element={<PayViewRouter />} />
        <Route path="browse" element={<PayStubList/>}/>
        <Route path="staff-login" element={<StaffLogin/>}/>
        <Route path="admin/*" element={<AdminRouter/>}/>
      </Routes>
      {/* {location.pathname === "/manage-staff/add" &&
        <Routes>
        </Routes>
      } */}
      {(state?.backgroundLocation && state.backgroundLocation.length > 0) &&
        <Routes location={state.backgroundLocation[1] || location}>
          <Route path="drawer" element={<Drawer />} />
          {/* <Route path="manage-staff/add" element={<MemberAdd />} /> */}
          <Route path="manage-staff/:type" element={<MyDialog />} />
          <Route path="dialog/:type" element={<MyDialog />} />
        </Routes>
      }
      {(state?.backgroundLocation && state.backgroundLocation.length > 1) &&
        <Routes>
          <Route path="dialog/:type" element={<MyDialog />} />
        </Routes>
      }
    </div>
  );
}

export default App;
