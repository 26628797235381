
import React, { CSSProperties, FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { AdditionalWorkType, PayInputType } from "../classes/PayStub";
import { THEME_YELLOW } from "../values/Colors";
import { parseLocale } from "./Tools";

export const AdditionalWorkInput:
    FC<{
        id?: string,
        values: AdditionalWorkType,
        readonly: boolean,
        styles?: {
            outerStyle?: CSSProperties,
            inputStyle?: CSSProperties
        },
        type?: HTMLInputTypeAttribute,
        placeholder?: string,
        onChange?: (value: { hour: string, ratio: string }) => void
    }> =
    ({
        id,
        values: { title, value: { hour: initHour, ratio: initRatio } },
        readonly,
        styles,
        type,
        placeholder,
        onChange
    }) => {
        const [isHourFocus, setIsHourFocus] = useState<boolean>(false)
        const [isRatioFocus, setIsRatioFocus] = useState<boolean>(false)
        const [hour, setHour] = useState(initHour)
        const [ratio, setRatio] = useState(initRatio)
        useEffect(()=>{
            console.log("in UseEffect",initHour,initRatio)
            setHour(initHour)
            setRatio(initRatio)
            if(onChange){
                onChange({ hour: initHour, ratio: initRatio })
            }
        },[])
        return (
            <div id={id}
                style={{
                    display: "flex", flexDirection: "row",
                    minHeight: "52px",
                    // paddingTop: 16, paddingBottom: 16,
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                    fontFamily: "Notosans",
                    fontSize: "14px",
                    ...styles?.outerStyle
                }}>
                <div style={{
                    height: "100%",
                    borderWidth: "2px", borderBottomColor: !isHourFocus ? "rgba(112, 112, 112, 0.2)" : THEME_YELLOW, borderBottomStyle: "solid",
                    flex: 2, display: "flex",
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                }}
                    onFocus={() => {
                        setIsHourFocus(true)
                    }}
                    onBlur={() => {
                        setIsHourFocus(false)
                    }}>
                    <span style={{
                        wordBreak: "keep-all", whiteSpace: "nowrap",
                        fontSize: "14px",
                    }}>{title}</span>
                    <input style={{
                        flex: 1, borderWidth: "0px",
                        outlineWidth: "0px",
                        height: "100%",
                        boxSizing: "border-box",
                        width: "0px",
                        paddingInlineStart: "8px", paddingInlineEnd: "8px",
                        textAlign: "right",
                        ...styles?.inputStyle
                    }}
                        readOnly={readonly}
                        type={type}
                        placeholder={placeholder}
                        value={hour}
                        onChange={(event) => {
                            const parseInput = event.target.value.replace(/[^0-9.]/g, "")
                            setHour(parseInput)
                            if (onChange)
                                onChange({ hour: parseInput, ratio: ratio })
                        }} />
                    <span>{`시간`}</span>
                </div>
                <div style={{
                    height: "100%", marginLeft: "16px",
                    borderWidth: "2px", borderBottomColor: !isRatioFocus ? "rgba(112, 112, 112, 0.2)" : THEME_YELLOW, borderBottomStyle: "solid",
                    flex: 1, display: "flex",
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                }}
                    onFocus={() => {
                        setIsRatioFocus(true)
                    }}
                    onBlur={() => {
                        setIsRatioFocus(false)
                    }}>
                    <input style={{
                        flex: 1, borderWidth: "0px",
                        outlineWidth: "0px",
                        height: "100%",
                        boxSizing: "border-box",
                        width: "0px",
                        paddingTop: "2px",
                        paddingInlineStart: "8px", paddingInlineEnd: "8px",
                        textAlign: "right",
                        ...styles?.inputStyle
                    }}
                        readOnly={readonly}
                        type={type}
                        placeholder={placeholder}
                        value={ratio}
                        onChange={(event) => {
                            const parseInput = event.target.value.replace(/[^0-9.]/g, "")
                            setRatio(parseInput)
                            if (onChange)
                                onChange({ hour: hour, ratio: parseInput })
                        }} />
                    <span>{`배`}</span>
                </div>
            </div>
        )
    }