import { collection, getDocs, query, where } from "firebase/firestore"
import { fireStore } from "../fireConfig"

/**
 * https://www.data.go.kr/data/15081808/openapi.do
 */
export interface NtsValidReq {
    b_no : string,
    start_dt : string,
    p_nm : string,
    p_nm2? : string,
    b_nm? : string,
    corp_no? : string,
    b_sector? : string,
    b_type? : string
}
export interface NtsStatus {
    b_no: string,
    b_stt: "계속사업자"|"휴업자"|"폐업자",
    b_stt_cd: "01"|"02"|"03",
    tax_type : string,
    tax_type_cd : string,
    end_dt : string,
    utcc_yn : string,
    tax_type_change_dt : string,
    invoice_apply_dt : string
}
/**
 * https://www.data.go.kr/data/15081808/openapi.do
 */
export interface NtsValidResp {
    status_code : "OK"|"TOO_LARGE_REQUEST"|"BAD_JSON_REQUEST"|"REQUEST_DATA_MALFORMED"|"INTERNAL_ERROR",
    request_cnt : number,
    valid_cnt : number,
    data: {
        b_no : string,
        valid: "01"|"02",
        valid_msg? : string,
        request_param: NtsValidReq,
        status?: NtsStatus,
    }[],
    description? : string
}
export const checkNts = async (businesses: NtsValidReq[])
    : Promise<NtsValidResp> => {
    const result = await fetch("http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=kY1o9%2FQykMdi9pDFkzYmLSO%2BHCuEWK%2BU1JM%2FKpmUKPKkJuhi1Jsle%2FqVRhZMWU7SRxWyNr878gtHuzpm4oId3A%3D%3D", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            businesses: businesses
        })
    })
    return await result.json()
}

export const checkNtsResult = async (req: NtsValidReq)
    : Promise<{
        result: number,
        status?: NtsStatus,
        description?: string
    }> => {
    const isDuplicate = await isDuplicateNts(req.b_no)
    if(isDuplicate){
        return {
            result : 2,
            description : "이미 가입된 사업자입니다."
        }
    }
    const { status_code, data, description } = await checkNts([req])
    if (status_code !== "OK") {
        return {
            result: 1,
            description: description
        }
    }
    const { valid, valid_msg, status } = data[0]
    if (valid !== "01") {
        return {
            result: 1,
            description: valid_msg
        }
    }
    const { b_stt, b_stt_cd } = status!
    if (b_stt_cd !== "01") {
        return {
            result: 1,
            description: `${b_stt}는 가입할 수 없습니다.`
        }
    }
    return {
        result: 0,
        status: status
    }
}
/**
 * duplicate = true, notDuplicate = false
 * @param bn 
 * @returns isDuplicate
 */
export const isDuplicateNts = async(bn : string) : Promise<boolean> => {
    const col = collection(fireStore,"users")
    const queryNts = query(col,where("bn","==",bn))
    const docs = await getDocs(queryNts)

    return !docs.empty
}