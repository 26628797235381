
import React, { ChangeEventHandler, CSSProperties, FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { PayInputType } from "../classes/PayStub";
import { THEME_YELLOW } from "../values/Colors";
import { parseLocale } from "./Tools";
import plus from "../images/manage/ic_plus@2x.png"

export const TitleInputUnit:
    FC<{
        id?: string,
        title? : string,
        value : string,
        readonly : boolean,
        styles? : {
            outerStyle? : CSSProperties,
            inputStyle? : CSSProperties,
            titleStyle? : CSSProperties
        },
        removeBorder? : boolean,
        titleStyle?: CSSProperties
        outerStyle?: CSSProperties
        type?: HTMLInputTypeAttribute,
        placeholder?: string,
        unit? : string,
        onChange? : ChangeEventHandler<HTMLInputElement>
        onBlur?: ()=>void
    }> =
    ({
        id,
        title,
        value,
        readonly,
        styles,
        removeBorder = false,
        titleStyle,
        outerStyle,
        type,
        placeholder,
        unit,
        onChange,
        onBlur
    }) => {
        const [isFocus, setIsFocus] = useState<boolean>(false)
        return (
            <div id={id}
                onFocus={() => {
                    setIsFocus(true)
                }}
                onBlur={() => {
                    setIsFocus(false)
                    onBlur && onBlur()
                }}
                style={{
                    display: "flex", flexDirection: "row",
                    // paddingTop: 16, paddingBottom: 16,
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                    fontFamily: "Notosans",
                    fontSize: "14px",
                    ...styles?.outerStyle,...outerStyle
                }}>
                <span style={{
                    wordBreak: "keep-all", whiteSpace: "nowrap",
                    fontSize: "14px",padding:"8px 0px",
                    ...styles?.titleStyle,...titleStyle
                }}>{title}</span>
                <div style={{
                    height:"100%",
                    flex: 1, display: "flex", alignItems:"center",
                    boxSizing: "border-box",
                    borderBottom : removeBorder ? "0px solid black" 
                        : isFocus 
                            ? `2px solid ${THEME_YELLOW}`  : `2px solid rgba(112, 112, 112, 0.2)`
                }}>
                    <input style={{
                        flex: 1, borderWidth: "0px",
                        outlineWidth: "0px",
                        boxSizing: "border-box",
                        width: "0px",
                        paddingTop: "2px",
                        paddingInlineStart: "8px", paddingInlineEnd: "8px",
                        textAlign: "right",
                        ...styles?.inputStyle
                    }}
                        readOnly={readonly}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange} />
                    {unit &&
                        <span>{unit}</span>}
                </div>
            </div>
        )
    }