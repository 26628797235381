import moment from "moment";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { MonthItem, MonthSet, MonthUnit } from "../classes/Month";
import { staffConverter, StaffWithId } from "../classes/Staff";
import {  UserWithId } from "../classes/User";
import { back, logo, menu, background, ic_coin_yellow, ic_staff_white, ic_staff_yellow, ic_coin_white, img_manage_store, ic_staff, ic_pay, ic_logout, ic_notice, img_manage_user, ic_user, ic_guide, staff_on, staff_off, pay_on, pay_off, user_on, user_off, service_on, service_off, notice_on, notice_off, ic_share, qna_on, qna_off } from "../images/images";
import styles from "../css/Main.module.css"
import { useMediaQuery } from 'react-responsive'
import { fireAuth } from "../fireConfig";
import { StaffManage } from "./StaffManage";
import { PayManage } from "./PayManage";
import { Info } from "./Info";
import { HowToUse } from "./HowToUse";
import { Notice } from "./Notice";
import { InfoModify } from "./InfoModify";
import { QnARouter } from "./QnARouter";

interface AndroidBridge {
    shareLink(url:string) : any
}

declare var native : AndroidBridge

export const Main: FC<{}> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const reduxState = useSelector((state: { user: UserWithId, staff: StaffWithId[], monthSet: MonthSet }) => state);
    const MENU_PADDING = "8px"
    const createTime = reduxState.user.data.createTime
    const btnStaffHover = useState<boolean>(false)
    const btnPayHover = useState<boolean>(false)
    const moveToStaffManage = () => {
        navigate("/manage-staff")
    }
    const moveToPayManage = () => {
        navigate("/manage-pay")
    }
    const openDrawer = () => {
        navigate("/drawer", {
            state: {
                backgroundLocation: [location],
                prePath: location.pathname,
            }
        })
    }
    // const currentPath = useMemo(()=>{
    //     console.log(location)
    // }, [location])
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    useEffect(() => {
        console.log(location.pathname)
        if (!isMobile && location.pathname === "/main") {
            navigate("/main/manage-staff")
        }
        if (isMobile && location.pathname === "/main/manage-staff") {
            navigate("/manage-staff", { replace: true })
        }
    },[isMobile])
    const pcTabList = [
        {
            title : "회사관리",
            icon : img_manage_store,
            children : [
                {
                    title : "직원관리",
                    path : "manage-staff",
                    ic_on :staff_on,
                    ic_off: staff_off
                },
                {
                    title : "급여관리",
                    path : "manage-pay",
                    ic_on :pay_on,
                    ic_off: pay_off
                },
                // {
                //     title : "고객 QnA",
                //     path : "qna",
                //     ic_on :pay_on,
                //     ic_off: pay_off
                // },
            ]
        },
        {
            title: "회원관리",
            icon: img_manage_user,
            children: [
                {
                    title: "회원정보",
                    path: "info",
                    ic_on: user_on,
                    ic_off: user_off
                },
                {
                    title: "이용안내",
                    path: "how-to-use",
                    ic_on: service_on,
                    ic_off: service_off
                },
                {
                    title: "공지사항",
                    path: "notice",
                    ic_on: notice_on,
                    ic_off: notice_off
                },
                {
                    title: "Q&A",
                    path: "qna/0",
                    ic_on: qna_on,
                    ic_off: qna_off
                },
            ]
        }
    ]
    return isMobile ?
        <div className="parent-div">
            <img className={styles.background_img} src={background} />
            <div className={styles.main_ctn}>
                <div className={styles.header_ctn}>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                        onClick={openDrawer} >
                        <img
                            src={menu} />
                    </div>
                    <div className={styles.header_center}>
                        <img src={logo} />
                    </div>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                        onClick={async () => {
                            var url = "https://norang.kr"
                            try {
                                if (navigator.share)
                                    navigator.share({
                                        url: url
                                    })
                                else {
                                    native.shareLink(url)
                                }
                            } catch (error) {
                                console.log(error)
                                // alert("지원하지 않는 브라우저입니다.\n크롬,사파리와 같은 웹 전용 앱을 이용해주세요.")
                            }
                        }} >
                        <img
                            src={ic_share} />
                    </div>
                </div>
                <div className={styles.body_ctn}>
                    <div className={styles.top_ctn}>
                        <div
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center",
                            }}>
                            <span style={{
                                paddingRight: "8px",
                            }}>회사명</span>
                            <span style={{ fontWeight: 500 }}>{reduxState.user.data.companyName}</span>
                        </div>
                        <div
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center", justifyContent: "space-between"
                            }}>
                            <div style={{
                                display: "flex", flexDirection: "row"
                            }}>
                                <span style={{
                                    paddingRight: "8px",
                                }}>현재 보유 직원</span>
                                <span style={{ fontWeight: 500 }}>{`${reduxState.staff.length}명`}</span>
                            </div>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                color: "#767676", fontSize: "12px"
                            }}>
                                <span>{createTime ? moment(createTime.toDate()).format("YYYY - MM - DD 등록") : "알 수 없음"}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mid_hori_line} />
                    <div style={{
                        padding: "0px 12px",
                        display: "flex", justifyContent: "space-evenly"
                    }}>
                        <div className={`pointer-cursor ${styles.btn_main}`}
                            onMouseEnter={() => {
                                btnStaffHover[1](true)
                            }}
                            onMouseLeave={() => {
                                btnStaffHover[1](false)
                            }}
                            onClick={moveToStaffManage}>
                            <img
                                src={btnStaffHover[0] ? ic_staff_white : ic_staff_yellow}
                                style={{
                                    height: "25%", width: "auto",
                                    maxHeight: "calc(180px * 0.25)",
                                }} />
                            <span style={{ fontSize: "14px" }}>직원관리</span>
                        </div>
                        <div className={`pointer-cursor ${styles.btn_main}`}
                            onMouseEnter={() => {
                                btnPayHover[1](true)
                            }}
                            onMouseLeave={() => {
                                btnPayHover[1](false)
                            }}
                            onClick={moveToPayManage}>
                            <img
                                src={btnPayHover[0] ? ic_coin_white : ic_coin_yellow}
                                style={{
                                    height: "25%", width: "auto",
                                    maxHeight: "calc(180px * 0.25)",
                                }} />
                            <span style={{ fontSize: "14px" }}>급여관리</span>
                        </div>
                    </div>
                </div>

                <div className={styles.foo_ctn}>
                    <p className={styles.foo_p}>
                        노랑봉투<br />
                        <span className={styles.foo_span}>
                            {`주식회사 플로션`}<br />
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br />
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br />
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br />
                        </span>
                    </p>
                </div>
            </div>
        </div>
        : <div className="parent-div">
            <div className={styles.main_ctn}>
                <div className={styles.header_ctn}
                    style={{ borderBottom: "1px solid #EADFDB" }}>
                    <div className="pointer-cursor" style={{
                        height: "100%", width: "auto",
                        marginLeft: "36px", boxSizing: "border-box",
                        display: "flex", alignItems: "center"
                    }}
                        onClick={()=>{
                            navigate("")
                        }}>
                        <img src={logo} style={{ height: "80%", width: "auto" }} />
                    </div>
                    <div style={{
                        marginRight: "36px", boxSizing: "border-box",
                        display: "flex", alignItems: "center",
                        fontSize: "16px"
                    }} >
                        <span style={{ marginRight: "16px" }}>
                            <span style={{ fontWeight: 500, marginRight: "8px" }}>{`회사명`}</span>
                            <span>{`${reduxState.user.data.companyName}`}</span>
                        </span>
                        <span style={{ marginRight: "32px" }}>
                            <span style={{ fontWeight: 500, marginRight: "8px" }}>{`직원수`}</span>
                            <span>{`${reduxState.staff.length}명`}</span>
                        </span>
                        <span className="pointer-cursor" style={{ fontWeight: 500, color: "#F46767" }}
                            onClick={async () => {
                                await fireAuth.signOut()
                                navigate("/login", { replace: true })
                            }}>{`로그아웃`}</span>
                    </div>
                </div>
                <div style={{
                    flex: 1,display: "flex",overflowY:"hidden"
                }}>
                    <div //TabContainer
                        style={{
                            borderRight:"1px solid #D0D0D0",
                            display: "flex", flexDirection: "column",boxSizing:"border-box"
                        }}>
                        {pcTabList.map((ctnValue,index1) => {
                            const {title,icon,children} = ctnValue
                            return (
                                <div key={`pcTabList_parent_${index1}`} className={styles.tab_container} id={`tab-parent-${index1}`}>
                                    <div className={styles.tab_title_ctn}>
                                        <img className={styles.tab_title_icon} src={icon} alt={"building"} />
                                        <span style={{ padding: MENU_PADDING }}>
                                            {title}
                                        </span>
                                    </div>
                                    {children.map((child,index2) => {
                                        const {title,path,ic_off,ic_on} = child
                                        const isCurrent = path.includes("qna") 
                                            ? location.pathname.includes("/qna")
                                            : location.pathname.includes("/"+path)
                                        return (
                                            <div key={`pcTabList_child_${index2}`} 
                                                className={styles.tab_item_ctn} id={`tab-child-${index1}-${index2}`}
                                                style={{
                                                    backgroundColor: isCurrent ? "#FDCA56" : "white"
                                                }}>
                                                <img className={styles.tab_item_icon} src={isCurrent ? ic_on : ic_off} />
                                                <span className="pointer-cursor"
                                                    style={{color: isCurrent ? "#FFFFFF" : "#9B9B9B"}}
                                                    onClick={() => {
                                                        navigate(path)
                                                    }}>
                                                    {title}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    {/* <div style={{flex:1 ,border:"1px solid black"}}> */}
                    <Routes>
                        <Route path="*" element={<Navigate replace to="manage-staff"/>}/>
                        <Route path="manage-staff" element={<StaffManage />} />
                        <Route path="manage-pay/*" element={<PayManage />} />
                        <Route path="info" element={<Info />} />
                        <Route path="info-modi" element={<InfoModify />} />
                        <Route path="how-to-use" element={<HowToUse />} />
                        <Route path="notice" element={<Notice />} />
                        <Route path="qna/*" element={<QnARouter />} />
                    </Routes>
                    {/* </div> */}
                </div>
            </div>
        </div>
}
