import React, { FC,  useEffect,  } from "react";
import exit from "../images/menu/exit@2x.png"
import { useLocation, useNavigate } from "react-router-dom";
import { fireAuth} from "../fireConfig";
import { useSelector } from "react-redux";
import { User, UserWithId } from "../classes/User";
import styles from "../css/Drawer.module.css"
import { ic_guide, ic_home_menu, ic_logout, ic_notice, ic_pay, ic_qna, ic_staff, ic_user, img_letter, img_manage_store, img_manage_user, logo, qna_on } from "../images/images";
export const Drawer: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as { backgroundLocation: Location[] };
    const reduxState = useSelector((state: { user: UserWithId }) => state.user)
    const MENU_PADDING = "8px"
    const onDismiss = () => {
        navigate(-1)
    }
    const movePageIncludeMenu = (targetPage: "/main" | "/manage-staff" | "/manage-pay") => {
        console.log(state.backgroundLocation)
        if (state.backgroundLocation[0].pathname === targetPage) {
            navigate(-1)
        } else {
            console.log("target", targetPage)
            navigate(targetPage, { replace: true })
        }
    }
    return (
        <div className={styles.drawer_ctn}>
            <div className={styles.background_ctn}
                onClick={onDismiss}>
                <div className={styles.body_ctn}
                    onClick={(e) => { e.stopPropagation() }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }} >
                        <div className={styles.top_btn_ctn}>
                            <div style={{ display: "flex", alignItems: "center" }}
                                onClick={() => {
                                    const path = "/main"
                                    movePageIncludeMenu(path)
                                }}>
                                <img className={`pointer-cursor ${styles.top_side_icon}`}
                                    style={{
                                        height:"30px"
                                    }}
                                    src={logo} alt={"close"} />
                                <span style={{
                                    fontSize: "12px",color: "#6A6A6A",alignSelf:"flex-end",marginLeft:"8px"
                                }}>v1.0.3</span>
                            </div>
                            <img className={`pointer-cursor ${styles.top_side_icon}`}
                                src={exit} alt={"close"}
                                onClick={onDismiss} />
                        </div>
                        <div className={styles.top_user_id}>
                            <span className="name_deco">{reduxState.data.name}</span>
                            <span style={{
                                fontSize: "18px"
                            }}><span style={{ fontWeight: "bold", marginInlineEnd: "12px" }}>{` 님`}</span>{`반갑습니다`}</span>
                        </div>
                        {/* tab1 */}
                        <div className={styles.tab_container}>
                            <div className={styles.tab_title_ctn}>
                                <img className={styles.tab_title_icon} src={img_manage_store}  alt={"building"}/>
                                <span style={{ padding: MENU_PADDING }}>
                                    회사관리
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_staff}/>
                                <span className="pointer-cursor"
                                    onClick={() => {
                                        const path = "/manage-staff"
                                        movePageIncludeMenu(path)
                                    }}>
                                    직원관리
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_pay}/>
                                <span className="pointer-cursor"
                                    onClick={() => {
                                        const path = "/manage-pay"
                                        movePageIncludeMenu(path)
                                    }}>
                                    급여관리
                                </span>
                            </div>
                            {/* <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_pay}/>
                                <span className="pointer-cursor"
                                    onClick={() => {                                        
                                        navigate("/qna", { replace: true })
                                    }}>
                                    고객 QnA
                                </span>
                            </div> */}
                        </div>
                        {/* tab2 */}
                        <div className={styles.tab_container}>
                            <div className={styles.tab_title_ctn}>
                                <img className={styles.tab_title_icon} src={img_manage_user}  alt={"member"}/>
                                <span style={{ padding: MENU_PADDING }}>
                                    회원관리
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_user}/>
                                <span className="pointer-cursor"
                                    onClick={() => {
                                        navigate("/info", { replace: true })
                                    }}>
                                    회원정보
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_guide}/>
                                <span className="pointer-cursor"
                                    onClick={() => {
                                        navigate("/how-to-use", { replace: true })
                                    }}>
                                    이용안내
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}>
                                <img className={styles.tab_item_icon} src={ic_notice}/>
                                <span className="pointer-cursor"
                                    onClick={() => {
                                        console.log("Notice")
                                        navigate("/notice", { replace: true })
                                    }}>
                                    공지사항
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}
                                style={{
                                    cursor:"pointer"
                                }}>
                                <img className={styles.tab_item_icon} src={ic_qna}/>
                                <span className="pointer-cursor"
                                    onClick={async () => {
                                        navigate("/qna/0", { replace: true })
                                    }}>
                                    {`Q&A`}
                                </span>
                            </div>
                            <div className={styles.tab_item_ctn}
                                style={{
                                    cursor:"pointer"
                                }}>
                                <img className={styles.tab_item_icon} src={ic_logout}/>
                                <span className="pointer-cursor"
                                    onClick={async () => {
                                        await fireAuth.signOut()
                                        navigate("/login", { replace: true })
                                    }}>
                                    로그아웃
                                </span>
                            </div>
                            {/* <div style={{
                                display: "flex", alignItems: "center"
                            }}>
                                <img style={{ width: "30px"}} />
                                <span style={{ fontSize: "18px",padding:MENU_PADDING }}
                                    onClick={()=>[

                                        navigate("/dialog/delete-user",{
                                            state : {
                                                backgroundLocation : [...state.backgroundLocation,location]
                                            }
                                        })
                                    ]}>
                                    - 서비스 탈퇴 및 해지
                                </span>
                            </div> */}
                        </div>
                        <img className={styles.footer}
                            src={img_letter}
                            onClick={(e) => {
                                console.log("Letter")
                                e.stopPropagation()
                            }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

// const styles: { [key: string]: CSSProperties } = {
//     header_side_ctn: {
//         height: "100%", width: "auto", aspectRatio: "1",
//         padding: "12px 5% 12px 12px", boxSizing: "border-box",
//         // border: "1px solid black",
//         display: "flex", alignItems: "center", justifyContent: "center"
//     },
//     btn_type: {
//         borderRadius: "4px",
//         display: "flex", alignItems: "center", justifyContent: "center"
//     },
//     input_box: {
//         height: "48px", boxSizing: "border-box",
//         backgroundColor: "#F5F4F1",
//         borderWidth: "0px", borderRadius: "8px",
//         outlineWidth: "0px", fontFamily: "NotoSans",
//         padding: "0px 16px 0px 16px"
//     }
// }