import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import React, { FC, SetStateAction, useMemo, useRef, useState } from "react";
import { CSSProperties } from "react";
import { useNavigate,Link, useLocation, Routes, Route } from "react-router-dom";
import { Staff, staffConverter, StaffWithId } from "../classes/Staff";
import { fireAuth, fireStore } from "../fireConfig";
import ic_check from "../images/manage-pay/ic_check@2x.png"
import menu from "../images/common/menu@2x.png"
import arrow_down from "../images/manage/ic_arrow_down@2x.png"
import { DONE, FIVE_PER, PAY_MANAGEMENT, STAFF_MANAGEMENT } from "../values/strings";
import { useDispatch, useSelector } from "react-redux";
import { User, UserWithId } from "../classes/User";
import { changeSendState, changeState, deleteUsersRedux, getStaffList } from "../redux/reducer/staff/staff";
import { MonthItem, MonthSet, MonthUnit } from "../classes/Month";
import { PayStub, payStubConverter } from "../classes/PayStub";
import styles from "../css/PayManage.module.css"
import { ic_kakao, ic_pay, ic_send } from "../images/images";
import { PayList } from "../components/PayList";
import { SelectMonth } from "../components/SelectMonth";
import { useBlocker } from "../components/react-router-dom-revive";
import { PayRouter } from "./PayRouter";
import { SendDialog } from "../components/SendDialog";
import { PayViewRouter } from "./PayViewRouter";
import { selectMonthItem } from "../redux/reducer/month-set/month-set";
import { ServiceBanner } from "../components/ServiceBanner";

export const PayManage: FC = () => {
    const [sendDialog,setSendDialog] = useState<boolean>(false)
    const [sendTargetList, setSendTargetList] = useState<StaffWithId[]>([]);
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();
    const location = useLocation();
    const reduxState = useSelector((state: { user: UserWithId, staff: StaffWithId[], monthSet : MonthSet,isMobile:boolean }) => state);
    const isMobile: boolean = reduxState.isMobile
    const staffList: StaffWithId[] = reduxState.staff
    const filter = (staffList: StaffWithId[], type: number[]): StaffWithId[] => {
        return staffList.filter(({data}) => {
            return type.includes(data.hireType.type)
        })
    }
    const employees: StaffWithId[] = useMemo(() => filter(staffList, [0]), [staffList]);
    const partTimeJob: StaffWithId[] = useMemo(() => filter(staffList, [1,2]), [staffList]);

    const monthSetList : MonthItem[] = reduxState.monthSet.monthList
    const selectMonth : MonthItem | undefined = reduxState.monthSet.selected ?? monthSetList[0]
    const selectMonthToPattern = useMemo(()=>selectMonth?.value?.year+"-"+selectMonth?.value?.month,[selectMonth])
    const headers = isMobile
        ? [
            {
                title: "순번",
                flex: 1
            },
            {
                title: "이름",
                flex: 4
            },
            {
                title: "등록",
                flex: 4
            },
            {
                title: "열람",
                flex: 4
            },
            {
                title: "상태",
                flex: 4
            },
            {
                title: "checked",
                flex: 3
            }
        ]
        : [
            {
                title: "순번",
                flex: 2
            },
            {
                title: "이름",
                flex: 4
            },
            {
                title: "직급",
                flex: 4
            },
            {
                title: "등록",
                flex: 4
            },
            {
                title: "열람",
                flex: 4
            },
            {
                title: "상태",
                flex: 4
            },
            {
                title: "checked",
                flex: 3
            }
    ]
    const openDrawer = () => {
        navigate("/drawer", {
            state: {
                backgroundLocation: [location],
                prePath : location.pathname,
            }
        })
    }

    const changeStaffState = (staff : StaffWithId) => {
        const index = staffList.indexOf(staff)
        const nextState = !staff.data.state
        reduxDispatch(changeState({index,nextState}))
    }

    const getCheckedStaffList = () : {staffWithId:StaffWithId,index : number}[] => {
        return staffList.map((staffWithId,index)=>{
            return {
                staffWithId : staffWithId,
                index : index
            }
        }).filter((staffWithIndex)=>{
            return staffWithIndex.staffWithId.data.state
        })
    }
    const [onProcess, setOnProcess] = useState(false)
    const sendPayStub = async (staffWithIndexList: { staffWithId: StaffWithId, index: number }[], targetMonth: string) => {
        if (staffWithIndexList.length > 0) {
            setSendTargetList(staffWithIndexList.map(({staffWithId})=> staffWithId))
            setOnProcess(true)
            try {
                await Promise.all(
                    staffWithIndexList.map(async (staffWithIndex) => {
                        const [year, month] = targetMonth.split("-")
                        const body = JSON.stringify({
                            phoneNumber: staffWithIndex.staffWithId.data.phone.replaceAll("-", ""),
                            companyName: reduxState.user.data.companyName ?? "",
                            staffName: staffWithIndex.staffWithId.data.name,
                            year: parseInt(year),
                            month: parseInt(month) + 1,
                            link: `https://norang.kr/browse?id=${staffWithIndex.staffWithId.id}&month-set=${targetMonth}`
                        })

                        const result = await fetch("https://asia-northeast3-nobong-79c64.cloudfunctions.net/sendKakaoAt", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: body,
                        })
                        console.log(await result.text())
                        const docRef = doc(fireStore, "staffs", staffWithIndex.staffWithId.id, "payStub", targetMonth).withConverter(payStubConverter)
                        await updateDoc(docRef, {
                            isSend: true
                        })
                        reduxDispatch(changeSendState({ targetMonth: targetMonth, staffIndex: staffWithIndex.index }))
                    }))
                resetToFalseAll()
                setSendDialog(!sendDialog)
                setOnProcess(false)
            } catch (error) {
                setOnProcess(false)
            }
        }
    }

    const resetToFalseAll = () => {
        const copyListArray = staffList.slice()
        copyListArray.forEach((staff : StaffWithId)=>{
            staff.data.state = false;
        })
        reduxDispatch(getStaffList(copyListArray))
    }
    const [selectModal, setSelectModal] = useState<boolean>(false)
    useBlocker(()=>{
        setSelectModal(!selectModal)
    }, selectModal)
    const MobileBody = () => (
        <div className={styles.main_ctn}>
            <div className={styles.header_ctn}>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                    onClick={openDrawer} >
                    <img
                        src={menu} />
                </div>
                <div className={styles.header_center}>
                    <img src={ic_pay} />
                    <span >{PAY_MANAGEMENT}</span>
                </div>
            </div>
            <div className={styles.body_ctn}>
                <div className={styles.top_ctn}>
                    {selectMonth &&
                        <div className="pointer-cursor"
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            onClick={() => {
                                setSelectModal(!selectModal)
                            }}>
                            <span style={{ fontSize: "13px", fontWeight: 500 }}>
                                {selectMonth.text}
                            </span>
                            <img src={arrow_down} style={{ height: "16px" }} />
                        </div>
                    }
                    <div className={`pointer-cursor ${styles.send_ctn}`}
                        onClick={() => {
                            if (!onProcess)
                                sendPayStub(getCheckedStaffList(), selectMonthToPattern)
                        }}>
                        <img src={ic_kakao} />
                        <span>{`카카오톡 발송하기`}</span>
                    </div>
                </div>
                {/* 테이블 */}
                <div style={{
                    flex: 1, display: "flex", flexDirection: "column",
                }}>
                    {/* table header */}
                    <div className={styles.row_ctn}
                        style={{
                            padding: "0px 5%",
                            height: "32px"
                        }}>
                        {headers.map((value, index) => {
                            const { title, flex } = value
                            if (index != headers.length - 1)
                                return (
                                    <div key={`header_cell_${title}_${index}`}
                                        className={`${styles.row_cell} ${styles.header_cell}`}
                                        style={{
                                            flex: flex,
                                        }}>{title}</div>
                                )
                            else
                                return (
                                    <div key={`header_cell_${title}_${index}`}
                                        className={`${styles.row_cell} ${styles.header_cell}`}
                                        style={{
                                            flex: flex,
                                            display: "flex", justifyContent: "center"
                                        }}>
                                        <img src={ic_check} style={{ width: "20px" }} />
                                    </div>
                                )
                        })}
                    </div>
                    {/* table-body */}
                    <div style={{
                        flex: 1,
                        display: "flex", flexDirection: "column"
                    }}>
                        {/* table-employee */}
                        {[
                            { list: employees },
                            { list: partTimeJob }
                        ].map((listSet, index) => {
                            const { list } = listSet
                            return (
                                //Each List Start Point
                                <PayList
                                    isMobile={isMobile}
                                    list={list}
                                    selectMonth={selectMonth}
                                    selectMonthToPattern={selectMonthToPattern}
                                    staffList={staffList}
                                    isEmployee={index}
                                    headers={headers}
                                    changeStaffState={changeStaffState} />
                            )
                        })}
                    </div>
                </div>
            </div>
            
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                <ServiceBanner type={0} />
            </div>
        </div>
    )
    const NoMobileBody = () => (
        <div className={styles.main_ctn}
            style={{ maxWidth: "2400px" }}>
            <div className={styles.header_ctn}>
                <div className={`${styles.header_side_ctn} ${styles.left}`}
                    style={{ paddingLeft: "2.5%" }}>
                    <img src={ic_pay} />
                    <span style={{ fontWeight: "bold" }}>{PAY_MANAGEMENT}</span>
                    <div className={styles.top_ctn}
                        style={{
                            marginLeft: "24px"
                        }}>
                        {selectMonth &&
                            <div className="pointer-cursor"
                                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                onClick={() => {
                                    setSelectModal(!selectModal)
                                }}>
                                <span style={{ fontSize: "14px", fontWeight: 500 }}>
                                    {selectMonth.text}
                                </span>
                                <img src={arrow_down} style={{ height: "16px" }} />
                            </div>
                        }
                    </div>
                </div>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                    style={{ paddingRight: "2.5%" }}>
                    <div className={`pointer-cursor ${styles.send_ctn}`}
                        style={{
                            height: "42px", padding: "0px 16px"
                        }}
                        onClick={() => {
                            if (!onProcess)
                                sendPayStub(getCheckedStaffList(), selectMonthToPattern)
                        }}>
                        <img src={ic_kakao} />
                        <span style={{ fontSize: "14px" }}>{`카카오톡 발송하기`}</span>
                    </div>
                </div>
            </div>
            <div className={styles.body_ctn}
                style={{
                    overflowY: "hidden"
                }}>
                {/* table-body */}
                <div style={{
                    flex: 1,
                    overflow: "hidden",
                    display: "flex", flexDirection: "column"
                }}>
                    {/* table-body */}
                    <div style={{
                        overflow: "hidden",
                        display: "flex", flexDirection: "row"
                    }}>
                        {/* table-employee */}
                        {[
                            { list: employees },
                            { list: partTimeJob }
                        ].map((listSet, index) => {
                            const { list } = listSet
                            const middleLine: CSSProperties = employees.length > partTimeJob.length
                                ? index === 0 ? { borderRight: "1px solid #E0E0E0" } : {}
                                : index === 1 ? { borderLeft: "1px solid #E0E0E0" } : {}
                            return (
                                //Each List Start Point
                                <PayList
                                    key={`paymanage_nomobile_${index}`}
                                    isMobile={isMobile}
                                    middleLine={middleLine}
                                    list={list}
                                    selectMonth={selectMonth}
                                    selectMonthToPattern={selectMonthToPattern}
                                    staffList={staffList}
                                    isEmployee={index}
                                    headers={headers}
                                    changeStaffState={changeStaffState} />
                            )
                        })}
                    </div>
                </div>
            </div>
            
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                <ServiceBanner type={0} style={{ width: "45%" }} />
                <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} />
            </div>
        </div>
    )
    return (
        <Routes>
            {/* {isMobile &&  */}
            <Route path="" element={
                <div className={isMobile ? "parent-div" : "parent-div-per"}>
                    <SendDialog
                        isMobile={isMobile}
                        open={sendDialog}
                        targetList={sendTargetList}
                        onClose={() => { setSendDialog(!sendDialog) }} />
                    <SelectMonth
                        isOpen={selectModal}
                        monthSet={reduxState.monthSet}
                        onSelectMonth={(item)=>{
                            reduxDispatch(selectMonthItem(item))
                            navigate(-1)
                        }}
                        onRequestClose={() => { setSelectModal(!selectModal) }} />
                    {isMobile ? <MobileBody /> : <NoMobileBody />}
                </div>} />
            <Route path="pay" element={<PayRouter/>} />
            <Route path="pay-view" element={<PayViewRouter/>} />
        </Routes>
    )
}
