import { BannerWithId } from "../../../classes/Banner";
import { HTU } from "../../../classes/HTU";
import { SAVE_HTU } from "./howtouse.type";

const initialState : HTU[] = [] 

export const saveHtu = (data : HTU[] | undefined) => {
    return {
        type : SAVE_HTU,
        payload : data
    }
}
export const htuReducer = (state: HTU[] = initialState, action: { type: string, payload?: any }): HTU[] => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case SAVE_HTU:
            return action.payload;
        default:
            break;
    }
    return state
}