
import React, { CSSProperties, FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { PayInputType } from "../classes/PayStub";
import { THEME_YELLOW } from "../values/Colors";
import { parseLocale } from "./Tools";
import plus from "../images/manage/ic_plus@2x.png"
import { ic_minus } from "../images/images";

export const MonthlyPaymentInput:
    FC<{
        id?: string,
        values : PayInputType,
        readonly : boolean,
        styles? : {
            outerStyle? : CSSProperties,
            inputStyle? : CSSProperties,
            titleStyle? : CSSProperties
        },
        type? : HTMLInputTypeAttribute,
        placeholder? : string,
        unit? : string,
        onChange? : (value : string) => void
        onClickDelete? : () => void
    }> =
    ({
        id,
        values : {title,value : initValue,type : valueType},
        readonly,
        styles,
        type,
        placeholder,
        unit = "원",
        onChange,
        onClickDelete
    }) => {
        const [isFocus, setIsFocus] = useState<boolean>(false)
        const [value, setValue] = useState(initValue)
        useEffect(()=>{
            setValue(initValue)
        },[initValue])
        return (
            <div id={id}
                onFocus={() => {
                    setIsFocus(true)
                }}
                onBlur={() => {
                    setIsFocus(false)
                }}
                style={{
                    borderWidth: "2px", borderBottomColor: !isFocus ? "rgba(112, 112, 112, 0.2)" : THEME_YELLOW, borderBottomStyle: "solid",
                    display: "flex", flexDirection: "row",
                    minHeight: "52px",
                    // paddingTop: 16, paddingBottom: 16,
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                    fontFamily: "Notosans",
                        fontSize: "14px",
                        ...styles?.outerStyle
                }}>
                {valueType === "add" && !readonly &&
                    <img src={ic_minus}
                        style={{ height: "32px", width: "auto",marginRight:"8px" }} 
                        onClick={onClickDelete}/>}
                <span style={{
                    wordBreak: "keep-all", whiteSpace: "nowrap",
                    fontSize:"14px",...styles?.titleStyle
                }}>{title}</span>
                <div style={{
                    flex: 1, display: "flex",
                    boxSizing: "border-box",
                }}>
                    <input style={{
                        flex: 1, borderWidth: "0px",
                        outlineWidth: "0px",
                        height: "100%",
                        boxSizing: "border-box",
                        width: "0px",
                        paddingTop: "2px",
                        paddingInlineStart: "8px", paddingInlineEnd: "8px",
                        textAlign: "right",
                        ...styles?.inputStyle
                    }}
                        readOnly={readonly}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={(event) => {
                            if (onChange) {
                                const parseInput = parseLocale(event.target.value)
                                setValue(parseInput)
                                onChange(parseInput)
                            }
                        }} />
                </div>
                <span>{unit}</span>
            </div>
        )
    }