import React, {FC, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../images/images";
import { DARK_GRAY, GRAY, RED, THEME_YELLOW } from "../../values/Colors";
import { FIVE_PER, PLEASE_INPUT_PHONE_TYPE } from "../../values/strings";
import icon_delete from "../../images/login/btn_delete@2x.png"
import { fireStore } from "../../fireConfig";
import { doc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AccessKey, saveAccessKeyList } from "../../redux/reducer/accesskey/accesskey";

export const AdminLogin : FC = () => {
    const naviagate = useNavigate()
    const pwRef = useRef<HTMLInputElement>(null)
    const [id, setId] = useState<string>("")
    const [pw, setPw] = useState<string>("")
    const [errorCode, setErrorCode] = useState<number>(0)
    const reduxDispatch = useDispatch()
    const adminKeyList = useSelector((state : {accessKey : AccessKey[]}) => state.accessKey)
    // const [adminKeyList,setAdminKeyList] = useState<{
    //     title : string,
    //     auth : number,
    //     value : string
    // }[]>([])

    useEffect(()=>{
        getAccessCodeFromDB()
    },[])

    const getAccessCodeFromDB = async () => {
        const docRef = doc(fireStore,"accessCode/access_code")
        const docGet = (await getDoc(docRef)).data()
        reduxDispatch(saveAccessKeyList(docGet!.code))
    }

    const accessAdmin = (id: string, pw: string) => {
        const idIndex = adminKeyList.findIndex(({ value,title }) =>
            title === id
        )
        if (idIndex < 0) {
            setErrorCode(1)
            return
        }
        const adminKeyNValue = adminKeyList[idIndex]
        if(adminKeyNValue.value !== pw){
            setErrorCode(2)
            return
        }
        const { auth } = adminKeyNValue
        naviagate("", {
            replace: true, state: {
                auth_level: auth
        }})
    }
    const resetErrorCode = () => {
        setErrorCode(0)
    }
    return (
        <div className="parent-div">
            <div style={{
                width: "100%",
                // maxWidth: "400px", 
                height: "100%",
                // padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                display: "flex", flexDirection: "column",
                alignItems: "center"
            }}>
                {/* <div style={{ flex: 1 }} /> */}
                <div style={{
                    flex: 1,
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                    width: "100%",
                    display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    {/* <div> */}
                    <img src={logo} style={{
                        width: "30%", alignSelf: "center",
                        maxWidth: "200px",
                        marginBottom: 48
                    }} />
                    {/* inputCtn */}
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                            alignItems: "center"
                        }}>
                            <input
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={id}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") pwRef.current?.focus()
                                }}
                                onChange={(e) => {
                                    setId(e.target.value)
                                    resetErrorCode()
                                }}
                                placeholder="아이디" />
                            {id
                                && <img src={icon_delete}
                                    style={{ height: "18px" }}
                                    onClick={() => {
                                        setId("")
                                        resetErrorCode()
                                    }} /> }
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode == 1
                                ? "등록되지 않은 아이디입니다."
                                : "\u200b"}</span>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                            alignItems: "center"
                        }}>
                            <input
                                ref={pwRef}
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={pw}
                                type="password"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") accessAdmin(id,pw)
                                }}
                                onChange={(e) => {
                                    setPw(e.target.value)
                                    resetErrorCode()
                                }}
                                placeholder="비밀번호" />
                            {pw
                                && <img src={icon_delete}
                                    style={{ height: "18px" }}
                                    onClick={() => {
                                        setPw("")
                                        resetErrorCode()
                                    }} /> }
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode == 2
                                ? "비밀번호가 일치하지 않습니다"
                                : "\u200b"}</span>
                    </div>
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            width: "100%", maxWidth: "400px",
                            padding: "12px",
                            marginTop: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={() => { accessAdmin(id,pw) }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{"접속하기"}</span>
                    </div>
                </div>
                <div style={{
                    width: "100%",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,boxSizing:"border-box",
                    backgroundColor:"rgba(245, 244, 241, 0.85)",
                }}>
                    <p style={{fontFamily:"Notosans",fontSize:"12px",color:DARK_GRAY,fontWeight:500}}>
                        노랑봉투<br/>
                        <span style={{fontSize:"10px",fontWeight:"normal"}}>
                            {`주식회사 플로션`}<br/>
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br/>
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br/>
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br/>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}