import { FC, useEffect, useState } from "react";
import styles from "../../../css/QnA/QnAEdit.module.css"
import { CONTENTS, QNA_ANSWER, TITLE } from "../../../values/strings";
import ReactModal from 'react-modal'
import PulseLoader from 'react-spinners/PulseLoader'
import { THEME_YELLOW } from "../../../values/Colors";
import { useNavigate, useParams } from "react-router-dom";
import { QnA } from "../../../classes/QnA";
import { doc, DocumentReference, getDoc, Timestamp } from "firebase/firestore";
import { fireStore } from "../../../fireConfig";
import ReactQuill from "react-quill";
import { deleteAnswer, pushAnswer, updateQnA } from "../../../functions/firebaseTools";
import { useRef } from "react";
import { deleteQnARef } from "../../../redux/reducer/qna/qna";
import { useDispatch } from "react-redux";

export const AdminQnAView: FC = () => {
    const navigate = useNavigate();
    const reduxDispatch = useDispatch()
    const { id } = useParams<"id">();
    const [loadingModal, setLoadingModal] = useState<boolean>(false)

    const [title, setTitle] = useState<string>("")
    const [contents, setContents] = useState<string>("")
    const [answerHtml, setAnswerHtml] = useState<string>("")
    const [qnaOriginData, setQnAOriginData] = useState<QnA | undefined>(undefined)

    const quillRef = useRef<ReactQuill>(null)
    
    useEffect(() => {
        if (id)
            getQnAData(id)
    }, [id])

    const goBack = () => {
        navigate(-1)
    }

    const getQnAData = async (id: string) => {
        const docRef = doc(fireStore, `qna/${id}`) as DocumentReference<QnA>
        const snapshot = await getDoc(docRef)
        const qnaData = snapshot.data()!
        setQnAOriginData(qnaData)
        setTitle(qnaData.questionTitleHtml)
        setContents(qnaData.questionContentsHtml)
        setAnswerHtml(qnaData.answer?.answerHtml ?? "")
    }

    const finishAnswer = async () => {
        console.log(quillRef.current?.unprivilegedEditor?.getLength())
        if (id && quillRef.current) {
            setLoadingModal(true)
            try {
                if ((quillRef.current?.unprivilegedEditor?.getLength() ?? 0) < 1) {
                    alert("답변을 작성해주세요.")
                    throw new Error()
                }
                await pushAnswer(id, {
                    answerHtml: answerHtml,
                    answerTime: Timestamp.now()
                })
                goBack()
            } catch (error) {
                setLoadingModal(false)
            }
        }
    }

    const deleteA = async () => {
        setLoadingModal(true)
        if (id) {
            if (window.confirm("삭제 후 되돌릴 수 없습니다\n삭제하시겠습니까?")){

                const ref = await deleteAnswer(id)
                if (ref) {
                    reduxDispatch(deleteQnARef(ref))
                    goBack()
                }
            }
        }
        setLoadingModal(false)
    }
    
    return (
        <div className={"parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <ReactModal
                isOpen={loadingModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                    },
                    content: {
                        position: "relative", inset: "0px",
                        verticalAlign: "center",
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                        borderWidth: "0px", backgroundColor: "transparent"
                    }
                }}>
                <PulseLoader color={THEME_YELLOW} size={14} margin={8} speedMultiplier={1} />
            </ReactModal>
            <div className={styles.main_ctn}
                style={{
                    padding: "30px 2.5%", maxWidth: "2400px",
                    display: "flex",
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box"
                }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center",
                    fontSize: "14px", marginBottom: "12px",
                }}>
                    <span style={{
                        fontSize: "22px", fontWeight: 500,
                    }}>{QNA_ANSWER}</span>
                    <div style={{
                        borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "#757575", border: "1px solid #757575",
                        marginRight: "14px",
                        cursor: "pointer"
                    }} onClick={deleteA}>
                        <span >{"삭제"}</span>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    boxSizing: "border-box", 
                    overflow: "hidden", marginTop:"18px"
                }}>
                    <span style={{ 
                        width: "60px",
                        marginRight: 24, fontSize: 16,marginBottom:"12px",textAlign:"right" }}>{TITLE}</span>
                    <input style={{
                        flex:1,
                        maxWidth: "800px", boxSizing:"border-box",
                        display: "flex", overflowY: "hidden",
                        padding:"12px 15px",
                        border:"1px solid #ccc"
                    }}
                        readOnly={true}
                        value={title}/>
                </div>
                <div style={{
                    flex: 1,
                    display: "flex",
                    boxSizing: "border-box",
                    overflow: "hidden", marginTop:"18px"
                }}>
                    <span style={{ 
                        width:"60px",
                        marginRight: 24, fontSize: 16, marginBottom: "12px",textAlign:"right" }}>{CONTENTS}</span>
                    <div style={{flex:1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                        <div style={{
                            maxWidth: "800px",
                            display: "flex", overflow: "hidden",
                            flex: 1,
                        }}>
                            <ReactQuill
                                style={{
                                    flex: 1,
                                    display: "flex", flexDirection: "column",
                                }}
                                readOnly={true}
                                modules={{
                                    toolbar: false
                                }} value={contents} />
                        </div>
                    </div>
                </div>
                <div style={{
                    flex:3,
                    display: "flex",
                    boxSizing: "border-box",
                    overflow: "hidden", marginTop:"18px"
                }}>
                    <span style={{ 
                        width:"60px",
                        marginRight: 24, fontSize: 16,marginBottom:"12px",textAlign:"right" }}>{"답변내용"}</span>
                    <div style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                        <div style={{ flex:1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                            <div style={{
                                maxWidth: "800px",
                                display: "flex", overflow: "hidden",
                                flex: 1,
                            }}>
                                <ReactQuill 
                                    ref={quillRef}
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                    }}
                                    modules={{
                                        toolbar: false
                                    }} value={answerHtml}
                                    onChange={setAnswerHtml} />
                            </div>
                        </div>
                        <div style={{
                            margin: "24px 0px", display: "flex",
                            fontSize: "18px"
                        }}>
                            <div className={`pointer-cursor`} style={{
                                border: `1px solid ${THEME_YELLOW}`, borderRadius: 2,
                                backgroundColor: THEME_YELLOW,
                                padding: "8px 32px", marginRight: 24,
                                color: "#FFFFFF",
                            }} onClick={finishAnswer}>
                                <span>{!qnaOriginData?.answer ? "답변등록" : "답변수정"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}