import { BannerWithId } from "../../../classes/Banner";
import { DELETE_BANNER, SAVE_BANNERS, SAVE_BASE64, UNSHIFT_BANNER, UPDATE_BANNER } from "./banners.type";

const initialState : BannerWithId[] = [] 

export const saveBanners = (data : BannerWithId[] | undefined) => {
    return {
        type : SAVE_BANNERS,
        payload : data
    }
}
export const saveBase64 = (data: { base64: string, index: number }) => {
    return {
        type: SAVE_BASE64,
        payload: data
    }
}
export const unshiftBanner = (data : BannerWithId) => {
    return {
        type : UNSHIFT_BANNER,
        payload : data
    }
}
export const updateBanner = (data : BannerWithId) => {
    return {
        type : UPDATE_BANNER,
        payload : data
    }
}
export const deleteBanner = (data : BannerWithId) => {
    return {
        type : DELETE_BANNER,
        payload : data
    }
}
export const bannersReducer = (state: BannerWithId[] = initialState, action: { type: string, payload?: any }): BannerWithId[] => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case SAVE_BANNERS:
            return action.payload;
        case SAVE_BASE64:
            var copyList = state.slice()
            copyList[action.payload.index].base64 = action.payload.base64
            return copyList;
        case UNSHIFT_BANNER :
            var copyList = state.slice()
            copyList.unshift(action.payload)
            return copyList
        case UPDATE_BANNER:
            var copyList = state.slice()
            copyList[action.payload.index] = action.payload
            return copyList
        case DELETE_BANNER:
            return state.filter((bannerWithId) => {
                return bannerWithId.id !== action.payload.id
            })
        default:
            break;
    }
    return state
}