export const birthCalculator = (firstNumber : number) : String => {
    if (firstNumber === 1 || firstNumber === 2) {
        return "19"
    } else if (firstNumber === 3 || firstNumber === 4) {
        return "20"
    } else if (firstNumber === 5 || firstNumber === 6) {
        return "19"
    } else if (firstNumber === 7 || firstNumber === 8) {
        return "20"
    } else return "21"
}