import React, {FC, useEffect} from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AdminLogin } from "./AdminLogin";
import { AdminMain } from "./AdminMain";

export const AdminRouter : FC = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if(state?.auth_level !== undefined){
            console.log(state?.auth_level)
        }else{
            console.log("here")
            navigate("",{replace:true})
        }
    }, [state])

    return (
        <Routes>
            {state?.auth_level !== undefined
                ? <Route path="*" element={<AdminMain/>}/>
                : <Route path="" element={<AdminLogin />} />}
        </Routes>
    )
}