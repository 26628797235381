import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react";

export const FullScreen:FC<{style?:CSSProperties}> = (props) => {
    
    const divRef = useRef<HTMLDivElement>(null);
    const [viewHeight, setViewHeight] = useState<number>(0)

    useEffect(() => {
        resize()
    }, [])

    useEffect(
        useCallback(() => {
            const windowResize = () => {
                resize()
            }
            window.addEventListener("resize", windowResize)
            return () => window.removeEventListener("resize", windowResize)
        }, [viewHeight])
    )

    const resize = () => {
        if (divRef.current) {
            divRef.current.style.height = "100%"
            const nextHeight = divRef.current.offsetHeight
            if (viewHeight === 0 || viewHeight < nextHeight) {
                divRef.current.style.height = nextHeight + "px"
                setViewHeight(nextHeight)
            } else {
                divRef.current.style.height = viewHeight + "px"
            }
        }
    }
    
    return(
        <div
            ref={divRef}
            style={{
                boxSizing:"border-box",
                display:"flex", flexDirection:"column",
                alignItems:"center",justifyContent:"center",
                position: "absolute",
                left: 0, top: 0,
                backgroundColor: "white", width: "100%", height: "100%",
                ...props.style
            }}>
            {props.children}
        </div>
    )
}