import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { QnA } from '../../classes/QnA'
import { ServiceBanner } from '../../components/ServiceBanner'
import styles from "../../css/QnA/QnAEdit.module.css"
import { CONTENTS, QNA, QNA_ANSWER, QNA_EDIT, TITLE } from '../../values/strings'
import ReactQuill, { Quill } from 'react-quill';
import { THEME_YELLOW } from '../../values/Colors'
import { UserWithId } from '../../classes/User'
import { deleteAnswer, updateQnA } from '../../functions/firebaseTools'
import { doc, DocumentReference, getDoc, Timestamp } from 'firebase/firestore'
import ReactModal from 'react-modal'
import PulseLoader from 'react-spinners/PulseLoader'
import { fireStore } from '../../fireConfig'
import { back } from '../../images/images'
import { deleteQnARef } from '../../redux/reducer/qna/qna'

export const QnAView: FC = () => {
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();
    const { id } = useParams<"id">();
    const location = useLocation()
    const { isMobile, user } = useSelector((state: { isMobile: boolean, user: UserWithId }) => state)

    const [title, setTitle] = useState<string>("")
    const [contents, setContents] = useState<string>("")

    const [modiMode, setModiMode] = useState<boolean>(false)
    const [loadingModal, setLoadingModal] = useState<boolean>(false)

    const [qnaOriginData, setQnAOriginData] = useState<QnA | undefined>(undefined)
    const goBack = () => {
        navigate(-1)
    }

    const quillRef = useRef<ReactQuill>(null)

    useEffect(() => {
        if (id)
            getQnAData(id)
    }, [id])

    const getQnAData = async (id: string) => {
        const docRef = doc(fireStore, `qna/${id}`) as DocumentReference<QnA>
        const snapshot = await getDoc(docRef)
        const qnaData = snapshot.data()!
        setQnAOriginData(qnaData)
        setTitle(qnaData.questionTitleHtml)
        setContents(qnaData.questionContentsHtml)
    }

    const toggleModiMode = () => {
        setModiMode(!modiMode)
    }
    const finishEdit = async () => {
        if (id && quillRef) {
            setLoadingModal(true)
            try {
                if (title.length < 1 || (quillRef.current?.unprivilegedEditor?.getLength() ?? 0) < 10) {
                    alert("제목과 내용 10자 이상을 입력해 주세요.")
                    throw new Error()
                }
                await updateQnA(id, {
                    questionTitleHtml: title,
                    questionContentsHtml: contents
                })
                goBack()
            } catch (error) {
                setLoadingModal(false)
            }
        }
    }
    const deleteA = async () => {
        setLoadingModal(true)
        if (id) {
            if (window.confirm("삭제 후 되돌릴 수 없습니다\n삭제하시겠습니까?")){
                const ref = await deleteAnswer(id)
                if (ref) {
                    reduxDispatch(deleteQnARef(ref))
                    goBack()
                }
            }
        }
        setLoadingModal(false)
    }

    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <ReactModal
                isOpen={loadingModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                    },
                    content: {
                        position: "relative", inset: "0px",
                        verticalAlign: "center",
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                        borderWidth: "0px", backgroundColor: "transparent"
                    }
                }}>
                <PulseLoader color={THEME_YELLOW} size={14} margin={8} speedMultiplier={1} />
            </ReactModal>
            <div className={styles.main_ctn}
                style={{ maxWidth: "2400px", overflowY: "hidden" }}>
                {isMobile ?
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{ paddingLeft: "5%" }}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <span>{QNA}</span>
                        </div></div> :
                    <div className={styles.header_ctn}
                        style={{
                            margin: "12px 0px"
                        }}>
                        <div className={`${styles.header_side_ctn} ${styles.left}`}>
                            <span style={{ fontWeight: "bold", fontSize: 30 }}>{QNA}</span>
                        </div>
                    </div>}
                <div style={{
                    flex: 1, flexDirection: "column", display: "flex",
                    overflow: !qnaOriginData?.answer ? "auto" : "scroll"
                }}>
                    <div style={{
                        display: "flex", margin: isMobile ? "0px 5%" : "0px 2.5%",
                        flexDirection: isMobile ? "column" : "row",
                        boxSizing: "border-box",
                        overflow: !qnaOriginData?.answer ? "hidden" : undefined
                    }}>
                        <span style={{ marginRight: 24, fontSize: 16, marginBottom: "12px" }}>{TITLE}</span>
                        <input style={{
                            outlineWidth: "0px",
                            flex: 1,
                            maxWidth: "800px", boxSizing: "border-box",
                            display: "flex", overflowY: "hidden",
                            padding: "12px 15px",
                            border: "1px solid #ccc"
                        }}
                            readOnly={!modiMode}
                            placeholder="질문 제목을 입력해 주세요."
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }} />
                    </div>
                    <div style={{
                        flex: !qnaOriginData?.answer ? 1 : undefined,
                        display: "flex", margin: isMobile ? "24px 5%" : "24px 2.5%",
                        flexDirection: isMobile ? "column" : "row",
                        boxSizing: "border-box",
                        overflow: !qnaOriginData?.answer ? "hidden" : undefined
                    }}>
                        <span style={{ marginRight: 24, fontSize: 16, marginBottom: "12px" }}>{CONTENTS}</span>
                        <div style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                            <div style={{ flex: !qnaOriginData?.answer ? 1 : undefined, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                                <div style={{
                                    maxWidth: "800px",
                                    display: "flex", overflow: "hidden",
                                    flex: 1,
                                }}>
                                    <ReactQuill theme="snow"
                                        ref={quillRef}
                                        style={{
                                            flex: 1, maxWidth: "800px",
                                            display: "flex", flexDirection: "column",
                                        }}
                                        readOnly={!modiMode}
                                        placeholder="질문 내용을 입력해주세요 (1000자 이하)"
                                        modules={{
                                            toolbar: false
                                        }} value={contents} onChange={(value, delta, source, editor) => {
                                            editor.getLength() <= 1000 &&
                                                setContents(value)
                                        }} />
                                </div>
                            </div>
                            {!qnaOriginData?.answer ?
                                modiMode
                                    ?
                                    <div style={{
                                        margin: "24px 0px", display: "flex",
                                        fontSize: "18px"
                                    }}>
                                        <div className="pointer-cursor" style={{
                                            border: "1px solid #767676", borderRadius: 2,
                                            padding: "8px 32px", marginRight: 24
                                        }} onClick={toggleModiMode}>
                                            <span>취소</span>
                                        </div>
                                        <div className="pointer-cursor" style={{
                                            border: `1px solid ${THEME_YELLOW}`, borderRadius: 2,
                                            backgroundColor: THEME_YELLOW,
                                            padding: "8px 32px", marginRight: 24,
                                            color: "#FFFFFF"
                                        }} onClick={finishEdit}>
                                            <span>등록</span>
                                        </div>
                                    </div>
                                    :
                                    <div className={`pointer-cursor`} style={{
                                        margin: "24px 0px", display: "flex",
                                        fontSize: "18px"
                                    }}>
                                        <div style={{
                                            border: `1px solid ${THEME_YELLOW}`, borderRadius: 2,
                                            backgroundColor: THEME_YELLOW,
                                            padding: "8px 32px", marginRight: 24,
                                            color: "#FFFFFF"
                                        }} onClick={toggleModiMode}>
                                            <span>수정</span>
                                        </div>
                                        <div style={{
                                            border: `1px solid #F46767`, borderRadius: 2,
                                            backgroundColor: "#F46767",
                                            padding: "8px 32px", marginRight: 24,
                                            color: "#FFFFFF"
                                        }} onClick={deleteA}>
                                            <span>삭제</span>
                                        </div>
                                    </div>
                                : null}
                        </div>
                    </div>
                    {qnaOriginData?.answer
                        &&
                        <div style={{
                            flex: 1,
                            display: "flex", margin: isMobile ? "0px 5%" : "0px 2.5%",
                            flexDirection: isMobile ? "column" : "row",
                            boxSizing: "border-box",
                            overflow: undefined
                            // !isMobile ? "hidden" : undefined
                        }}>
                            <span style={{ marginRight: 24, fontSize: 16, marginBottom: "12px" }}>{"답변"}</span>
                            <div style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                                <div style={{ flex: !qnaOriginData?.answer ? 1 : undefined, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                                    <div style={{
                                        maxWidth: "800px",
                                        display: "flex", overflow: "hidden",
                                        flex: 1,
                                    }}>
                                        <ReactQuill theme="snow"
                                            style={{
                                                flex: 1,
                                                display: "flex", flexDirection: "column",
                                            }}
                                            readOnly={!modiMode}
                                            placeholder="질문 내용을 입력해주세요 (1000자 이하)"
                                            modules={{
                                                toolbar: false
                                            }} value={qnaOriginData?.answer?.answerHtml} />
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                <ServiceBanner type={0} style={isMobile ? {} : { width: "45%" }} />
                {!isMobile &&
                    <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} />}
            </div>
        </div>
    )
}