import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from "../../../css/QnA/QnAList.module.css"
import { QnA } from '../../../classes/QnA'
import { arrow_back_black, arrow_back_grey, arrow_next_black, arrow_next_grey, ic_qna } from '../../../images/images'
import { QNA_MANAGEMENT } from '../../../values/strings'
import { QnAPage } from '../../../components/QnA/QnAPage'
import { DocumentReference } from 'firebase/firestore'
import { THEME_YELLOW } from '../../../values/Colors'
export const AdminQnAList: FC = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { page: pageFromParams = "0" } = useParams<"page">();
    const { isMobile,qna:qnaList } = useSelector((state: { qna: DocumentReference<QnA>[], isMobile: boolean }) => state)

    const pagePerSection = 10;
    const qnaPerPage = 10;
    const page = parseInt(pageFromParams)
    const pageArr = useMemo(() => {
        var len = qnaList.length;
        var cnt = Math.floor(len / qnaPerPage) + (Math.floor(len % qnaPerPage) > 0 ? 1 : 0);
        var tmp = []
        for (var i = 0; i < cnt; i++) {
            tmp.push(i)
        }
        return tmp
    }, [qnaList])

    useEffect(() => {
        console.log(location.state,qnaList)
    }, [])

    const goToView = (ref : DocumentReference<QnA>)=>{
        navigate(`../view/${ref.id}`,{
            state: location.state
        })
    }

    const moveOtherPage = (page : number) => {
        navigate(`../${page}`,{
            state : location.state
        })

    }

    return (
        <div className={"parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <div className={styles.main_ctn}
                style={{
                    padding: "30px 2.5%", maxWidth: "2400px",
                    display: "flex",
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box"
                }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center",
                    fontSize: "14px", marginBottom: "12px",
                }}>
                    <span style={{
                        fontSize: "22px", fontWeight: 500,
                    }}>{QNA_MANAGEMENT}</span>
                </div>
                {qnaList.length > 0
                    &&
                <QnAPage
                    isManage
                    qnaList={qnaList}
                    page={page}
                    qnaPerPage={qnaPerPage}
                    onClick={goToView} />}

                {qnaList.length > 0
                    ?
                <div style={{
                    display: "flex",
                    padding: "24px",
                    justifyContent: "center", alignItems: "center",
                }}>
                    <img className={`pointer-cursor`} src={Math.floor(page / pagePerSection) !== 0
                        ? arrow_back_black
                        : arrow_back_grey}
                        style={{ width: "42px", height: "42px",marginRight:"12px" }}
                        onClick={() => {
                            if (Math.floor(page / pagePerSection) !== 0) {
                                const currentSection = Math.floor(page / pagePerSection)
                                const targetPage = currentSection * pagePerSection - 1
                                moveOtherPage(targetPage)
                            }
                        }} />
                    {pageArr.slice(Math.floor(page / pagePerSection) * pagePerSection, (Math.floor(page / pagePerSection) + 1) * pagePerSection)
                        .map((value) => {
                            const isSelected = value === page
                            return (
                                <span key={`Section_${value}`}
                                className={`pointer-cursor`}
                                    style={{
                                        color: isSelected ? "white" : "black",
                                        margin: "0px 4px",
                                        display: "flex", flexDirection: "row",
                                        alignItems: "center", justifyContent: "center",
                                        width: "30px", height: "30px",
                                        borderRadius: "6px",
                                        backgroundColor: isSelected ? THEME_YELLOW : undefined
                                    }} onClick={() => {
                                moveOtherPage(value)
                                    }}>
                                    {value + 1}
                                </span>
                            )
                        })}
                    <img className={`pointer-cursor`} src={
                        Math.ceil((page+1)/pagePerSection) !== Math.ceil(pageArr.length/pagePerSection)
                        ? arrow_next_black
                        : arrow_next_grey}
                        style={{ width: "42px", height: "42px",marginLeft:"12px" }}
                        onClick={() => {
                            if (Math.ceil((page+1)/pagePerSection) !== Math.ceil(pageArr.length/pagePerSection)) {
                                const currentSection = Math.floor(page / pagePerSection)
                                const targetPage = (currentSection + 1) * pagePerSection
                                navigate(`../${targetPage}`)
                            }
                        }} />
                </div>:<div style={{
                        display:"flex",justifyContent:"center"
                    }}>
                        <span style={{color:"#ACACAC"}}>등록된 질문이 없습니다.</span></div>
}
            </div>
        </div>
    )
}