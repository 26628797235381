import { Dialog } from "@mui/material";
import { getDownloadURL, list, ref, getMetadata } from "firebase/storage";
import moment, { Moment } from "moment";
import React,{CSSProperties, FC, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MonthItem, MonthSet, MonthUnit } from "../../classes/Month";
import { StaffWithId } from "../../classes/Staff";
import { User, UserWithId } from "../../classes/User";
import { AdminStaffList } from "../../components/AdminStaffList";
import { reduxUpdateUser } from "../../components/GetInitValue";
import { SelectMonth } from "../../components/SelectMonth";
import styles from "../../css/AdminStaffList.module.css"
import { fireStorage } from "../../fireConfig";
import { arrow_down_fill, search } from "../../images/images";
import { getMonthList, selectMonthItem } from "../../redux/reducer/month-set/month-set";
import { updateUser } from "../../redux/reducer/user/user";
import { updateUsers } from "../../redux/reducer/users/users";
import { THEME_YELLOW } from "../../values/Colors";
import { InfoModify } from "../InfoModify";
import { MemberModify } from "../MemberModify";
import { PayRouter } from "../PayRouter";

export const AdminStaffManage : FC = ()=>{
    const navigate = useNavigate()
    const location = useLocation()
    const { users, staff, monthSet,user } = useSelector((state: {
        users: UserWithId[], staff: StaffWithId[], 
        monthSet: MonthSet, user : UserWithId,
    }) => state)
    const { monthList,selected } = monthSet
    const reduxDispatch = useDispatch();

    const [searchUsers,setSearchUsers] = useState<UserWithId[]>(users)
    const filter = (staffList: StaffWithId[], type: number[]): StaffWithId[] => {
        return staffList.filter(({data}) => {
            return type.includes(data.hireType.type) && data.employer === user.id
        })
    }
    useEffect(()=>{
        console.log("ChangeUsers")
    },[users])
    const employees: StaffWithId[] = useMemo(() => filter(staff, [0]), [user,staff]);
    const partTimeJob: StaffWithId[] = useMemo(() => filter(staff, [1,2]), [user,staff]);

    const createTime = useMemo(()=>{
        return moment.unix(user.data.createTime?.seconds ?? 0).format("YYYY.MM.DD")
    },[user])
    const bn = useMemo(()=>{
        return user.data.bn?.replace(/([0-9]{1,3})([0-9]{1,2})([0-9]{1,5})$/, "$1-$2-$3")
    },[user])
    const staffNumb = useMemo(()=>{
        return `${partTimeJob.length + employees.length}명`
    },[employees,partTimeJob])

    const selectMonth : MonthItem | undefined = selected ?? monthList[0]
    const selectMonthToPattern = useMemo(()=>selectMonth?.value?.year+"-"+selectMonth?.value?.month,[selectMonth])
    useMemo(() : void => {
        const createMoment = moment.unix(user.data.createTime?.seconds!!!)
        const currentMoment = moment()

        const diff = function (firstMoment: Moment, currentDate: Moment): number {
            const firstYear = firstMoment.year(), firstMonth = firstMoment.month()
            const currentYear = currentDate.year(), currentMonth = currentDate.month()
            if (firstYear === currentYear) {
                return currentMonth - firstMonth
            } else {
                const yearGap = currentYear - firstYear
                const gapToMonth = yearGap * 12
                return currentMonth + gapToMonth - firstMonth
            }
        }(createMoment, currentMoment)

        currentMoment.add(1, "M")

        const monthList: MonthItem[] = []
        for (var i = 0; i <= diff; i++) {
            // const isFirst = i === 0
            const eachMoment = currentMoment.subtract(1, "M")
            const text = eachMoment.format("YYYY년 MM월")
            const yandm: MonthUnit = {
                year: eachMoment.get("y"),
                month: eachMoment.get("M")
            }
            monthList.push({
                text: text,
                value: yandm
            })
        }
        if(!monthList.some((monthItem)=> monthItem?.text === selectMonth?.text)){
            reduxDispatch(selectMonthItem(monthList[0]))
        }
        reduxDispatch(getMonthList(monthList))
    
    }, [user])
    
    const [searchTarget, setSearchTarget] = useState<string>("")
    const [currentTarget, setCurrentTarget] = useState<string>("")
    const searchFilter = (text: string) =>{
        setCurrentTarget(text)
        const newList = users.filter((user)=>{
            return user.data.companyName?.includes(text)
        })
        setSearchUsers(newList)
    }

    const [monthModal,setMonthModal] = useState<boolean>(false)
    const [brModal, setBrModal] = useState<boolean>(false)
    const [brImage, setBrImage] = useState<string>("")
    useEffect(()=>{
        if (users.length){
            searchFilter(searchTarget)
            console.log(user)
            if (!(user.id.length > 0)) {
                console.log(user.id)
                reduxDispatch(updateUser(users[0]))
            }
        }
    }, [users])

    const in48HourUsers = useMemo(()=>{
        return searchUsers.filter((value,index)=>{
            var createTime = value.data.createTime
            console.log(createTime)
            if(createTime){
                var userCreateMoment = moment.unix(createTime.seconds);
                // var currentMoment = moment()
                // console.log(userCreateMoment," : ",currentMoment)
                var diff = moment().diff(userCreateMoment,"hours")
                console.log(diff)
                if(diff <= 48 ){
                    return true
                }
            }
            return false
        })
    },[users])
    const [modiTarget, setModiTarget] = useState<StaffWithId>()
    const headers = [
        {
            title: "순번",
            flex: 1
        },
        {
            title: "이름",
            flex: 1
        },
        {
            title: "전화번호",
            flex: 2
        },
        {
            title: "성별",
            flex: 0.5
        },
        {
            title: "주민등록번호",
            flex: 2
        },
        {
            title: "편집",
            flex: 1
        },
        {
            title : "직원수정",
            flex: 1
        }
    ]
    return(
        <div className={styles.main_ctn}>
            <SelectMonth
                isOpen={monthModal}
                monthSet={monthSet}
                onSelectMonth={(item)=>{
                    console.log(item)
                    reduxDispatch(selectMonthItem(item))
                    setMonthModal(!monthModal)
                }}
                onRequestClose={() => { setMonthModal(!monthModal) }} />
            <div
                style={{
                    maxWidth:"200px",
                    padding:"30px 8px",
                    display: "flex", flexDirection:"column",
                            borderRight: "1px solid #D0D0D0",
                            // overflow:"scroll"
                }}>
                <span style={{ fontSize: "22px", fontWeight: 500,marginBottom:"12px" }}>사용자 관리</span>
                <div style={{
                    display: "flex", padding: "4px",
                    border: "1px solid #C3C3C3",borderRadius:"4px",boxSizing:"border-box"
                }}>
                    <input
                        style={{
                            flex:1,
                            borderColor:"#C3C3C3",
                            borderWidth: "0px 1px 0px 0px",outlineWidth:"0px",
                            marginRight:"4px"
                        }} 
                        value={searchTarget}
                        onChange={(e)=>{
                            setSearchTarget(e.target.value.trimLeft())
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                searchFilter(searchTarget)
                            }
                        }}/>
                    <img className="pointer-cursor" 
                        style={{ height: "24px", width: "auto", boxSizing: "border-box" }}
                        src={search}
                        onClick={()=>{
                            searchFilter(searchTarget)
                        }}/>
                </div>

                <span style={{ fontSize: "12px",wordBreak:"break-all" }}>
                    {currentTarget.length
                        ? `'${currentTarget}' 으로 검색한 결과`
                        : "\u200b"}</span>
                <div style={{
                    display: "flex", flexDirection: "column",
                    overflowY: "auto"
                }}>
                    {in48HourUsers.length > 0 &&
                        <div style={{
                            display: "flex", flexDirection: "column",
                        }}>
                            {in48HourUsers.map((listUser) => {
                                const isSelect = listUser.id === user.id
                                return (
                                    <div className="pointer-cursor"
                                        style={{
                                            position:"relative",
                                            alignSelf: "center", padding: "4px",
                                            fontWeight: isSelect
                                                ? 500 : "normal",
                                            color: isSelect ? "#000000" : "#909090"
                                        }}
                                        onClick={() => {
                                            reduxDispatch(updateUser(listUser))
                                            navigate("", {
                                                replace: true,
                                                state: location.state
                                            });
                                        }}>
                                        {listUser.data.companyName}
                                        <div style={{
                                            position:"absolute",
                                            top:0,left:0,
                                            width:5,height:5,borderRadius:8,
                                            backgroundColor:"#FF5555"
                                        }}/>
                                    </div>
                                )
                            })}
                            <div style={{margin:"8px 0px",border:"0.5px solid #D1D1D1"}}/>
                        </div>
                    }
                {searchUsers.map((listUser) => {
                    const isSelect = listUser.id === user.id
                    return (
                        <div className="pointer-cursor"
                            style={{
                                alignSelf:"center",padding:"4px",
                                fontWeight: isSelect
                                    ? 500 : "normal",
                                color : isSelect ? "#000000" : "#909090"
                            }}
                            onClick={() => {
                                reduxDispatch(updateUser(listUser))
                                navigate("",{
                                    replace : true,
                                    state : location.state
                                });
                            }}>
                            {listUser.data.companyName}
                        </div>
                    )
                })}
                </div>
            </div>
            <Routes>
                <Route path="" element={
                    <div className={styles.body_ctn}
                        style={{
                            flex: 1,
                            maxWidth:"100%",
                            padding: "30px 0px",
                            overflowY: "hidden", flexDirection: "column"
                        }}>
                        <Dialog
                            fullWidth={true}
                            open={brModal}
                            onClose={() => { setBrModal(false) 
                            setBrImage("")}}>
                            <img src={brImage} />
                        </Dialog>
                        <div className="side_margin_2_5per" style={{
                            display: "flex", flexDirection: "row",
                            justifyContent: "space-between",
                            fontSize: "14px"
                        }}>
                            <div
                                style={{
                                    padding: "12px 26px", backgroundColor: "#F7F7F7", borderRadius: "5px",
                                    color: "#717171",
                                }}>
                                <span style={{ marginRight: "10px" }}>
                                    <span style={{ marginRight: "10px" }}>최초 가입일</span>
                                    <span style={{ color: "#575757" }}>{createTime}</span>
                                </span>
                                <span style={{ marginRight: "10px" }}>
                                    <span style={{ marginRight: "10px" }}>사업자번호</span>
                                    <span style={{ color: "#575757" }}>{bn}</span>
                                </span>
                                <span>
                                    <span style={{ marginRight: "10px" }}>직원수</span>
                                    <span style={{ color: "#575757" }}>{staffNumb}</span>
                                </span>
                            </div>
                            <div className="pointer-cursor"
                                style={{
                                    border: "1px solid #C3C3C3", color: "#767676",
                                    padding: "12px 0px", borderRadius: "5px", fontWeight: 500,
                                    display:"flex",alignItems:"center"
                                }}
                                onClick={() => {
                                    setMonthModal(!monthModal)
                                }}>
                                <span style={{
                                    padding:"0px 41px",
                                    borderRight: "1px solid #C3C3C3"
                                }}>{selectMonth?.text}</span>
                                <img src={arrow_down_fill} style={{height:"14px",padding:"0px 8px"}}/>
                            </div>
                        </div>
                        <div className="side_margin_2_5per" style={{
                            display: "flex", flexDirection: "row", alignItems: "center"
                        }}>
                            <span  style={{
                                fontWeight: 500, fontSize: "22px",
                                marginBottom: "18px", marginTop: "18px"
                            }}>{user.data.companyName}</span>
                            <span className="pointer-cursor"
                                style={{
                                    padding: "4px 8px", backgroundColor:THEME_YELLOW, color:"white", borderRadius: "8px",
                                    fontWeight:500,marginLeft:"14px"
                                }}
                                onClick={async () => {
                                    if (user) {
                                        await reduxUpdateUser(user.id, reduxDispatch)
                                        navigate("info-modi", {
                                            state: { ...location.state }
                                        })
                                    }
                                }}>
                                수정
                            </span>
                            <span className="pointer-cursor"
                                style={{
                                    padding: "4px 8px", backgroundColor:"#FF5555", color:"white", borderRadius: "8px",
                                    fontWeight:500,marginLeft:"14px"
                                }}
                                onClick={async () => {
                                    if (user) {
                                        var userId = user.id
                                        if (window.confirm(`${user.data.companyName} 회사를 삭제 및 탈퇴시키겠습니까?`))
                                            try {
                                                console.log(users)
                                                const requestUrl = process.env.NODE_ENV != "production"
                                                ? "http://localhost:5001/nobong-79c64/us-central1/deleteUser"
                                                : "https://us-central1-nobong-79c64.cloudfunctions.net/deleteUser"
                                                console.log(requestUrl)
                                                const deleteResult = await fetch(requestUrl, {
                                                    method: "POST",
                                                    headers: { "Content-Type": "application/json" },
                                                    body: JSON.stringify({
                                                        uid: userId
                                                    })
                                                })
                                                const jsonResult = await deleteResult.json()
                                                console.log(jsonResult)
                                                if (!(jsonResult).result) {
                                                    throw new Error("삭제 실패 서버 반환")
                                                }
                                                const newUsersList = users.filter((value) => value.id !== user.id)
                                                await reduxUpdateUser(newUsersList[0].id, reduxDispatch)
                                                console.log(newUsersList)
                                                reduxDispatch(updateUsers(newUsersList))
                                            } catch (error) {
                                                console.log(error)
                                                alert("삭제 중 오류가 발생하였습니다.")
                                            }
                                    }
                                }}>
                                삭제
                            </span>
                            <span className="pointer-cursor"
                                style={{
                                    visibility:"hidden",
                                    padding: "4px 8px", border: `1px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                    fontWeight:500,marginLeft:"14px"
                                }}
                                onClick={async () => {
                                    console.log(user.id)
                                    try {
                                        const storageRef = ref(fireStorage, user.id)
                                        const fileList = await list(storageRef)
                                        // const firstItemRef = fileList.items[0]
                                        const comparableArray = await Promise.all(
                                            fileList.items.map(async (fileRef,) => {
                                                const metaData = await getMetadata(fileRef,)
                                                return {
                                                    fileRef: fileRef,
                                                    meta: metaData
                                                }
                                            })
                                        )
                                        const firstItemRef = comparableArray.sort((a, b) => {
                                            const aTime = moment(a.meta.updated).toDate().getTime()
                                            const bTime = moment(b.meta.updated).toDate().getTime()
                                            if (aTime < bTime) return 1
                                            else if (aTime == bTime) return 0
                                            else return -1
                                        })[0]?.fileRef
                                        if (!firstItemRef) {
                                            alert("등록된 사업자등록증 파일이 없는 사용자입니다.")
                                            return
                                        }
                                        console.log("firstItemRef is Not undefined")
                                        const url = await getDownloadURL(firstItemRef)
                                        // // if (!contentType?.includes("pdf")) {
                                        // //     setBrImage(url)
                                        // //     setBrModal(true)
                                        // // } else {
                                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                                        if (newWindow) newWindow.opener = null
                                    } catch (error) {
                                        alert("사업자등록증 파일이 없는 사용자입니다.")
                                        console.log(error)
                                    }
                                }}>
                                사업자등록증
                            </span>
                        </div>
                        {/* table-body */}
                        <div style={{
                            flex: 1, height: "100%",
                            overflow: "hidden",
                            display: "flex", flexDirection: "column"
                        }}>
                            <div style={{
                                overflow: "hidden",
                                display: "flex", flexDirection: "row"
                            }}>
                                {/* table-employee */}
                                {[
                                    { list: employees },
                                    { list: partTimeJob }
                                ].map((listSet, index) => {
                                    const { list } = listSet
                                    const middleLine: CSSProperties = employees.length > partTimeJob.length
                                        ? index === 0 ? { borderRight: "1px solid #E0E0E0" } : {}
                                        : index === 1 ? { borderLeft: "1px solid #E0E0E0" } : {}
                                    return (
                                        <AdminStaffList
                                            isMobile={false}
                                            middleLine={middleLine}
                                            headers={headers}
                                            staffList={staff}
                                            selectMonth={selectMonth}
                                            selectMonthToPattern={selectMonthToPattern}
                                            isEmployee={index}
                                            openModifyDialog={(staffWithId)=>{
                                                console.log(location.state,"sdfasdfs")
                                                setModiTarget(staffWithId)
                                                navigate("member-modi", {
                                                    state: {
                                                        ...location.state,
                                                    }
                                                })
                                            }}
                                            list={list} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                } />
                <Route path="pay-edit" element={<PayRouter/>}/>
                <Route path="info-modi" element={<InfoModify/>}/>
                <Route path="member-modi" element={<MemberModify modiTarget={modiTarget}
                    onDismiss={()=>{navigate(-1)}}/>}/>
            </Routes>

        </div>
    )
}