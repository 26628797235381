export const PLEASE_INPUT_EMAIL_TYPE : string = "이메일 형식으로 입력해주세요."
export const PLEASE_INPUT_PHONE_TYPE : string = "전화번호 형식으로 입력해주세요."
export const PLEASE_INPUT_SSN_TYPE : string = "주민등록번호 형식으로 입력해주세요."
export const NOT_SIGN_USER : string = "가입되지 않은 회원입니다"
export const NO_ENROLLMENT_STAFF : string = "위 정보와 일치하는 근로자가 없습니다."
export const NOT_MATCH_PASSWORD : string = "비밀번호가 일치하지 않습니다."
export const LOGIN : string = "로그인"
export const NOT_MEMBER_YET : string = "아직 회원이 아닌가요?"
export const JOIN : string = "회원가입"
export const STAFF_MANAGEMENT : string = "직원관리"
export const PAY_MANAGEMENT : string = "급여관리"
export const ADD_STAFF : string = "직원추가"
export const TEN_PER : string = "10%"
export const FIVE_PER : string = "5%"
export const DONE : string = "완료"
export const PLEASE_INPUT_NAME : string = "이름을 입력해 주세요."
export const QNA : string = "Q&A"
export const QNA_MANAGEMENT : string = "Q&A 관리"
export const QNA_ANSWER : string = "Q&A 답변하기"
export const QNA_EDIT : string = "Q&A 질문등록";
export const DO_YOU_HAVE_ANY_QUESTION : string = "궁금하신 점이 있으신가요?"
export const TITLE : string = "제목";
export const CONTENTS : string = "내용";