import { FirestoreDataConverter, Timestamp } from "firebase/firestore";
import { HireType } from "../values/types"
import { PayStub } from "./PayStub";
export interface Staff{
    employer: string
    hireType: HireType
    name: string
    phone: string
    ssn: string[]
    deduction: number
    gender: string
    part : string
    rank : string
    createTime: Timestamp
    acceptTime : Timestamp
    state : boolean
}
export interface StaffWithId {
    data : Staff ,
    id : string,
    payStubObj : {[key : string]: PayStub}
}
export class Staff implements Staff{
    
    constructor(
        employer: string,
        hireType: HireType,
        name: string,
        phone: string,
        ssn: string[],
        deduction: number,
        gender: string,
        part:string,
        rank:string,
        createTime: Timestamp,
        acceptTime?: Timestamp,
        state?: boolean,
    ) {
        this.employer = employer
        this.hireType = hireType
        this.name = name
        this.phone = phone
        this.ssn = ssn
        this.deduction = deduction
        this.gender = gender
        this.part = part ?? ""
        this.rank = rank ?? ""
        this.createTime = createTime
        this.acceptTime = acceptTime ?? new Timestamp(0,0)
        this.state = state ?? false
    }
}


export const staffConverter: FirestoreDataConverter<Staff> = {
    toFirestore: (staff: Staff) => {
        return {
            employer: staff.employer,
            hireType: staff.hireType,
            name: staff.name,
            phone: staff.phone,
            ssn: staff.ssn,
            deduction: staff.deduction,
            gender: staff.gender,
            part : staff.part,
            rank : staff.rank,
            createTime: staff.createTime,
            acceptTime : staff.acceptTime,
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return new Staff(
            data.employer, data.hireType, data.name, data.phone,
            data.ssn, data.deduction, data.gender, data.part ?? "", data.rank ?? "", data.createTime, data.acceptTime
        )
    }

}