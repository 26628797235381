import React, { ChangeEvent, ChangeEventHandler, FC } from "react";
import styles from '../css/HourlyWageInput.module.css'
import { parseLocale } from "./Tools";
export const HourlyWageInput : FC<{
    readonly? : boolean
    value : string
    onChange? : (event :ChangeEvent<HTMLInputElement>) => void
}> = ({
    readonly = true,
    value,
    onChange
}) =>{
    return(
        <div className={styles.hourly_wage_input_ctn}>
            <span>{`통상시급 입력`}</span>
            <input
                readOnly={readonly}
                value={value}
                onChange={onChange} />
            <span>원</span>
        </div>
    )
}