import { collection, doc, getDoc, getDocs, query, Timestamp, updateDoc } from "firebase/firestore";
import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MonthItem } from "../classes/Month";
import { DayPs, EmpPs, PartPs, PayStub, payStubConverter } from "../classes/PayStub";
import { Staff, staffConverter, StaffWithId } from "../classes/Staff";
import { userConverter } from "../classes/User";
import { fireStore } from "../fireConfig";
import logo from "../images/login/logo_login.png"
import { FIVE_PER } from "../values/strings";
import { GRAY, RED, THEME_YELLOW } from "../values/Colors";
import moment, { Moment } from "moment";
import "moment/locale/ko";
import arrow_down from "../images/manage-pay/ic_arrow_down@2x.png"
import arrow_up from "../images/manage-pay/ic_arrow_up@2x.png"
import { useBlocker } from "../components/react-router-dom-revive";
import ReactModal from "react-modal";
import audio_on from "../images/manage/Ic_adio_on@2x.png"
import audio_off from "../images/manage/Ic_adio_off@2x.png"
import agree_off from "../images/agree/agree_off@2x.png"
import agree_on from "../images/agree/agree_on@2x.png"
import styles from '../css/PayStubList.module.css'
import { back, img_read, logo_read } from "../images/images";
import { Emp } from "../components/ViewPageComp/Emp";
import { Part } from "../components/ViewPageComp/Part";
import { Day } from "../components/ViewPageComp/Day";
import { ServiceBanner } from "../components/ServiceBanner";
import ReactQuill,{Quill} from 'react-quill';
import { getConsentItem } from "../functions/getConsentItem";
import { birthCalculator } from "../values/BirthCalculator";

export const PayStubList: FC = () => {
    const navigate = useNavigate()
    const [term, setTerm] = useState("");
    const [privacy, setPrivacy] = useState("");
    const getConsentItems = async()=>{
        setTerm(await getConsentItem("term"))
        setPrivacy(await getConsentItem("privacy"))
    }
    
    const [searchParams, setSearchParams] = useSearchParams()
    const targetIds = searchParams.getAll("id")
    const [targetMonth,setTargetMonth] = useState(searchParams.get("month-set") ?? "")
    const [payStubSetList, setPayStubList] = useState<{ companyName: string, staffWithId: StaffWithId }[]>([])
    const [payStubSet, setPayStubSet] = useState<{ companyName: string, staffWithId: StaffWithId }>()
    // const [viewPayStub, setViewPayStub] = use
    const monthIdToMonthSet = (monthId: string): { monthItem: MonthItem, monthId: string } => {

        var eachMoment : Moment;
        if(monthId.split("-")[1]!=="0"){
            eachMoment = moment(monthId, "YYYY-MM").add(1, "M")
        }else {
            eachMoment = moment(monthId+"1","YYYY-MM")
        }
        const text = eachMoment.format("YYYY년 MM월")
        const monthItem: { monthItem: MonthItem, monthId: string } = {
            monthItem: {
                value: {
                    year: eachMoment.get("y"),
                    month: eachMoment.get("M")
                },
                text: text,
            },
            monthId: monthId
        }
        return monthItem
    }
    const selectMonth = useMemo(() => {
        const payStub = payStubSet?.staffWithId.payStubObj[targetMonth]
        if (payStub) {
            //console.log("selectMonth : ", targetMonth)
            return monthIdToMonthSet(targetMonth)
        } else {
            //console.log("SeletMonth No Match")
            const tmpList: string[] = []
            for (const key in payStubSet?.staffWithId.payStubObj) {
                tmpList.push(key)
            }
            tmpList.sort((a, b) => {
                const [aYear, aMonth] = a.split("-").map(v => parseInt(v))
                const [bYear, bMonth] = b.split("-").map(v => parseInt(v))
                if (aYear !== bYear) {
                    return bYear - aYear
                } else
                    return bMonth - aMonth
            })

            //console.log(tmpList.length)
            if (tmpList.length > 0) {
                //console.log("tmpList Length > 0 and First", tmpList[0])
                setTargetMonth(tmpList[0])
            }
        }
    }, [targetMonth, payStubSet])
    const viewData = useMemo(() => {
        //console.log(payStubSet?.staffWithId.payStubObj[selectMonth?.monthId ?? ""])
        console.log(payStubSet)

        const payStubItem = payStubSet?.staffWithId.payStubObj[selectMonth?.monthId ?? ""]
        const body = {
            staffInfo: payStubItem?.staffInfo ?? payStubSet?.staffWithId.data,
            data : payStubItem?.data,
            type : payStubItem?.type
        }
        console.log(body)
        return body
    }, [selectMonth,payStubSet])
    const birthDate = useMemo(() => {
        const ssn = viewData.staffInfo?.ssn;
        if (ssn) {
            return (birthCalculator( parseInt(ssn[1][0])))
                + ssn[0].substring(0, 2) + "-" + ssn[0].substring(2, 4) + "-" + ssn[0].substring(4, 6)
        } else
            return ""
    }, [viewData])

    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const listDivRef = useRef<HTMLDivElement>(null)
    const scrollTop = useState<number>();
    const needMoreScroll = useMemo(() => {
        return listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!
    }, [scrollTop[0]])

    const sendedPayStubList = useMemo((): { monthItem: MonthItem, monthId: string }[] => {

        const payStubList: { monthItem: MonthItem, monthId: string }[] = []
        const payStubObj = payStubSet?.staffWithId.payStubObj
        for (const key in payStubObj) {
            if (Object.prototype.hasOwnProperty.call(payStubObj, key)) {
                const payStubItem = payStubObj[key];
                if (payStubItem.isSend) {
                    var eachMoment : Moment;
                    if(key.split("-")[1]!=="0"){
                        eachMoment = moment(key, "YYYY-MM").add(1, "M")
                    }else {
                        eachMoment = moment(key+"1","YYYY-MM")
                    }
                    const text = eachMoment.format("YYYY년 MM월")
                    const monthItem: { monthItem: MonthItem, monthId: string } = {
                        monthItem: {
                            value: {
                                year: eachMoment.get("y"),
                                month: eachMoment.get("M")
                            },
                            text: text
                        },
                        monthId: key
                    }
                    payStubList.push(monthItem)
                }
            }
        }
        payStubList.sort(({ monthItem: { value: a } }, { monthItem: { value: b } }) => {
            if (b.year !== a.year) {
                return b.year - a.year
            } else return b.month - a.month
        })
        return payStubList
    }, [payStubSet])
    const [modalVisible2, setModalVisible2] = useState<boolean>(false)
    const [tmpPayStubSet, setTmpPayStubSet] = useState<{ companyName: string, staffWithId: StaffWithId }>()

    const [modalVisible3, setModalVisible3] = useState<boolean>(false)
    const [firstAccept, setFirstAccept] = useState<boolean>(false)
    const [secondAccept, setSecondAccept] = useState<boolean>(false)
    const isAcceptAll = useMemo(() => {
        return firstAccept && secondAccept
    }, [firstAccept, secondAccept])

    const {state} = useLocation()
    const [ssnModal, setSsnModal] = useState<boolean>(state?.isCheck ? false : true)
    const [firstSSN, setFirstSSN] = useState<string|undefined>(undefined)
    const [checkSSN, setCheckSSN] = useState<string>("")
    const [errorCode, setErrorCode] = useState<number>(0)

    const enterWithSSN = () => {
        if (checkSSN === firstSSN) {
            setSsnModal(false)
        }else{
            setErrorCode(1)
        }
    }
    useEffect(() => {
        recordStaffPayStubInfo()
        getConsentItems()
    }, [])

    useEffect(() => {
        if (payStubSetList.length) {
            const lessTime = moment().subtract(1, "months");
            payStubSetList.forEach(({ staffWithId: { data } }, index) => {
                const acceptTime = data?.acceptTime ?? new Timestamp(0, 0)
                const toMoment = moment.unix(acceptTime.seconds).utc()
                if (toMoment.isBefore(lessTime)) {
                    setModalVisible3(true)
                }
            })
        }
    }, [payStubSetList])

    useBlocker(() => {
        //console.log("here?")
        setModalVisible(false)
        setModalVisible2(false)
    }, ((modalVisible || modalVisible2)))

    const recordStaffPayStubInfo = async () => {
        const payStubList = await getStaffPayStubInfo()
        searchParams.get("month-set") ?? setTargetMonth(searchParams.get("month-set")!)
        console.log(payStubList,searchParams.get("month-set"))
        setPayStubList(payStubList)
    }
    const getStaffPayStubInfo = async () => {
        return (await Promise.all(targetIds.map(async (id, index) => {
            const staffDocRef = doc(fireStore, "staffs", id).withConverter(staffConverter)
            const staffDoc = (await getDoc(staffDocRef)).data()


            if(!firstSSN && staffDoc){
                setFirstSSN(staffDoc.ssn[0])
            }
            const payStubCollectionRef = collection(fireStore, staffDocRef.path, "payStub").withConverter(payStubConverter)
            const payStubQuery = query(payStubCollectionRef)
            const payStubDocs = await getDocs(payStubQuery)

            const payStubList = await Promise.all(payStubDocs.docs.map((documentSnapshot, index2) => {
                return {
                    id: documentSnapshot.id,
                    data: documentSnapshot.data()
                }
            }).filter((docWithId) => {
                return docWithId.data.isSend
            }))

            const payStubObject: { [key: string]: PayStub } = {}
            payStubList.forEach(async (doc) => {
                const id: string = doc.id
                payStubObject[id] = doc.data
            })

            const companyUid = staffDoc?.employer
            var companyName = "알 수 없음"
            if (companyUid) {
                const companyRef = doc(fireStore, "users", companyUid).withConverter(userConverter)
                companyName = (await getDoc(companyRef)).data()?.companyName ?? "알 수 없음"
            }
            const returnBody = {
                companyName: companyName,
                staffWithId: {
                    id: staffDocRef.id,
                    data: staffDoc,
                    payStubObj: payStubObject
                } as StaffWithId
            }
            //console.log(returnBody)
            if (index === 0) {
                setPayStubSet(returnBody)
            }
            return returnBody
        })))
    }

    const goBack = () :void =>{
        navigate(-1)
    }
    return (
        <div className="parent-div"
            style={{display:"flex",justifyContent:"center"}}>
            <ReactModal //정보동의
                isOpen={
                    modalVisible3
                    // process.env.NODE_ENV === "production" 
                    // ? modalVisible3 
                    // : true
                }
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",justifyContent:"center"
                    },
                    content: {
                        maxWidth:"800px",
                        flex: 1, position: "relative", inset: "0px",
                        display: "flex", flexDirection: "column",
                        border: "0px solid black"
                    }
                }}>
                <div
                    style={{
                        width: "100%",
                        // maxWidth: "400px",
                        height: "100%",
                        display: "flex", flexDirection: "column",
                        alignItems: "center",
                        fontFamily: "Notosans",
                        padding: `${FIVE_PER}`, boxSizing: "border-box"
                    }}>

                    <img src={logo} style={{
                        width: "30%",
                        maxWidth: "240px",
                        margin: 18
                    }} />
                    <p style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", color: "#727272" }}>
                        <span>노란봉투는 서비스의 이용을 위해</span><br />
                        <span>한달에 한번의 약관 동의와 개인정보 수집에</span><br />
                        <span>대한 동의가 필요합니다.</span>
                    </p>
                    <div style={{
                        flex: 1, display: "flex", flexDirection: "column", width: "100%",
                        overflow:"hidden"
                    }}>
                        <div style={{
                            flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <span style={{ fontWeight: 500 }}>
                                이용약관</span>
                            <ReactQuill theme="snow"
                                style={{ flex: 1, overflowY: "auto", width: "100%" }}
                                readOnly={true}
                                modules={{
                                    toolbar: false,
                                }} value={term} defaultValue={term} />
                        </div>
                        <div style={{
                            boxSizing: "border-box", fontSize: "12px",
                            display: "flex", justifyContent: "flex-end"
                        }}>
                            <div style={{
                                height: "48px", boxSizing: "border-box",
                                fontFamily: "NotoSans",
                                padding: "12px 0px 12px 0px",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <img className="pointer-cursor"
                                    src={firstAccept ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setFirstAccept(true)
                                    }} />
                                <span style={{
                                    color: "#191919", marginInlineEnd: "16px",
                                    wordBreak: "keep-all", whiteSpace: "nowrap",
                                }}>{"동의"}</span>
                                <img className="pointer-cursor"
                                    src={!firstAccept ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setFirstAccept(false)
                                    }} />
                                <span style={{
                                    color: "#191919",
                                    wordBreak: "keep-all", whiteSpace: "nowrap",
                                }}>{"동의 안함"}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        flex: 1, display: "flex", flexDirection: "column", width: "100%",
                        overflow:"hidden"
                    }}>
                        <div style={{
                            flex: 1, overflowY:"hidden",display:"flex",flexDirection:"column",
                            alignItems: "center"
                        }}>
                            <span style={{ fontWeight: 500 }}>
                            개인 정보 취급 방침</span>
                            <ReactQuill theme="snow"
                                style={{ flex: 1, overflowY: "auto", width: "100%" }}
                                readOnly={true}
                                modules={{
                                    toolbar: false,
                                }} value={privacy} defaultValue={privacy} />
                        </div>
                        <div style={{
                            boxSizing: "border-box", fontSize: "12px",
                            display: "flex", justifyContent: "flex-end"
                        }}>
                            <div style={{
                                height: "48px", boxSizing: "border-box",
                                fontFamily: "NotoSans",
                                padding: "12px 0px 12px 0px",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <img className="pointer-cursor"
                                    src={secondAccept ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setSecondAccept(true)
                                    }} />
                                <span style={{
                                    color: "#191919", marginInlineEnd: "16px",
                                    wordBreak: "keep-all", whiteSpace: "nowrap",
                                }}>{"동의"}</span>
                                <img className="pointer-cursor"
                                    src={!secondAccept ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setSecondAccept(false)
                                    }} />
                                <span style={{ color: "#191919", wordBreak: "keep-all", whiteSpace: "nowrap", }}>{"동의 안함"}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        height: "50px", boxSizing: "border-box", fontFamily: "Notosans",
                        padding: "8px 0px 8px 0px", alignSelf: "flex-end",
                        display: "flex",
                        alignItems: "center", justifyContent: "flex-end"
                    }}>
                        <img className="pointer-cursor"
                            src={isAcceptAll ? agree_on : agree_off} style={{ height: "100%" }}
                            onClick={() => {
                                if (isAcceptAll) {
                                    setFirstAccept(false)
                                    setSecondAccept(false)
                                } else {
                                    setFirstAccept(true)
                                    setSecondAccept(true)
                                }
                            }} />
                        <span style={{
                            color: "#9A9A9A", fontWeight: 500, fontSize: "14px",
                            wordBreak: "keep-all", whiteSpace: "nowrap",
                        }}>{"약관 전체동의"}</span>
                    </div>
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            width: "100%", maxWidth: "400px",
                            padding: "12px",
                            marginTop: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={() => {
                            if (isAcceptAll) {
                                payStubSetList.forEach(async({ staffWithId: { id } }) => {
                                    const staffDocRef = doc(fireStore, "staffs", id).withConverter(staffConverter)
                                    try {
                                        await updateDoc(staffDocRef, {
                                            acceptTime: new Date()
                                        })
                                        setModalVisible3(false)
                                    } catch (error) {
                                        alert(error)
                                    }
                                })
                            }
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{"저장"}</span>
                    </div>
                </div>
            </ReactModal>
            <ReactModal //앞자리 확인
                isOpen={ssnModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",justifyContent:"center"
                    },
                    content: {
                        maxWidth:"800px",
                        flex: 1, position: "relative", inset: "0px",
                        display: "flex", flexDirection: "column",
                        border: "0px solid black"
                    }
                }}>
                <div
                    style={{
                        width: "100%",
                        // maxWidth: "400px",
                        height: "100%",
                        display: "flex", flexDirection: "column",
                        fontFamily: "Notosans",
                        padding: `${FIVE_PER}`, boxSizing: "border-box"
                    }}>
                    <img src={logo_read} style={{
                        width: "30%",
                        maxWidth: "180px",
                        marginBottom:"18px"
                    }} />
                    <div style={{
                        display: "flex", flexDirection: "column",flex:1
                    }}>
                        <span style={{marginBottom:"36px"}}>
                            <span style={{ fontSize: "24px", fontWeight: "bold" }}>급여명세서 열람을 위해</span><br />
                            <span style={{ fontSize: "21px" }}>귀하의 정보를 입력해주세요</span>
                        </span>
                        <span style={{ fontSize: "14px", fontWeight: 500, marginBottom: "10px" }}>급여 명세서 조회하기</span>
                        <input
                            style={{
                                padding: "15px 12px", backgroundColor: "#F5F4F1", borderWidth: "0px", outlineWidth: "0px",
                                fontSize: "14px"
                            }}
                            placeholder="생년월일 6자리를 입력해주세요 (ex.910101)"
                            value={checkSSN}
                            maxLength={6}
                            onChange={(e)=>{
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, "")
                                setCheckSSN(onlyNumber)
                                if(errorCode > 0){
                                    setErrorCode(0)
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    enterWithSSN()
                                }
                            }} />
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode > 0 ? "일치하지 않습니다." : "\u200b"}</span>
                        <div style={{
                            flex: 1, display: "flex", flexDirection: "column", alignItems: "center",
                            justifyContent:"center"
                        }}>
                            <div style={{ maxWidth: "480px", display: "flex", flexDirection: "column" }}>
                                <img src={img_read} style={{ maxWidth: "100%" }} />
                            </div>
                        </div>
                    </div>

                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            width: "100%", 
                            padding: "12px",
                            marginTop: "24px",
                            borderRadius: "7px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={() => {
                            enterWithSSN()
                        }}>
                        <span style={{ color: "white", fontWeight: "bold"}}>{"열람하기"}</span>
                    </div>
                </div>
            </ReactModal>
            <ReactModal //근무지 변경
                style={{
                    overlay: {
                        display: "flex",
                        backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center",
                        height: "100%", justifyContent: "center",
                        boxSizing: "border-box",
                        padding: "24px"
                    },
                    content: {
                        flex: 1,
                        position: "relative",
                        inset: "auto",
                        border: "none",
                        padding: "0px",
                        // overflowY: "scroll",
                        width: "100%", boxSizing: "border-box", backgroundColor: "transparent",
                        display: "flex", justifyContent: "center", maxHeight: "100%"
                    }
                }}
                ariaHideApp={false}
                isOpen={modalVisible}
                onRequestClose={() => { setModalVisible(!modalVisible) }}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true} onAfterClose={() => {
                    if (tmpPayStubSet) {
                        setPayStubSet(tmpPayStubSet)
                    }
                    setTmpPayStubSet(undefined)
                }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"

                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`근무지 선택`}</span>
                    <div style={{ height: "30px" }}>
                        {(scrollTop[0] ?? 0) >= 52 && <img src={arrow_up} style={{ height: "100%" }} />}
                    </div>
                    <div ref={listDivRef}
                        style={{
                            flex: 1, width: "100%",
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "flex-start",
                            overflowY: "auto", boxSizing: "border-box"
                        }}
                        onScroll={(ev) => {
                            scrollTop[1](listDivRef.current?.scrollTop!!!)
                        }}>
                        {payStubSetList.map((item, index) => {
                            const { companyName } = item
                            return (
                                <div className="pointer-cursor" key={`${companyName}_${index}`} style={{
                                    flex: 1,
                                    border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", padding: "12px", marginBottom: index < payStubSetList.length - 1 ? "12px" : undefined,
                                    width: "100%"
                                }}
                                    onClick={() => {
                                        //console.log(item)
                                        setModalVisible(!modalVisible)
                                        setTmpPayStubSet(item)
                                    }}>{`${companyName}`}</div>
                            )
                        })}
                    </div>
                    <div style={{ height: "30px" }}>
                        {needMoreScroll >= 52 && <img src={arrow_down} style={{ height: "100%" }} />}
                    </div>
                </div>
            </ReactModal>
            <ReactModal //다른달 선택
                style={{
                    overlay: {
                        display: "flex",
                        backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center",
                        height: "100%", justifyContent: "center",
                        boxSizing: "border-box",
                        padding: "24px"
                    },
                    content: {
                        flex: 1,
                        position: "relative",
                        inset: "auto",
                        border: "none",
                        padding: "0px",
                        // overflowY: "scroll",
                        width: "100%", boxSizing: "border-box", backgroundColor: "transparent",
                        display: "flex", justifyContent: "center", maxHeight: "100%"
                    }
                }}
                ariaHideApp={false}
                isOpen={modalVisible2}
                onRequestClose={() => {
                    setModalVisible2(!modalVisible2)
                }}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                onAfterClose={() => {
                    // if (searchContainer) {
                    //     searchParams.set(searchContainer.name, searchContainer.value)
                    //     setSearchParams(searchParams)
                    // }
                    // setSearchContainer(undefined)
                }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white",
                    flex: 1,
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`다른 달 임금명세서 선택`}</span>
                    <div style={{ height: "30px" }}>
                        {(scrollTop[0] ?? 0) >= 52 && <img src={arrow_up} style={{ height: "100%" }} />}
                    </div>
                    <div ref={listDivRef}
                        style={{
                            flex: 1, width: "100%",
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "flex-start",
                            overflowY: "auto", boxSizing: "border-box"
                        }}
                        onScroll={(ev) => {
                            scrollTop[1](listDivRef.current?.scrollTop!!!)
                        }}>
                        {sendedPayStubList.map((item, index) => {
                            const { monthItem: { text }, monthId } = item
                            return (
                                <div className="pointer-cursor" key={`${text}_${index}`} style={{
                                    flex: 1,
                                    border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", padding: "12px", marginBottom: index < sendedPayStubList.length - 1 ? "12px" : undefined,
                                    width: "100%"
                                }}
                                    onClick={() => {
                                        setModalVisible2(!modalVisible2)
                                        console.log(monthId)
                                        setTargetMonth(monthId)
                                        // setSearchContainer({ name: "month-set", value: monthId })
                                    }}>{`${text}`}</div>
                            )
                        })}
                    </div>
                    <div style={{ height: "30px" }}>
                        {needMoreScroll >= 52 && <img src={arrow_down} style={{ height: "100%" }} />}
                    </div>
                </div>
            </ReactModal>
            <div className={styles.main_ctn}
                style={{maxWidth:"800px"}}>
                <div className={styles.header_ctn}>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                        onClick={goBack} >
                        <img
                            src={back} />
                    </div>
                    <div className={styles.header_center}>
                        <img src={logo} />
                    </div>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}>
                        <span style={{ fontSize: "12px" }}>{selectMonth?.monthItem.text && selectMonth.monthItem.text+"분" }</span>
                    </div>
                </div>
                <div className={styles.body_ctn}>
                    <div className={`side_margin_5per`}
                        style={{display: "flex",boxSizing: "border-box",alignItems: "flex-end"}}>
                        <span className="name_deco">{viewData.staffInfo?.name}</span>
                        <span style={{ marginLeft: "4px", fontSize: "18px", fontWeight: "bold" }}>님</span>
                    </div>
                    {/* topper */}
                    <div className={`side_margin_5per`}
                        style={{marginTop: "12px",display: "flex", flexDirection: "column",boxSizing: "border-box",}}>
                        <div style={{display: "flex", justifyContent: "space-between",fontSize: "12px"}}>
                            {viewData.type?.type! < 2
                                ?
                                <span>
                                    <span style={{ fontWeight: 500, marginRight: "8px" }}>{`부서`}</span>
                                    <span>{viewData?.staffInfo && viewData.staffInfo.part }</span>
                                </span>
                                :
                                <span>
                                    <span style={{ fontWeight: 500, marginRight: "8px" }}>{`생년월일(사번)`}</span>
                                    <span>{birthDate}</span>
                                </span>
                            }
                            <div style={{display: "flex", fontSize: "12px",cursor: payStubSetList.length > 1 ? "pointer" : "auto"}}
                                onClick={() => {
                                    if (payStubSetList.length > 1) {
                                        setModalVisible(!modalVisible)
                                    }
                                }}>
                                <span>
                                    {payStubSet && payStubSet?.companyName}
                                </span>
                                {payStubSetList.length > 1 &&
                                    <img src={arrow_down} style={{ height: "18px" }} />}
                            </div>
                        </div>
                        <div style={{ marginTop: "6px", display: "flex", justifyContent: viewData.type?.type! < 2 ? "space-between" : "flex-end", fontSize: "12px" }}>
                            {viewData.type?.type! < 2 &&
                                <span>
                                    <span style={{ fontWeight: 500, marginRight: "8px" }}>{`직급`}</span>
                                    <span>{viewData.staffInfo && viewData.staffInfo?.rank}</span>
                                </span>}
                            <div style={{ display: "flex", fontSize: "12px", cursor: sendedPayStubList.length > 1 ? "pointer" : "auto" }}
                                onClick={() => {
                                    if (sendedPayStubList.length > 1) {
                                        setModalVisible2(!modalVisible2)
                                    }
                                }}>
                                {viewData.data?.paymentDate.seconds
                                    && <span><span style={{ fontWeight: 500, marginRight: "8px" }}>지급일</span>{`${moment.unix(viewData.data?.paymentDate.seconds).format("YYYY-MM-DD")}`}</span>
                                }
                                {sendedPayStubList.length > 1 &&
                                    <img src={arrow_down} style={{ height: "18px" }} />}
                            </div>
                        </div>
                        {viewData.type?.type! < 2 &&
                            <div style={{ marginTop: "6px", display: "flex", justifyContent: "space-between", fontSize: "12px" }}>
                                <span>
                                    <span style={{ fontWeight: 500, marginRight: "8px" }}>{`생년월일(사번)`}</span>
                                    <span>{birthDate}</span>
                                </span>
                            </div>}
                    </div>
                    <div style={{ minHeight: 5, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                    {sendedPayStubList.length && viewData.data
                        ?
                        viewData.type?.type === 0
                            ? <Emp payStubData={viewData.data as EmpPs} />
                            : viewData.type?.type === 1
                                ? <Part payStubData={viewData.data as PartPs} />
                                : <Day payStubData={viewData.data as DayPs} />
                        : <div style={{
                            padding: "24px 0px 24px 0px",
                            display: "flex", justifyContent: "center",fontSize:"12px"
                        }}><span>조회가능한 임금명세서가 없습니다.</span></div>}
                </div>
                <div style={{
                    boxSizing: "border-box", display: "flex",
                    width: "100%",
                    borderTop: "1px solid #e4e8eb",
                    justifyContent: "space-around", backgroundColor: "#fafbfc"
                }}>
                    <ServiceBanner type={1} />
                </div>
            </div>
        </div>
    )
}