import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import moment, { min, Moment } from "moment";
import React, { CSSProperties, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MonthItem } from "../../classes/Month";
import { AdditionalWorkType, EmpPs, PartPs, PayCategory, PayInputType, PayStub, payStubConverter } from "../../classes/PayStub";
import { StaffWithId } from "../../classes/Staff";
import { fireStore } from "../../fireConfig";
import { setStaffPay, updateStaffPay } from "../../redux/reducer/staff/staff";
import { THEME_YELLOW } from "../../values/Colors";
import { PayInput } from "../../components/PayInput";
import { useBlocker } from "../../components/react-router-dom-revive";
import { Dialog } from "@mui/material"
import { calIncomeTax, commaString2Number, cutOffFuntion, parseLocale } from "../../components/Tools";
import { MonthlyPaymentInput } from "../../components/MonthlyPaymentInput";
import styles from '../../css/PayEmployee.module.css'
import { ic_audio_off, ic_audio_on, ic_pay, back, ic_paper, plus, ic_arrow_down, ic_arrow_up, ic_history } from "../../images/images";
import { PaymentDatePicker } from "../../components/PaymentDatePicker";
import { HourlyWageInput } from "../../components/HourlyWageInput";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import { useMediaQuery } from "react-responsive";
import { EnrollModal } from "../../components/EnrollModal";
import { Tax } from "../../redux/reducer/tax/tax";

export const PayPartTime: FC = () => {
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    const taxState = useSelector((state : {tax : Tax}) => state.tax)
    const [enrollModal,setEnrollModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const location = useLocation()
    const { staff, payStub, targetMonth, staffIndex, readonly } =
        location.state as {
            staff: StaffWithId,
            payStub?: PayStub,
            targetMonth: MonthItem,
            staffIndex: number,
            readonly: boolean
        }
    const [isInit,setIsInit] = useState<boolean>(true);
    const payStubData = payStub?.data as PartPs | undefined
    const selectMonthToPattern = targetMonth.value.year + "-" + targetMonth.value.month
    const reduxDispatch = useDispatch();
    const { id, data, payStubObj } = staff
    const sendedPayStubList = useMemo((): { payStub: PayStub, monthItem: MonthItem }[] => {
        const payStubList: { payStub: PayStub, monthItem: MonthItem }[] = []
        for (const key in payStubObj) {
            if (Object.prototype.hasOwnProperty.call(payStubObj, key)) {
                const payStubItem = payStubObj[key];
                if (payStubItem.isSend && payStubItem.type.type === 1) {
                    var eachMoment : Moment;
                    if(key.split("-")[1]!=="0"){
                        eachMoment = moment(key, "YYYY-MM").add(1, "M")
                    }else {
                        eachMoment = moment(key+"1","YYYY-MM")
                    }
                    const text = eachMoment.format("YYYY년 MM월")
                    const monthItem: MonthItem = {
                        value: {
                            year: eachMoment.get("y"),
                            month: eachMoment.get("M")
                        },
                        text: text
                    }
                    payStubList.push({
                        payStub: payStubItem,
                        monthItem: monthItem
                    })
                }
            }
        }
        payStubList.sort(({ monthItem: { value: a } }, { monthItem: { value: b } }) => {
            if (b.year !== a.year) {
                return b.year - a.year
            } else return b.month - a.month
        })
        //console.log(payStubList)
        return payStubList
    }, [])

    const cutOffUnit: number = 10

    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const listDivRef = useRef<HTMLDivElement>(null)
    const scrollTop = useState<number>();
    const needMoreScroll = useMemo(() => {
        return listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!
    }, [scrollTop[0]])

    const [newTitle, setNewTitle] = useState<string>("")
    const [addVisible, setAddVisible] = useState<boolean>(false)
    const canSavePaystub = () => {
        var state = true
        if (paymentAmount.length < 2) {
            state = false
        }
        return state
    }
    const getCurrentPayStubValue = (): PayStub => {
        return {
            type: staff.data.hireType,
            staffInfo: staff.data,
            data: {
                paymentDate: new Timestamp(paymentDate.unix(), 0),
                hourlyWage: hourlyWage,//시급
                monthlyWorkHour: monthlyWorkHour,//월 근로 시간
                monthlyWorkMinutes: monthlyWorkMinutes,//월 근로 분
                monthlyHolidayHour: monthlyHolidayHour,//월 주휴 시간
                monthlyHolidayMinutes: monthlyHolidayMinutes,//월 주휴 분
                deductionItems: deductionItems,//공제항목
                paymentAmount: paymentAmount,//지급액계
                additionalPayments : additionalPayments,//추가 지급 계
                deductionAmount: deductionAmount,//공제액계
                amount: amount,//실수령액
                isIncludeInsurance : isIncludeInsurance
            } as PartPs
        }
    }

    const setPayInRedux = (payStub: PayStub) => {
        const pushIsSend: PayStub = {
            ...payStub,
            isSend: false
        }
        reduxDispatch(setStaffPay({ id: selectMonthToPattern, payStub: pushIsSend, staffIndex: staffIndex }))
    }
    const updatePayInRedux = (payStub: PayStub) => {
        reduxDispatch(updateStaffPay({ id: selectMonthToPattern, payStub: payStub, staffIndex: staffIndex }))
    }
    const savePayDoc = async () => {
        // console.log(payStubData?.deductionItems)
        const docRef = doc(fireStore, "staffs", id, "payStub", selectMonthToPattern).withConverter(payStubConverter)
        const getPayDoc = await getDoc(docRef)
        const currentPayStub = getCurrentPayStubValue()
        if (getPayDoc.exists()) {
            //console.log("update prograssing")
            await updateDoc(docRef, currentPayStub)
            updatePayInRedux(currentPayStub)
        } else {
            //console.log("set prograssing")
            await setDoc(docRef, { ...currentPayStub, isSend: false })
            setPayInRedux(currentPayStub)
        }
        setEnrollModal(true)
    }
    const adjustValue = (prePayStub: PayStub) => {
        const { hourlyWage, deductionItems, paymentDate, 
            monthlyWorkHour, monthlyWorkMinutes, monthlyHolidayHour,monthlyHolidayMinutes,
            additionalPayments,
        isIncludeInsurance } = prePayStub.data as PartPs
        console.log(prePayStub)
        setPaymentDate(moment.unix(paymentDate.seconds))
        setHourlyWage(hourlyWage)
        setMonthlyWorkHour(monthlyWorkHour)
        setMonthlyWorkMinutes(monthlyWorkMinutes)
        setMonthlyHolidayHour(monthlyHolidayHour)
        setMonthlyHolidayMinutes(monthlyHolidayMinutes)
        setIsIncludeInsurance(isIncludeInsurance)
        setMonthlyPayments(additionalPayments)
        setDeductionItems(deductionItems)
    }
    const openDialog = () => {
        setModalVisible(!modalVisible)
    }
    const goBack = () => {
        navigate(-1)
    }
    useBlocker(() => {
        setModalVisible(false)
        setAddVisible(false)
    }, modalVisible || addVisible)

    useEffect(() => {
        console.log("PayStubㄹㄹㄹ", payStubData)
    }, [])

    const changePaymentsValue = (type: "deduction"|"monthly", value: string | { hour: string, ratio: string }, index: number) => {
        var targetCopyList: PayInputType[] | AdditionalWorkType[]
        switch (type) {
            case "monthly":
                targetCopyList = additionalPayments.slice()
                targetCopyList[index].value = value as string
                console.log(targetCopyList)
                setMonthlyPayments(targetCopyList)
                break;
            case "deduction":
                targetCopyList = deductionItems.slice()
                targetCopyList[index].value = value as string
                setDeductionItems(targetCopyList)
                break;
            default:
                return;
        }
    }
    const [paymentDate, setPaymentDate] = useState<Moment>(payStubData?.paymentDate ? moment.unix(payStubData.paymentDate.seconds) : moment())

    //시급
    const [hourlyWage, setHourlyWage] = useState<string>(payStubData?.hourlyWage ?? "")
    //월 근로 시간
    const [monthlyWorkHour, setMonthlyWorkHour] = useState<string>(payStubData?.monthlyWorkHour ?? "")
    //월 근로 분
    const [monthlyWorkMinutes, setMonthlyWorkMinutes] = useState<string>(payStubData?.monthlyWorkMinutes ?? "")
    //월 주휴 시간
    const [monthlyHolidayHour, setMonthlyHolidayHour] = useState<string>(payStubData?.monthlyHolidayHour ?? "")
    //월 주휴 분
    const [monthlyHolidayMinutes, setMonthlyHolidayMinutes] = useState<string>(payStubData?.monthlyHolidayMinutes ?? "")

    const workHourSum = useMemo(() => {
        const minuteSum = commaString2Number(monthlyWorkMinutes) + commaString2Number(monthlyHolidayMinutes)
        const hourSum = commaString2Number(monthlyWorkHour) + commaString2Number(monthlyHolidayHour)
        return hourSum + (minuteSum / 60)
    }, [monthlyWorkHour, monthlyWorkMinutes, monthlyHolidayHour, monthlyHolidayMinutes])

    const [additionalPayments, setMonthlyPayments] = useState<PayInputType[]>(
        payStubData?.additionalPayments ?? []
    )
    const paymentAmount = useMemo<string>(() => {
        if (readonly)
            return payStubData?.paymentAmount!
        else {
            var workPay = Math.floor(workHourSum * commaString2Number(hourlyWage))
            var addAmount = 0
            additionalPayments.forEach((value) => {
                addAmount += commaString2Number(value.value)
            })
            return cutOffFuntion(100, (workPay+addAmount).toLocaleString(), 10)
        }
    }, [hourlyWage, workHourSum,additionalPayments])

    const [isIncludeInsurance, setIsIncludeInsurance] = useState<number>(payStubData?.isIncludeInsurance  ?? 2);
    const [itemType, setItemType] = useState<"deduction"|"monthly">("monthly")

    const addPaymentItem = (title: string, type: "deduction"|"monthly") => {
        if (type === "deduction") {
            const copyList = deductionItems.slice()
            copyList.push({
                name: `deduction_${deductionItems.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setDeductionItems(copyList)
        }
        if (type === "monthly") {
            const copyList = additionalPayments.slice()
            copyList.push({
                name: `monthly_${additionalPayments.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setMonthlyPayments(copyList)
        }
    }
    const deletePaymentItem = (index: number, type: "deduction"|"monthly") => {
        if (type === "deduction") {
            const copyList = deductionItems.slice()
            copyList.splice(index, 1)
            setDeductionItems(copyList)
        }
        if (type === "monthly") {
            const copyList = additionalPayments.slice()
            copyList.splice(index, 1)
            setMonthlyPayments(copyList)
        }
    }
    const deductionInit: PayInputType[] = [
        {
            name: "incomeTax",
            title: "소득세",
            value: ""
        },
        {
            name: "localeIncomeTax",
            title: "지방소득세",
            value: ""
        },
        {
            name: "nationalPension",
            title: "국민연금",
            value: ""
        },
        {
            name: "employmentInsurance",
            title: "고용보험",
            value: ""
        },
        {
            name: "healthInsurance",
            title: "건강보험",
            value: ""
        },
        {
            name: "longTermCareInsurance",
            title: "장기요양보험",
            value: ""
        },
    ]
    const [deductionItems, setDeductionItems] = useState<PayInputType[]>(payStubData?.deductionItems ?? deductionInit)
    const deductionAmount = useMemo(() => {
        var amount = 0
        if (isIncludeInsurance > 2) {
            deductionItems.forEach((value) => {
                amount += commaString2Number(value.value)
            })
            return parseLocale(amount)
        }
        else if(isIncludeInsurance > 1){
            var allAmount = commaString2Number(paymentAmount)
            console.log(allAmount)
            return cutOffFuntion(3.3, allAmount.toLocaleString(), cutOffUnit)
        }
        else return "0"
    }, [deductionItems, paymentAmount,additionalPayments])

    const paymentObserver = useMemo(() => {
        //0.소득세,1.지방소득세,2.국민연금,3.고용보험,4.건강보험,5.장기요양보험
        console.log("Try",isInit)
        if(isInit) {
            console.log("Stuck")
            setIsInit(false)
            // setDeductionItems(deductionInit)
            return
        }
        console.log("Pass : ",deductionItems)
        const deductionItemCopy = deductionItems.slice()
        var payment;
        if (isIncludeInsurance > 2) {
            payment = paymentAmount
        }
        else{
            payment = "0"
        }
        //IncomeTax 소득세
        const incomeTax = calIncomeTax(payment, staff.data.deduction, cutOffUnit)
        deductionItemCopy[0].value = incomeTax
        deductionItemCopy[1].value = cutOffFuntion(10, incomeTax, cutOffUnit)
        deductionItemCopy[2].value = cutOffFuntion(taxState.nationalPension, payment, cutOffUnit)
        deductionItemCopy[3].value = cutOffFuntion(taxState.employmentInsurance, payment, cutOffUnit)
        const healthInsurance = cutOffFuntion(taxState.healthInsurance, payment, cutOffUnit)
        deductionItemCopy[4].value = healthInsurance
        deductionItemCopy[5].value = cutOffFuntion(taxState.longtermCareInsurance, healthInsurance, cutOffUnit)
        setDeductionItems(deductionItemCopy)
    }, [paymentAmount, isIncludeInsurance])

    const amount = useMemo(() => {
        const p: number = commaString2Number(paymentAmount)
        const d: number = commaString2Number(deductionAmount)
        return (p - d).toLocaleString()
    }, [paymentAmount, deductionAmount])
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}>
        <EnrollModal
            isMobile={isMobile}
            open={enrollModal}
            onClose={() => { navigate(-1) }} />
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={modalVisible}
                onClose={() => { setModalVisible(!modalVisible) }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`지난달 내역`}</span>
                    <div style={{ height: "30px" }}>
                        {(scrollTop[0] ?? 0) >= 52 && <img src={ic_arrow_up} style={{ height: "100%" }} />}
                    </div>
                    <div ref={listDivRef}
                        style={{
                            flex: 1, width: "100%",
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "flex-start",
                            overflowY: "auto", boxSizing: "border-box"
                        }}
                        onScroll={(ev) => {
                            scrollTop[1](listDivRef.current?.scrollTop!!!)
                        }}>
                        {sendedPayStubList.map((item, index) => {
                            const { monthItem, payStub } = item
                            return (
                                <div className="pointer-cursor"
                                    key={`${monthItem.text}`} style={{
                                        flex: 1,
                                        border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        boxSizing: "border-box", padding: "12px", marginBottom: index < sendedPayStubList.length - 1 ? "12px" : undefined,
                                        width: "100%"
                                    }}
                                    onClick={() => {
                                        console.log(item)
                                        adjustValue(payStub)
                                        setModalVisible(!modalVisible)
                                    }}>{`${monthItem.text}`}</div>
                            )
                        })}
                    </div>
                    <div style={{ height: "30px" }}>
                        {needMoreScroll >= 52 && <img src={ic_arrow_down} style={{ height: "100%" }} />}
                    </div>
                </div>
            </Dialog>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        // overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={addVisible}
                onClose={() => {
                    setAddVisible(!addVisible)
                    setNewTitle("")
                }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold", alignSelf: "center" }}>{`추가 ${itemType === "deduction" ? "공제명" : "지급명"}`}</span>
                    <input
                        style={{
                            border: `2px solid ${THEME_YELLOW}`, borderRadius: "12px", height: "48px",
                            padding: "8px",
                            boxSizing: "border-box", margin: "12px 0px 12px 0px", outlineWidth: "0px"
                        }}
                        value={newTitle}
                        onChange={(e) => {
                            setNewTitle(e.target.value)
                        }} />
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            padding: "12px",
                            borderRadius: "52px",
                            backgroundColor: newTitle ? THEME_YELLOW : "#EBEBEB",
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={async () => {
                            if (newTitle) {
                                addPaymentItem(newTitle, itemType)
                                setAddVisible(!addVisible)
                                setNewTitle("")
                            } else {
                                alert("모든 입력란을 알맞게 채워주세요")
                            }
                        }}>
                        <span style={{ color: newTitle ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                    </div>
                </div>
            </Dialog>
            {isMobile
                ? <div className={styles.main_ctn}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <img src={ic_pay} />
                            <span>{"급여등록"}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}>
                            <span style={{ fontSize: "12px" }}>{`${targetMonth.text}분`}</span>
                        </div>
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center"
                            }}>
                            <span className="name_deco">{staff.data.name}</span>
                            <span style={{
                                marginLeft: "8px",
                                fontSize: "14px", fontWeight: 500
                            }}>{`아르바이트`}</span>
                        </div>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex", flexDirection: "column"
                            }} >
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                        {/* paymentAmount */}
                        <div className={`side_margin_5per`} style={{
                            display: "flex", flexDirection: "column",
                        }}>
                            <TitleInputUnit
                                title="시급입력"
                                outerStyle={{
                                    padding: "4px 0px"
                                }}
                                titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                value={hourlyWage}
                                unit="원"
                                readonly={readonly}
                                onChange={(event) => {
                                    const input: string = event.target.value
                                    setHourlyWage(parseLocale(input))
                                }} />
                            <div style={{ display: "flex", padding: "4px 0px" }}>
                                <TitleInputUnit
                                    outerStyle={{ flex: 2 }}
                                    title="월 근로시간"
                                    titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                    value={monthlyWorkHour}
                                    unit="시간"
                                    readonly={readonly}
                                    onChange={(event) => {
                                        const input: string = event.target.value
                                        setMonthlyWorkHour(parseLocale(input))
                                    }} />
                                <TitleInputUnit
                                    outerStyle={{ flex: 1 }}
                                    titleStyle={{ marginRight: 16 }}
                                    value={monthlyWorkMinutes}
                                    placeholder="10분 단위"
                                    unit="분"
                                    readonly={readonly}
                                    onBlur={() => {
                                        const numb = commaString2Number(monthlyWorkMinutes)
                                        if (numb > 0 && numb < 10) {
                                            setMonthlyWorkMinutes("10")
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input: string = event.target.value
                                        const removeComma: string = input.replace(/[^0-9]/g, "")
                                        var str = ""
                                        if (removeComma.length > 0) {
                                            const numberInput: number = parseInt(removeComma)
                                            if (numberInput >= 50) {
                                                str = "50"
                                            } else if (numberInput >= 10) {
                                                const cutoff: number = Math.floor(numberInput / 10) * 10
                                                const pushComma: string = cutoff.toLocaleString()
                                                str = pushComma
                                            } else {
                                                str = numberInput.toLocaleString()
                                            }
                                        } else
                                            str = ""
                                        setMonthlyWorkMinutes(str)
                                    }} />
                            </div>
                            <div style={{
                                display: "flex", padding: "4px 0px"
                            }}>
                                <TitleInputUnit
                                    outerStyle={{ flex: 2 }}
                                    title="월 주휴시간"
                                    titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                    value={monthlyHolidayHour}
                                    unit="시간"
                                    readonly={readonly}
                                    onChange={(event) => {
                                        const input: string = event.target.value
                                        setMonthlyHolidayHour(parseLocale(input))
                                    }} />
                                <TitleInputUnit
                                    outerStyle={{ flex: 1 }}
                                    titleStyle={{ marginRight: 16 }}
                                    value={monthlyHolidayMinutes}
                                    placeholder="10분 단위"
                                    unit="분"
                                    readonly={readonly}
                                    onBlur={() => {
                                        const numb = commaString2Number(monthlyHolidayMinutes)
                                        if (numb > 0 && numb < 10) {
                                            setMonthlyHolidayMinutes("10")
                                        }
                                    }}
                                    onChange={(event) => {
                                        const input: string = event.target.value
                                        const removeComma: string = input.replace(/[^0-9]/g, "")
                                        var str = ""
                                        if (removeComma.length > 0) {
                                            const numberInput: number = parseInt(removeComma)
                                            if (numberInput >= 50) {
                                                str = "50"
                                            } else if (numberInput >= 10) {
                                                const cutoff: number = Math.floor(numberInput / 10) * 10
                                                const pushComma: string = cutoff.toLocaleString()
                                                str = pushComma
                                            } else {
                                                str = numberInput.toLocaleString()
                                            }
                                        } else
                                            str = ""
                                        setMonthlyHolidayMinutes(str)
                                    }} />
                            </div>
                        </div>
                        {/* isIncludeInsurance */}
                        <div className={`side_margin_5per`} style={{
                            borderTop: "1px solid #E3E3E3",
                            paddingTop: "16px", paddingBottom: "16px", marginTop: "24px",
                            display: "flex", flexDirection: "column",
                            fontFamily: "Notosans", boxSizing: "border-box",
                        }}>
                            <span style={{
                                color: "#191919", marginBottom: "4px",
                                fontWeight: 500
                            }}>{"4대보험 해당 여부"}</span>
                            <div style={{
                                height: "30px", boxSizing: "border-box",
                                display: "flex", alignItems: "center"
                            }}>
                                <img className="pointer-cursor"
                                    src={isIncludeInsurance === 3 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                    onClick={() => {
                                        !readonly &&
                                            setIsIncludeInsurance(3)
                                    }} />
                                <span style={{ color: "#191919", marginInlineEnd: "16px" }}>{"예"}</span>
                                <img className="pointer-cursor"
                                    src={isIncludeInsurance === 2 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                    onClick={() => {
                                        !readonly &&
                                            setIsIncludeInsurance(2)
                                    }} />
                                <span style={{ color: "#191919", marginInlineEnd: "16px"  }}>{"아니오"}</span>
                                <img className="pointer-cursor"
                                    src={isIncludeInsurance === 1 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                    onClick={() => {
                                        !readonly &&
                                            setIsIncludeInsurance(1)
                                    }} />
                                <span style={{ color: "#191919" }}>{"3.3% 미공제"}</span>
                            </div>
                        </div>
                        {/* deductions */}
                        {isIncludeInsurance > 2 &&
                            <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column" }}>
                                <span className="name_deco"
                                    style={{ fontSize: "18px" }}>{`공제항목`}</span>
                                <div className={`${styles.deduct_ctn}`}>
                                    {deductionItems.map((value, index) => {
                                        return (
                                            <MonthlyPaymentInput
                                                key={`${value.title}_${index}`}
                                                styles={{
                                                    titleStyle: {
                                                        fontWeight: 500
                                                    }
                                                }}
                                                readonly={readonly}
                                                values={value}
                                                onChange={(value) => {
                                                    changePaymentsValue("deduction", value, index)
                                                }}
                                                onClickDelete={() => {
                                                    deletePaymentItem(index, "deduction")
                                                }} />
                                        )
                                    })}
                                    {!readonly &&
                                        <div className={styles.btn_add_item_ctn}
                                            onClick={() => {
                                                setItemType("deduction")
                                                setAddVisible(!addVisible)
                                            }}>
                                            <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                            <img src={plus}
                                                style={{ height: "32px", width: "auto" }} />
                                        </div>}
                                </div>
                            </div>}
                        <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                            {/* PaymentAmount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={true}
                                title={"지급액계"}
                                value={paymentAmount}
                                onChange={() => { }} />
                            {/* DeductionAmount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={true}
                                title={"공제액계"}
                                value={deductionAmount}
                                onChange={() => { }} />
                            {/* Amount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={true}
                                title={"실수령액"}
                                value={amount} />
                        </div>
                    </div>

                    {!readonly &&
                        <div className={styles.foo_ctn}>
                            <div className={`pointer-cursor ${styles.btn_pre_paystub_dialog}`}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <span style={{ fontSize: "12px", fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                <img src={ic_paper} style={{ height: "28px" }} />
                            </div>
                            {/* submit btn */}
                            <div className={`pointer-cursor ${styles.btn_submit}`}
                                style={{
                                    backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                }}
                                onClick={async () => {
                                    if (canSavePaystub()) {
                                        savePayDoc()
                                    } else {
                                        alert("모든 입력란을 알맞게 채워주세요")
                                    }
                                }}>
                                <span style={{ color: canSavePaystub() ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                            </div>
                        </div>}
                </div>
                :
                <div className={styles.main_ctn}
                    style={{ maxWidth: "2400px" }}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{ paddingLeft: "2.5%" }}>
                            <img src={ic_pay} />
                            <span>{"급여등록"}</span>
                        </div>
                        {!readonly &&
                            <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                                style={{
                                    flexDirection: "row", paddingRight: "2.5%"
                                }}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <div style={{
                                    border: `1px solid ${THEME_YELLOW}`, borderRadius: "6px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", height: "36px", padding: "4px 16px"
                                }}>
                                    <span style={{ fontSize: "14px", color: THEME_YELLOW, fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                    <img src={ic_history} style={{ height: "24px" }} />
                                </div>

                            </div>}
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_2_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "space-between"
                            }}>
                            <span style={{
                                fontSize: "14px", fontWeight: 500
                            }}><span className="name_deco"
                                style={{
                                    marginRight: "8px"
                                }}>{staff.data.name}</span>{`아르바이트`}</span>
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                style={{ rightBoxStyle: { marginLeft: "8px",cursor:"pointer" } }}
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                        <div style={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex", flexDirection: "column"
                        }}>
                            <div style={{
                                overflow: "hidden",
                                display: "flex", flexDirection: "row"
                            }}>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto"
                                    }}>
                                    {/* paymentAmount */}
                                    <div className={`side_margin_5per`} style={{
                                        marginTop: "16px", marginBottom: "16px",
                                        display: "flex", flexDirection: "column"
                                    }}>
                                        <TitleInputUnit
                                            title="시급입력"
                                            outerStyle={{
                                                padding: "4px 0px"
                                            }}
                                            titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                            value={hourlyWage}
                                            unit="원"
                                            readonly={readonly}
                                            onChange={(event) => {
                                                const input: string = event.target.value
                                                setHourlyWage(parseLocale(input))
                                            }} />
                                        <div style={{ display: "flex", padding: "4px 0px" }}>
                                            <TitleInputUnit
                                                outerStyle={{ flex: 2 }}
                                                title="월 근로시간"
                                                titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                                value={monthlyWorkHour}
                                                unit="시간"
                                                readonly={readonly}
                                                onChange={(event) => {
                                                    const input: string = event.target.value
                                                    setMonthlyWorkHour(parseLocale(input))
                                                }} />
                                            <TitleInputUnit
                                                outerStyle={{ flex: 1 }}
                                                titleStyle={{ marginRight: 16 }}
                                                value={monthlyWorkMinutes}
                                                placeholder="10분 단위"
                                                unit="분"
                                                readonly={readonly}
                                                onBlur={() => {
                                                    const numb = commaString2Number(monthlyWorkMinutes)
                                                    if (numb > 0 && numb < 10) {
                                                        setMonthlyWorkMinutes("10")
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    const input: string = event.target.value
                                                    const removeComma: string = input.replace(/[^0-9]/g, "")
                                                    var str = ""
                                                    if (removeComma.length > 0) {
                                                        const numberInput: number = parseInt(removeComma)
                                                        if (numberInput >= 50) {
                                                            str = "50"
                                                        } else if (numberInput >= 10) {
                                                            const cutoff: number = Math.floor(numberInput / 10) * 10
                                                            const pushComma: string = cutoff.toLocaleString()
                                                            str = pushComma
                                                        } else {
                                                            str = numberInput.toLocaleString()
                                                        }
                                                    } else
                                                        str = ""
                                                    setMonthlyWorkMinutes(str)
                                                }} />
                                        </div>
                                        <div style={{
                                            display: "flex", padding: "4px 0px"
                                        }}>
                                            <TitleInputUnit
                                                outerStyle={{ flex: 2 }}
                                                title="월 주휴시간"
                                                titleStyle={{ minWidth: "80px", fontWeight: 500 }}
                                                value={monthlyHolidayHour}
                                                unit="시간"
                                                readonly={readonly}
                                                onChange={(event) => {
                                                    const input: string = event.target.value
                                                    setMonthlyHolidayHour(parseLocale(input))
                                                }} />
                                            <TitleInputUnit
                                                outerStyle={{ flex: 1 }}
                                                titleStyle={{ marginRight: 16 }}
                                                value={monthlyHolidayMinutes}
                                                placeholder="10분 단위"
                                                unit="분"
                                                readonly={readonly}
                                                onBlur={() => {
                                                    const numb = commaString2Number(monthlyHolidayMinutes)
                                                    if (numb > 0 && numb < 10) {
                                                        setMonthlyHolidayMinutes("10")
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    const input: string = event.target.value
                                                    const removeComma: string = input.replace(/[^0-9]/g, "")
                                                    var str = ""
                                                    if (removeComma.length > 0) {
                                                        const numberInput: number = parseInt(removeComma)
                                                        if (numberInput >= 50) {
                                                            str = "50"
                                                        } else if (numberInput >= 10) {
                                                            const cutoff: number = Math.floor(numberInput / 10) * 10
                                                            const pushComma: string = cutoff.toLocaleString()
                                                            str = pushComma
                                                        } else {
                                                            str = numberInput.toLocaleString()
                                                        }
                                                    } else
                                                        str = ""
                                                    setMonthlyHolidayMinutes(str)
                                                }} />
                                        </div>

                                        <span className="name_deco" style={{
                                            fontSize: "18px", fontWeight: "bold",marginTop:"16px"
                                        }}>{`추가지급`}</span>
                                        {additionalPayments.map((value, index) => {
                                            return (
                                                <MonthlyPaymentInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        changePaymentsValue("monthly", value, index)
                                                    }}
                                                    onClickDelete={() => {
                                                        deletePaymentItem(index, "monthly")
                                                    }} />
                                            )
                                        })}
                                        {!readonly &&
                                            <div className={styles.btn_add_item_ctn}
                                                onClick={() => {
                                                    setItemType("monthly")
                                                    setAddVisible(!addVisible)
                                                }}>
                                                <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                <img src={plus}
                                                    style={{ height: "32px", width: "auto" }} />
                                            </div>}
                                    </div>

                                    {/* isIncludeInsurance */}
                                    <div className={`side_margin_5per`} style={{
                                        borderTop: "1px solid #E3E3E3",
                                        paddingTop: "16px", paddingBottom: "16px", 
                                        display: "flex", flexDirection: "column",
                                        fontFamily: "Notosans", boxSizing: "border-box",
                                    }}>
                                        <span style={{
                                            color: "#191919", marginBottom: "4px",
                                            fontWeight: 500
                                        }}>{"4대보험 해당 여부"}</span>
                                        <div style={{
                                            height: "30px", boxSizing: "border-box",
                                            display: "flex", alignItems: "center"
                                        }}>
                                            <img className="pointer-cursor"
                                                src={isIncludeInsurance === 3 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                                onClick={() => {
                                                    !readonly &&
                                                        setIsIncludeInsurance(3)
                                                }} />
                                            <span style={{ color: "#191919", marginInlineEnd: "16px" }}>{"예"}</span>
                                            <img className="pointer-cursor"
                                                src={isIncludeInsurance === 2 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                                onClick={() => {
                                                    !readonly &&
                                                        setIsIncludeInsurance(2)
                                                }} />
                                            <span style={{ color: "#191919" }}>{"아니오"}</span>
                                            <img className="pointer-cursor"
                                                src={isIncludeInsurance === 1 ? ic_audio_on : ic_audio_off} style={{ height: "80%" }}
                                                onClick={() => {
                                                    !readonly &&
                                                        setIsIncludeInsurance(1)
                                                }} />
                                            <span style={{ color: "#191919" }}>{"3.3% 미공제"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto"
                                    }}>
                                    {/* deductions */}
                                    {isIncludeInsurance > 2 &&
                                        <div className="side_margin_5per"
                                            style={{
                                                display: "flex", flexDirection: "column",
                                                marginTop: "16px", marginBottom: "16px"
                                            }}>
                                            <span className="name_deco"
                                                style={{ fontSize: "18px" }}>{`공제항목`}</span>
                                            <div className={`${styles.deduct_ctn}`}>
                                                {deductionItems.map((value, index) => {
                                                    return (
                                                        <MonthlyPaymentInput
                                                            key={`${value.title}_${index}`}
                                                            styles={{
                                                                titleStyle: {
                                                                    fontWeight: 500
                                                                }
                                                            }}
                                                            readonly={readonly}
                                                            values={value}
                                                            onChange={(value) => {
                                                                changePaymentsValue("deduction", value, index)
                                                            }}
                                                            onClickDelete={() => {
                                                                deletePaymentItem(index, "deduction")
                                                            }} />
                                                    )
                                                })}
                                                {!readonly &&
                                                    <div className={styles.btn_add_item_ctn}
                                                        onClick={() => {
                                                            setItemType("deduction")
                                                            setAddVisible(!addVisible)
                                                        }}>
                                                        <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                        <img src={plus}
                                                            style={{ height: "32px", width: "auto" }} />
                                                    </div>}
                                            </div>
                                        </div>}
                                    <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                                        {/* PaymentAmount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={true}
                                            title={"지급액계"}
                                            value={paymentAmount}
                                            onChange={() => { }} />
                                        {/* DeductionAmount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={true}
                                            title={"공제액계"}
                                            value={deductionAmount}
                                            onChange={() => { }} />
                                        {/* Amount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={true}
                                            title={"실수령액"}
                                            value={amount} />
                                    </div>
                                </div>
                            </div>
                            {!readonly &&
                                <div className={styles.foo_ctn}
                                    style={{
                                        flexDirection: "row", justifyContent: "flex-end"
                                    }}>
                                    {/* submit btn */}
                                    <div className="pointer-cursor" style={{
                                        border: "1px solid #767676", borderRadius: "3px",
                                        color: "#767676",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px", marginRight: "12px"
                                    }}
                                        onClick={() => { navigate(-1) }}>취소</div>
                                    <div className="pointer-cursor" style={{
                                        backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                        color: canSavePaystub() ? "white" : "#B9B9B9",
                                        borderRadius: "3px",
                                        fontWeight: 500,
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px"
                                    }}
                                        onClick={async () => {
                                            if (canSavePaystub()) {
                                                savePayDoc()
                                            } else {
                                                alert("모든 입력란을 알맞게 채워주세요")
                                            }
                                        }}>등록</div>
                                </div>}
                        </div>
                    </div>
                </div>}
        </div>
    )
}