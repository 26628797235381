import { FirestoreDataConverter, Timestamp } from "firebase/firestore"
export interface BannerWithId {
    banner : Banner,
    id : string,
    index?:number,
    base64? : string
}
export interface Banner {
    type : number,
    name : string,
    url : string,
    destination : string,
    createTime : Timestamp
}

export class Banner implements Banner{
    constructor(
        type : number,
        name : string,
        url : string,
        destination : string,
        createTime : Timestamp
    ){
        this.type = type
        this.name = name
        this.url = url
        this.destination = destination
        this.createTime = createTime
    }
}

export const bannerConverter : FirestoreDataConverter<Banner> = {
    toFirestore : (banner : Banner) => {
        return {
            type : banner.type,
            name : banner.name,
            url : banner.url,
            destination : banner.destination,
            createTime : banner.createTime
        }
    },
    fromFirestore : (snapshot,options) => {
        const {
            type,name,url,destination,createTime
        } = snapshot.data(options) as Banner
        return new Banner(type,name,url,destination,
            new Timestamp(createTime.seconds,createTime.nanoseconds))
    }
}