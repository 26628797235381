import { Dialog } from "@mui/material"
import { deleteDoc, doc } from "firebase/firestore";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { MonthSet } from "../classes/Month";
import { StaffWithId } from "../classes/Staff";
import { fireAuth, fireStore } from "../fireConfig";
import { deleteUsersRedux } from "../redux/reducer/staff/staff";
import { THEME_YELLOW } from "../values/Colors";
import arrow_down from "../images/manage-pay/ic_arrow_down@2x.png"
import arrow_up from "../images/manage-pay/ic_arrow_up@2x.png"
import { selectMonthItem } from "../redux/reducer/month-set/month-set";
import { sendPasswordResetEmail } from "firebase/auth";
import { FirebaseError } from "firebase/app";

export const MyDialog =  () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {type} = useParams<"type">();
    const reduxDispatch = useDispatch();
    const data = location.state.data
    const reduxState = useSelector((state: { monthSet: MonthSet }) => state);
    const { monthSet } = reduxState

    const [email, setEmail] = useState<string>("")
    const [errorCode, setErrorCode] = useState<number>(0)
    const listDivRef = useRef<HTMLDivElement>(null)
    const scrollTop = useState<number>();
    const needMoreScroll = useMemo(()=>{
        return listDivRef.current?.scrollHeight!!!- listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!
    },[scrollTop[0]])
    // delete-user
    var targetList: string[] = [];
    if(type === "delete-user"){
        targetList = data.targetList
        //console.log("Is DeleteUser : "+targetList.length)
    }

    //
    if(type === "resign"){
        
    }
    const onDismiss = ()=> {
        navigate(-1)
    }

    const deleteUser = async (targetList : string[]) => {
        const targetUsers = targetList;
        await deleteUserFireStore(targetUsers);
        reduxDispatch(deleteUsersRedux(targetUsers))
    }

    const deleteUserFireStore = async (id: string[]) => {
        await Promise.all(
            id.map(async (id, index): Promise<void> => {
                const docRef = doc(fireStore, "staffs", id)
                await deleteDoc(docRef)
                //console.log(id)
            }))
    }


    return (
        <Dialog
            PaperProps={{
                style:{
                    backgroundColor:"transparent",
                    boxShadow:"none",borderRadius:"0px",
                    flexDirection:"row",justifyContent:"center",
                    overflow:"auto"      
                }
            }}
            fullWidth={true}
            open={true}
            onClose={onDismiss}>
            {type === "delete-user"
                ? 
                <div style={{
                    backgroundColor:"white",
                    borderRadius: "24px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column"
                }}>
                    <span style={{ fontFamily: "NotoSans", margin: 24, fontWeight: 500 }}>{`삭제하시겠습니까?(${targetList.length}명)`}</span>
                    <div style={{
                        width: "100%", display: "flex",
                        borderWidth: "1px 0px 0px 0px", borderStyle: "solid", borderColor: "#B7B7B7"
                    }}>
                        <div className="pointer-cursor"
                            style={{
                                flex: 1, display: "flex",
                                alignItems: "center", justifyContent: "center", padding: 16
                            }}
                            onClick={() => {
                                onDismiss()
                            }}>
                            <span style={{ fontFamily: "NotoSans" }}>{`취소`}</span>
                        </div>
                        <div className="pointer-cursor"
                            style={{
                                flex: 1, display: "flex",
                                boxSizing: "border-box",
                                borderWidth: "0px 0px 0px 1px", borderStyle: "solid", borderColor: "#B7B7B7",
                                alignItems: "center", justifyContent: "center", padding: 16
                            }}
                            onClick={async () => {
                                await deleteUser(targetList)
                                //console.log("Finish DeleteUser")
                                onDismiss()
                            }}>
                            <span style={{ fontFamily: "NotoSans" }}>{`삭제`}</span>
                        </div>
                    </div>
                </div>
                : type === "no-auth"
                    ? <div style={{
                        borderRadius: "0px",
                        width: "100%",
                        boxSizing: "border-box",
                        display: "flex", flexDirection: "column",
                        fontFamily: "NotoSans"
                    }}>
                        <div style={{
                            width: "100%", display: "flex",
                            backgroundColor: THEME_YELLOW,
                            alignItems: "center", justifyContent: "center",
                        }}>
                            <span style={{ margin: 12, fontWeight: 500, color: "white" }}>{`서비스 이용불가`}</span>
                        </div>
                        <div style={{ backgroundColor: "white",fontSize:"12px" }}>
                            <p style={{textAlign:"center"}}>
                                <span><span style={{fontWeight:"bold"}}>{`회원정보 > 결제하기`}</span>{`를 통해`}</span><br/>
                                <span>{`노랑봉투의 서비스를 이용하실 수 있습니다.`}</span>
                            </p>
                        </div>
                    </div>
                    : type === "select-month"
                        ? <div style={{
                            borderRadius: "24px",
                            padding:"24px",
                            width: "100%", maxWidth: "300px",
                            boxSizing: "border-box",
                            display: "flex", flexDirection: "column",
                            alignItems:"center",
                            fontFamily: "NotoSans",
                            backgroundColor: "white"
                            
                        }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`지난달 내역`}</span>
                            <div style={{ height: "30px" }}>
                                {(scrollTop[0] ?? 0) >= 52 && <img src={arrow_up} style={{ height: "100%" }} />}
                            </div>
                            <div ref={listDivRef}
                                style={{
                                    flex: 1, width: "100%",
                                    display: "flex", flexDirection: "column",
                                    alignItems: "center", justifyContent: "flex-start",
                                    overflowY: "auto", boxSizing: "border-box"
                                }}
                                onScroll={(ev) => {
                                    scrollTop[1](listDivRef.current?.scrollTop!!!)
                                }}>
                                {monthSet.monthList.map((item, index) => {
                                    return (
                                        <div className="pointer-cursor"
                                            key={`${item.text}`} style={{
                                                flex: 1,
                                                border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                                display: "flex", alignItems: "center", justifyContent: "center",
                                                boxSizing: "border-box", padding: "12px", marginBottom: index < monthSet.monthList.length - 1 ? "12px" : undefined,
                                                width: "100%"
                                            }}
                                            onClick={() => {
                                                //console.log(item)
                                                reduxDispatch(selectMonthItem(item))
                                                navigate(-1)
                                            }}>{`${item.text}`}</div>
                                    )
                                })}
                            </div>
                            <div style={{ height: "30px" }}>
                                {needMoreScroll >= 52 && <img src={arrow_down} style={{ height: "100%" }} />}
                            </div>
                        </div>
                        : type == "find-password"
                        ?<div style={{
                            borderRadius: "0px",
                            width: "100%",
                            boxSizing: "border-box",
                            display: "flex", flexDirection: "column",
                            fontFamily: "NotoSans"
                            }}>
                                <div style={{
                                    width: "100%", display: "flex",
                                    backgroundColor: THEME_YELLOW,
                                    alignItems: "center", justifyContent: "center",
                                }}>
                                    <span style={{ margin: 12, fontWeight: 500, color: "white" }}>{`비밀번호 재설정`}</span>
                                </div>
                                <div style={{ backgroundColor: "white", fontSize: "12px" }}>
                                    <input style={{
                                        outlineWidth: "0px", borderWidth: "0px",
                                        padding: "24px 12px 24px 12px", boxSizing: "border-box",
                                        width: "100%"
                                    }}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setErrorCode(0)
                                        }}
                                        placeholder="이메일" />
                                    <span style={{color:"red"}}>{errorCode > 0
                                        ? errorCode > 1
                                            ? errorCode > 2
                                                ? "일치하는 사용자가 없습니다."
                                                : "유효하지 않은 이메일입니다."
                                            : "이메일을 적어주세요" 
                                        : ""}</span>
                                    <div className="pointer-cursor"
                                        style={{
                                            width: "100%", display: "flex",
                                            backgroundColor: THEME_YELLOW,
                                            alignItems: "center", justifyContent: "center",
                                        }} onClick={async () => {
                                            try {
                                                await sendPasswordResetEmail(fireAuth, email)
                                                navigate("/dialog/send-reset-password-mail", {
                                                    state: { ...location.state,email:email},
                                                replace:true
                                            })
                                        } catch (error) {
                                            if(error instanceof FirebaseError){
                                                console.log(error.message.toString())
                                                switch (error.code) {
                                                    case "auth/missing-email":
                                                        setErrorCode(1)
                                                        break;
                                                    case "auth/invalid-email":
                                                        setErrorCode(2)
                                                        break;
                                                    case "auth/user-not-found":
                                                        setErrorCode(3)
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                    }}>
                                        <span style={{ margin: 12, fontWeight: 500, color: "white" }}>{`확인`}</span>
                                    </div>
                                </div>
                            </div>
                            : type === "send-reset-password-mail"
                                ? <div style={{
                                    borderRadius: "0px",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    display: "flex", flexDirection: "column",
                                    fontFamily: "NotoSans"
                                }}>
                                    <div style={{
                                        width: "100%", display: "flex",
                                        backgroundColor: THEME_YELLOW,
                                        alignItems: "center", justifyContent: "center",
                                    }}>
                                        <span style={{ margin: 12, fontWeight: 500, color: "white" }}>{`메일 발송 성공`}</span>
                                    </div>
                                    <div style={{ backgroundColor: "white", fontSize: "12px" }}>
                                        <p style={{ textAlign: "center" }}>
                                            <span><span style={{ fontWeight: "bold" }}>{`${location.state.email}`}</span>{`로`}</span><br />
                                            <span>{`비밀번호 재설정 메일을 보냈습니다.`}</span>
                                        </p>
                                    </div>
                                </div>
                                : null}
        </Dialog>
    )
}