import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { QnA } from '../../classes/QnA'
import { ServiceBanner } from '../../components/ServiceBanner'
import styles from "../../css/QnA/QnAEdit.module.css"
import { CONTENTS, QNA, QNA_EDIT, TITLE } from '../../values/strings'
import ReactQuill,{Quill} from 'react-quill';
import { THEME_YELLOW } from '../../values/Colors'
import { UserWithId } from '../../classes/User'
import { pushQnA } from '../../functions/firebaseTools'
import { arrayUnion, deleteDoc, doc, DocumentReference, Timestamp, updateDoc } from 'firebase/firestore'
import ReactModal from 'react-modal'
import PulseLoader from 'react-spinners/PulseLoader'
import { pushQnARef } from '../../redux/reducer/qna/qna'
import { back } from '../../images/images'
import { fireStore } from '../../fireConfig'
import { pushMyQnA } from '../../redux/reducer/user/user'

export const QnAEdit: FC = () => {
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();
    // const location = useLocation()
    const { isMobile,user } = useSelector((state: { isMobile: boolean, user : UserWithId}) => state)

    const [title, setTitle] = useState<string>("")
    const [contents,setContents] = useState<string>("")

    const [loadingModal,setLoadingModal] = useState<boolean>(false)
    const goBack = () => {
        navigate(-1)
    }
    const quillRef = useRef<ReactQuill>(null)

    useEffect(() => {
        console.log(user.id)
    }, [])

    const finishEdit = async () => {
        if(quillRef){
        setLoadingModal(true)
        var docRef : DocumentReference<QnA> | undefined = undefined;
        try {
            if (title.length < 1 || (quillRef.current?.unprivilegedEditor?.getLength() ?? 0) < 10) {
                alert("제목과 내용 10자 이상을 입력해 주세요.")
                throw new Error()
            }
            docRef = await pushQnA({
                createTime: Timestamp.now(),
                writer: user.id,
                questionTitleHtml: title,
                questionContentsHtml: contents,
                readable : true
            })
            await updateDoc(doc(fireStore,"users",user.id),{
                qnaList : arrayUnion(docRef.id)
            })
            reduxDispatch(pushMyQnA(docRef.id))
            reduxDispatch(pushQnARef(docRef))
            goBack()
        } catch (error) {
            if(docRef){
                deleteDoc(docRef)
            }
            console.log(error)
            setLoadingModal(false)
        }}
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <ReactModal
                isOpen={loadingModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                    },
                    content: {
                        position: "relative",inset:"0px",
                        verticalAlign: "center",
                        display: "flex",
                        justifyContent: "center", alignItems: "center",
                        borderWidth:"0px",backgroundColor:"transparent"
                    }
                }}>
                <PulseLoader color={THEME_YELLOW} size={14} margin={8} speedMultiplier={1} />
            </ReactModal>
            <div className={styles.main_ctn}
                style={{ maxWidth: "2400px", overflowY: "hidden" }}>
                {isMobile ?
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{ paddingLeft: "5%" }}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <span>{QNA}</span>
                        </div></div>
                    :
                    <div className={styles.header_ctn}
                        style={{
                            margin: "12px 0px"
                        }}>
                        <div className={`${styles.header_side_ctn} ${styles.left}`}>
                            <span style={{ fontWeight: "bold", fontSize: 30 }}>{QNA_EDIT}</span>
                        </div>
                    </div>}
                <div style={{
                    display: "flex", margin: isMobile ?"0px 5%" :"0px 2.5%",
                    flexDirection: isMobile ? "column" :"row",
                    boxSizing: "border-box", 
                    overflow: "hidden" 
                }}>
                    <span style={{ marginRight: 24, fontSize: 16,marginBottom:"12px" }}>{TITLE}</span>
                    <input style={{
                        flex:1, outlineWidth:"0px",
                        maxWidth: "800px", boxSizing:"border-box",
                        display: "flex", overflowY: "hidden",
                        padding:"12px 15px",
                        border:"1px solid #ccc"
                    }}
                        placeholder="질문 제목을 입력해 주세요."
                        value={title}
                        onChange={(e)=>{
                            setTitle(e.target.value)
                        }}/>
                    {/* <div style={{
                        flex:1,
                        maxWidth: "800px",
                        display: "flex", overflowY: "hidden"
                    }}>
                        <ReactQuill theme="snow"
                            // ref={quillRef}
                            style={{
                                display: "flex", flexDirection: "column",
                                flex: 1, overflowY: "hidden"
                            }}
                            placeholder="질문 제목을 입력해주세요."
                            modules={{
                                toolbar: false
                            }} value={title} onChange={(value) => {
                                setTitle(value)
                            }} />
                    </div> */}
                </div>
                <div style={{
                    flex:1,
                    display: "flex", margin: isMobile ?"24px 5%" : "24px 2.5%",
                    flexDirection: isMobile ? "column" :"row",
                    boxSizing: "border-box",
                    overflow: "hidden",
                }}>
                    <span style={{ marginRight: 24, fontSize: 16,marginBottom:"12px" }}>{CONTENTS}</span>
                    <div style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                            <div style={{
                                maxWidth: "800px",
                                display: "flex", overflow: "hidden",
                                flex: 1,
                            }}>
                                <ReactQuill theme="snow"
                                    ref={quillRef}
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                    }}
                                    placeholder={`질문 내용을 입력해주세요 (1000자 이하)\n본인 외에는 질문내용을 볼 수 없습니다.`}
                                    modules={{
                                        toolbar: false
                                    }} value={contents} onChange={(value) => {
                                        setContents(value)
                                    }} />
                            </div>
                        </div>
                        {!isMobile &&
                        <div style={{
                            margin: "24px 0px", display: "flex",
                            fontSize:"18px"
                        }}>
                            <div className="pointer-cursor" style={{
                                border:"1px solid #767676",borderRadius:2,
                                padding:"8px 32px",marginRight:24
                            }} onClick={goBack}>
                                <span>취소</span>
                            </div>
                            <div className="pointer-cursor" style={{
                                borderRadius:2,
                                backgroundColor:THEME_YELLOW,
                                padding:"8px 32px",marginRight:24,
                                color:"#FFFFFF"
                            }} onClick={finishEdit}>
                                <span>등록</span>
                            </div>
                        </div>}
                    </div>
                </div>
                {isMobile &&
                    <div style={{
                        margin: "24px 5%", display: "flex",
                        fontSize: "16px",justifyContent:"center"
                    }}>
                        <div className="pointer-cursor" style={{
                            flex:1,justifyContent:"center",display: "flex",
                            border: "1px solid #767676", borderRadius: 2,
                            padding: "8px 32px", marginRight: 24
                        }} onClick={goBack}>
                            <span>취소</span>
                        </div>
                        <div className="pointer-cursor" style={{
                            flex:1,justifyContent:"center",display: "flex",
                            borderRadius: 2,
                            backgroundColor: THEME_YELLOW,
                            padding: "8px 32px",
                            color: "#FFFFFF"
                        }} onClick={finishEdit}>
                            <span>등록</span>
                        </div>
                    </div>}

            </div>
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                <ServiceBanner type={0} style={isMobile ? {} : { width: "45%" }} />
                {!isMobile &&
                    <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} />}
            </div>
        </div>
    )
}