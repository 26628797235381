import { ChangeEventHandler, CSSProperties, FC, HTMLInputTypeAttribute, useMemo, useState,} from "react"
import { PayStub } from "../classes/PayStub"
import { THEME_YELLOW } from "../values/Colors"
import { cutOffFuntion } from "./Tools"


export const PayInput:
    FC<{
        style? : CSSProperties
        titleStyle? : CSSProperties,
        id? : string,
        value: string,
        placeholder?: string,
        type? : HTMLInputTypeAttribute,
        onChange?: ChangeEventHandler<HTMLInputElement> | undefined,
        title?: string,
        rightButton?: () => JSX.Element,
        unit? : string,
        readonly? : boolean,
    }> = ({ id, readonly, style, titleStyle,
        value, placeholder, type, title, rightButton, onChange = undefined, unit }) => {
        const [isFocus, setIsFocus] = useState<boolean>(false)
        return (
            <div id={id}
                onFocus={() => {
                    setIsFocus(true)
                }}
                onBlur={()=>{
                    setIsFocus(false)
                }}
                style={{
                    borderWidth: "2px", borderBottomColor: !isFocus ? "rgba(112, 112, 112, 0.2)" : THEME_YELLOW, borderBottomStyle: "solid",
                    display: "flex", flexDirection: "row",
                    minHeight:"52px",
                    // paddingTop: 16, paddingBottom: 16,
                    boxSizing: "border-box",
                    alignItems: "center", justifyContent: "flex-start",
                    fontFamily: "Notosans",
                    fontSize:"14px",
                    ...style
                }}>
                <span style={{
                    wordBreak: "keep-all", whiteSpace: "nowrap",
                    ...titleStyle
                }}>{title}</span>
                <div style={{
                    flex: 1, display: "flex",
                    boxSizing: "border-box",
                }}>
                    <input style={{
                        flex: 1, borderWidth: "0px",
                        outlineWidth: "0px",
                        height: "100%",
                        boxSizing: "border-box",
                        width: "0px",
                        paddingTop: "2px",
                        paddingInlineStart: "8px", paddingInlineEnd: "8px",
                        textAlign: "right"
                    }}
                        readOnly={readonly}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange} />
                    {rightButton ? rightButton() : null}
                </div>
                <span>{unit ?? "원"}</span>
            </div>
        )
    }