import React, { CSSProperties, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate,Link, useLocation } from "react-router-dom";
import { Staff, staffConverter, StaffWithId } from "../classes/Staff";
import check_on from "../images/manage/check_on@2x.png"
import check_off from "../images/manage/check_off@2x.png"
import arrow_down from "../images/manage/ic_arrow_down@2x.png"
import { GRAY, THEME_YELLOW } from "../values/Colors";
import { DONE, FIVE_PER, PAY_MANAGEMENT, STAFF_MANAGEMENT } from "../values/strings";
import { useDispatch, useSelector } from "react-redux";
import { User, userConverter, UserWithId } from "../classes/User";
import { changeState, deleteUsersRedux, getStaffList } from "../redux/reducer/staff/staff";
import { MonthItem, MonthSet, MonthUnit } from "../classes/Month";
import styles from "../css/StaffManage.module.css"
import { btn_edit_square, edit, ic_delete, ic_staff, logo, menu, plus } from "../images/images";
import { useBlocker } from "../components/react-router-dom-revive";
import ReactModal from "react-modal";
import { MemberAdd } from "./MemberAdd";
import { fireStore } from "../fireConfig";
import { deleteDoc, doc } from "@firebase/firestore";
import { MemberModify } from "./MemberModify";
import { useMediaQuery } from 'react-responsive'
import { StaffList } from '../components/StaffList'
import { ServiceBanner } from "../components/ServiceBanner";

export const StaffManage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const reduxState = useSelector((state: { user: UserWithId, staff: StaffWithId[], monthSet : MonthSet,isMobile:boolean }) => state);
    const isMobile: boolean = reduxState.isMobile
    useEffect(()=>{
        console.log(isMobile,location.pathname)
        if(!isMobile && location.pathname === "/manage-staff"){
            navigate("/main/manage-staff",{replace:true})
        }
    },[isMobile])

    const reduxDispatch = useDispatch();
    const user = reduxState.user.data;
    const staffList: StaffWithId[] = reduxState.staff
    const filter = (staffList: StaffWithId[], type: number[]): StaffWithId[] => {
        return staffList.filter(({data}) => {
            return type.includes(data.hireType.type)
        })
    }
    const employees: StaffWithId[] = useMemo(() => filter(staffList, [0]), [staffList]);
    const partTimeJob: StaffWithId[] = useMemo(() => filter(staffList, [1,2]), [staffList]);

    const [onEdit, setOnEdit] = useState<boolean>(false);
    const listRef = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)]
    const scrolling = useState<boolean>(false)
    // const scrollCheck = useMemo(()=>{
    //     const ref = listRef[0]
    //     const scrollState = ref.current?.scrollHeight!!! - ref.current?.clientHeight!!! - ref.current?.scrollTop!!!
    //     const needMoreScroll = scrollState >= 44
    //     console.log(`ScrollCheck : ${scrollState} : ${needMoreScroll}`)
    // }, [scrolling[0]])
    const headers = isMobile 
    ? [
        {
            title: "순번",
            flex: 1
        },
        {
            title: "이름",
            flex: 1
        },
        {
            title: "전화번호",
            flex: 3
        },
        {
            title: "성별",
            flex: 1
        },
        {
            title: "주민등록번호",
            flex: 2
        },
        {
            title: "",
            flex: 0.5
        },
    ] 
    : [
        {
            title: "순번",
            flex: 1
        },
        {
            title: "이름",
            flex: 1
        },
        {
            title: "직급",
            flex: 2
        },
        {
            title: "전화번호",
            flex: 2
        },
        {
            title: "성별",
            flex: 1
        },
        {
            title: "주민등록번호",
            flex: 2
        },
        {
            title: "",
            flex: 0.5
        },
    ] 


    const [addModal,setAddModal] = useState<boolean>(false)
    const [modifyModal,setModifyModal] = useState<boolean>(false)
    const [modiTarget, setModiTarget] = useState<StaffWithId|undefined>(undefined)
    const openModifyDialog = (staffWithId : StaffWithId) => {
        setModiTarget(staffWithId)
        setModifyModal(true)
    }
    const openDrawer = () => {
        navigate("/drawer", {
            state: {
                backgroundLocation: [location],
                prePath : location.pathname,
            }
        })
    }
    
    const changeStaffState = (staff : StaffWithId) => {
        const index = staffList.indexOf(staff)
        const nextState = !staff.data.state
        reduxDispatch(changeState({index,nextState}))
    }
    
    const getCheckedStaffList = () : StaffWithId[] => {
        return staffList.filter((staff)=>{
            return staff.data.state
        })
    }
    
    const resetToFalseAll = () => {
        const copyListArray = staffList.slice()
        copyListArray.forEach((staff : StaffWithId)=>{
            staff.data.state = false;
        })
        reduxDispatch(getStaffList(copyListArray))
    }
    
    const [deleteModal,setDeleteModal] = useState<boolean>(false)
    const [targetList, setTargetList] = useState<StaffWithId[]>([])

    const deleteUser = async (targetList : string[]) => {
        const targetUsers = targetList;
        await deleteUserFireStore(targetUsers);
        reduxDispatch(deleteUsersRedux(targetUsers))
    }

    const deleteUserFireStore = async (id: string[]) => {
        await Promise.all(
            id.map(async (id, index): Promise<void> => {
                const docRef = doc(fireStore, "staffs", id)
                await deleteDoc(docRef)
                //console.log(id)
            }))
    }
    const openDeleteDialog = () => {
        const targetList: StaffWithId[] = getCheckedStaffList()
        if (targetList.length) {
            setTargetList(targetList)
            setDeleteModal(!deleteModal)
        }
    }
    const moveToAdd = () =>{
        setAddModal(true)
    }
    const convertToEdit = () => {
        if (user.canServe())
            setOnEdit(true)
        else
            navigate("/dialog/no-auth", {
                state: {
                    backgroundLocation: [location]
                }
            })
    }

    useBlocker(() => {
        if(addModal)
            setAddModal(false)
        if(onEdit)
            setOnEdit(false)
        if(deleteModal)
            setDeleteModal(false)
        if(modifyModal){
            setModifyModal(false)
        }
    },isMobile && ( onEdit || addModal || deleteModal || modifyModal))

    const MobileBody = () => (
        <div className={styles.main_ctn}>
            <div className={styles.header_ctn}>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                    onClick={onEdit ? openDeleteDialog : openDrawer} >
                    <img
                        src={onEdit ? ic_delete : menu} />
                    {onEdit &&
                        <span style={{ fontWeight: 500, fontSize: "12px", color: "#F46767" }}>삭제</span>}
                </div>
                <div className={styles.header_center}>
                    <img src={ic_staff} />
                    <span >{onEdit ? "직원편집" : STAFF_MANAGEMENT}</span>
                </div>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                    onClick={onEdit ? moveToAdd : convertToEdit}>
                    {onEdit && <span style={{ fontSize: "12px" }}>직원추가</span>}
                    <img
                        src={onEdit ? plus : edit} />
                </div>
            </div>
            <div className={styles.body_ctn}>
                {/* 테이블 */}
                <div style={{
                    flex: 1, display: "flex", flexDirection: "column",
                }}>
                    {/* table header */}
                    <div className={styles.row_ctn}
                        style={{
                            padding: "0px 5%",
                            height: "32px"
                        }}>
                        {headers.map((value, index) => {
                            const { title, flex } = value
                            return (
                                <div key={`header_cell_${title}_${index}`}
                                    className={`${styles.row_cell} ${styles.header_cell}`}
                                    style={{
                                        flex: flex,
                                    }}>{title}</div>
                            )
                        })}
                    </div>
                    {/* table-body */}
                    <div style={{
                        flex: 1,
                        display: "flex", flexDirection: "column"
                    }}>
                        {/* table-employee */}
                        {[
                            { list: employees },
                            { list: partTimeJob }
                        ].map((listSet, index) => {
                            const  { list } = listSet
                            return (
                                <StaffList 
                                    isMobile={isMobile}
                                    headers={headers}
                                    isEmployee={index}
                                    list={list}
                                    onEdit={onEdit}
                                    openModifyDialog={openModifyDialog}
                                    changeStaffState={changeStaffState}/>
                            )
                        })}
                    </div>
                </div>
                {onEdit &&
                    <div className="pointer-cursor"
                        key="btn-edit-finish"
                        style={{
                            boxSizing: "border-box",
                            padding: "12px",
                            margin: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={async () => {
                            resetToFalseAll()
                            setOnEdit(false)
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{DONE}</span>
                    </div>}
            </div>
            {!onEdit &&
                <div style={{
                    boxSizing: "border-box", display: "flex",
                    width: "100%",
                    borderTop: "1px solid #e4e8eb",
                    justifyContent: "space-around", backgroundColor: "#fafbfc"
                }}>
                    <ServiceBanner type={0} />
                </div>}
        </div>
    )
    const NotMobileBody = () => (
        <div className={styles.main_ctn}
            style={{maxWidth:"2400px"}}>
            <div className={styles.header_ctn}>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                    style={{paddingLeft:"2.5%"}}>
                    <img src={ic_staff} />
                    <span style={{
                        fontWeight:"bold"
                    }}>{onEdit ? "직원편집" : STAFF_MANAGEMENT}</span>
                </div>
                <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                    style={{paddingRight:"2.5%"}}
                    onClick={onEdit ? moveToAdd : convertToEdit}>
                    {onEdit
                        ? <div style={{
                            border: `1px solid ${THEME_YELLOW}`,borderRadius:"3px",
                            display:"flex",alignItems:"center",justifyContent:"center",
                            boxSizing:"border-box",height:"32px",padding:"0px 16px"
                        }}><span style={{
                            fontSize: "14px",color:THEME_YELLOW,fontWeight:500
                        }}>직원추가 +</span>
                        </div>
                        : <img src={edit} />}
                </div>
            </div>
            <div className={styles.body_ctn}
                style={{
                    overflowY: "hidden",
                }}>
                {/* table-body */}
                <div style={{
                    flex: 1,
                    overflow: "hidden",
                    display: "flex", flexDirection: "column"
                }}>
                    <div style={{
                        overflow: "hidden",
                        display: "flex", flexDirection: "row"
                    }}>
                        {/* table-employee */}
                        {[
                            { list: employees },
                            { list: partTimeJob }
                        ].map((listSet, index) => {
                            const { list } = listSet
                            const middleLine: CSSProperties = employees.length > partTimeJob.length
                                ? index === 0 ? { borderRight: "1px solid #E0E0E0" } : {}
                                : index === 1 ? { borderLeft: "1px solid #E0E0E0" } : {}
                            return (
                                <StaffList key={`staffManage_${index}`}
                                    isMobile={isMobile}
                                    middleLine={middleLine}
                                    headers={headers}
                                    isEmployee={index}
                                    list={list}
                                    onEdit={onEdit}
                                    openModifyDialog={openModifyDialog}
                                    changeStaffState={changeStaffState} />
                            )
                        })}
                    </div>

                    {onEdit && <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`} style={{
                        display:"flex",alignItems:"center",justifyContent:"flex-end",minHeight:"60px",height:"60px",
                    }}
                        onClick={openDeleteDialog} >
                        <img
                            src={ic_delete} />
                        {onEdit &&
                            <span style={{ fontWeight: 500, fontSize: "12px", color: "#F46767" }}>삭제</span>}
                    </div>}
                </div>
                {onEdit &&
                    <div className="pointer-cursor"
                        key="btn-edit-finish"
                        style={{
                            boxSizing: "border-box",
                            padding: "12px",
                            margin: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={async () => {
                            resetToFalseAll()
                            setOnEdit(false)
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{DONE}</span>
                    </div>}
            </div>
            {!onEdit &&
                <div style={{
                    boxSizing: "border-box", display: "flex",
                    width: "100%",
                    borderTop: "1px solid #e4e8eb",
                    justifyContent: "space-around", backgroundColor: "#fafbfc"
                }}>
                    <ServiceBanner type={0} style={{ width: "45%" }} />
                    <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} />
                </div>}
        </div>
    )
    return (
        <div className={ isMobile ? "parent-div" : "parent-div-per"}>
            {isMobile && <ReactModal
                style={{
                    content: {
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        inset: "auto",
                        border: "none",
                        padding: "0px",
                        // overflowY: "scroll",
                        boxSizing: "border-box", backgroundColor: "white",
                    }
                }}
                isOpen={addModal}
                ariaHideApp={false}>
                <MemberAdd 
                    onDismiss={()=>{
                        setAddModal(false)
                    }}/>
            </ReactModal>}
            {isMobile &&<ReactModal
                style={{
                    content: {
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        inset: "auto",
                        border: "none",
                        padding: "0px",
                        // overflowY: "scroll",
                        boxSizing: "border-box", backgroundColor: "white",
                    }
                }}
                isOpen={modifyModal}
                ariaHideApp={false}>
                <MemberModify
                    onDismiss={() => {
                        setModifyModal(false)
                    }}
                    modiTarget={modiTarget} />
            </ReactModal>}
            <ReactModal
                style={{
                    overlay: {
                        display: "flex",
                        backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center",
                        height: "100%", justifyContent: "center",
                        boxSizing: "border-box",
                        padding: "24px"
                    },
                    content: {
                        flex: 1,
                        position: "relative",
                        inset: "auto",
                        border: "none",
                        padding: "5px",
                        // overflowY: "scroll",
                        width: "100%", boxSizing: "border-box", backgroundColor: "transparent",
                        display: "flex", justifyContent: "center", maxHeight: "100%"
                    }
                }}
                isOpen={deleteModal}
                ariaHideApp={false}>
                <div style={{
                    backgroundColor: "white",
                    boxShadow: "0px 0px 3px 2px lightgray",
                    borderRadius: "8px",
                    width: "90%",
                    maxWidth: "400px",
                    padding: "12px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column"
                }}>
                    <span style={{ alignSelf: "center", fontWeight: 500, color: THEME_YELLOW }}>{`직원삭제`}</span>
                    <span style={{ alignSelf: "center", fontSize: "14px", textAlign: "center", margin: "8px 0px" }}>
                        {targetList.length < 2
                            ? `${targetList[0]?.data?.name} 직원을 삭제하시겠습니까?`
                            : <>{`${targetList[0]?.data?.name} 외 ${targetList.length - 1}명의 직원을`}<br />삭제하시겠습니까?</>
                        }
                    </span>
                    <div style={{
                        width: "100%", display: "flex", fontSize: "14px", justifyContent: "flex-end",
                        color: "#767676"
                    }}>
                        <div className="pointer-cursor"
                            style={{
                                display: "flex",
                                alignItems: "center", justifyContent: "center", padding: 8
                            }}
                            onClick={() => {
                                setDeleteModal(false)
                            }}>
                            <span style={{ fontFamily: "NotoSans" }}>{`취소`}</span>
                        </div>
                        <div className="pointer-cursor"
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "center", padding: 8
                            }}
                            onClick={async () => {
                                await deleteUser(targetList.map((staffWithId) => staffWithId.id))
                                //console.log("Finish DeleteUser")
                                setDeleteModal(false)
                            }}>
                            <span style={{ fontFamily: "NotoSans" }}>{`삭제`}</span>
                        </div>
                    </div>
                </div>
            </ReactModal>
            {!isMobile && addModal &&
                <MemberAdd onDismiss={() => {
                    setAddModal(false)
                }} />}
            {!isMobile && modifyModal &&
                <MemberModify
                    onDismiss={() => {
                        setModifyModal(false)
                    }} modiTarget={modiTarget} />}
            {!isMobile
                ? (!addModal && !modifyModal) && <NotMobileBody />
                : <MobileBody />}
        </div>
    )
}
