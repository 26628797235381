import { Timestamp } from "firebase/firestore"
import { User, UserWithId } from "../../../classes/User"
import { PUSH_MY_QNA, UPDATE_EXPIRATION_DATE, UPDATE_USER } from "./user.type"

const initialState : UserWithId = {
    data : new User(),
    id : ""}
export const updateUser = (data : UserWithId | undefined) => {
    return {
        type : UPDATE_USER,
        payload : data
    }
}
export const updateExpirationDate = (data : Timestamp) => {
    return {
        type : UPDATE_EXPIRATION_DATE,
        payload : data
    }
}
export const pushMyQnA = (qnaId : string) => {
    return{
        type : PUSH_MY_QNA,
        payload : qnaId
    }
}
export const userReducer = (state: UserWithId = initialState, action: { type: string, payload?: any }) : UserWithId => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case UPDATE_USER:
            return action.payload;
        case UPDATE_EXPIRATION_DATE:
            state.data.expirationDate = action.payload
            return {
                ...state,
                data : state.data.copy(),
            }
        case PUSH_MY_QNA:
            const newList = (state.data.qnaList ?? []).slice(0)
            newList.push(action.payload)
            state.data.qnaList = newList
            return {
                ...state
            }
        default:
            break;
    }
    return state
}