import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { getDownloadURL, getStorage, ref, } from "firebase/storage";
import moment from "moment";
import React,{FC, useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Banner, bannerConverter } from "../../classes/Banner";
import { Notice } from "../../classes/Notice";
import styles from '../../css/AdminBanner.module.css'
import { fireFunctions, fireStorage, fireStore } from "../../fireConfig";
import { ic_upload } from "../../images/images";
import { unshiftBanner } from "../../redux/reducer/banners/banners";
import { unshiftNotice } from "../../redux/reducer/notice/notice";
import { THEME_YELLOW } from "../../values/Colors";
export const AdminNoticeEdit: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const reduxDispatch = useDispatch();

    const [title,setTitle] = useState<string>("")
    const [content,setContent] = useState<string>("")

    const [onProcess, setOnProcess] = useState<boolean>(false)

    const goBack = () =>{
        navigate(-1)
    }

    const canUpload = () : boolean => {
        return (title.length > 0
            && content.length > 0 ) ?? false
    }
    const uploadNotice = async() => {
        try {
            if (canUpload())
                if (!onProcess) {
                    setOnProcess(true)

                    const noticeCol = collection(fireStore, "notices")
                    const notice: Notice = {
                        title: title,
                        content: content,
                        createTime: new Timestamp(moment().unix(), 0)
                    }
                    const docRef = await addDoc(noticeCol, notice)
                    reduxDispatch(unshiftNotice({
                        notice: notice,
                        id: docRef.id
                    }))
                    goBack()
                } else alert("업로드가 진행중입니다. 버튼을 한 번만 눌러주세요.")
            else alert("모든 항목을 채워주세요.")
        } catch (error) {
            alert(error)
        }
        setOnProcess(false)
    }
    return (
        <div className={styles.main_ctn}
            style={{
                padding: "30px 2.5%",
                display: "flex",
                overflowY: "hidden", flexDirection: "column"
            }}>
            <div style={{
                display: "flex", flexDirection: "row",
                justifyContent: "space-between", alignItems: "center",
                fontSize: "14px", marginBottom: "12px"
            }}>
                <span style={{
                    fontSize: "22px", fontWeight: 500,
                }}>공지사항 추가</span>

                <div style={{
                    display: "flex", flexDirection: "row"
                }}>
                    <div style={{
                        borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "#757575", border: "1px solid #757575",
                        marginRight: "14px",
                        cursor:"pointer"
                    }} onClick={goBack}>
                        <span >{"취소"}</span>
                    </div>
                    <div style={{
                        backgroundColor: THEME_YELLOW, borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "white", fontWeight: 500,
                        cursor:"pointer"
                    }} onClick={uploadNotice}>
                        <span >{"등록"}</span>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    color: "#161616",marginBottom:"24px"
                }}>
                <span style={{ marginRight: "20px",width:"80px" }}>제목</span>
                <input style={{
                    width: "320px",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px"
                }}
                    placeholder="공지사항 제목을 입력해 주세요."
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }}
                />
            </div>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems:"flex-start",
                    color: "#161616"
                }}>
                <span style={{ marginRight: "20px", width: "80px" }}>내용</span>
                <textarea style={{
                    width: "80%",
                    minHeight: "500px", maxHeight: "500px",
                    boxSizing: "border-box",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px", resize: "none"
                }}
                    placeholder="공지내용을 입력해 주세요."
                    value={content}
                    onChange={(e) => { setContent(e.target.value) }}
                />
            </div>
        </div>
    )
}
