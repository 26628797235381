import { SAVE_TAX_VALUE } from "./tax.type";
import {chart,overflow} from "../../../income.json"

export interface Tax {
    nationalPension : number,
    healthInsurance : number,
    employmentInsurance : number,
    longtermCareInsurance : number,
    chart : {
        level : number,
        more : number,
        less : number,
        deduction : number[]
    }[],
    overflow : {
        add : number,
        below : number,
        over : number,
        per1 : number,
        per2 : number
    }[]
}
const initialState : Tax = {
    nationalPension : 4.5,
    healthInsurance : 3.43,
    employmentInsurance : 0.8,
    longtermCareInsurance : 11.52,
    chart : chart,
    overflow : overflow
}
export const saveTaxValue = (data : Tax) => {
    return {
        type : SAVE_TAX_VALUE,
        payload : data
    }
}
export const taxReducer  = (state: Tax = initialState, action: { type: string, payload?: any }) : Tax => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case SAVE_TAX_VALUE:
            return action.payload;
        default:
            break;
    }
    return state
}