import { CSSProperties, FC, useState } from "react";
import { HTU } from "../classes/HTU";
import { answer_pc, arrow_down_pc, arrow_up_pc, question_pc } from "../images/images";
import { THEME_YELLOW } from "../values/Colors";


/**
    * Return HTU Icon type default question.
    * Size Fixed To 30px:30px 
    */
// const QuestionIcon: FC<{ type?: "question" | "answer" }> = ({ type = "question" }) =>
//     <div style={{
//         borderRadius: "99px", 
//         minWidth: "30px", minHeight: "30px",
//         maxWidth: "30px", maxHeight: "30px",
//         display: "flex", alignItems: "center", justifyContent: "center",
//         backgroundColor: type === "question" ? THEME_YELLOW : "white",
//         boxSizing:"border-box",
//         border: type === "question" ? "" : `1px solid ${THEME_YELLOW}`,
//     }}>
//         <span style={{ fontSize: "14px", fontWeight: "bold", color: type === "question" ? "white" : THEME_YELLOW, }}>
//             {type === "question" ? "Q" : "A"}
//         </span>
//     </div>

export const QnAItem: FC<{ itemValue: HTU, outerStyle?: CSSProperties, isNotMobile?:"OK"|"NO" }> = ({ itemValue, outerStyle,isNotMobile }) => {
    const [isOpenAnswer, setIsOpenAnswer] = useState<boolean>(false)
    const isNotMobi = isNotMobile==="OK"
    return (
        <div style={{
            display: "flex", flexDirection: "column",
            boxSizing: "border-box",
            ...outerStyle
        }}>
            <div className="pointer-cursor" style={{
                display: "flex",
                padding: "8px 0px",
                borderTop:"1px solid #E8E8E8",
            }} onClick={()=>setIsOpenAnswer(!isOpenAnswer)}>
                <div className={isNotMobi ? "side_margin_2_5per" : "side_margin_5per"} style={{
                    flex:1,
                    display: "flex", 
                    alignItems: "center"
                }}>
                    <img src={question_pc} style={{width:"30px"}}/>
                    <span style={{ fontSize: "14px", fontWeight: 500, marginLeft: "12px",whiteSpace:"pre-wrap",flex:1  }}>
                        {itemValue.question.replaceAll("\\n","\n")}</span>
                    {isNotMobi && <img src={!isOpenAnswer ? arrow_down_pc : arrow_up_pc} style={{width:"36px"}}/>}
                </div>
            </div>
            {isOpenAnswer &&
                <div style={{
                    display: "flex",
                    padding: "8px 0px",
                    borderTop: "1px solid #E8E8E8",
                    backgroundColor : isNotMobi ? "#FDF9F0" : undefined
                }}>
                    <div className={isNotMobi ? "side_margin_2_5per" : "side_margin_5per"} style={{
                        flex: 1,
                        display: "flex", alignItems: "center"
                    }}>
                    <img src={answer_pc} style={{width:"30px"}}/>
                        <span style={{ fontSize: "14px", marginLeft: "12px", whiteSpace: "pre-wrap" }}>
                            {itemValue.answer.replaceAll("\\n", "\n")}</span>
                    </div>
                </div>}
        </div>
    )
}