import moment, { Moment } from "moment";
import React, { CSSProperties, FC, useRef, useState } from "react";
import styles from '../css/PaymentDatePicker.module.css'
import { ic_calendar } from "../images/images";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale'
export const PaymentDatePicker : FC<{
    style? : {
        containerStyle? : CSSProperties,
        rightBoxStyle? : CSSProperties
    }
    value : Moment
    onChange : (moment : Moment) => void
}> = ({ style,value, onChange }) => {
    return (
        <div className={styles.ctn}
            style={{...style?.containerStyle}}>
            <span className={styles.title}>지급일</span>
            <div style={{ minWidth: 120, height: 36,...style?.rightBoxStyle }}>
                <DatePicker
                    locale={ko}
                    onChange={(date) => {
                        if (date instanceof Date){
                            const newMoment = moment(date)
                            onChange(newMoment)
                        }
                    }}
                    customInput={
                        <div className={styles.date_picker}>
                            <span className={styles.date}>{value.format("YYYY.MM.DD")}</span>
                            <img className={styles.icon} src={ic_calendar} />
                        </div>} />
            </div>
        </div>
    )
}