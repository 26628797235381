import React, { FC } from "react";
import { Route, Routes } from "react-router";
import { QnAEdit } from "./QnA/QnAEdit";
import { QnAList } from "./QnA/QnAList";
import { QnAView } from "./QnA/QnAView";

export const QnARouter : FC = () => {
    return(
        <Routes>
            <Route path=":page" element={<QnAList/>}/>
            <Route path="edit" element={<QnAEdit/>}/>
            <Route path="view/:id" element={<QnAView/>}/>
        </Routes>
    )
}