import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { fireAuth, fireStore } from "../fireConfig";
import logo from "../images/login/logo_login.png"
import icon_delete from "../images/login/btn_delete@2x.png"
import { FullScreen } from "../components/FullScreen";
import { BLACK, DARK_GRAY, GRAY, RED, THEME_YELLOW } from "../values/Colors";
import { FIVE_PER, NO_ENROLLMENT_STAFF, PLEASE_INPUT_PHONE_TYPE, PLEASE_INPUT_SSN_TYPE } from "../values/strings";
import { createSearchParams, useNavigate } from "react-router-dom"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { staffConverter } from "../classes/Staff";

export const StaffLogin: FC = () => {

    const [phone, setPhone] = useState<string>("");
    const phoneRef = useRef<HTMLInputElement>(null)
    const [ssn, setSSN] = useState<string>("");
    const ssnRef = useRef<HTMLInputElement>(null)
    //0기본, 1전화번호 형식 오류, 2주민번호 형식 오류, 3비밀번호 불일치
    const [errorCode, setErrorCode] = useState<number>(0)

    const phoneRegex = /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})-([0-9]+)?-([0-9]{4})$/
    const ssnRegex = /([0-9]{6})$/
    const navigate = useNavigate();


    const signIn = async (phone: string, ssn: string) => {
        if (phoneRegex.test(phone))
            if(ssnRegex.test(ssn)){
                const staffCollection = collection(fireStore,"staffs").withConverter(staffConverter)
                const staffQuery = query(staffCollection,where("phone","==",phone), where("ssn","array-contains",ssn))
                const staffDocs = await getDocs(staffQuery)
                if (staffDocs.size > 0) {
                    const targetUid = await Promise.all(staffDocs.docs.map((documentSnapshot) => {
                        return documentSnapshot.id
                    }))
                    navigate({
                        pathname : "/browse",
                        search : `?${createSearchParams({
                            id : targetUid
                        })}`
                    },{
                        state : {
                            isCheck : true
                        }
                    })
                } else {
                    setErrorCode(3)
                }
            }
            else {
                setErrorCode(2)
                fucusOn(ssnRef)
            }
        else {
            setErrorCode(1)
            fucusOn(phoneRef)
        }
    }
    const fucusOn = (ref: RefObject<HTMLElement>) => {
        ref.current?.focus()
    }

    const resetErrorCode = () => {
        setErrorCode(0)
    }
    return (
        <FullScreen>
            <div style={{
                width: "100%",
                // maxWidth: "400px", 
                height: "100%",
                // padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                display: "flex", flexDirection: "column",
                alignItems: "center"
            }}>
                {/* <div style={{ flex: 1 }} /> */}
                <div style={{
                    flex: 1,
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                    width: "100%",
                    display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    {/* <div> */}
                    <img src={logo} style={{
                        width: "30%", alignSelf: "center",
                        maxWidth: "200px",
                        marginBottom: 48
                    }} />
                    {/* inputCtn */}
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                            alignItems: "center"
                        }}>
                            <input
                                ref={phoneRef}
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={phone}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") ssnRef.current?.focus()
                                }}
                                onChange={(e) => {
                                    const nonHyphen = e.target.value.replace(/[^0-9]/g, "")
                                    const betweenHyphen = nonHyphen
                                        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                                    setPhone(betweenHyphen)
                                }}
                                maxLength={14}
                                placeholder="핸드폰 번호"
                                type="tel" />
                            {phone
                                && <img src={icon_delete}
                                    style={{ height: "18px" }}
                                    onClick={() => {
                                        setPhone("")
                                        resetErrorCode()
                                    }} /> }
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode == 1
                            ? PLEASE_INPUT_PHONE_TYPE
                            : "\u200b"}</span>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                        }}>
                            <input
                                ref={ssnRef}
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={ssn}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") signIn(phone, ssn)
                                }}
                                onChange={(e) => {
                                    const nonHyphen = e.target.value.replace(/[^0-9]/g, "")
                                    const betweenHyphen = nonHyphen.replace(/([0-9]{6})([0-9]{1,7})$/, "$1-$2")
                                    setSSN(betweenHyphen)
                                }}
                                maxLength={6}
                                placeholder="주민등록번호 앞 6자리"
                                type="tel" />
                            {ssn && <img src={icon_delete}
                                style={{ height: "18px" }}
                                onClick={() => {
                                    setSSN("")
                                    resetErrorCode()
                                }} />}
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode === 2 
                                ? PLEASE_INPUT_SSN_TYPE
                                : errorCode === 3
                                    ? NO_ENROLLMENT_STAFF
                                    : "\u200b"}</span>
                    </div>
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            width: "100%", maxWidth: "400px",
                            padding: "12px",
                            marginTop: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={() => { signIn(phone, ssn) }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{"열람하기"}</span>
                    </div>
                </div>
                <div style={{
                    width: "100%",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,boxSizing:"border-box",
                    backgroundColor:"rgba(245, 244, 241, 0.85)",
                }}>
                    <p style={{fontFamily:"Notosans",fontSize:"12px",color:DARK_GRAY,fontWeight:500}}>
                        노랑봉투<br/>
                        <span style={{fontSize:"10px",fontWeight:"normal"}}>
                            {`주식회사 플로션`}<br/>
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br/>
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br/>
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br/>
                        </span>
                    </p>
                </div>
            </div>
        </FullScreen>
    )
}