import moment from "moment";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { MonthItem } from "../../classes/Month";
import { DayPs, PartPs, PayStub } from "../../classes/PayStub";
import { StaffWithId } from "../../classes/Staff";
import { User, UserWithId } from "../../classes/User";
import { Day } from "../../components/ViewPageComp/Day";
import styles from '../../css/PayViewPage.module.css'
import { back, logo } from "../../images/images";
import { birthCalculator } from "../../values/BirthCalculator";

export const DayWorkView :FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {user : reduxUserState, isMobile} = useSelector((state: { user: UserWithId,isMobile:boolean }) => state);
    const { staff, payStub } =
        location.state as {
            staff: StaffWithId,
            payStub?: PayStub,
            targetMonth: MonthItem,
            staffIndex: number,
            readonly: boolean
        }
    const birthDate =
        (birthCalculator(parseInt(staff.data.ssn[1][0])))+ staff.data.ssn[0].substring(0, 2) + "-" + staff.data.ssn[0].substring(2, 4) + "-" + staff.data.ssn[0].substring(4, 6)
    const payStubData = payStub?.data as DayPs
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={!isMobile ? {
                display: "flex", justifyContent:"center"
            } : undefined}>
            <div className={styles.main_ctn}
                style={!isMobile ? {
                    maxWidth: "800px"
                } : undefined}>

                <div className={styles.header_ctn}>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                        onClick={goBack} >
                        <img
                            src={back} />
                    </div>
                    <div className={styles.header_center}>
                        <img src={logo} />
                    </div>
                </div>
                <div className={styles.body_ctn}>
                    <div className={`side_margin_5per`}
                        style={{
                            display: "flex",
                            boxSizing: "border-box",
                            alignItems: "flex-end"
                        }}>
                        <span className="name_deco">{staff.data.name}</span>
                        <span style={{ marginLeft: "4px", fontSize: "18px", fontWeight: "bold" }}>님</span>
                    </div>

                    <div className={`side_margin_5per`}
                        style={{
                            marginTop:"12px",
                            display: "flex", flexDirection: "column",
                            boxSizing: "border-box",
                        }}>
                        <div style={{
                            display: "flex", justifyContent: "space-between",
                            fontSize: "12px"
                        }}>
                            <span>
                                <span style={{ fontWeight: 500, marginRight: "8px" }}>{`생년월일(사번)`}</span>
                                <span>{birthDate}</span>
                            </span>
                            <span>{reduxUserState.data.companyName}</span>
                        </div>
                        <div style={{
                            marginTop:"6px",
                            display: "flex", justifyContent: "flex-end",
                            fontSize: "12px"
                        }}>
                            <span>
                                <span style={{ fontWeight: 500, marginRight: "8px" }}>지급일</span>
                                {moment.unix(payStubData.paymentDate.seconds).format("YYYY-MM-DD")}</span>
                        </div>
                    </div>
                    <div style={{ minHeight: 5, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                    <Day payStubData={payStubData}/>
                </div>
                {/* <ServiceBanner type={1}/> */}
            </div>
        </div>
    )
}