import { getDownloadURL, ref } from "firebase/storage";
import React,{FC, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BannerWithId } from "../../classes/Banner";
import { BannerFlag } from "../../components/BannerFlag";
import styles from '../../css/AdminBanner.module.css'
import { fireStorage } from "../../fireConfig";
import { THEME_YELLOW } from "../../values/Colors";
import { AdminBannerEdit } from "./AdminBannerEdit";
import { AdminBannerModi } from "./AdminBannerModi";
export const AdminBanner: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [type, setType] = useState<number>(0)
    const banners : BannerWithId[] = useSelector((state : {banners : BannerWithId[]}) => state.banners.map((value,index)=>{
        value.index = index
        return value
    }))
    const sortingBanners = useMemo(()=>{
        return banners.filter(({banner})=>banner.type === type)
    },[banners,type])
    return (
        <div className="parent-div-per">
            <Routes>
                <Route path=""
                    element={
                        <div className={styles.main_ctn}
                            style={{
                                padding: "30px 2.5%",
                                display: "flex",
                                overflowY: "hidden", flexDirection: "column"
                            }}>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", marginBottom: "12px"
                            }} onClick={()=>console.log(banners)}>
                                <span style={{
                                    fontSize: "22px", fontWeight: 500,
                                }}>배너광고</span>
                                <div className="pointer-cursor" style={{
                                    backgroundColor: THEME_YELLOW, borderRadius: "6px",
                                    padding: "10px 37px", display: "flex", alignItems: "center",
                                    color: "white", fontWeight: 500
                                }}
                                    onClick={()=>{
                                        navigate("edit",{
                                            state : {
                                                ...location.state,
                                                type : type
                                            }
                                        })
                                    }}>
                                    <span >{"추가"}</span>
                                </div>
                            </div>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                justifyContent: "flex-start", alignItems: "center",
                                fontSize: "14px", marginBottom: "12px"
                            }}>
                                <div className="pointer-cursor" style={{
                                    borderRadius: "6px",
                                    padding: "10px 37px", display: "flex", alignItems: "center",
                                    marginRight: "14px",
                                    ...type === 0
                                        ? { color: "white", fontWeight: 500, backgroundColor: THEME_YELLOW }
                                        : { color: "#757575", border: "1px solid #757575" },
                                }} onClick={() => {
                                    setType(0)
                                }}>사업자</div>
                                <div className="pointer-cursor" style={{
                                    borderRadius: "6px",
                                    padding: "10px 37px", display: "flex", alignItems: "center",
                                    ...type === 1
                                        ? { color: "white", fontWeight: 500, backgroundColor: THEME_YELLOW }
                                        : { color: "#757575", border: "1px solid #757575" },
                                }} onClick={() => {
                                    setType(1)
                                }}>근로자</div>
                            </div>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                flexWrap:"wrap",overflow:"auto"
                            }}>
                                {sortingBanners.map((value, index) =>
                                    <BannerFlag
                                        style={{
                                            marginRight:"16px",marginBottom:"48px"
                                        }} 
                                        value={value}
                                        onClick={()=>{
                                            console.log(value)
                                            navigate("modi",{
                                                state : {
                                                    ...location.state,
                                                    bannerWithId : value
                                                }
                                            })
                                        }} />
                                )}
                            </div>
                        </div>
                    } />
                <Route path="edit" element={<AdminBannerEdit/>} />
                <Route path="modi" element={<AdminBannerModi/>} />
            </Routes>
        </div>
    )
}