import { MonthItem, MonthSet } from "../../../classes/Month"
import { GET_MONTHLIST, SELECT_MONTHITEM } from "./month-set.type"

const initialState : MonthSet = {
    monthList : []
}
export const getMonthList = (data : MonthItem[]) => {
    return {
        type : GET_MONTHLIST,
        payload : data
    }
}
export const selectMonthItem = (data : MonthItem) => {
    return {
        type : SELECT_MONTHITEM,
        payload : data
    }
}

export const monthSetReducer = (state: MonthSet = initialState, action: { type: string, payload?: any }) : MonthSet => {
    switch (action.type) {
        case GET_MONTHLIST:
            return {
                ...state,
                monthList : action.payload
            }
        case SELECT_MONTHITEM:
            return {
                ...state,
                selected : action.payload
            }
        default:
            break;
    }
    return state
}