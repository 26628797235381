import React, { FC, useEffect, useMemo, useState } from "react";
import { InputJoin } from "../components/InputJoin";
import logo from "../images/join/logo_join.png"
import upload from "../images/join/ic_upload.png"
import { DARK_GRAY, GRAY, THEME_YELLOW } from "../values/Colors";
import { FIVE_PER, JOIN, LOGIN } from "../values/strings";
import { useRef } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { fireAuth, fireFunctions, fireStorage, fireStore } from "../fireConfig";
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { isValidEmail, isValidPassword, isValidPhone } from "../components/Tools";
import { createUserWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber, updatePhoneNumber } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { doc, setDoc } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { GetInitValue } from "../components/GetInitValue";
import { useDispatch, useSelector } from "react-redux";
import { UserWithId } from "../classes/User";
import { StaffWithId } from "../classes/Staff";
import { MonthSet } from "../classes/Month";
import { checkNtsResult, NtsValidReq } from "../functions/checkNts";
import agree_off from "../images/agree/agree_off@2x.png"
import agree_on from "../images/agree/agree_on@2x.png"
import { getConsentItem } from "../functions/getConsentItem";
import ReactModal from "react-modal";
import ReactQuill,{Quill} from 'react-quill';

export const Join: FC = () => {
    const navigate = useNavigate();
    const reduxDispatch = useDispatch()
    const reduxState = useSelector((state: { user: UserWithId, staff: StaffWithId[], monthSet: MonthSet }) => state)

    const [companyName, setCompanyName] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("")
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [authCode, setAuthCode] = useState<string>("");
    const [isConfirm, setIsConfirm] = useState<boolean>(false)
    const [authCodeVisible, setAuthCodeVisible] = useState<boolean>(false)
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [bn, setBn] = useState<string>("");
    const [startDt, setStartDt] = useState<string>("");
    const [pNm,setRepresendtative] = useState<string>("")
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [isAccept, setIsAccept] = useState<boolean>(false)
    const [term, setTerm] = useState("");
    const [privacy, setPrivacy] = useState("");
    const getConsentItems = async()=>{
        setTerm(await getConsentItem("term"))
        setPrivacy(await getConsentItem("privacy"))
    }
    const [consentModal,setConsentModal] = useState(false)

    const [file, setFile] = useState<File | undefined>(undefined);
    const [preview, setPreview] = useState<{type : string, preview : string|undefined} | undefined>(undefined);

    const [btnAuthActive, setBtnAuthActive] = useState<boolean>(true)
    const [errorCode, setErrorCode] = useState<number>(0)

    const [onAuthrizing, setOnAuthrizing] = useState<boolean>(false)
    const [onSinging,setOnSinging] = useState<boolean>(false)
    const signUp = async() => {
        setOnSinging(true)
        try {
            const req : NtsValidReq = {
                b_no : bn,
                p_nm : pNm,
                start_dt : startDt
            }
            // b_no: "8391201434",
            // start_dt: "20200623",
            // p_nm: "선우건영"
            const ntsResult = await checkNtsResult(req)
            if(ntsResult.result === 2){
                alert(`${ntsResult.description}`)
                setOnSinging(false)
                return
            }
            if(ntsResult.result === 1){
                alert(`사업자등록정보 진위 확인\n${ntsResult.description}`)
                setOnSinging(false)
                return
            }
            const userCredential = await createUserWithEmailAndPassword(fireAuth,email,password)
            const {uid} = userCredential.user
            // const uploadResult = await uploadFile(uid) //
            
            // if(!uploadResult.result){
            //     return
            // }
            // const {filename} = uploadResult.data
            // const storageRef = ref(fireStorage,uid+"/"+filename)
            // const downloadUrl = await getDownloadURL(storageRef)
            const storeDocRef= doc(fireStore,"users",uid)
            await setDoc(storeDocRef,{
                companyName : companyName,
                name : name,
                phone : phone,
                email : email,
                bn : bn,
                brDownloadUrl : "downloadUrl",
                brRef : storeDocRef,
                createTime : new Date(),
                ntsStatus : {
                    req : req,
                    resq : ntsResult.status
                },
                isApprove: false,
            });
            await GetInitValue(uid, reduxDispatch, reduxState)
            navigate("/main", { replace: true })
        } catch (error) {
            setOnSinging(false)
            if (error instanceof FirebaseError) {
                if (error.code === "auth/email-already-in-use") {
                    alert("이미 가입한 회원입니다.")
                }
                else {
                    console.log(error)
                    alert(error)
                }
            } else {
                console.log(error)
                alert(error)
            }
            //console.log(error)
        }
        setOnSinging(false)
    }
    const changeCanSignState = (): boolean => {

        var state = true;

        if (companyName.length < 1) {
            state = false
            redOrBlack("company_name",false)
        }
        if (name.length < 1) {
            state = false
            redOrBlack("name_input",false)
        }
        if (!isValidPhone(phone)) {
            state = false
            redOrBlack("phone_input",false)
        }
        if (!isValidEmail(email)) {
            state = false
            redOrBlack("email_input",false)
        }
        if (!isValidPassword(password)) {
            state = false
            redOrBlack("password_input",false)
        }
        if (password !== confirmPassword || confirmPassword.length < 1) {
            state = false
            redOrBlack("confirmPassword_input",false)
        }
        if (bn.length < 10) {
            state = false
            redOrBlack("bn_input",false)
        }
        if (pNm.length < 1) {
            state = false
            redOrBlack("p_nm_input",false)
        }
        if (startDt.length < 8) {
            state = false
            redOrBlack("start_dt_input",false)
        }
        if (!isAccept) {
            state = false
            redOrBlack("agree_txt",false)
        }
        if(!window.confirmationResult){
            state = false
            redOrBlack("bn_phone_auth",false)
        } else if (!isConfirm) {
            state = false
            redOrBlack("auth_input", false)
            redOrBlack("bt_confirm", false)
            if (!authCodeVisible) {
                redOrBlack("bn_phone_auth", false)
            }
        }

        return state

    }
    const redOrBlack = (id: string, type: boolean) => {
        const targetDocument = document.getElementById(id)
        if (targetDocument) {
            // console.log(id)
            if (!["bn_photo", "bn_phone_auth", "bt_confirm"].includes(id)) {
                if (id === "agree_txt") {
                    targetDocument.style.color = type ? "#9A9A9A" : "red"
                } else
                    targetDocument.style.color = type ? "black" : "red"
            }
            else
                targetDocument.style.background = type ? THEME_YELLOW : "red"
        }
    }
    const uploadFile = async (uid: string): Promise<any> => {
        connectFunctionsEmulator(fireFunctions, "localhost", 5001)

        try {
            const formData = new FormData()
            if (file instanceof Blob) {
                //console.log("file Exist", file.name)
                formData.append("file", file, file.name)
                formData.append("uid", uid)
            }
            else
                return

            const result = await fetch(
                process.env.NODE_ENV != "production"
                    ? `http://localhost:5001/nobong-79c64/us-central1/uploadBR`
                    : `https://us-central1-nobong-79c64.cloudfunctions.net/uploadBR`, {
                method: "POST",
                body: formData
            })
            // //console.log(formData.get("file"))
            return await result.json()
        } catch (error) {
            console.log("Line : 164"+error)
        }
    }

    useEffect(() => {
        getConsentItems()
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (callback: any) => {
                console.log("callback")
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
            },
            'expired-callback': () => {
                console.log("expired-callback")
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        }, fireAuth)
    }, [])

    return (
        <div className="parent-div"
            style={{ display: "flex", justifyContent: "center" }}>
            <ReactModal
                style={{
                    overlay: {
                        display: "flex",
                        alignItems: "center", justifyContent: "center",
                        padding: "36px",
                        boxSizing: "border-box",
                        backgroundColor: "rgba(0,0,0,0.6)",
                        overflow: "hidden"
                    },
                    content: {
                        position: "relative",
                        maxHeight: "100%",
                        padding:"0px",
                        inset: "0px",
                        display: "flex", flexDirection: "column",
                        border: "0px solid black", alignItems: "center",
                        backgroundColor: "rgba(0,0,0,0)",
                        overflow: "hidden"
                    }
                }}
                isOpen={consentModal}
                onRequestClose={() => { 
                    console.log("requestClose")
                    setConsentModal(!consentModal) }}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={false}>
                <div style={{
                    padding: "24px",
                    width: "100%", maxWidth: "600px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white",
                    overflow: "hidden",
                    fontWeight:500
                }}>
                    <div style={{ overflow: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        이용약관
                        <ReactQuill theme="snow"
                            readOnly={true}
                            style={{marginBottom:"24px"}}
                            modules={{
                                toolbar: false,
                            }} value={term} defaultValue={term} />
                        개인 정보 취급 방침
                        <ReactQuill theme="snow"
                            readOnly={true}
                            modules={{
                                toolbar: false,
                            }} value={privacy} defaultValue={privacy} />
                    </div>
                    <div  className="pointer-cursor" style={{
                        boxSizing: "border-box",
                        width: "100%",
                        padding: "12px",
                        marginTop: "24px",
                        borderRadius: "52px",
                        backgroundColor: THEME_YELLOW,
                        display: "flex", justifyContent: "center"
                    }}
                        onClick={() => {
                            setIsAccept(true)
                            setConsentModal(!consentModal)
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{"동의"}</span>
                    </div>
                </div>
            </ReactModal>
            <div
                style={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "100%",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,
                    display: "flex", flexDirection: "column",
                    justifyContent: "center",
                    boxSizing: "border-box"
                }}>
                <div style={{
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 48, paddingBottom: 24,
                    boxSizing: "border-box"
                }}>
                    <img src={logo} style={{ marginBottom: 12 }} />
                    <span style={{
                        fontFamily: "Notosans",
                        fontSize: "12px",
                        color: DARK_GRAY
                    }}>{"회원가입을 위해 아래 정보를 입력해주세요"}</span>
                </div>
                <div style={{
                    flex: 1, flexDirection: "column", overflowY: "auto", overflowX: "hidden"
                }}>
                    <InputJoin
                        id="company_name"
                        onChange={(e) => {
                            setCompanyName(e.target.value)
                            redOrBlack("company_name", true)
                        }}
                        value={companyName}
                        type="text"
                        title="회사명"
                        placeholder="회사명을 입력해 주세요." />
                    <InputJoin
                        id="name_input"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            redOrBlack("name_input", true)
                        }}
                        type="text"
                        title="이름"
                        placeholder={"이름을 입력해 주세요."} />
                    <InputJoin
                        id="phone_input"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                            setIsConfirm(false)
                            setAuthCodeVisible(false)
                            redOrBlack("phone_input", true)
                            redOrBlack("bn_phone_auth", true)
                            setErrorCode(0)
                        }}

                        inputStyle={{
                            color : isConfirm ? DARK_GRAY : "black"
                        }}
                        type="tel"
                        title="전화번호"
                        placeholder={"전화번호를 입력해 주세요."} 
                        rightButton={() => {
                            return (
                                !isConfirm ?
                                <div id="bn_phone_auth" className="pointer-cursor"
                                    style={{
                                        backgroundColor: THEME_YELLOW,
                                        borderRadius: "36px",
                                        boxSizing: "border-box",
                                        maxHeight: "100%",
                                        display: "flex", justifyContent: "center", padding: "16px",
                                        alignItems: "center"
                                    }}
                                    onClick={async () => {
                                        if (btnAuthActive) {
                                            alert("인증번호는 국제번호로 발신되니 수신이 안되는 경우 차단 목록을 확인해주세요.")
                                            setBtnAuthActive(false)
                                            redOrBlack("bn_phone_auth", true)
                                            setErrorCode(0)
                                            const appVerifier = window.recaptchaVerifier
                                            try {
                                                const typePhone = "+82" + phone.replace(/[^0-9]/g, "").substr(1)
                                                const confirmationResult = await signInWithPhoneNumber(fireAuth, typePhone, appVerifier)
                                                window.confirmationResult = confirmationResult
                                                setAuthCodeVisible(true)
                                            } catch (error) {
                                                //console.log(error)
                                                if (error instanceof FirebaseError) {
                                                    switch (error.code) {
                                                        case "auth/invalid-phone-number":
                                                            setErrorCode(1)
                                                            break;
                                                        case "auth/too-many-requests":
                                                            setErrorCode(2)
                                                            break;
                                                        default:
                                                            setErrorCode(3)
                                                            break;
                                                    }
                                                }
                                                else{
                                                    alert(error)
                                                    setErrorCode(3)
                                                }
                                            }
                                            setBtnAuthActive(true)
                                        }
                                    }}>
                                    <span style={{
                                        wordBreak: "keep-all", fontSize: "12px",
                                        color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                                    }}>인증번호받기</span>
                                </div> : null
                            )
                        }} />
                    <span style={{ color: "red", fontSize: "12px" }}>{
                        errorCode > 0
                            ? errorCode > 1
                                ? errorCode > 2
                                    ? errorCode > 3
                                        ? ""
                                        : "인증 모듈에 오류가 발생하였습니다."
                                    : "너무 많이 시도되었습니다. 잠시 후에 다시 시도해주세요."
                                : "유효하지 않은 전화번호입니다."
                            : ""}</span>
                    <div id="sign-in-button" style={{}}></div>
                    {authCodeVisible && <InputJoin
                        id="auth_input"
                        value={authCode}
                        onChange={(e) => {
                            if (!isConfirm) {
                                setAuthCode(e.target.value)
                                redOrBlack("auth_input", true)
                                redOrBlack("bt_confirm", true)
                                setErrorCode(0)
                            }
                        }}
                        inputStyle={{
                            color : isConfirm ? DARK_GRAY : "black"
                        }}
                        type="number"
                        title="인증번호"
                        maxLength={6}
                        placeholder={"인증번호를 입력해 주세요."} 
                        rightButton={() => {
                            return (
                                <div id="bt_confirm" className="pointer-cursor"
                                    style={{
                                        backgroundColor: THEME_YELLOW,
                                        borderRadius: "36px",
                                        boxSizing: "border-box",
                                        maxHeight: "100%",
                                        display: "flex", justifyContent: "center", padding: "16px",
                                        alignItems: "center",
                                    }}
                                    onClick={async () => {
                                        if (!isConfirm && !onAuthrizing)
                                            try {
                                                setOnAuthrizing(true)
                                                const confirmResult = await window.confirmationResult.confirm(authCode)
                                                setIsConfirm(true)
                                                redOrBlack("bt_confirm", true)
                                                redOrBlack("auth_input", true)
                                                await confirmResult.user.delete()
                                                setOnAuthrizing(false)
                                            } catch (error) {
                                                setOnAuthrizing(false)
                                                console.log(error)
                                                if (error instanceof FirebaseError) {
                                                    switch (error.code) {
                                                        case "auth/invalid-verification-code":
                                                            setErrorCode(4)
                                                            break;
                                                        case "auth/code-expired":
                                                            setErrorCode(5)
                                                            break;
                                                        case "auth/user-token-expired":
                                                            break;
                                                        default:
                                                            setErrorCode(0)
                                                            break;
                                                    }
                                                }
                                            }
                                    }}>
                                    <span style={{
                                        wordBreak: "keep-all", fontSize: "12px",
                                        color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                                    }}>{isConfirm ? "인증완료" : "인증하기"}</span>
                                </div>
                            )
                        }} />}

                    <span style={{ color: "red", fontSize: "12px" }}>{
                        errorCode > 3
                            ? errorCode > 4
                                ? errorCode > 5
                                    ? "너무 많이 시도되었습니다. 잠시 후에 다시 시도해주세요."
                                    : "만료된 인증번호입니다. 인증번호를 다시 발급받아주세요"
                                : "인증번호가 일치하지 않습니다."
                            : ""
                    }</span>
                    <InputJoin
                        id="email_input"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            redOrBlack("email_input", true)
                        }}
                        type="email"
                        title="아이디"
                        placeholder={"이메일 형식의 아이디를 입력해 주세요."} />
                    <InputJoin
                        id="password_input"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                            redOrBlack("password_input", true)
                        }}
                        type="password"
                        title="비밀번호"
                        placeholder={"영문, 숫자를 포함하여 8자리 이상 입력해 주세요."} />
                    <InputJoin
                        id="confirmPassword_input"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value)
                            redOrBlack("confirmPassword_input", true)
                        }}
                        type="password"
                        title="비밀번호 확인"
                        placeholder={"비밀번호를 다시 입력해 주세요."} />
                    <InputJoin
                        id="bn_input"
                        value={bn}
                        onChange={(e) => {

                            setBn(e.target.value.replace(/[^0-9]/g, ""))
                            redOrBlack("bn_input", true)
                        }}
                        maxLength={10}
                        type="text"
                        title="사업자 번호"
                        placeholder={"사업자 번호를 입력해 주세요."}
                        // rightButton={() => {
                        //     return (
                        //         <div id="bn_photo" className="pointer-cursor"
                        //             style={{
                        //                 backgroundColor: THEME_YELLOW,
                        //                 borderRadius: "36px",
                        //                 boxSizing: "border-box",
                        //                 maxHeight: "100%",
                        //                 display: "flex", justifyContent: "center", padding: "16px",
                        //                 alignItems: "center"
                        //             }}
                        //             onClick={() => {
                        //                 fileInputRef.current?.click();
                        //             }}>
                        //             <img src={upload} />
                        //             <span style={{
                        //                 marginInlineStart: "6px",
                        //                 wordBreak: "keep-all", fontSize: "12px",
                        //                 color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                        //             }}>업로드</span>
                        //             <input
                        //                 ref={fileInputRef}
                        //                 style={{ flex: 0, width: "1px", height: "1px" }}
                        //                 type="file" isAccept="application/pdf,image/*"
                        //                 onChange={(e) => {
                        //                     if (e.target.files) {
                        //                         redOrBlack("bn_photo", true)
                        //                         let file = e.target.files[0]
                        //                         let reader = new FileReader();
                        //                         reader.onloadend = () => {
                        //                             console.log(file)
                        //                             setFile(file)
                        //                             if (file.type !== "application/pdf") {
                        //                                 const imageUrl = reader.result?.toString()
                        //                                 setPreview({type:file.type,preview:imageUrl})
                        //                             }else{
                        //                                 setPreview({type:file.type,preview:file.name})
                        //                             }
                        //                         }
                        //                         reader.readAsDataURL(file)
                        //                     }
                        //                 }} />
                        //         </div>
                        //     )
                        // }} 
                        />
                    <InputJoin
                        id="p_nm_input"
                        value={pNm}
                        onChange={(e) => {

                            setRepresendtative(e.target.value)
                            redOrBlack("p_nm_input", true)
                        }}
                        type="text"
                        title="대표자성명"
                        placeholder={"대표자 성명을 입력해 주세요."}/>
                    <span style={{fontSize:"12px",color:DARK_GRAY,fontFamily:"Notosans"
                    }}>{"대표자 이름이 영문일 경우 ex. 홍길동 -> HONG KIL DONG"}</span>
                    <InputJoin
                        id="start_dt_input"
                        value={startDt}
                        onChange={(e) => {

                            setStartDt(e.target.value.replace(/[^0-9]/g, ""))
                            redOrBlack("start_dt_input", true)
                        }}
                        maxLength={8}
                        type="text"
                        title="개업일자"
                        placeholder={"ex)2022년 01월 02일 -> 20200102"}/>
                    <div style={{
                        height: "50px", boxSizing: "border-box", fontFamily: "Notosans",
                        padding: "8px 0px 8px 0px", alignSelf: "flex-end",
                        display: "flex",
                        alignItems: "center", justifyContent: "flex-end"
                    }}>
                        <img className="pointer-cursor"
                            src={ isAccept ? agree_on : agree_off} style={{ height: "100%" }}
                            onClick={() => {
                                // var newState = !isAccept
                                // if(newState) {
                                //     redOrBlack("agree_txt", true)
                                if(isAccept) setIsAccept(!isAccept)
                                else setConsentModal(true)
                                // }
                                // setIsAccept(newState)
                            }} />
                        <span id="agree_txt" style={{
                            color: "#9A9A9A", fontWeight: 500, fontSize: "14px",
                            wordBreak: "keep-all", whiteSpace: "nowrap",
                        }}>{"약관 전체동의"}</span>
                    </div>
                    {preview && (
                        preview.type !== "application/pdf"
                            ?
                            <img src={preview.preview}
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    height: "auto"
                                }} />
                            :
                            <span style={{fontSize:"14px"}}>{preview.preview}</span>
                    )}
                </div>
                <div style={{
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 24, paddingBottom: 24,
                    boxSizing: "border-box"
                }}>
                    <div  className="pointer-cursor" style={{
                        boxSizing: "border-box",
                        width: "100%",
                        padding: "12px",
                        marginTop: "8px",
                        borderRadius: "52px",
                        backgroundColor: THEME_YELLOW,
                        display: "flex", justifyContent: "center"
                    }}
                        onClick={() => {
                            if (changeCanSignState()) {
                                if (!onSinging)
                                    signUp()
                            } else {
                                alert("모든 입력란을 알맞게 채워주세요")
                            }
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{JOIN}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}