import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";
import { GRAY, THEME_YELLOW } from "../values/Colors";
import { StaffWithId } from "../classes/Staff"
import check_on from "../images/manage/check_on@2x.png"
import check_off from "../images/manage/check_off@2x.png"
import arrow_down from "../images/manage/ic_arrow_down@2x.png"
import arrow_up from "../images/manage/ic_arrow_up@2x.png"
import { btn_edit_square, edit, ic_arrow_up, ic_delete, ic_staff, logo, menu, plus } from "../images/images";
import styles from "../css/StaffManage.module.css"
import { MonthItem } from "../classes/Month";
import { useLocation, useNavigate } from "react-router-dom";

export const AdminStaffList: FC<{
    isMobile: boolean
    middleLine?: CSSProperties,
    isEmployee: number,
    list: StaffWithId[],
    headers: { title: string, flex: number }[],
    staffList : StaffWithId[],
    onEdit?: boolean
    selectMonth : MonthItem | undefined,
    selectMonthToPattern : string,
    changeStaffState?: (staff: StaffWithId) => void
    openModifyDialog?: (staff: StaffWithId) => void
}> = ({
    isMobile,
    middleLine = {},
    isEmployee,
    list,
    headers,
    staffList,
    onEdit,
    selectMonthToPattern,
    selectMonth,
    changeStaffState,
    openModifyDialog
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const listRef = useRef<HTMLDivElement>(null)
    const keyName = isEmployee === 0 ? "employee" : "part-time-job-row"
    const title = isEmployee === 0 ? "직원" : "아르바이트/일용직"
    const [scrollState, setScrollState] = useState<number>(0)
    useEffect(() => {
        setScrollState(listRef.current?.scrollHeight!!! - listRef.current?.clientHeight!!! - listRef.current?.scrollTop!!!)
    }, [list])
    return (
        <div key={`${keyName}_list`}
            style={{
                flex: 1,
                display: "flex", flexDirection: "column",
                boxSizing: "border-box", ...middleLine
            }}>

            <div className={styles.row_ctn}>
                <span className={styles.row_cell}
                    style={{
                        flex: 1, fontSize: "16px"
                    }}>{title}
                    <span className={styles.row_cell}
                        style={{
                            marginLeft: "4px",
                            color: THEME_YELLOW, fontSize: "16px"
                        }}>{`${list.length}명`}</span></span>
                <div style={{ flex: 7 }} />
            </div>
            <div className={styles.row_ctn}
                style={{
                    height: "48px"
                }}>
                {headers.map((value, index) => {
                    const { title, flex } = value
                    return (
                        (onEdit && index === 0)
                            ? <div key={`header_cell_${title}_${index}`}
                                className={`${styles.row_cell} ${styles.header_cell}`}
                                style={{
                                    flex: flex,
                                    padding: "12px 0px",
                                    color: THEME_YELLOW,
                                    borderBottom: "0px solid white"
                                }}>{"선택"}</div>
                            : <div key={`header_cell_${title}_${index}`}
                                className={`${styles.row_cell} ${styles.header_cell}`}
                                style={{
                                    flex: flex,
                                    padding: "12px 0px",
                                    borderBottom: "0px solid white"
                                }}>{title}</div>
                    )
                })}
            </div>
            {(listRef.current?.scrollTop ?? 0) >= 43 &&
                <img src={ic_arrow_up} style={{ width: "5%", height: "auto", alignSelf: "center", maxHeight: "36px", maxWidth: "36px", }} />}
            <div
                ref={listRef}
                style={{
                    flex: 1,
                    overflowY: "auto",
                    scrollbarWidth:"none",
                    color: "#767676",
                }}
                onScroll={(ev) => {
                    setScrollState(listRef.current?.scrollHeight!!! - listRef.current?.clientHeight!!! - listRef.current?.scrollTop!!!)
                }}>
                {list?.length
                    ? list.map((value, index1) => {
                        const { data,payStubObj } = value
                        const thisPayStub = payStubObj[selectMonthToPattern]
                        const shortSNN = data.ssn.length > 2
                            ? data.ssn.slice(0, 8) : data.ssn.join("-").slice(0, 8)
                        const Kgender = data.gender === "M" ? "남" : "여"
                        const values = [index1 + 1, data.name, data.phone, Kgender, shortSNN, data,data]
                        const state: number = thisPayStub
                        ? thisPayStub.isSend
                            ? 2 //"발송 완료"
                            : 1 //"발송 전"
                        : 0 //"등록 전"
                        return (
                            <div className={styles.row_ctn} style={{
                                borderWidth: index1 === 0
                                    ? "1px 0px 1px 0px"
                                    : "0px 0px 1px 0px",
                                borderStyle: "solid", borderColor: "#EADFDB",
                                backgroundColor: index1 % 2 === 0 ? "white" : "#FCFAF5",
                                height: "56px",
                                padding: "0px", margin: "0px 5%"
                                // minHeight: "48px",maxHeight:"48px"
                            }} key={`${keyName}-row_${index1}`}>
                                {values.map((value2, index2) => {
                                    const { flex } = headers[index2]
                                    if (index2 === 0 && onEdit)
                                        return (
                                            <div key={`${keyName}-row-cell_${index1}_${index2}`}
                                                className={styles.row_cell}
                                                style={{
                                                    height: "100%", maxHeight: "30px",
                                                    flex: flex,
                                                    fontWeight: "normal"
                                                }}>
                                                <img className="pointer-cursor"
                                                    style={{ height: "100%", }}
                                                    src={data.state ? check_on : check_off}
                                                    onClick={() => {
                                                        if (changeStaffState)
                                                            changeStaffState(value)
                                                    }} />
                                            </div>
                                        )
                                    else if (index2 === values.length - 2) {
                                        return (
                                            <div key={`${keyName}-row-cell_${index1}_${index2}`}
                                                className={styles.row_cell}
                                                style={{
                                                    height: "100%", maxHeight: "30px",
                                                    flex: flex,
                                                    fontWeight: "normal", boxSizing: "border-box",
                                                    display: "flex", alignItems: "center", justifyContent: "center"
                                                }}
                                                onClick={()=>{
                                                    if(state > 0){
                                                        const staffIndex = staffList.indexOf(value)
                                                        navigate("pay-edit", {
                                                            state: {
                                                                ...location.state,
                                                                staff: value,
                                                                payStub: thisPayStub,
                                                                targetMonth: selectMonth,
                                                                staffIndex: staffIndex,
                                                            }
                                                        })
                                                    }
                                                }}>
                                                <span
                                                    style={{
                                                        fontFamily: "Notosans", fontSize: "12px",
                                                        padding: "4px 0px", width: "52px",
                                                        borderRadius: "12px", boxSizing: "border-box",
                                                        wordBreak: "keep-all", whiteSpace: "nowrap", textAlign: "center",
                                                        ...state > 0 //index2 == 3 보기
                                                                        ? { color: "white", backgroundColor: THEME_YELLOW, cursor: "pointer" } //발송 전 및 완료
                                                                        : { color: "#757575", border: "1px solid #757575" } //등록 전
                                                    }}>
                                                    {"편집"}
                                                </span>
                                                {/* <img className="pointer-cursor"
                                                        style={{ height: "80%", maxHeight: "44px", }}
                                                        src={btn_edit_square}
                                                        onClick={() => {
                                                            if (openModifyDialog)
                                                                openModifyDialog(value)
                                                        }} /> */}
                                            </div>)
                                    }
                                    else if (index2 === values.length - 1) {
                                        return (
                                            <div key={`${keyName}-row-cell_${index1}_${index2}`}
                                                className={styles.row_cell}
                                                style={{
                                                    height: "100%", maxHeight: "30px",
                                                    flex: flex,
                                                    fontWeight: "normal", boxSizing: "border-box",
                                                    display: "flex", alignItems: "center", justifyContent: "center"
                                                }}>
                                                <img className="pointer-cursor"
                                                    style={{ height: "80%", maxHeight: "44px", }}
                                                    src={btn_edit_square}
                                                    onClick={() => {
                                                        if (openModifyDialog)
                                                            openModifyDialog(value)
                                                    }} />
                                            </div>)
                                    }
                                    else
                                        return (
                                            <span className={styles.row_cell} key={`${keyName}-row-cell_${index1}_${index2}`}
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    flex: flex
                                                }}>{value2}</span>
                                        )
                                })}
                            </div>
                        )
                    })
                    :
                    <div style={{
                        display: "flex", justifyContent: "center"
                    }}>
                        <span className={styles.row_cell}>{`등록된 ${isEmployee === 0 ? "직원이" : "아르바이트가"} 없습니다.`}</span>
                    </div>}
            </div>
            {(scrollState ?? 0) >= 43 &&
                <img src={arrow_down} style={{ width: "5%", height: "auto", alignSelf: "center", maxHeight: "36px", maxWidth: "36px" }} />}
        </div>
    )
}