import { ChangeEventHandler, CSSProperties, FC, HTMLInputTypeAttribute,} from "react"
import { GRAY } from "../values/Colors"

export const InputJoin:
    FC<{
        id? : string,
        value: string,
        placeholder?: string,
        readonly? : boolean,
        type? : HTMLInputTypeAttribute,
        onChange?: ChangeEventHandler<HTMLInputElement> | undefined,
        title?: string,
        rightButton?: () => JSX.Element | null,
        style? : CSSProperties,
        inputStyle? : CSSProperties,
        maxLength? : number
    }> = ({ id,style,maxLength,inputStyle,readonly,
        value, placeholder, type, title, rightButton, onChange }) => {
        return (
            <div id={id}
                style={{
                    display: "flex", flexDirection: "row",
                    height: "60px",
                    paddingTop: 16, paddingBottom: 16,
                    boxSizing: "border-box",
                    borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                    alignItems: "center",...style
                }}>
                <span style={{ fontFamily: "NotoSans", fontWeight: 500, wordBreak: "keep-all", whiteSpace: "nowrap" }}>{title}</span>
                <input style={{
                    flex: 1, borderWidth: "0px",
                    width: "0px",
                    fontSize: "12px",
                    outlineWidth: "0px", fontFamily: "NotoSans",
                    paddingInlineStart: "4px", paddingInlineEnd: "4px",
                    ...inputStyle
                }}
                    readOnly={readonly}
                    type={type}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange} />
                {rightButton ? rightButton() : null}
            </div>
        )
    }