import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { fireAuth, fireStore } from "../fireConfig";
import logo from "../images/login/logo_login.png"
import icon_delete from "../images/login/btn_delete@2x.png"
import icon_arrow_join from "../images/login/ic_arrow_join@2x.png"
import { FullScreen } from "../components/FullScreen";
import { BLACK, DARK_GRAY, GRAY, RED, THEME_YELLOW } from "../values/Colors";
import { signInWithEmailAndPassword, AuthError } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { FIVE_PER, JOIN, LOGIN, NOT_MATCH_PASSWORD, NOT_MEMBER_YET, NOT_SIGN_USER, PLEASE_INPUT_EMAIL_TYPE } from "../values/strings";
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";

export const Login: FC = () => {

    const [email, setEmail] = useState<string>("");
    const emailRef = useRef<HTMLInputElement>(null)
    const [pass, setPass] = useState<string>("");
    const passRef = useRef<HTMLInputElement>(null)
    //0기본, 1이메일 형식 오류, 2해당 유저 없음, 3비밀번호 불일치
    const [errorCode, setErrorCode] = useState<number>(0)
    const location = useLocation()
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();

    useEffect(() => {
        const onAuthStateChanged = fireAuth.onAuthStateChanged(async(user) => {
            if (user && location.pathname === "/login") {
                // await reduxUpdateUser(user.uid)
                navigate("/main", { replace: true })
            }
        })

        return () => onAuthStateChanged()
    }, [])

    // const reduxUpdateUser = async (uid: string) => {
    //     const userDocRef = doc(fireStore, "users", uid).withConverter(userConverter)
    //     const userDoc = await getDoc(userDocRef)
    //     reduxDispatch(updateUser(userDoc.data()))
    // }
    const signIn = async (email: string, pass: string) => {
        if (pass.length > 0)
            try {
                const userCredential = await signInWithEmailAndPassword(fireAuth, email, pass)
                // await reduxUpdateUser(userCredential.user.uid)
                // navigate("/manage-staff", { replace: true })
            } catch (error) {
                if (error instanceof FirebaseError) {
                    const errorCode = error.code
                    console.log(errorCode)
                    switch (errorCode) {
                        case "auth/invalid-email":
                            setErrorCode(1)
                            fucusOn(emailRef)
                            break;
                        case "auth/user-disabled":
                            setErrorCode(2)
                            fucusOn(emailRef)
                            break;
                        case "auth/user-not-found":
                            setErrorCode(2)
                            fucusOn(emailRef)
                            break;
                        case "auth/wrong-password":
                            setErrorCode(3)
                            fucusOn(passRef)
                            break;
                        default:
                            fucusOn(emailRef)
                            break;
                    }
                } else {
                    console.log(error)
                }
            }
        else {
            console.log("fdsfds")
            setErrorCode(3)
            fucusOn(passRef)
        }
    }
    const fucusOn = (ref: RefObject<HTMLElement>) => {
        ref.current?.focus()
    }

    const resetErrorCode = () => {
        setErrorCode(0)
    }
    return (
        <FullScreen>
            <div style={{
                width: "100%",
                // maxWidth: "400px", 
                height: "100%",
                // padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                display: "flex", flexDirection: "column",
                alignItems: "center"
            }}>
                {/* <div style={{ flex: 1 }} /> */}
                <div style={{
                    flex: 1,
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`, boxSizing: "border-box",
                    width: "100%",
                    display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    {/* <div> */}
                    <img src={logo} style={{
                        width: "30%", alignSelf: "center",
                        maxWidth: "200px",
                        marginBottom: 48
                    }} />
                    {/* inputCtn */}
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                            alignItems: "center"
                        }}>
                            <input
                                ref={emailRef}
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={email}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") passRef.current?.focus()
                                }}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    resetErrorCode()
                                }}
                                placeholder="이메일"
                                type="email" />
                            {email
                                ? <img src={icon_delete}
                                    style={{ height: "18px" }}
                                    onClick={() => {
                                        setEmail("")
                                        resetErrorCode()
                                    }} /> : null}
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode == 1 ? PLEASE_INPUT_EMAIL_TYPE : errorCode == 2 ? NOT_SIGN_USER : "\u200b"}</span>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "column",
                        width: "100%", maxWidth: "400px",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            width: "100%",
                            boxSizing: "border-box",
                            borderStyle: "solid", borderColor: GRAY, borderWidth: "0px 0px 1px 0px",
                            padding: "12px",
                        }}>
                            <input
                                ref={passRef}
                                style={{
                                    flex: 1, borderWidth: "0px", borderColor: "white",
                                    outlineWidth: "0px", fontFamily: "NotoSans"
                                }}
                                value={pass}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") signIn(email, pass)
                                }}
                                onChange={(e) => {
                                    setPass(e.target.value)
                                    resetErrorCode()
                                }}
                                placeholder="비밀번호"
                                type="password" />
                            {pass && <img src={icon_delete}
                                style={{ height: "18px" }}
                                onClick={() => {
                                    setPass("")
                                    resetErrorCode()
                                }} />}
                        </div>
                        <span style={{
                            fontFamily: "NotoSans", fontSize: "14px", paddingTop: "2px", paddingBottom: "2px", color: RED
                        }}>{errorCode === 3 ? NOT_MATCH_PASSWORD : "\u200b"}</span>
                    </div>
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            width: "100%", maxWidth: "400px",
                            padding: "12px",
                            marginTop: "24px",
                            borderRadius: "52px",
                            backgroundColor: THEME_YELLOW,
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={() => { signIn(email, pass) }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{LOGIN}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", padding: "24px 0px 24px 0px", boxSizing: "border-box" }}>
                        <span style={{
                            alignSelf: "flex-end",
                            color: DARK_GRAY,
                            display: "flex",
                            fontSize: "13px",
                            alignItems: "center", justifyContent: "center"
                        }}>{NOT_MEMBER_YET}
                            <div className="pointer-cursor"
                                style={{
                                    display: "flex",
                                    alignItems: "center", justifyContent: "center",
                                    marginInlineStart: "4px",
                                    fontSize: "13px",
                                }}
                                onClick={() => {
                                    // alert("navigation To Join")
                                    navigate("/join")
                                }}>
                                <span style={{ color: BLACK }}>{JOIN}</span>
                                <img src={icon_arrow_join} style={{ height: "13px" }} />
                            </div>
                        </span>
                    </div>
                    <span className="pointer-cursor"
                        style={{ color: DARK_GRAY, fontFamily: "NotoSans", fontSize: "12px" }}
                        onClick={() => {
                            navigate("/dialog/find-password", {
                                state: {
                                    backgroundLocation : [location]
                                }
                            })
                        }}>{"비밀번호 찾기"}</span>
                </div>
                <div style={{
                    width: "100%",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,boxSizing:"border-box",
                    backgroundColor:"rgba(245, 244, 241, 0.85)",
                }}>
                    <p style={{fontFamily:"Notosans",fontSize:"12px",color:DARK_GRAY,fontWeight:500}}>
                        노랑봉투<br/>
                        <span style={{fontSize:"10px",fontWeight:"normal"}}>
                            {`주식회사 플로션`}<br/>
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br/>
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br/>
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br/>
                        </span>
                    </p>
                </div>
            </div>
        </FullScreen>
    )
}