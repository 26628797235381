import React,{CSSProperties, FC, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { BannerWithId } from "../classes/Banner";
import { ServeBannerObj } from "../redux/reducer/serve-banner/serveBanners";
import { bannerWithOutDuplication, getImageUrl } from "./Tools";

export const ServiceBanner : FC<{
    type : 0 | 1,
    isRan? : boolean,
    style? : CSSProperties
}> = ({
    type,
    isRan = false,
    style
}) => {
    const reduxState = useSelector((state : {serveBanner : ServeBannerObj,banners : BannerWithId[]}) => state)
    const servedBanners: BannerWithId[] = reduxState.serveBanner[type]
    const originBanners: BannerWithId[] = reduxState.banners.filter(({ banner }) => banner.type === type)
    const getRan = () => {
        if (originBanners.length > 1) {
            const ranBanners = bannerWithOutDuplication(originBanners, servedBanners)
            // console.log(servedBanners[servedBanners.length - 1].banner.type, " : ", ranBanners[ranBanners.length - 1].banner.type)
            return ranBanners[ranBanners.length - 1]
        } else {
            return undefined
        }
    }
    const currentBanner: BannerWithId | undefined = isRan
        ? getRan()
        : servedBanners[servedBanners.length-1]
    const reduxDispatch = useDispatch()

    const [imageUrl, setImageUrl] = useState<string>("")

    useEffect(() => {
        if(!currentBanner)
            return
        const { banner, base64, index } = currentBanner
        const { destination } = banner
        if (base64) {
            setImageUrl(base64)
        } else
            getImageUrl(reduxDispatch, destination, index!, (src) => {
                setImageUrl(src)
            })
    }, [servedBanners])
    const openInNewTab = () => {
        if (currentBanner) {
            var url = currentBanner.banner.url
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }
    return(
        servedBanners[servedBanners.length-1] ?
        <img className="pointer-cursor" style={{width:"100%", maxWidth:"640px",alignSelf:"center",...style}} src={imageUrl}
            onClick={openInNewTab}/> 
            : null
    )
}