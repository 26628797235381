import { Notice, NoticeWithId } from "../../../classes/Notice";
import { DELETE_NOTICE, SAVE_NOTICE, UNSHIFT_NOTICE, UPDATE_NOTICE } from "./notice.type";

const initialState : NoticeWithId[] = [] 

export const saveNotice = (data : NoticeWithId[] | undefined) => {
    return {
        type : SAVE_NOTICE,
        payload : data
    }
}
export const unshiftNotice = (data : NoticeWithId) => {
    return {
        type : UNSHIFT_NOTICE,
        payload : data
    }
}
export const updateNotice = (data : NoticeWithId) => {
    return {
        type : UPDATE_NOTICE,
        payload : data
    }
}
export const deleteNotice = (data : NoticeWithId) => {
    return {
        type : DELETE_NOTICE,
        payload : data
    }
}
export const noticeReducer = (state: NoticeWithId[] = initialState, action: { type: string, payload?: any }): NoticeWithId[] => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case SAVE_NOTICE:
            return action.payload;
        case UNSHIFT_NOTICE:
            var copyList = state.slice()
            copyList.unshift(action.payload)
            return copyList
        case UPDATE_NOTICE:
            var copyList = state.slice()
            copyList[action.payload.index] = action.payload
            return copyList
        case DELETE_NOTICE:
            return state.filter((noticeWithId)=>{
                return noticeWithId.id !== action.payload.id
            })
        default:
            break;
    }
    return state
}