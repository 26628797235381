import { Dialog } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import React,{FC, useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Notice, NoticeWithId } from "../../classes/Notice";
import { useBlocker } from "../../components/react-router-dom-revive";
import styles from '../../css/AdminBanner.module.css'
import { fireFunctions, fireStorage, fireStore } from "../../fireConfig";
import { deleteNotice, updateNotice } from "../../redux/reducer/notice/notice";
import { THEME_YELLOW } from "../../values/Colors";
export const AdminNoticeModi : FC = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const reduxDispatch = useDispatch();
    const {noticeWithId} = location.state as { noticeWithId : NoticeWithId}
    const {notice,id,index} = noticeWithId
    const {title : titleOrigin, content : contentOrigin, createTime} = notice

    const [title,setTitle] = useState<string>(titleOrigin ?? "")
    const [content,setContent] = useState<string>(contentOrigin ??"")

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [onProcess, setOnProcess] = useState<boolean>(false)

    const goBack = () =>{
        navigate(-1)
    }
    const canUpload = () : boolean => {
        return (title.length > 0
            && content.length > 0 ) ?? false
    }
   
    const uploadNotice = async() => {
        try {
            if (!onProcess && canUpload()) {
                setOnProcess(true)

                const notiDoc = doc(fireStore, "notices", id)
                const notice: Notice =
                {
                    title: title,
                    content: content,
                    createTime : new Timestamp(createTime.seconds,createTime.nanoseconds)
                }
                await updateDoc(notiDoc,{...notice})

                reduxDispatch(updateNotice({
                    notice : notice,
                    id : id,
                    index : index
                }))
                goBack()
            } else {
                alert("모든 항목을 채워주세요")
            }
        } catch (error) {
            alert(error)
        }
        setOnProcess(false)
    }

    const deleteNoti = async() => {
        const notiRef = doc(fireStore,"notices",noticeWithId.id)
        await deleteDoc(notiRef)
        reduxDispatch(deleteNotice(noticeWithId))
    }
    
    const changeModalState = () => {
        setDeleteModal(!deleteModal)
    }

    useBlocker(()=>{
        changeModalState()
    },deleteModal)
    return (
        <div className={styles.main_ctn}
            style={{
                padding: "30px 2.5%",
                display: "flex",
                overflowY: "hidden", flexDirection: "column"
            }}>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                    }
                }}
                fullWidth={true}
                open={deleteModal}
                onClose={()=>{
                    setDeleteModal(!deleteModal)
                }}>

                <div style={{
                    borderRadius: "4px",
                    margin: "8px",
                    padding: `32px`,
                    width: "100%", maxWidth: "480px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontWeight: 500, color: "#000000",fontSize:"20px" }}>{`공지삭제`}</span>
                    <span style={{ fontSize: "16px", margin: "16px 0px",color:"#767676" }}>
                        {`'${titleOrigin}' 공지를`}<br />삭제하시겠습니까?
                    </span>
                    <div style={{
                        width: "100%", display: "flex", fontSize: "14px", justifyContent: "space-between",
                        color: "#767676",marginTop:"8px"
                    }}>
                        <div className="pointer-cursor"
                            style={{
                                border: `1px solid ${THEME_YELLOW}`, borderRadius:"3px",
                                color:THEME_YELLOW, fontWeight:500,
                                width: "45%",
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "center", padding: "16px",
                            }}
                            onClick={changeModalState}>
                            <span style={{ fontFamily: "NotoSans" }}>{`취소`}</span>
                        </div>
                        <div className="pointer-cursor"
                            style={{
                                backgroundColor:THEME_YELLOW, borderRadius:"3px",
                                color:"#FFFFFF", fontWeight:500,
                                width: "45%",
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "center", padding: "16px",
                            }}
                            onClick={async () => {
                                deleteNoti()
                                //console.log("Finish DeleteUser")
                                setDeleteModal(false)
                                goBack()
                            }}>
                            <span style={{ fontFamily: "NotoSans" }}>{`삭제`}</span>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div style={{
                display: "flex", flexDirection: "row",
                justifyContent: "space-between", alignItems: "center",
                fontSize: "14px", marginBottom: "12px"
            }}>
                <span style={{
                    fontSize: "22px", fontWeight: 500,
                }}>공지사항 수정</span>

                <div style={{
                    display: "flex", flexDirection: "row"
                }}>
                    <div style={{
                        borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "#757575", border: "1px solid #757575",
                        marginRight: "14px",
                        cursor:"pointer"
                    }} onClick={goBack}>
                        <span >{"취소"}</span>
                    </div>
                    <div style={{
                        backgroundColor: THEME_YELLOW, borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "white", fontWeight: 500,
                        cursor:"pointer"
                    }} onClick={uploadNotice}>
                        <span >{"수정"}</span>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    color: "#161616",marginBottom:"24px"
                }}>
                <span style={{ marginRight: "20px",width:"80px" }}>제목</span>
                <input style={{
                    width: "320px",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px"
                }}
                    placeholder="공지사항 제목을 입력해 주세요."
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }}
                />
            </div>
            <div 
                style={{
                    flex:1,
                    display: "flex", flexDirection: "row",
                    alignItems: "flex-start",
                    color: "#161616"
                }}>
                <span style={{ marginRight: "20px", width: "80px" }}>내용</span>
                <div style={{
                    flex:1, boxSizing:"border-box",
                    alignItems:"flex-start",
                    display: "flex", flexDirection: "column",
                }}>
                    <textarea style={{
                        width: "80%",
                        minHeight: "500px",maxHeight:"500px",
                        boxSizing: "border-box",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px", resize: "none"
                    }}
                        placeholder="공지내용을 입력해 주세요."
                        value={content}
                        onChange={(e) => { setContent(e.target.value) }}
                    />

                    <div style={{

                        backgroundColor: "#FF5555", borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "white", fontWeight: 500,
                        marginTop: "14px",
                        cursor: "pointer"
                    }} onClick={changeModalState}>
                        <span >{"삭제"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}