import React, { FC, MouseEventHandler, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StaffWithId } from "../classes/Staff";
import { fireAuth, fireStore } from "../fireConfig";
import back from "../images/common/arrow_back@2x.png"
import { BLACK, THEME_YELLOW } from "../values/Colors";
import { useDispatch, useSelector } from "react-redux";
import { User, userConverter, UserWithId } from "../classes/User";
import moment from "moment";
import styles from '../css/Info.module.css'
import { edit, ic_user, img_manage_user } from "../images/images";
import { useMediaQuery } from "react-responsive";
import { Dialog } from "@mui/material";
import { deleteUser } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { ServiceBanner } from "../components/ServiceBanner";
import { ServeBannerObj } from "../redux/reducer/serve-banner/serveBanners";
export const Info: FC = () => {
    // const IMP = window.IMP
    const navigate = useNavigate();
    const [modalVisible,setModalVisible] = useState<boolean>(false)
    const reduxState = useSelector((state: { user: UserWithId, staff: StaffWithId[],isMobile: boolean }) => {
        return state
    });
    const user = reduxState.user.data
    const isMobile: boolean = reduxState.isMobile


    // const [nextPayDate, setNextPayDate] = useState<{ merchant_uid: string, customer_uid: string, schedule_at: number } | undefined>(undefined)

    // const billing = async (uid: string) => {
    //     //console.log("billings Request : ", uid)
    //     const bilingResult = await (await fetch("https://asia-northeast3-nobong-79c64.cloudfunctions.net/billings", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({
    //             "customer_uid": uid, // 카드(빌링키)와 1:1로 대응하는 값,
    //             "custom_data": "direct",
    //             "test": "test",
    //             "input": "input"
    //         })
    //     })).json()
    //     //console.log(bilingResult)
    //     const { isSuccess, code } = bilingResult
    //     if (isSuccess && code === 0) {
    //         const userDocRef = doc(fireStore, "users", uid).withConverter(userConverter)
    //         const getUserDoc = (await getDoc(userDocRef)).get("firstPaymentDate")
    //         const { paymentTimeUnix } = bilingResult
    //         //console.log(paymentTimeUnix)
    //         const paymentTimeToMoment = moment.unix(paymentTimeUnix)
    //         if (!getUserDoc) {
    //             updateDoc(userDocRef, {
    //                 firstPaymentDate: paymentTimeToMoment.toDate()
    //             })
    //             // user.firstPaymentDate = new Timestamp(paymentTimeToMoment.unix(),0)
    //         }
    //         const expirationDate = moment(paymentTimeToMoment).add(1, "month").subtract(1, "days").hours(0).minutes(0).seconds(0)
    //         updateDoc(userDocRef, {
    //             expirationDate: expirationDate.toDate()
    //         })
    //         reduxDispatch(updateExpirationDate(new Timestamp(expirationDate.unix(), 0)))
    //         // await recordFirstPayment(bilingResult.paymentTimeUnix)
    //     }
    // }
    // const payBtnProps = useMemo((): {
    //     div: { style?: CSSProperties, onClick?: MouseEventHandler<HTMLDivElement> },
    //     span: { style?: CSSProperties, text: string }
    // } => {
    //     // const payBtnProps = () => {
    //     if (user.isApprove) {
    //         //console.log("isApprove")
    //         if (user.canServe()) {
    //             //console.log("canServe")
    //             return {
    //                 div: {
    //                     style: {
    //                         backgroundColor: THEME_YELLOW,
    //                         // border: `1px solid ${THEME_YELLOW}`,
    //                     },
    //                     onClick: () => {
    //                         //console.log(user.canServe())
    //                     }
    //                 },
    //                 span: {
    //                     text: `만료 기간 : `,
    //                     style: {
    //                         color: "white",
    //                         fontWeight: 500,
    //                     }
    //                 }
    //             }
    //         }
    //         else {
    //             //console.log("!canServe")
    //             return {
    //                 div: {
    //                     style: {
    //                         border: `1px solid ${THEME_YELLOW}`,
    //                     },
    //                     onClick: async () => {
    //                         const { email, name, phone } = user
    //                         const uid = fireAuth.currentUser?.uid
    //                         const m_redirect_url = process.env.NODE_ENV !== "production"
    //                             ? 'http://localhost:3000/info'
    //                             : 'https://norang.kr/info'
    //                         if (uid) {
    //                             try {
    //                                 const getToken = await fetch("https://norang.kr/getImpToken", {
    //                                     method: "GET",
    //                                     headers: { "Content-Type": "application/json" },
    //                                 })
    //                                 const access_token = await getToken.text()
    //                                 //console.log(access_token)
    //                                 const getBilingInfo = await fetch(`https://us-central1-nobong-79c64.cloudfunctions.net/getSubscribeCustomerInfo?uid=${uid}`, {
    //                                     method: "GET"
    //                                 })
    //                                 const { code, response } = (await getBilingInfo.json())
    //                                 if (code === 0) {
    //                                     //console.log(response)
    //                                     if (window.confirm(`이미 등록된 카드가 존재합니다.\n카드이름 : ${response.card_name}\n카드번호 : ${response.card_number}\n위 카드로 결제하시겠습니까?`)) {
    //                                         try {
    //                                             //console.log("beforeUid",uid)
    //                                             await billing(uid)
    //                                         } catch (error) {
    //                                             //console.log(error)
    //                                             alert("결제 중 오류가 발생했습니다.")
    //                                         }
    //                                     } else {
    //                                         if (window.confirm(`새로운 카드를 등록하기 위해선 아래 카드를 삭제해야합니다.\n카드이름 : ${response.card_name}`
    //                                             + `\n카드번호 : ${response.card_number}\n위 카드를 삭제하시겠습니까?`)) {
    //                                             //console.log("삭제할래잉")
    //                                             const deleteBillingKey = await fetch(`https://asia-northeast3-nobong-79c64.cloudfunctions.net/deleteBillingKey?uid=${uid}`, {
    //                                                 method: "GET"
    //                                             })
    //                                             const code = (await deleteBillingKey.json()).code
    //                                             if (code === 0) {
    //                                                 alert("등록된 카드를 삭제하였습니다.")
    //                                             } else {
    //                                                 alert("등록된 카드를 삭제에 실패하였습니다.")
    //                                             }
    //                                         }
    //                                     }
    //                                 } else {
    //                                     IMP.request_pay({
    //                                         pg: "html5_inicis.INIBillTst", // 실제 계약 후에는 실제 상점아이디로 변경
    //                                         pay_method: 'card', // 'card'만 지원됩니다.
    //                                         merchant_uid: uid + "_" + moment().unix(), // 상점에서 관리하는 주문 번호
    //                                         name: '노란봉투 서비스 정기결제',
    //                                         amount: 990, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
    //                                         customer_uid: uid, // 필수 입력. 고유 카드 코드
    //                                         buyer_email: email,
    //                                         buyer_name: name,
    //                                         buyer_tel: phone,
    //                                         m_redirect_url: m_redirect_url // 예: https://www.my-service.com/payments/complete/mobile
    //                                     }, async function (rsp: any) {
    //                                         if (rsp.success) {
    //                                             try {
    //                                                 await billing(uid)
    //                                             } catch (error) {
    //                                                 //console.log("In Info rsp.success false", rsp)
    //                                                 //console.log(error)
    //                                                 alert("결제 중 오류가 발생했습니다.")
    //                                             }
    //                                         } else {
    //                                             //console.log("빌링키 발급실패", rsp)
    //                                         }
    //                                     })
    //                                 }
    //                             } catch (error) {
    //                                 //console.log(error)
    //                             }
    //                         } else {
    //                             alert("다시 로그인 후 재시도 해주세요.")
    //                         }
    //                     }
    //                 },
    //                 span: {
    //                     text: "서비스 가입하기"
    //                 }
    //             }
    //         }
    //     } else {
    //         return {
    //             div: {
    //                 style: {
    //                     backgroundColor: "rgba(245, 244, 241, 0.85)",
    //                 }
    //             },
    //             span: {
    //                 text: "승인 후, 결제 및 가입 가능",
    //                 style: {
    //                     color: BLACK
    //                 }
    //             }
    //         }
    //     }
    // }, [user])

    // useEffect(() => {

    //     IMP.init("imp25192885")
    //     // getNextPayDate()
    // }, [])
    // useEffect(() => {
    //     if (reduxState.user.createTime) {
    //         getNextPayDate()
    //     }
    // }, [reduxState.user])

    // const getNextPayDate = async () => {
    //     const collectionRef = collection(fireStore, "scheduleLog")
    //     const callbackQuery = query(collectionRef, where("customer_uid", "==", fireAuth.currentUser!.uid), where("state", "==", "schedule"), orderBy("schedule_at", "asc"))
    //     const callbackDocs = await getDocs(callbackQuery)
    //     var nextPayDate: { merchant_uid: string, customer_uid: string, schedule_at: number } | undefined = undefined
    //     // const orderByScedule_at = callbackDocs.docs.
    //     if (callbackDocs.size > 0) {
    //         //console.log("Here")
    //         const docInfo = callbackDocs.docs[0]
    //         const { customer_uid, schedule_at } = docInfo.data()
    //         nextPayDate = {
    //             schedule_at: schedule_at,
    //             customer_uid: customer_uid,
    //             merchant_uid: docInfo.id
    //         }
    //         // if(user.expirationDate?.seconds ?? 0 )
    //     }
    //     setNextPayDate(nextPayDate)
    // }
    // const cancelSchedulePay = async (customer_uid: string, merchant_uid: string): Promise<void> => {
    //     const cancelResult = await (await fetch("https://asia-northeast3-nobong-79c64.cloudfunctions.net/cancleSchedule", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({
    //             customer_uid: customer_uid, // 카드(빌링키)와 1:1로 대응하는 값,
    //             merchant_uid: merchant_uid
    //         })
    //     })).json()
    //     const { result } = cancelResult
    //     if (result) {
    //         setNextPayDate(undefined)
    //     }
    // }
    const goBack = () => {
        navigate(-1)
    }
    const openResignDialog = () => {
        setModalVisible(!modalVisible)
    }
    const [onProcess, setOnProcess] = useState<boolean>(false)
    const resign = async () => {
        if (!onProcess) {
            setOnProcess(true)
            try {
                console.log("Fff")
                await deleteUser(fireAuth.currentUser!!!)
                alert("탈퇴완료")
            } catch (error) {
                if (error instanceof FirebaseError) {
                    if (error.code === "auth/requires-recent-login") {
                        alert("인증 유효기간이 만료되었습니다. 재로그인 후 시도해주세요.")
                    } else alert(error)
                } else {
                    alert(error)
                }
            }
            setOnProcess(false)
            openResignDialog()
        }
    }
    const goToModi = () => {
        navigate("../info-modi")
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display:"flex",flexDirection:"column",alignItems:"center"
            }}>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                    }
                }}
                fullWidth={true}
                open={modalVisible}
                onClose={() => { setModalVisible(!modalVisible) }}>
                <div style={{
                    borderRadius: "4px",
                    margin:"8px",
                    padding: "24px",
                    width: "100%", maxWidth: "420px",
                    boxSizing: "border-box",
                    boxShadow : "0px 0px 3px 2px lightgray",
                    display: "flex", flexDirection: "column",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{fontSize:"18px",fontWeight:"bold"}}>노랑봉투 탈퇴</span>
                    <span style={{fontSize:"16px", color:"#767676",marginTop:"12px"}}>정말 노랑봉투를 탈퇴하시겠습니까?</span>
                    <span style={{fontSize:"14px", color:"#AAAAAA",marginBottom:"12px"}}>저장된 회원정보가 전부 삭제됩니다.</span>
                    <div style={{display:"flex",justifyContent:"space-between",fontWeight:500}}>
                        <div style={{
                            border:`1px solid ${THEME_YELLOW}`, borderRadius:"4px",
                            color:THEME_YELLOW,
                            flex:1,padding:"8px 0px",maxWidth:"45%",
                            display:"flex",justifyContent:"center",
                        }} onClick={resign}>
                            <span>확인</span>
                        </div>
                        <div style={{
                            borderRadius:"4px",backgroundColor:THEME_YELLOW,
                            color:"white",
                            flex:1,padding:"8px 0px",maxWidth:"45%",
                            display:"flex",justifyContent:"center",
                        }} onClick={openResignDialog}>
                            <span>취소</span>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className={styles.main_ctn}
                style={{ maxWidth: "1280px" }}>
                {isMobile ?
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            onClick={goBack} >
                            <img
                                src={back} />
                        </div>
                        <div className={styles.header_center}>
                            <img src={ic_user} />
                            <span >{"회원정보"}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                            onClick={goToModi}>
                            <img
                                src={edit} />
                        </div>
                    </div>
                    :
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                            <img src={ic_user} />
                            <span style={{fontWeight:500}}>{"회원정보"}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                            onClick={goToModi}>
                            <img
                                src={edit} style={{width:"36px",height:"auto"}} />
                        </div>
                    </div>}
                <div className={styles.body_ctn}>
                    <div className={`side_margin_5per ${styles.top_ctn}`}
                        style={{fontSize: isMobile ? "12px" : "15px"}}>
                        <img src={img_manage_user} />
                        <div>
                            <span style={{
                                fontWeight: 500
                            }}>회사명/담당자명</span>
                            <span>{`${user.companyName ?? "알 수 없음"} / ${user.name}`}</span>
                        </div>
                        <div>
                            <span style={{
                                fontWeight: 500
                            }}>직원 수</span>
                            <span>{`${reduxState.staff.length}명`}</span>
                        </div>
                    </div>
                    <div className={`side_margin_5per ${styles.bot_ctn}`}
                        style={{fontSize: isMobile ? "12px" : "15px"}}>
                        <div style={{
                            display: "flex", flexDirection: "row", justifyContent: "space-between",
                            marginBottom: "12px", color: BLACK
                        }}>
                            <span style={{
                                fontWeight: 500
                            }}>가입일</span>
                            <span>{`${moment(user.createTime?.toDate()).format("YYYY년 MM월 DD일")}`}</span>
                        </div>
                        <div style={{
                            display: "flex", flexDirection: "row", justifyContent: "space-between",
                            color: BLACK
                        }}>
                            <span style={{
                                fontWeight: 500
                            }}>사업자 번호</span>
                            <span>{`${user.bn?.replace(/([0-9]{1,3})([0-9]{1,2})([0-9]{1,5})$/, "$1-$2-$3")}`}</span>
                        </div>
                    </div>
                </div>
                <div className={`side_margin_5per pointer-cursor`}
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                        style={{
                            padding: "8px 8px",
                            color: "#9B9B9B", fontWeight: 500,
                            fontSize: isMobile ? "12px" : "15px",
                        }}
                        onClick={openResignDialog}>
                        <span>탈퇴하기</span>
                    </div>
                </div>
            </div>
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                {isMobile
                    ?
                    <ServiceBanner type={0} />
                    :
                    <>
                        <ServiceBanner type={0} style={{ width: "45%" }} />
                        <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} /></>
                }
            </div>
        </div>
    )
}
