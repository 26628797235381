import { PayStub } from "../../../classes/PayStub"
import { Staff, StaffWithId } from "../../../classes/Staff"
import { CHANGE_SEND_STATE, CHANGE_STATE, CONCAT_STAFF, DELETE_USERS, GET_STAFF, SET_STAFF_PAY, UPDATE_STAFF_DATA, UPDATE_STAFF_PAY } from "./staff.type"

const initialState : StaffWithId[] = []
export const getStaffList = (data : StaffWithId[]) => {
    return {
        type : GET_STAFF,
        payload : data
    }
}
export const concatStaff = (data : StaffWithId) => {
    return {
        type : CONCAT_STAFF,
        payload : data
    }
}
export const changeState = (data : {
    index : number,
    nextState : boolean
}) => {
    return {
        type : CHANGE_STATE,
        payload : data
    }
}
export const deleteUsersRedux = (data : string[])=>{
    return {
        type : DELETE_USERS,
        payload : data
    }
}
export const setStaffPay = (data: { id: string, payStub: PayStub, staffIndex: number }) => {
    return {
        type: SET_STAFF_PAY,
        payload: data
    }
}
export const updateStaffPay = (data: { id: string, payStub: PayStub, staffIndex: number }) => {
    return {
        type: SET_STAFF_PAY,
        payload: data
    }
}
export const changeSendState = (data : { targetMonth : string, staffIndex : number})=>{
    return {
        type : CHANGE_SEND_STATE,
        payload : data
    }
}
export const updateStaffData = (data : {newStaffData : Staff,deletePayStubId : string, staffIndex : number})=> {
    return{
        type : UPDATE_STAFF_DATA,
        payload : data
    }
}
export const staffReducer = (state: StaffWithId[] = initialState, action: { type: string, payload?: any }) : StaffWithId[] => {
    switch (action.type) {
        case GET_STAFF:
            return action.payload;
        case CONCAT_STAFF:
            return state.concat(action.payload)
        case UPDATE_STAFF_DATA:
            const targetIndex = action.payload.staffIndex
            delete state[targetIndex].payStubObj[action.payload.deletePayStubId]
            state[targetIndex].data = action.payload.newStaffData
            return state.slice();
        case CHANGE_STATE:
            state[action.payload.index].data.state = action.payload.nextState
            return state.slice()
        case DELETE_USERS:
            return state.filter((staff) => {
                return action.payload.indexOf(staff.id) < 0
            })
        case UPDATE_STAFF_PAY:
        case SET_STAFF_PAY:
            const id: string = action.payload.id
            const staffIndex: number = action.payload.staffIndex
            const payStub: PayStub = action.payload.payStub
            state[staffIndex].payStubObj[id] = payStub
            return state.slice()
        case CHANGE_SEND_STATE:
            const targetMonth : string = action.payload.targetMonth
            const index: number = action.payload.staffIndex
            state[index].payStubObj[targetMonth].isSend = true
            return state.slice()
        default:
            break;
    }
    return state
}