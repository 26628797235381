import React, {FC, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectMonthItem } from "../redux/reducer/month-set/month-set";
import { THEME_YELLOW } from "../values/Colors";
import arrow_down from "../images/manage-pay/ic_arrow_down@2x.png"
import arrow_up from "../images/manage-pay/ic_arrow_up@2x.png"
import { MonthItem, MonthSet } from "../classes/Month";
import ReactModal from "react-modal";
export const SelectMonth : FC<{
    isOpen : boolean,
    monthSet : MonthSet
    onRequestClose : ()=>void,
    onSelectMonth : (item : MonthItem)=> void
}> = ({
    isOpen,
    monthSet,
    onRequestClose,
    onSelectMonth
})=>{
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const listDivRef = useRef<HTMLDivElement>(null)
    const [scrollState, setScrollState] = useState<number>(0)
    // const reduxState = useSelector((state: { monthSet: MonthSet }) => state);

    useEffect(() => {
        setScrollState(listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!)
    }, [monthSet])
    return(

        <ReactModal
            style={{
                overlay: {
                    display: "flex",
                    alignItems: "center", justifyContent: "center",
                    padding: "36px",
                    boxSizing: "border-box",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    overflow: "hidden"
                },
                content: {
                    position: "relative",
                    maxHeight: "100%",
                    padding: "36px",
                    maxWidth: "300px", width: "100%",
                    inset: "0px",
                    display: "flex", flexDirection: "column",
                    border: "0px solid black", alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0)",
                    overflow: "hidden"
                }
            }}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}>
            <div style={{
                borderRadius: "24px",
                padding: "24px",
                width: "100%", maxWidth: "300px",
                boxSizing: "border-box",
                display: "flex", flexDirection: "column",
                alignItems: "center",
                fontFamily: "NotoSans",
                backgroundColor: "white",
                overflow: "hidden"
            }}>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`지난달 내역`}</span>
                <div style={{ height: "30px" }}>
                    {(listDivRef.current?.scrollTop ?? 0) >= 52 && <img src={arrow_up} style={{ height: "100%" }} />}
                </div>
                <div ref={listDivRef}
                    style={{
                        flex: 1, width: "100%",
                        display: "flex", flexDirection: "column",
                        alignItems: "center", justifyContent: "flex-start",
                        overflowY: "auto", boxSizing: "border-box"
                    }}
                    onScroll={(ev) => {
                        setScrollState(listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!)
                    }}>
                    {monthSet.monthList.map((item, index) => {
                        return (
                            <div className="pointer-cursor"
                                key={`${item.text}`} style={{
                                    flex: 1,
                                    border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", padding: "12px", marginBottom: index < monthSet.monthList.length - 1 ? "12px" : undefined,
                                    width: "100%"
                                }}
                                onClick={() => {
                                    //console.log(item)
                                    onSelectMonth(item)
                                }}>{`${item.text}`}</div>
                        )
                    })}
                </div>
                <div style={{ height: "30px" }}>
                    {(scrollState ?? 0) >= 52 && <img src={arrow_down} style={{ height: "100%" }} />}
                </div>
            </div>
        </ReactModal>
    )
}