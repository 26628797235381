import { SAVE_ACCESS_KEY_LIST } from "./accesskey.type";

export interface AccessKey {
    title : string,
    auth : number,
    value : string
}

const initialState : AccessKey[] = []
export const saveAccessKeyList = (accessKeyList : AccessKey[]) => {
    return {
        type : SAVE_ACCESS_KEY_LIST,
        payload : accessKeyList
    }
}

export const accessKeyReducer = (state : AccessKey[] = initialState, action : {type : any, payload : any}) : AccessKey[] => {
    switch (action.type) {
        case SAVE_ACCESS_KEY_LIST:
            return action.payload
        default:
            break;
    }
    return state
}