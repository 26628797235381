import { FirestoreDataConverter, Timestamp } from "firebase/firestore";
import { HireType } from "../values/types";
import { Staff } from "./Staff";
export type PayCategory = "monthly" | "irregular" |"additionalWork"|"deduction"
export type PayInputTypeType =  "add"
export interface PayInputType{ name : string, title : string, value : string ,type? : PayInputTypeType}
export interface AdditionalWorkType{ name : string, title : string, value : {hour : string, ratio : string}}
export interface EmpPs {
    paymentDate : Timestamp,
    workingDays : string,
    workingHours : string,
    monthlyPayments : PayInputType[],//매월지급
    hourlyWage : string,//통상시급
    additionWorks : AdditionalWorkType[],//추가근로시간
    additionalWorkAllowance : PayInputType,//연장근로수당
    irregularPayments : PayInputType[],//부정기지급
    deductionItems : PayInputType[],//공제항목
    paymentAmount : string,//지급액계
    deductionAmount : string,//공제액계
    amount : string//실수령액
}
export interface PartPs {
    additionalPayments : PayInputType[],//추가지급
    paymentDate : Timestamp,//지급일
    hourlyWage : string,//시급
    monthlyWorkHour : string,//월근로시간
    monthlyWorkMinutes : string,//월근로분
    monthlyHolidayHour : string, // 월주휴시간
    monthlyHolidayMinutes : string,//월주휴분
    isIncludeInsurance : number,//보험 공제 여부
    deductionItems : PayInputType[],//공제항목
    paymentAmount : string,//지급액계
    deductionAmount : string,//공제액계
    amount : string//실수령액
}
export interface DayPs {    
    additionalPayments : PayInputType[],//추가지급
    paymentDate : Timestamp,//지급일
    paymentAmount : string,//지급액계
    deductionAmount : string,//공제액계
    amount : string//실수령액
}

export interface PayStub {
    type : HireType
    staffInfo : Staff
    data : EmpPs | PartPs | DayPs
    isSend?: boolean,
}
export class PayStub implements PayStub {
    constructor(
        type: HireType,
        staffInfo: Staff,
        data: EmpPs | PartPs | DayPs,
        isSend?: boolean,
    ) {
        this.type = type
        this.staffInfo = staffInfo
        this.data = data
        this.isSend = isSend ?? false
    }
}
export const payStubConverter: FirestoreDataConverter<PayStub> = {
    toFirestore: (payStub: PayStub) => {
        const returnBody : PayStub= {
            type : payStub.type,
            staffInfo : payStub.staffInfo,
            data : payStub.data,
            isSend: payStub.isSend ?? false,
        }
        return returnBody
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return new PayStub(
            data.type,
            data.staffInfo,
            data.data,
            data.isSend
        )
    }
}