import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Notice as NoticeItem, NoticeWithId } from "../classes/Notice";
import { useBlocker } from "../components/react-router-dom-revive";
import { ServiceBanner } from "../components/ServiceBanner";
import { getNoticeList } from "../components/Tools";
import styles from "../css/Notice.module.css"
import { back, ic_arrow_down } from "../images/images";
import { saveNotice } from "../redux/reducer/notice/notice";
export const Notice : FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const reduxDispatch = useDispatch();
    const [clickedItem,setClickedItem] = useState<NoticeItem|undefined>(undefined) 
    
    const {notice : noticeItemList, isMobile} =  useSelector((state : {notice : NoticeWithId[],isMobile : boolean}) => state)

    useEffect(()=>{
        getNoticeList().then(noticeList=>{
            reduxDispatch(saveNotice(noticeList))
        })
    }, [location])
    const goBack = () => {
        isMobile
            ? navigate(-1) : setClickedItem(undefined)
    }

    useBlocker(() => {
        setClickedItem(undefined)
        
        console.log("Ffff")
    },(clickedItem !== undefined) && isMobile)
    

    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <div className={styles.main_ctn}
                style={{ maxWidth: "1280px" }}>
                <div className={styles.header_ctn}>
                    {(isMobile || clickedItem !== undefined) &&
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                        <img src={back} onClick={goBack} />
                    </div>}
                    <div className={styles.header_center}>
                        <span>{"공지사항"}</span>
                    </div>
                </div>
                <div className={styles.body_ctn}>

                {clickedItem !== undefined
                    ?
                    <>
                        <div style={{
                            display: "flex", flexDirection: "column",
                            boxSizing: "border-box",
                            borderTop: "1px solid #E8E8E8",
                            borderBottom: "1px solid #E8E8E8",
                        }}>
                            <div className="side_margin_5per" style={{
                                display: "flex", alignItems: "center",
                                padding: "8px 0px",
                            }}>
                                <div style={{
                                    flex: 1, display: "flex", flexDirection: "column",
                                    fontSize: "14px",  whiteSpace: "pre-wrap",
                                }}>
                                    <span>{clickedItem.title}</span>
                                    <span style={{ fontSize: "12px" }}>{moment.unix(clickedItem.createTime.seconds).format("YYYY.MM.DD")}</span>
                                </div>
                            </div>
                        </div>
                        <span className="side_margin_5per" style={{
                            display: "flex", alignItems: "center",
                            boxSizing:"border-box", fontSize:"14px",
                            padding: "8px 0px",whiteSpace: "pre-wrap",
                        }}>{clickedItem.content}</span>
                    </>
                    :
                    noticeItemList.map((value, index) => {
                        const { title, createTime } = value.notice
                        return (
                            <div key={`notice_${index}`} 
                                className="pointer-cursor" style={{
                                display: "flex", flexDirection: "column",
                                boxSizing: "border-box",
                                borderTop: "1px solid #E8E8E8",
                                borderBottom: index === noticeItemList.length - 1 ? "1px solid #E8E8E8" : ""
                            }}
                                onClick={()=>{
                                    setClickedItem(value.notice)
                                }}>
                                <div className="side_margin_5per" style={{
                                    display: "flex", alignItems: "center",
                                    padding: "8px 0px",
                                }}>
                                    <div style={{
                                        flex: 1, display: "flex", flexDirection: "column",
                                        fontSize: "14px",  whiteSpace: "pre-wrap",
                                    }}>
                                        <span>{title}</span>
                                        <span style={{ fontSize: "12px" }}>{moment.unix(createTime.seconds).format("YYYY.MM.DD")}</span>
                                    </div>
                                    <img src={ic_arrow_down} style={{ height: "24px", transform: "rotate(-90deg)" }} />
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                {isMobile
                    ?
                    <ServiceBanner type={0} />
                    :
                    <>
                        <ServiceBanner type={0} style={{ width: "45%" }} />
                        <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} /></>
                }
            </div>
        </div>
    )
}