import { doc, getDoc } from "firebase/firestore";
import { fireStore } from "../fireConfig";

export const getConsentItem = async (docId : "term"|"privacy") : Promise<string> => {
    const consentDocRef = doc(fireStore,"consent-items",docId)
    const consentData = (await getDoc(consentDocRef))?.data();
    if(consentData && consentData.htmlValue){
        return (consentData.htmlValue)
    }
    return ""
}