import React, { FC, useEffect, useState } from "react";
import { EmpPs, PartPs, PayStub } from "../../classes/PayStub";
import { MonthlyPaymentInput } from "../../components/MonthlyPaymentInput";
import { PayInput } from "../../components/PayInput";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import styles from '../../css/PayViewPage.module.css'
import { back, ic_arrow_down, ic_arrow_up, logo } from "../../images/images";

export const Emp: FC<{payStubData : EmpPs}> = ({payStubData}) => {

    const [isOpenDeduct,setIsOpenDeduct] = useState<boolean>(false)
    const [isOpenMothlyPayment,setIsOpenMothlyPayment] = useState<boolean>(false)
    const [isOpenIrregular,setIsOpenIrregular] = useState<boolean>(false)
    return (
        <>
            <div className={`side_margin_5per`} style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <TitleInputUnit
                    title="근로일수"
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={payStubData.workingDays}
                    removeBorder={true}
                    unit="일"
                    readonly={true} />
                <TitleInputUnit
                    title="근로시간"
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={payStubData.workingHours}
                    removeBorder={true}
                    unit="시간"
                    readonly={true} />
            </div>
            <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                {/* PaymentAmount */}
                <PayInput
                    style={{ borderWidth: "0px" }}
                    readonly={true}
                    title={"지급액계"}
                    value={payStubData.paymentAmount} />
                {/* DeductionAmount */}
                <PayInput
                    style={{ borderWidth: "0px" }}
                    readonly={true}
                    title={"공제액계"}
                    value={payStubData.deductionAmount}
                    onChange={() => { }} />
                {/* Amount */}
                <div style={{ minHeight: 1, backgroundColor: "#F5F5F5" }} />
                <PayInput
                    style={{ borderWidth: "0px" }}
                    titleStyle={{ fontWeight: 500 }}
                    readonly={true}
                    title={"실수령액"}
                    value={payStubData.amount} />
            </div>
            {!isOpenMothlyPayment
                ?
                <div className="side_margin_5per"
                    style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        border: "1px solid #F5F5F5", marginBottom: "18px",
                        padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                    }}
                    onClick={() => {
                        setIsOpenMothlyPayment(!isOpenMothlyPayment)
                    }}>
                    <span>매월지급</span>
                    <img src={ic_arrow_down} style={{ height: "20px" }} />
                </div>
                :
                <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                    <div className={`${styles.deduct_ctn}`}
                        style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenMothlyPayment(!isOpenMothlyPayment)
                            }}>
                            <span>매월지급</span>
                            <img src={ic_arrow_up} style={{ height: "20px" }} />
                        </div>
                        {payStubData.monthlyPayments.map((value, index) => {
                            return (
                                <MonthlyPaymentInput
                                    key={`${value.title}_${index}_emp_monthly`}
                                    styles={{
                                        titleStyle: {
                                            fontWeight: 500
                                        }
                                    }}
                                    readonly={true}
                                    values={value} />
                            )
                        })}
                        <PayInput
                            readonly={true}
                            titleStyle={{ fontWeight: 500 }}
                            title={payStubData.additionalWorkAllowance.title}
                            value={payStubData.additionalWorkAllowance.value} />
                    </div>
                </div>
            }
            {payStubData.irregularPayments.length > 0 && !isOpenIrregular
                ?
                <div className="side_margin_5per"
                    style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        border: "1px solid #F5F5F5", marginBottom: "18px",
                        padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                    }}
                    onClick={() => {
                        setIsOpenIrregular(!isOpenIrregular)
                    }}>
                    <span>부정기지급</span>
                    <img src={ic_arrow_down} style={{ height: "20px" }} />
                </div>
                :
                <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                    <div className={`${styles.deduct_ctn}`}
                        style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenIrregular(!isOpenIrregular)
                            }}>
                            <span>부정기지급</span>
                            <img src={ic_arrow_up} style={{ height: "20px" }} />
                        </div>
                        {payStubData.irregularPayments.map((value, index) => {
                            return (
                                <MonthlyPaymentInput
                                    key={`${value.title}_${index}_emp_irregular`}
                                    styles={{
                                        titleStyle: {
                                            fontWeight: 500
                                        }
                                    }}
                                    readonly={true}
                                    values={value} />
                            )
                        })}
                    </div>
                </div>
            }
            {!isOpenDeduct
                ?
                <div className="side_margin_5per"
                    style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        border: "1px solid #F5F5F5", marginBottom: "18px",
                        padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                    }}
                    onClick={() => {
                        setIsOpenDeduct(!isOpenDeduct)
                    }}>
                    <span>공제항목</span>
                    <img src={ic_arrow_down} style={{ height: "20px" }} />
                </div>
                :
                <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                    <div className={`${styles.deduct_ctn}`}
                        style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenDeduct(!isOpenDeduct)
                            }}>
                            <span>공제항목</span>
                            <img src={ic_arrow_up} style={{ height: "20px" }} />
                        </div>
                        {payStubData.deductionItems.map((value, index) => {
                            return (
                                <MonthlyPaymentInput
                                    key={`${value.title}_${index}_emp_deduct`}
                                    styles={{
                                        titleStyle: {
                                            fontWeight: 500
                                        }
                                    }}
                                    readonly={true}
                                    values={value} />
                            )
                        })}
                    </div>
                </div>
            }
            <div className="side_margin_5per" style={{ minHeight: 1, backgroundColor: "#F5F5F5", marginBottom: "18px" }} />
            <div className="side_margin_5per" style={{
                display: "flex", flexDirection: "column",
                boxSizing: "border-box", padding: "0px 4px",
                marginBottom: "18px"
            }}>
                <span style={{
                    wordBreak: "keep-all", whiteSpace: "nowrap",
                    fontSize: "14px", fontWeight: 500
                }}>연장근로수당</span>
                {payStubData.additionWorks.map((value, index) =>
                    <div key={`${value.title}_${index}_additionWorks`} style={{
                        marginTop: "6px",
                        display: "flex", justifyContent: "space-between",
                        fontSize: "12px"
                    }}>
                        <span style={{ color: "#767676", marginRight: "8px" }}>{`${value.title}`}</span>
                        <span
                            style={{ fontWeight: 500 }}>
                            {`${value.value.hour}시간 (*${value.value.ratio})`}</span>
                    </div>
                )}
            </div>
        </>
    )
}