import { DocumentReference, DocumentSnapshot } from "firebase/firestore"
import { QnA } from "../../../classes/QnA"
import { DELETE_QNA_REF, PUSH_QNA_REF, SAVE_QNA_REF_LIST } from "./qna.type"

const initialState : DocumentReference<QnA>[] = [] 

export const saveQnARefList = (qnaRefList : DocumentReference<QnA>[]) => {
    return {
        type : SAVE_QNA_REF_LIST,
        payload : qnaRefList
    }
}
export const pushQnARef = (qnaRef : DocumentReference<QnA>) => {
    return {
        type : PUSH_QNA_REF,
        payload : qnaRef
    }
}
export const deleteQnARef = (qnaRef : DocumentReference<QnA>)=>{
    return {
        type : DELETE_QNA_REF,
        payload : qnaRef
    }
}
export const qnaReducer = (
    state: DocumentReference<QnA>[] = initialState,
    action: {
        type: string,
        payload?: any
    }): DocumentReference<QnA>[] => {
    switch (action.type) {
        case SAVE_QNA_REF_LIST:
            return action.payload
        case PUSH_QNA_REF:
            return [action.payload].concat(state)
        case DELETE_QNA_REF:
            const qnaIndex = state.findIndex((value)=>{
                return value.id === action.payload.id
            })
            console.log(qnaIndex)
            if(qnaIndex < -1){
                return state
            }
            const newDeletedState = state.slice()
            newDeletedState.splice(qnaIndex,1)
            return newDeletedState
        default:
            break;
    }
    return state
}