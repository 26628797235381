import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { getDownloadURL, getStorage, ref, } from "firebase/storage";
import moment from "moment";
import React,{FC, useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Banner, bannerConverter } from "../../classes/Banner";
import styles from '../../css/AdminBanner.module.css'
import { fireFunctions, fireStorage, fireStore } from "../../fireConfig";
import { ic_upload } from "../../images/images";
import { unshiftBanner } from "../../redux/reducer/banners/banners";
import { THEME_YELLOW } from "../../values/Colors";
export const AdminBannerEdit: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const reduxDispatch = useDispatch();
    const {type} = location.state
    const [bannerName,setBannerName] = useState<string>("")
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File | undefined>(undefined);
    const [preview, setPreview] = useState<{type : string, preview : string|undefined} | undefined>(undefined);
    const [url,setUrl] = useState<string>("")

    const [onProcess, setOnProcess] = useState<boolean>(false)

    const readImageFile = (file : File) => {
        var reader = new FileReader();
        reader.onload = function(e) {
            var img = new Image();
            if(!e.target?.result){
                return
            }
            img.src = e.target.result as string

            img.onload = (e) => {
                var w = img.width
                var h = img.height

                console.log(`W : ${w}, H : ${h}`)
                if (w === 1280 && h === 256) {
                    setFile(file)
                    const imageUrl = reader.result?.toString()
                    setPreview({ type: file.type, preview: imageUrl })
                }else{
                    alert(`가로 : 1280px, 세로 : 256px 사이즈로 업로드해주세요`)
                }
            }
        }
        reader.readAsDataURL(file)
    }

    const goBack = () =>{
        navigate(-1)
    }

    const canUpload = () : boolean => {
        return (bannerName.length > 0
            && file
            && url.length > 0 ) ?? false
    }
    const uploadBanner = async() => {
        try {
            if (!onProcess && canUpload()) {
                setOnProcess(true)
                const uploadResult = await uploadFile() //

                console.log(uploadResult)
                if (!uploadResult.result) {
                    return
                }

                const { filename ,destination } = uploadResult.data
                console.log(filename,destination)
                const bannerCollection = collection(fireStore,"banners").withConverter(bannerConverter)
                const banner : Banner = new Banner(
                    type,bannerName,url,destination,new Timestamp(moment().unix(),0)
                )
                const docRef =  await addDoc(bannerCollection,banner)
                reduxDispatch(unshiftBanner({
                    banner : banner,
                    id : docRef.id
                }))
                goBack()
            } else {
                alert("모든 항목을 채워주세요")
            }
        } catch (error) {
            alert(error)
        }
        setOnProcess(false)
    }
    const uploadFile = async () => {
        connectFunctionsEmulator(fireFunctions, "localhost", 5001)
        try {
            const formData = new FormData()
            if (file instanceof Blob) {
                //console.log("file Exist", file.name)
                formData.append("file", file, file.name)
                formData.append("type", type)
            }
            else
                return

            const result = await fetch(
                process.env.NODE_ENV != "production"
                    ? `http://localhost:5001/nobong-79c64/us-central1/uploadBanner`
                    : `https://us-central1-nobong-79c64.cloudfunctions.net/uploadBanner`, {
                method: "POST",
                body: formData
            })
            // //console.log(formData.get("file"))
            return await result.json()
        } catch (error) {
            console.log("Line : 164" + error)
        }
    }
    return (
        <div className={styles.main_ctn}
            style={{
                padding: "30px 2.5%",
                display: "flex",
                overflowY: "hidden", flexDirection: "column"
            }}>
            <div style={{
                display: "flex", flexDirection: "row",
                justifyContent: "space-between", alignItems: "center",
                fontSize: "14px", marginBottom: "12px"
            }}>
                <span style={{
                    fontSize: "22px", fontWeight: 500,
                }}>배너광고 추가 - {type === 0 ? "사업자" : "근로자"}</span>

                <div style={{
                    display: "flex", flexDirection: "row"
                }}>
                    <div style={{
                        borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "#757575", border: "1px solid #757575",
                        marginRight: "14px",
                        cursor:"pointer"
                    }} onClick={goBack}>
                        <span >{"취소"}</span>
                    </div>
                    <div style={{
                        backgroundColor: THEME_YELLOW, borderRadius: "6px",
                        padding: "10px 37px", display: "flex", alignItems: "center",
                        color: "white", fontWeight: 500,
                        cursor:"pointer"
                    }} onClick={uploadBanner}>
                        <span >{"등록"}</span>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    color: "#161616",marginBottom:"24px"
                }}>
                <span style={{ marginRight: "20px",width:"80px" }}>배너제목</span>
                <input style={{
                    width: "320px",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px"
                }}
                    placeholder="배너제목을 입력해 주세요."
                    value={bannerName}
                    onChange={(e) => { setBannerName(e.target.value) }}
                />
            </div>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    color: "#161616",marginBottom:"24px"
                }}>
                <span style={{ marginRight: "20px",width:"80px" }}>이미지</span>
                <input style={{
                    width: "320px",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px",marginRight: "20px"
                }}
                    disabled={true}
                    accept="image/*"
                    placeholder="이미지를 업로드 주세요."
                    value={file?.name}/>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",padding: "11px 12px",
                        border: `1px solid ${THEME_YELLOW}`, borderRadius: "5px",
                        fontSize: "12px", color: THEME_YELLOW, fontWeight: 500
                    }}
                    onClick={() => {
                        fileInputRef.current?.click();
                    }}>
                    <span>업로드</span>
                    <img src={ic_upload} style={{ height: "16px", marginLeft: "4px" }} />
                </div>
                <input
                    ref={fileInputRef}
                    style={{ flex: 0, width: "1px", height: "1px" }}
                    type="file" accept="image/*"
                    onChange={(e) => {
                        if (e.target.files) {
                            let file = e.target.files[0]
                            if (file) {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                    console.log(file)
                                    readImageFile(file)
                                }
                                reader.readAsDataURL(file)
                            }
                        }
                    }} />
            </div>
            {preview &&
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "80px" }} />
                    <img style={{
                        width: "346px"
                    }} src={preview.preview} />
                </div>}
            <div
                style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    color: "#161616"
                }}>
                <span style={{ marginRight: "20px",width:"80px" }}>URL</span>
                <input style={{
                    width: "320px",
                    padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                    outlineWidth: "0px"
                }}
                    placeholder="URL을 입력해 주세요."
                    value={url}
                    onChange={(e) => { setUrl(e.target.value) }}
                />
            </div>
        </div>
    )
}
