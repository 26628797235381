import { combineReducers, createStore, Reducer, Store } from "redux";
import { bannersReducer } from "./reducer/banners/banners";
import { htuReducer } from "./reducer/howtouse/howtouse";
import { monthSetReducer } from "./reducer/month-set/month-set";
import { staffReducer } from "./reducer/staff/staff";
import { userReducer } from "./reducer/user/user";
import { usersReducer } from "./reducer/users/users";
import { noticeReducer } from "./reducer/notice/notice";
import { serveBannerReducer } from "./reducer/serve-banner/serveBanners";
import { taxReducer } from "./reducer/tax/tax";
import { isMobileReducer } from "./reducer/is-mobile/isMobile";
import { accessKeyReducer } from "./reducer/accesskey/accesskey";
import { qnaReducer } from "./reducer/qna/qna";

const rootReducer : Reducer = combineReducers({
    staff : staffReducer,
    user : userReducer,
    monthSet : monthSetReducer ,
    users : usersReducer,
    banners : bannersReducer,
    htu : htuReducer,
    notice : noticeReducer,
    serveBanner : serveBannerReducer,
    tax : taxReducer,
    isMobile : isMobileReducer,
    accessKey : accessKeyReducer,
    qna : qnaReducer
})

const store : Store = createStore(rootReducer);

export default store;