
import React, { FC } from "react";
import { useNavigate, } from "react-router-dom";
import back from "../images/common/arrow_back@2x.png"
import { FIVE_PER } from "../values/strings";
import styles from "../css/HowToUse.module.css"
import { THEME_YELLOW } from "../values/Colors";
import { QnAItem } from "../components/QnAItem";
import { HTU } from "../classes/HTU";
import { useMediaQuery } from "react-responsive";
import { ServiceBanner } from "../components/ServiceBanner";
import { useSelector } from "react-redux";

export const HowToUse: FC = () => {
    const navigate = useNavigate();
    const {htu , isMobile } = useSelector((state : {htu : HTU[],isMobile : boolean})=>state)
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <div className={styles.main_ctn}
                style={{ maxWidth: "1280px" }}>
                <div className={styles.header_ctn}>
                    {isMobile &&
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                        <img src={back} onClick={goBack} />
                    </div>}
                    <div className={styles.header_center}>
                        <span>{"서비스 이용안내"}</span>
                    </div>
                </div>
                <div className={styles.body_ctn}>
                    {htu.map((value, index) => {
                        return (
                            <QnAItem key={`QnA_${index}`}
                                itemValue={value}
                                outerStyle={{
                                    borderBottom : index === htu.length-1 ? "1px solid #E8E8E8" :"" 
                                }} />
                        )
                    })}
                </div>
                {/* <div style={{
                    // backgroundColor: "yellowgreen",
                    flex: 1,
                    display: "flex", flexDirection: "column",
                    boxSizing: "border-box",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,
                    fontFamily:"Notosans",
                    overflowY:"scroll"
                }}>
                    <div style={{
                        display:"flex",flexDirection:"row",alignItems:"center",
                        boxSizing: "border-box", padding: "12px", borderRadius: "12px",
                        border:"1px solid black"
                    }}>
                        <img src={service} style={{height:"48px"}} alt={"service"}/>
                        <p style={{fontSize:"14px"}}>
                            <span>{`노랑봉투의 서비스는`}</span><br/>
                            <span>{`월 정액 990원의 이용료를 통해 제공됩니다.`}</span>
                        </p>
                    </div>

                    <p style={{ fontSize: "12px", color: "#5A5A5A" }}>
                        <span style={{ fontWeight: 500, color: "#141414",fontSize:"14px" }}>{`노랑봉투 서비스 이용 상세`}</span><br />
                        <p>{`1. 노랑봉투는 급여명세서 및 직원 관리 서비스를 제공합니다.`}</p>
                        <p>
                            <span>2. 지속적인 서비스 이용을 위해, 입력하신 계좌 정보에 따른</span><br />
                            <span>월 990원의 서비스 이용료 결제가 필요합니다.</span><br />
                        </p>
                        <p>
                            <span>3. 서비스 이용료 결제는 서비스 가입일을 기준으로</span><br />
                            <span>매월 해당 일자에 결제가 이루어집니다.</span><br />
                            <span style={{ color: "#767676" }}>{`ex) 2021년 10월 20일 서비스 가입  >  매월 20일 결제`}</span>
                        </p>
                        <p>{`4. 결제가 완료된 후 서비스에 대한 환불이 불가합니다.`}</p>
                        <p>{`5. 서비스 이용 중단은 메뉴의 서비스 탈퇴 및 해지를 통해 가능합니다.`}</p>
                    </p>
                    <div style={{ flex: 1 }} />
                </div> */}
            </div>

            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                {isMobile
                    ?
                    <ServiceBanner type={0} />
                    :
                    <>
                        <ServiceBanner type={0} style={{ width: "45%" }} />
                        <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} /></>
                }
            </div>
        </div>
    )
}
