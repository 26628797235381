import { Dialog } from "@mui/material";
import React, {FC} from "react";
import { illust_enroll } from "../images/images";
import { THEME_YELLOW } from "../values/Colors";

export const EnrollModal : FC<{
    isMobile? : boolean
    open : boolean
    onClose : () => void
}> = ({
    isMobile = true,
    open,
    onClose
}) => {
    const fontRatio = isMobile ? 1 : 1.6
    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none", borderRadius: "0px",
                    flexDirection: "row", justifyContent: "center",
                }
            }}
            fullWidth={true}
            open={open}
            onClose={onClose}>
            <div style={{
                borderRadius: "4px",
                // padding: `${isMobile ? "18px" : "32px"} 36px`,
                padding: `24px`,
                width: "100%", maxWidth: "600px",
                boxSizing: "border-box",
                display: "flex", flexDirection: "column",
                fontFamily: "NotoSans",
                backgroundColor: "white"
            }}>
                <div style={{ display: "flex", flexDirection: "row",marginBottom:"8px" }}>
                    <div style={{ display: "flex", flexDirection: "column",flex:1 }}>
                        <span style={{fontSize:fontRatio*14, fontWeight:500,marginBottom:"8px"}}>임금명세서 등록완료</span>
                        <span style={{fontSize:fontRatio*10,whiteSpace:"pre-wrap"}}>{"발송버튼을 누르시면\n등록하신 명세서를 보내실 수 있어요."}</span>
                    </div>
                    <img src={illust_enroll}
                        style={{width:"30%",height:"auto"}}/>
                </div>
                <div className="pointer-cursor" style={{
                    padding: "12px 0px", backgroundColor: THEME_YELLOW,
                    borderRadius: "8px",
                    display: "flex", alignItems: "center", justifyContent: "center",
                    fontSize: fontRatio*12, fontWeight: 500, color: "white"
                }}
                    onClick={onClose}>확인</div>
            </div>
        </Dialog>
    )
}