import { DocumentReference } from "@firebase/firestore";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import React, { CSSProperties, FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { QnA } from "../../classes/QnA";
import { arrow_next, img_qna, lock } from "../../images/images";
import { THEME_YELLOW } from "../../values/Colors";

export const QnABox: FC<{
    style?: CSSProperties
    qnaRef: DocumentReference<QnA>,
    isManage?: boolean,
    index: number,
    userId?: string,
    onClick?: () => void
}> = ({
    style,
    qnaRef,
    isManage = false,
    index,
    userId,
    onClick
}) => {
        const [qna, setQnA] = useState<QnA | undefined>(undefined)
        const { isMobile } = useSelector((state: { isMobile: boolean }) => state)

        useEffect(() => {
            getQnAData()
        }, [qnaRef])

        const getQnAData = async () => {
            const snapshot = await getDoc(qnaRef)
            const qnaData = snapshot.data()
            setQnA(qnaData)
        }

        const yetCssValue: CSSProperties =
            isManage
                ? isMobile
                    ? {
                        marginLeft:"4px",
                        borderRadius: "8px", padding: "8px", 
                        backgroundColor: THEME_YELLOW,
                        fontSize: "12px",
                        minWidth: "48px", display: "flex", justifyContent: "center",
                        color: "white", border: `1px solid ${THEME_YELLOW}`,
                    } : {
                        borderRadius: "8px", padding: "8px", 
                        backgroundColor: THEME_YELLOW,
                        fontSize: "16px",
                        minWidth: "96px", display: "flex", justifyContent: "center",
                        color: "white", border: `1px solid ${THEME_YELLOW}`,
                    }
                : isMobile
                    ? {
                        marginLeft:"4px",
                        borderRadius: "8px", padding: "8px",
                        fontSize: "12px",
                        minWidth: "48px", display: "flex", justifyContent: "center",
                        color: "black", border: `1px solid #ADADAD`,
                    } : {
                        borderRadius: "8px", padding: "8px",
                        fontSize: "16px",
                        minWidth: "96px", display: "flex", justifyContent: "center",
                        color: "black", border: `1px solid #ADADAD`,
                    }
        const doneCssValue: CSSProperties =
            isManage
                ? isMobile
                    ? {
                        marginLeft:"4px",
                        borderRadius: "8px", padding: "8px",
                        width: "48px", display: "flex", justifyContent: "center",
                        fontSize: "12px",
                        color: THEME_YELLOW, border: `1px solid ${THEME_YELLOW}`
                    } : {
                        borderRadius: "8px", padding: "8px",
                        width: "96px", display: "flex", justifyContent: "center",
                        fontSize: "16px",
                        color: THEME_YELLOW, border: `1px solid ${THEME_YELLOW}`,
                    }
                : isMobile
                    ? {
                        marginLeft:"4px",
                        borderRadius: "8px", padding: "8px",
                        width: "48px", display: "flex", justifyContent: "center",
                        fontSize: "12px",
                        color: "black", border: `1px solid ${THEME_YELLOW}`
                    } : {
                        marginLeft:"4px",
                        borderRadius: "8px", padding: "8px",
                        width: "96px", display: "flex", justifyContent: "center",
                        fontSize: "16px",
                        color: "black", border: `1px solid ${THEME_YELLOW}`,
                    }
        return (
            <div className={`pointer-cursor`} style={{
                ...style,
                borderBottomWidth: "1px", borderBottomColor: "#E8E8E8", borderBottomStyle: "solid",
                padding: isMobile ? "12px" : "24px",
                display: "flex", flexDirection: "row",
                alignItems: "center", justifyContent: "flex-start",
                color: "black"
            }} onClick={() => { if ((isManage || userId == qna?.writer) && onClick) onClick() }}>
                <span style={{ fontSize: "18px", minWidth: "48px" }}>{index}</span>
                <div style={{ flex: 1, minWidth: 0, display: "flex" }}>
                    <span style={{ margin: isMobile ? "0px 12px" : "0px 24px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{qna?.questionTitleHtml}</span>
                    {userId !== qna?.writer && !isManage &&
                        <img style={{ width: "24px", height: "24px" }}
                            src={lock} />}
                </div>
                {
                    !qna?.answer
                        ? <div style={yetCssValue}>
                            <span style={{ whiteSpace: "nowrap", }}>{"미완료"}</span>
                        </div>
                        : <div style={doneCssValue}>
                            <span style={{ whiteSpace: "nowrap", }}>{"답변 완료"}</span>
                        </div>

                }
            </div>
        )
    }