import React , { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { PayStub } from "../classes/PayStub";
import { StaffWithId } from "../classes/Staff";
import { PayDayWork } from "./PayPages/PayDayWork";
import { PayEmployee } from "./PayPages/PayEmployee";
import { PayPartTime } from "./PayPages/PayPartTime";
// import { PayPartTime } from "./PayPartTime";
export const PayRouter: FC = () => {
    const location = useLocation()
    const { staff,payStub } =
        location.state as {
            staff: StaffWithId,
            payStub : PayStub
        }
    const { type } =  payStub ? payStub.type : staff.data.hireType

    useEffect(()=>{
        console.log(location)
    },[])
    if (type === 0) {
        return <PayEmployee />
    } else if (type === 1) {
        return <PayPartTime />
    } else {
        return <PayDayWork/>
    }
}
