import { getDownloadURL, ref } from "firebase/storage";
import moment from "moment";
import React,{FC, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BannerWithId } from "../../classes/Banner";
import { Notice,NoticeWithId } from "../../classes/Notice";
import { BannerFlag } from "../../components/BannerFlag";
import styles from '../../css/AdminNotice.module.css'
import { fireStorage } from "../../fireConfig";
import { ic_arrow_down } from "../../images/images";
import { THEME_YELLOW } from "../../values/Colors";
import { AdminBannerEdit } from "./AdminBannerEdit";
import { AdminBannerModi } from "./AdminBannerModi";
import { AdminNoticeEdit } from "./AdminNoticeEdit";
import { AdminNoticeModi } from "./AdminNoticeModi";
export const AdminNotice: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const notices : NoticeWithId[] = useSelector((state : {notice : NoticeWithId[]}) => state.notice.map((value,index)=>{
        value.index = index
        return value
    }))
    return (
        <div className="parent-div-per">
            <Routes>
                <Route path=""
                    element={
                        <div className={styles.main_ctn}
                            style={{
                                padding: "30px 2.5%",
                                display: "flex",
                                overflowY: "hidden", flexDirection: "column",
                                boxSizing:"border-box"
                            }}>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", marginBottom: "12px",
                            }}>
                                <span style={{
                                    fontSize: "22px", fontWeight: 500,
                                }}>공지사항</span>
                                <div className="pointer-cursor" style={{
                                    backgroundColor: THEME_YELLOW, borderRadius: "6px",
                                    padding: "10px 37px", display: "flex", alignItems: "center",
                                    color: "white", fontWeight: 500
                                }}
                                    onClick={() => {
                                        navigate("edit", {
                                            state: {
                                                ...location.state,
                                            }
                                        })
                                    }}>
                                    <span >{"추가"}</span>
                                </div>
                            </div>
                            <div style={{
                                flex: 1,
                                display:"flex", flexDirection:"column",
                                borderTop: "2px solid #B7B7B7",overflowY:"auto"
                            }}>
                                {notices.map((value, index) => {
                                    const { title, createTime } = value.notice
                                    console.log(index)
                                    return (
                                        <div key={`notice_${index}`}
                                            className="pointer-cursor" style={{
                                                display: "flex", flexDirection: "column",
                                                boxSizing: "border-box",
                                                borderTop: "1px solid #E8E8E8",
                                                borderBottom: index === notices.length - 1 ? "1px solid #E8E8E8" : ""
                                            }}
                                            onClick={() => {
                                                navigate("modi",{
                                                    state : {
                                                        ...location.state,
                                                        noticeWithId : value
                                                    }
                                                })
                                            }}>
                                            <div style={{
                                                display: "flex", alignItems: "center",
                                                padding: "16px 0px",
                                            }}>
                                                <div style={{
                                                    flex: 1, display: "flex", flexDirection: "column",
                                                    fontSize: "14px", whiteSpace: "pre-wrap",
                                                }}>
                                                    <span>{title}</span>
                                                    <span style={{ fontSize: "12px" }}>{moment.unix(createTime.seconds).format("YYYY.MM.DD HH:mm:ss")}</span>
                                                </div>
                                                <img src={ic_arrow_down} style={{ height: "24px", transform: "rotate(-90deg)" }} />
                                            </div>
                                        </div>)
                                }
                                )}
                            </div>
                        </div>
                    } />
                <Route path="edit" element={<AdminNoticeEdit/>} />
                <Route path="modi" element={<AdminNoticeModi/>} />
            </Routes>
        </div>
    )
}