import _back from './common/arrow_back@2x.png'
import _menu from "../images/common/menu@2x.png"
import _logo from "../images/login/logo_login.png"
import _background from "../images/main/background@2x.png"
import _ic_coin_white from "../images/main/ic_coin_white@2x.png"
import _ic_coin_yellow from "../images/main/ic_coin_yellow@2x.png"
import _ic_staff_white from "../images/main/ic_staff_white@2x.png"
import _ic_staff_yellow from "../images/main/ic_staff_yellow@2x.png"
import _img_letter from "../images/menu/img_letter@2x.png"
import _img_manage_store from "../images/menu/img_manage_store@2x.png"
import _img_manage_user from "../images/menu/img_manage_user@2x.png"
import _ic_staff from "../images/menu/ic_staff@2x.png"
import _ic_pay from "../images/menu/ic_pay@2x.png"
import _ic_user from "../images/menu/ic_user@2x.png"
import _ic_guide from "../images/menu/ic_guide@2x.png"
import _ic_notice from "../images/menu/ic_notice@2x.png"
import _ic_logout from "../images/menu/ic_logout@2x.png"
import _ic_home_menu from "../images/menu/ic_home_menu@2x.png"
import _edit from "../images/manage/btn_edit@2x.png"
import _plus from "../images/manage/ic_plus@2x.png"
import _ic_delete from "../images/manage/ic_delete@2x.png"
import _ic_calendar from "../images/pay/ic_calendar@2x.png"
import _ic_minus from "../images/pay/ic_minus@2x.png"
import _btn_edit_square from "../images/manage/btn_edit_square@2x.png"
import _ic_send from "../images/manage-pay/ic_send@2x.png"
import _ic_audio_off from '../images/manage/Ic_adio_off@2x.png'
import _ic_audio_on from '../images/manage/Ic_adio_on@2x.png'
import _ic_paper from '../images/pay/ic_paper_gray@2x.png'
import _ic_arrow_down from '../images/manage-pay/ic_arrow_down@2x.png'
import _ic_arrow_up from '../images/manage-pay/ic_arrow_up@2x.png'
import _staff_on from '../images/main-pc/staff_on@2x.png'
import _staff_off from '../images/main-pc/staff_off@2x.png'
import _pay_on from '../images/main-pc/pay_on@2x.png'
import _pay_off from '../images/main-pc/pay_off@2x.png'
import _user_on from '../images/main-pc/user_on@2x.png'
import _user_off from '../images/main-pc/user_off@2x.png'
import _notice_on from '../images/main-pc/notice_on@2x.png'
import _notice_off from '../images/main-pc/notice_off@2x.png'
import _service_on from '../images/main-pc/service_on@2x.png'
import _service_off from '../images/main-pc/service_off@2x.png'
import _ic_history from '../images/pay/ic_history@2x.png'
import _illust_enroll from '../images/illust/illust_enroll@2x.png'
import _illust_send from '../images/illust/illust_send@2x.png'
import _ad_off from '../images/admin/ad_off@2x.png'
import _ad_on from '../images/admin/ad_on@2x.png'
import _admin_user_off from '../images/admin/user_off@2x.png'
import _admin_user_on from '../images/admin/user_on@2x.png'
import _search from '../images/admin/search@2x.png'
import _logo_read from '../images/read/logo_read@2x.png'
import _img_read from '../images/read/img_read@2x.png'
import _arrow_down_fill from '../images/admin/arrow_down_fill@2x.png'
import _ic_upload from '../images/admin/ic_upload@2x.png'
import _ic_kakao from '../images/manage-pay/ic_kakao@2x.png'
import _insurance_off from '../images/admin/insurance_off@2x.png'
import _insurance_on from '../images/admin/insurance_on@2x.png'
import _img_people_pc from '../images/landing/img_people_pc@2x.png'
import _img_text_pc from '../images/landing/img_text_pc@2x.png'
import _landing_pc1 from '../images/landing/landing_pc1@2x.png'
import _landing_pc2 from '../images/landing/landing_pc2@2x.png'
import _landing_mobile1 from '../images/landing/landing_mobile1@2x.png'
import _landing_mobile2 from '../images/landing/landing_mobile2@2x.png'
import _arrow_down_pc from '../images/landing/arrow_down_pc@2x.png'
import _arrow_up_pc from '../images/landing/arrow_up_pc@2x.png'
import _answer_pc from '../images/landing/answer_pc@2x.png'
import _question_pc from '../images/landing/question_pc@2x.png'
import _terms_off from '../images/admin/terms_off@2x.png'
import _terms_on from '../images/admin/terms_on@2x.png'
import _ic_share from '../images/main/ic_share@2x.png'
import _qna_off from '../images/qna/pc/qna_off@2x.png'
import _qna_on from '../images/qna/pc/qna_on@2x.png'
import _ic_qna from '../images/qna/mobile/icon_qna@2x.png'
import _arrow_back_black from '../images/qna/pc/arrow_back_black@2x.png'
import _arrow_back_grey from '../images/qna/pc/arrow_back_grey@2x.png'
import _arrow_next_black from '../images/qna/pc/arrow_next_black@2x.png'
import _arrow_next_grey from '../images/qna/pc/arrow_next_grey@2x.png'
import _img_qna from '../images/qna/pc/img_qna@2x.png'
import _lock from '../images/qna/pc/lock@2x.png'
import _arrow_next from '../images/qna/mobile/arrow_next@2x.png'
export const arrow_next = _arrow_next
export const lock = _lock;
export const img_qna = _img_qna;
export const arrow_back_black = _arrow_back_black
export const arrow_back_grey = _arrow_back_grey
export const arrow_next_black = _arrow_next_black
export const arrow_next_grey = _arrow_next_grey
export const ic_qna = _ic_qna
export const qna_on = _qna_on
export const qna_off = _qna_off
export const ic_share = _ic_share
export const terms_on = _terms_on
export const terms_off = _terms_off
export const question_pc = _question_pc
export const answer_pc = _answer_pc
export const arrow_up_pc = _arrow_up_pc
export const arrow_down_pc = _arrow_down_pc
export const landing_mobile2 = _landing_mobile2
export const landing_mobile1 = _landing_mobile1
export const landing_pc2 = _landing_pc2
export const landing_pc1 = _landing_pc1
export const img_text_pc = _img_text_pc
export const img_people_pc = _img_people_pc
export const insurance_off = _insurance_off
export const insurance_on = _insurance_on
export const ic_kakao = _ic_kakao
export const ic_upload = _ic_upload
export const arrow_down_fill = _arrow_down_fill
export const img_read = _img_read
export const logo_read = _logo_read
export const search = _search
export const ad_off = _ad_off
export const ad_on = _ad_on
export const admin_user_off = _admin_user_off
export const admin_user_on = _admin_user_on
export const illust_enroll = _illust_enroll
export const illust_send = _illust_send
export const ic_history = _ic_history
export const service_off = _service_off
export const service_on = _service_on
export const notice_off = _notice_off
export const notice_on = _notice_on
export const user_off = _user_off
export const user_on = _user_on
export const pay_off = _pay_off
export const pay_on = _pay_on
export const staff_off = _staff_off
export const staff_on = _staff_on
export const ic_arrow_up = _ic_arrow_up
export const ic_arrow_down = _ic_arrow_down
export const ic_paper = _ic_paper
export const ic_audio_on = _ic_audio_on
export const ic_audio_off = _ic_audio_off
export const ic_send = _ic_send
export const btn_edit_square = _btn_edit_square
export const ic_minus = _ic_minus
export const ic_calendar = _ic_calendar
export const ic_delete = _ic_delete
export const plus = _plus
export const edit = _edit
export const ic_home_menu = _ic_home_menu
export const ic_logout = _ic_logout
export const ic_notice = _ic_notice
export const ic_guide = _ic_guide
export const ic_user = _ic_user
export const ic_pay = _ic_pay
export const ic_staff = _ic_staff
export const img_manage_store = _img_manage_store
export const img_manage_user = _img_manage_user
export const img_letter = _img_letter
export const ic_coin_white = _ic_coin_white
export const ic_coin_yellow = _ic_coin_yellow
export const ic_staff_white = _ic_staff_white
export const ic_staff_yellow = _ic_staff_yellow
export const back = _back
export const menu = _menu
export const logo = _logo
export const background = _background
