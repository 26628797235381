import React, { FC, useEffect, useState } from "react";
import { DayPs, EmpPs, PartPs, PayStub } from "../../classes/PayStub";
import { PayInput } from "../../components/PayInput";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import styles from '../../css/PayViewPage.module.css'

export const Day: FC<{payStubData : DayPs}> = ({payStubData}) => {

    return (
        <>
            <div className={`side_margin_5per`} style={{
                display: "flex", flexDirection: "column",
                marginBottom: "16px"
            }}>
                <TitleInputUnit
                    title="기본 임금"
                    outerStyle={{
                        padding: "4px 0px"
                    }}
                    removeBorder={true}
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={payStubData.paymentAmount}
                    unit="원"
                    readonly={true} />
            </div>
            {payStubData.additionalPayments?.length > 0 &&
                payStubData.additionalPayments.map((value)=>{
                    return (
                        <div className={`side_margin_5per`} style={{
                            display: "flex", flexDirection: "column",
                            marginBottom: "16px"
                        }}>
                            <TitleInputUnit
                                title={value.title}
                                outerStyle={{
                                    padding: "4px 0px"
                                }}
                                removeBorder={true}
                                titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                                value={value.value}
                                unit="원"
                                readonly={true} />
                        </div>
                    )
                })
            }
            {/* deductions */}
            <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column" }}>
                <div className={`${styles.deduct_ctn}`}>
                    <PayInput
                        style={{ borderWidth: "0px", height: 0 }}
                        readonly={true}
                        title={"3.3% 원천징수"}
                        value={payStubData.deductionAmount} />
                </div>
            </div>
            <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                {/* Amount */}
                <PayInput
                    style={{ borderWidth: "0px" }}
                    titleStyle={{ fontWeight: 500 }}
                    readonly={true}
                    title={"실수령액"}
                    value={payStubData.amount} />
            </div>
        </>
    )
}