import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, {FC, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PayStub, payStubConverter } from "../../classes/PayStub";
import { staffConverter, StaffWithId } from "../../classes/Staff";
import { userConverter } from "../../classes/User";
import {  fireStore } from "../../fireConfig";
import { admin_user_off, admin_user_on, ad_off, ad_on,  insurance_off, insurance_on, logo, notice_off, notice_on, qna_off, qna_on, terms_off, terms_on } from "../../images/images";
import { getStaffList } from "../../redux/reducer/staff/staff";
import { updateUsers } from "../../redux/reducer/users/users";
import styles from "../../css/Main.module.css"
import { AdminStaffManage } from "./AdminStaffManage";
import { AdminBanner } from "./AdminBanner";
import {  getNoticeList } from "../../components/Tools";
import { AdminNotice } from "./AdminNotice";
import { saveNotice } from "../../redux/reducer/notice/notice";
import { AdminTax } from "./AdminTax";
import { ChangeKeyModal } from "../../components/ChangeKeyModal";
import { useBlocker } from "../../components/react-router-dom-revive";
import { AdminTerms } from "./AdminTerms";
import { AdminQnARouter } from "./AdminQnARouter";
import { getAllOfQnARef } from "../../functions/firebaseTools";

export const AdminMain : FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const reduxDispatch = useDispatch()
    const [changeKeyVisible, setChangeKeyVisible] = useState(false)
    // const MENU_PADDING = "8px"

    const pcTabList = [
        {
            title: "사용자 관리",
            path: "manage-user",
            ic_on: admin_user_on,
            ic_off: admin_user_off
        },
        {
            title: "배너관리",
            path: "manage-ad",
            ic_on: ad_on,
            ic_off: ad_off
        },
        {
            title: "공지사항",
            path: "noti",
            ic_on: notice_on,
            ic_off: notice_off
        },
        {
            title: "요율관리",
            path: "tax",
            ic_on: insurance_on,
            ic_off: insurance_off
        },
        {
            title: "약관 관리",
            path: "term",
            ic_on: terms_on,
            ic_off: terms_off
        },
        {
            title: "QnA 관리",
            path: "qna/0",
            ic_on: qna_on,
            ic_off: qna_off
        },
    ]

    useEffect(()=>{
        if (location.state.auth_level <= 0)
            getAllInfomation()
        navigate(pcTabList[location.state.auth_level > 0 ? 1 : 0].path, {
            replace:true,
            state: location.state
        })
    },[])

    const getAllInfomation = () => {
        saveUserList()
        saveStaffList()
        saveNoticeList()
        getAllOfQnARef(reduxDispatch)
    }

    const saveNoticeList = async ()=>{
        const noticeList = await getNoticeList()
        reduxDispatch(saveNotice(noticeList))
    }
    
    const saveUserList = async () => {
        const colRef = collection(fireStore,"users").withConverter(userConverter)
        const userDocs = await getDocs(colRef)
        const userList = userDocs.docs.map((userDoc)=>{
            return {
                id: userDoc.id,
                data: userDoc.data()
            }
        }).filter((user)=>{
            return user.data.companyName?.length ?? 0 > 0
        }).sort((a, b) => {
            var nameA = (a.data.companyName ?? "").toUpperCase()
            var nameB = (b.data.companyName ?? "").toUpperCase()
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // 이름이 같을 경우
            return 0;
        })
        reduxDispatch(updateUsers(userList))
    }
    
    const saveStaffList = async () => {
        const staffList: StaffWithId[] = await getStaffListFromFireStore()
        reduxDispatch(getStaffList(staffList))
    }

    const getStaffListFromFireStore = async () => {

        const collectionRef = collection(fireStore,"staffs").withConverter(staffConverter)
        const staffQuery = query(collectionRef,orderBy("name","asc"))
        const querySnapshot = await getDocs(staffQuery)
        return Promise.all(
            querySnapshot.docs.map(async(doc)=>{
                const payStubObj = await getPayStubObjFromFireStore(doc.ref.path)
                return {
                    data :doc.data(),
                    id : doc.id,
                    payStubObj : payStubObj
                }
            })
        )
    }
    const getPayStubObjFromFireStore = async (path:string) : Promise<{ [key: string]: PayStub }> => {
        const staffPayStubCollection = collection(fireStore,path,"payStub").withConverter(payStubConverter)
        const payStubQuery = query(staffPayStubCollection)
        const querySnapshot = await getDocs(payStubQuery)
        const payStubObject: { [key: string]: PayStub } = {}
        querySnapshot.docs.forEach(async (doc) => {
            const id: string = doc.id
            payStubObject[id] = doc.data()
        })
        return payStubObject
    }
    const toogleModal = () => {
        setChangeKeyVisible(!changeKeyVisible)
    }
    useBlocker(()=>{
        toogleModal()
    },changeKeyVisible)
    return (
        <div className="parent-div">
            <ChangeKeyModal
                open={changeKeyVisible}
                onClose={toogleModal}/>
            <div className={styles.main_ctn}>
                <div className={styles.header_ctn}
                    style={{ borderBottom: "1px solid #EADFDB" }}>
                    <div style={{
                        height: "100%", width: "auto",
                        marginLeft: "36px", boxSizing: "border-box",
                        display: "flex", alignItems: "center"
                    }}>
                        <img src={logo} style={{ height: "80%", width: "auto" }} />
                    </div>
                    <div className="pointer-cursor" style={{
                        marginRight: "36px", boxSizing: "border-box",
                        display: "flex", alignItems: "center",
                        fontSize: "16px"
                    }}>
                        <span style={{ fontWeight: 500, color: "#F46767" }}
                            onClick={async () => {
                                navigate("",{
                                    replace:true
                                })
                            }}>{`로그아웃`}</span>
                    </div>
                </div>

                <div style={{
                    flex: 1,display: "flex",overflowY:"hidden"
                }}>
                    <div //TabContainer
                        style={{
                            borderRight: "1px solid #D0D0D0",
                            display: "flex", flexDirection: "column", boxSizing: "border-box"
                        }}>
                        <div className={styles.tab_container} style={{ flex: 1 }}>
                            {pcTabList.map((ctnValue, index1) => {
                                if (location.state?.auth_level > 0 && (index1 === 0 || index1 === 3)) {
                                    return
                                }
                                const { title, path, ic_off, ic_on } = ctnValue
                                const isCurrent = path.includes("qna") 
                                    ? location.pathname.includes("/qna")
                                    : location.pathname.includes("/"+path)
                                return (
                                    <div className={styles.tab_item_ctn} id={`tab-child-${index1}`}
                                        style={{
                                            minWidth: "150px",
                                            backgroundColor: isCurrent ? "#FDCA56" : "white"
                                        }}>
                                        <img className={styles.tab_item_icon} src={isCurrent ? ic_on : ic_off} />
                                        <span className="pointer-cursor"
                                            style={{ color: isCurrent ? "#FFFFFF" : "#9B9B9B" }}
                                            onClick={() => {
                                                navigate(path, {
                                                    state: location.state
                                                })
                                            }}>
                                            {title}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        {location.state?.auth_level === 0
                            && <div className="pointer-cursor"
                                style={{
                                    alignSelf: "center", marginBottom: "30px", color: "#9B9B9B"
                                }} onClick={toogleModal}> 관리자 관리</div>}
                    </div>
                    <Routes>
                        <Route path="manage-user/*" element={<AdminStaffManage/>} />
                        <Route path="manage-ad/*" element={<AdminBanner/>} />
                        <Route path="noti/*" element={<AdminNotice/>} />
                        <Route path="tax" element={<AdminTax/>} />
                        <Route path="term" element={<AdminTerms/>} />
                        <Route path="qna/*" element={<AdminQnARouter/>} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}