import { getDownloadURL, ref } from "@firebase/storage";
import moment from "moment";
import React,{CSSProperties, FC, useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Banner, BannerWithId } from "../classes/Banner";
import { fireStorage } from "../fireConfig";
import { saveBase64 } from "../redux/reducer/banners/banners";
import { getImageUrl } from "./Tools";
export const BannerFlag: FC<{
    value : BannerWithId,
    onClick? : () => void,
    style? : CSSProperties
}> = ({
    value ,
    onClick,
    style
}) => {
    const {banner,base64,index} = value
    const {name,createTime, destination} = banner
    const [imageUrl,setImageUrl] = useState<string>("")
    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (base64) {
            setImageUrl(base64)
        } else
            getImageUrl(reduxDispatch,destination,index!,(src)=>{
                setImageUrl(src)
            })
    }, [value])

    return (
        <div className="pointer-cursor" style={{
            display: "flex", flexDirection: "column",
            border:"1px solid #D0D0D0",
            borderRadius:"4px",
            width:"346px",...style
        }} onClick={onClick}>
            <img src={imageUrl}
                style={{borderRadius:"4px 4px 0px 0px"}} />
            <div style={{
                display: "flex", flexDirection: "column",
                padding:"12px"
            }}>
                <span style={{fontSize:"16px",color:"#191919"}}>{name}</span>
                <span style={{fontSize:"13px",color:"#767676"}}>{moment(createTime.toDate()).format("YYYY-MM-DD 등록")}</span>
            </div>
        </div>
    )
}