import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { HTU } from "../../classes/HTU";
import { QnAItem } from "../../components/QnAItem";
import styles from "../../css/Landing/LandingRouter.module.css"
import { img_people_pc, img_text_pc, landing_mobile1, landing_mobile2, landing_pc1, landing_pc2, logo } from "../../images/images";
import { DARK_GRAY, THEME_YELLOW } from "../../values/Colors";
import { FIVE_PER } from "../../values/strings";
import { LandingHowToUse } from "./LandingHowToUse";
import { LandingInfo } from "./LandingInfo";
import { LandingQnA } from "./LandingQnA";
type pathList = "" | "howtouse" | "htu"
export const LandingRouter: FC = () => {
    const isMobile = useSelector((state: { isMobile: boolean }) => state.isMobile)
    const navigate = useNavigate();
    const location = useLocation();
    const htu = useSelector((state: { htu: HTU[] }) => state.htu)
    const [currentPath, setCurrentPath] = useState<pathList>("")
    const tabList: { title: string, path: pathList }[] = [
        {
            title: "서비스 안내",
            path: ""
        },
        {
            title: "이용방법",
            path: "howtouse"
        },
        {
            title: "자주하는 질문",
            path: "htu"
        },
    ]
    const movePage = (path: pathList) => {
        navigate(path)
    }

    useEffect(() => {
        setCurrentPath(location.pathname.slice(1) as pathList)
        console.log(location.pathname.slice(1) === tabList[0].path)
    }, [location])
    return (
        <div className="parent-div">
            <div className={styles.main_ctn}>
                {isMobile
                    ?
                    <div className={styles.header_ctn}>
                        <div className={`${styles.header_center}`}>
                            <img src={logo} style={{ height: "80%", width: "auto" }} />
                        </div>
                    </div>
                    :
                    <div className={styles.header_ctn}>
                        <div className={`${styles.header_side_ctn} ${styles.left}`}>
                            <img src={logo} style={{ height: "80%", width: "auto" }} />
                        </div>
                        <div className={styles.header_center}>
                            {tabList.map((value) => {
                                const { title, path } = value
                                const isHighLight = currentPath === path
                                return <span className="pointer-cursor"
                                    style={{
                                        color: isHighLight ? THEME_YELLOW : "#191919",
                                        textDecorationLine: isHighLight ? "underline" : undefined,
                                        WebkitTextDecorationLine: isHighLight ? "underline" : undefined,
                                        textDecorationThickness: "2px",
                                        textUnderlineOffset: "6px"
                                    }}
                                    onClick={() => {
                                        movePage(path)
                                    }}>{title}</span>
                            })}
                        </div>
                        <div className={`${styles.header_side_ctn} ${styles.right}`}>
                            <div className="pointer-cursor" style={{
                                borderRadius: "6px",
                                padding: "8px 16px", display: "flex", alignItems: "center",
                                marginRight: "14px",
                                color: "#191919"
                            }}
                                onClick={() => {
                                    navigate("login")
                                }}>
                                로그인
                            </div>
                            <div className="pointer-cursor" style={{
                                borderRadius: "4px",
                                padding: "6px 16px", display: "flex", alignItems: "center",
                                marginRight: "14px",
                                color: "#191919", border: "1px solid #191919"
                            }} onClick={() => {
                                navigate("join")
                            }}>
                                회원가입
                            </div>
                        </div>
                    </div>}
                {isMobile &&
                <div style={{
                    display: "flex", justifyContent: "center"
                }}>
                    {tabList.map((value) => {
                        const { title, path } = value
                        const isHighLight = currentPath === path
                        return <span className="pointer-cursor"
                            style={{
                                fontWeight: 500, fontSize: "16px",
                                padding: "12px",
                                color: isHighLight ? THEME_YELLOW : "#191919",
                                textDecorationLine: isHighLight ? "underline" : undefined,
                                WebkitTextDecorationLine: isHighLight ? "underline" : undefined,
                                textDecorationThickness: "2px",
                                textUnderlineOffset: "6px"
                            }}
                            onClick={() => {
                                movePage(path)
                            }}>{title}</span>
                    })}
                </div>}
                <div className={styles.body_ctn}>
                    <Routes>
                        <Route path="" element={<LandingInfo />} />
                        <Route path="howtouse" element={<LandingHowToUse />} />
                        <Route path="htu" element={<LandingQnA />} />
                    </Routes>
                </div>
                {!isMobile &&
                <footer style={{
                    width: "100%",
                    padding: `0px ${FIVE_PER} 0px ${FIVE_PER}`,boxSizing:"border-box",
                    backgroundColor:"rgba(245, 244, 241, 0.85)",
                }}>
                    <p style={{fontFamily:"Notosans",fontSize:"12px",color:DARK_GRAY,fontWeight:500}}>
                        노랑봉투<br/>
                        <span style={{fontSize:"10px",fontWeight:"normal"}}>
                            {`주식회사 플로션`}<br/>
                            {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br/>
                            {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br/>
                            {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br/>
                        </span>
                    </p>
                </footer>}
            </div>

        </div>
    )
}