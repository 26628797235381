import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import React, {FC, useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styles from '../../css/AdminTax.module.css'
import { fireStore } from "../../fireConfig"
import { ic_upload } from "../../images/images"
import { saveTaxValue, Tax } from "../../redux/reducer/tax/tax"
import { GRAY, THEME_YELLOW } from "../../values/Colors"

export const AdminTax : FC = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const taxState = useSelector((state : {tax : Tax})=>state.tax)
    const [nation,setNation] = useState<string>(taxState.nationalPension.toString() ?? "")
    const [health,setHealth] = useState<string>(taxState.healthInsurance.toString() ?? "")
    const [employ,setEmploy] = useState<string>(taxState.employmentInsurance.toString() ?? "")
    const [longTerm,setLongTerm] = useState<string>(taxState.longtermCareInsurance.toString() ?? "")
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File | undefined>(undefined);
    const [chart, setChart] = useState<{
        level : number,
        more : number,
        less : number,
        deduction : number[]
    }[]>(taxState.chart);
    const [overflow, setOverflow] = useState<{
        add : number,
        below : number,
        over : number,
        per1 : number,
        per2 : number
    }[]>(taxState.overflow);

    const goBack = () =>{
        navigate(-1)
    }
    const onlyNumDot = (value : string) : string => {
        return value.replace(/[^0-9.]/g, "")
    }
    const canSave = () => {
        var state = true;
        if (nation.replace(/[^0-9]/g,"").length < 1) {
            state = false
        }
        if (health.replace(/[^0-9]/g,"").length < 1) {
            state = false
        }
        if (employ.replace(/[^0-9]/g,"").length < 1) {
            state = false
        }
        if (longTerm.replace(/[^0-9]/g,"").length < 1) {
            state = false
        }

        return state
    }
    const updateTaxValue = async () => {
        if(canSave()){
            const newTaxValue : Tax = {
                nationalPension : parseFloat(nation),
                healthInsurance : parseFloat(health),
                employmentInsurance : parseFloat(employ),
                longtermCareInsurance : parseFloat(longTerm),
                chart : chart,
                overflow : overflow
            }
            alert(`수정되었습니다`
            +`\n국민연금 ${newTaxValue.nationalPension}%`
            +`\n건강보험 ${newTaxValue.healthInsurance}%`
            +`\n고용보험 ${newTaxValue.employmentInsurance}%`
            +`\n장기요양보험 ${newTaxValue.longtermCareInsurance}%`)
            const docRef = doc(fireStore,"tax/value")
            await updateDoc(docRef,{
                ...newTaxValue
            })
            reduxDispatch(saveTaxValue(newTaxValue))
        }else{
            alert("모든 항목을 채워주세요")
        }
    }

    return (
        <div className="parent-div-per">
            <div className={styles.main_ctn}
                style={{
                    padding: "30px 2.5%",
                    display: "flex",
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box"
                }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center",
                    fontSize: "14px", marginBottom: "12px",
                }}>
                    <span style={{
                        fontSize: "22px", fontWeight: 500,
                    }}>요율관리</span>
                    <div style={{
                        display: "flex", flexDirection: "row"
                    }}>
                        <div style={{
                            borderRadius: "6px",
                            padding: "10px 37px", display: "flex", alignItems: "center",
                            color: "#757575", border: "1px solid #757575",
                            marginRight: "14px",
                            cursor: "pointer"
                        }} onClick={goBack}>
                            <span >{"취소"}</span>
                        </div>
                        <div style={{
                            backgroundColor: THEME_YELLOW, borderRadius: "6px",
                            padding: "10px 37px", display: "flex", alignItems: "center",
                            color: "white", fontWeight: 500,
                            cursor: "pointer"
                        }} onClick={updateTaxValue}>
                            <span >{"수정"}</span>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "120px" }}>국민연금(%)</span>
                    <input style={{
                        width: "320px",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px"
                    }}
                        placeholder="국민연금을 요율을 입력해 주세요."
                        value={nation}
                        onChange={(e) => { 
                            setNation(onlyNumDot(e.target.value)) 
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "120px" }}>건강보험(%)</span>
                    <input style={{
                        width: "320px",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px"
                    }}
                        placeholder="건강보험 요율을 입력해 주세요."
                        value={health}
                        onChange={(e) => { setHealth(onlyNumDot(e.target.value)) }}
                    />
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "120px" }}>고용보험(%)</span>
                    <input style={{
                        width: "320px",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px"
                    }}
                        placeholder="고용보험 요율을 입력해 주세요."
                        value={employ}
                        onChange={(e) => { setEmploy(onlyNumDot(e.target.value)) }}
                    />
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "120px" }}>장기요양보험(%)</span>
                    <input style={{
                        width: "320px",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px"
                    }}
                        placeholder="장기요양보험 요율을 입력해 주세요."
                        value={longTerm}
                        onChange={(e) => { setLongTerm(onlyNumDot(e.target.value)) }}
                    />
                </div>
                <div
                    style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        color: "#161616", marginBottom: "24px"
                    }}>
                    <span style={{ marginRight: "20px", width: "120px" }}>소득세</span>
                    <input style={{
                        width: "320px",
                        padding: "12px", border: "1px solid #ADADAD", borderRadius: "9px",
                        outlineWidth: "0px", marginRight: "20px"
                    }}
                        disabled={true}
                        accept="image/*"
                        placeholder="소득세.json파일을 업로드해 주세요."
                        value={file?.name} />
                    <div
                        style={{
                            display: "flex", flexDirection: "row",
                            alignItems: "center", padding: "11px 12px",
                            border: `1px solid ${THEME_YELLOW}`, borderRadius: "5px",
                            fontSize: "12px", color: THEME_YELLOW, fontWeight: 500
                        }}
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}>
                        <span>업로드</span>
                        <img src={ic_upload} style={{ height: "16px", marginLeft: "4px" }} />
                    </div>
                    <input
                        ref={fileInputRef}
                        style={{ flex: 0, width: "1px", height: "1px" }}
                        type="file" accept="application/json"
                        onChange={(e) => {
                            if (e.target.files) {
                                let file = e.target.files[0]
                                if (file) {
                                    let reader = new FileReader();
                                    reader.onloadend = (event) => {
                                        if (event.target) {
                                            var obj = JSON.parse(event.target.result as string) as {
                                                chart: {
                                                    level: number,
                                                    more: number,
                                                    less: number,
                                                    deduction: number[]
                                                }[],
                                                overflow: {
                                                    add: number,
                                                    below: number,
                                                    over: number,
                                                    per1: number,
                                                    per2: number
                                                }[]
                                            }
                                            console.log(obj)
                                            setChart(obj.chart)
                                            setOverflow(obj.overflow)
                                            setFile(file)
                                        }
                                        // readImageFile(file)
                                    }
                                    reader.readAsText(file)
                                }
                            }
                        }} />
                </div>
            </div>
        </div>
    )
}