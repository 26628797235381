import React, { FC, useEffect, useState } from "react";
import { InputJoin } from "../components/InputJoin";
import upload from "../images/join/ic_upload.png"
import { DARK_GRAY, GRAY, THEME_YELLOW } from "../values/Colors";
import { useRef } from "react";
import { getDownloadURL, ref, StorageReference, uploadBytes } from "firebase/storage";
import { fireAuth, fireFunctions, fireStorage, fireStore } from "../fireConfig";
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { isValidEmail, isValidPassword, isValidPhone } from "../components/Tools";
import { createUserWithEmailAndPassword, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, updatePhoneNumber } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { doc, setDoc } from "@firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { getDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { User, userConverter, UserWithId } from "../classes/User";
import styles from '../css/Info.module.css'
import { back, ic_user } from "../images/images";
import { updateUser } from "../redux/reducer/user/user";
import { useMediaQuery } from "react-responsive";
import { updateTargetUser } from "../redux/reducer/users/users";

export const InfoModify: FC = () => {
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();
    const location = useLocation()
    const isAdmin = location.pathname.includes("admin")
    const {data : userData,id} = useSelector((state : {user: UserWithId})=> state.user)
    const users = useSelector((state : { users : UserWithId[]}) => state.users)
    // const {user : userData} = location.state as {user : User}
    const [companyName, setCompanyName] = useState<string>(userData.companyName ?? "")
    const [name, setName] = useState<string>(userData.name ?? "");
    const [phone, setPhone] = useState<string>(userData.phone ?? "")
    const [email, setEmail] = useState<string>(userData.email ?? "");
    const [authCode, setAuthCode] = useState<string>("");
    const [isConfirm, setIsConfirm] = useState<boolean>(false)
    const [authCodeVisible, setAuthCodeVisible] = useState<boolean>(false)
    const [bn, setBn] = useState<string>(userData.bn ?? "");
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [file, setFile] = useState<File | undefined>(undefined);
    const [preview, setPreview] = useState<string | undefined>("");

    const [btnAuthActive, setBtnAuthActive] = useState<boolean>(true)
    const [errorCode, setErrorCode] = useState<number>(0)

    const updateUserInfo = async () => {
        try {
            var storageRef: StorageReference;
            var downloadUrl: string;
            var updateBody: { [key: string]: any } = {};
            if (file) {
                const uploadResult = await uploadFile(id) //
                console.log(uploadResult)
                if (!uploadResult.result) {
                    return
                }
                const { filename } = uploadResult.data
                storageRef = ref(fireStorage, id + "/" + filename)
                downloadUrl = await getDownloadURL(storageRef)
                updateBody.brDownloadUrl = downloadUrl
            }
            updateBody = {
                ...updateBody,
                name: name,
                phone: phone,
                bn: bn,
                companyName: companyName
            }
            const storeDocRef = doc(fireStore, "users", id)
            updateBody.brRef = storeDocRef
            await updateDoc(storeDocRef, updateBody);
            const newData = storeDocRef.withConverter(userConverter)
            const newUser = (await getDoc(newData)).data() ?? new User()
            var newBody = {
                data : newUser,
                id : id
            }
            reduxDispatch(updateUser(newBody))
            var userIndex = users.findIndex((value)=> value.id === id)
            console.log("InfoModify UserIndex : ",userIndex)
            if(userIndex > -1){
                reduxDispatch(updateTargetUser({
                    index : userIndex,
                    data : newBody
                }))
            }
            navigate(-1)

        } catch (error) {
            if (error instanceof FirebaseError) {
                if (error.code === "auth/email-already-in-use") {
                    alert("이미 가입한 회원입니다.")
                }
            }
            console.log(error)
        }
    }
    const changeCanSignState = (): boolean => {

        var state = true;

        if (companyName.length < 1) {
            state = false
            redOrBlack("company_name", false)
        }
        if (name.length < 1) {
            state = false
            redOrBlack("name_input", false)
        }
        if (!isValidPhone(phone)) {
            state = false
            redOrBlack("phone_input", false)
        }
        if (bn.length < 9) {
            state = false
            redOrBlack("bn_input", false)
        }
        if (userData.phone !== phone && !isAdmin) {
            if (!window.verificationId) {
                state = false
                redOrBlack("bn_phone_auth", false)
            } else if (!isConfirm) {
                state = false
                redOrBlack("auth_input", false)
                redOrBlack("bt_confirm", false)
            }
        }

        return state

    }
    const redOrBlack = (id: string, type: boolean) => {
        const targetDocument = document.getElementById(id)
        if (targetDocument) {
            if (!["bn_photo", "bn_phone_auth", "bt_confirm"].includes(id)) {
                targetDocument.style.color = type ? "black" : "red"
            }
            else
                targetDocument.style.background = type ? THEME_YELLOW : "red"
        }
    }
    const uploadFile = async (uid: string) => {
        connectFunctionsEmulator(fireFunctions, "localhost", 5001)

        try {
            const formData = new FormData()
            if (file instanceof Blob) {
                //console.log("file Exist", file.name)
                formData.append("file", file, file.name)
                formData.append("uid", uid)
            }
            else
                return

            const result = await fetch(
                process.env.NODE_ENV != "production"
                    ? `http://localhost:5001/nobong-79c64/us-central1/uploadBR`
                    : `https://us-central1-nobong-79c64.cloudfunctions.net/uploadBR`, {
                method: "POST",
                body: formData
            })
            // //console.log(formData.get("file"))
            return await result.json()
        } catch (error) {
            //console.log(error)
        }
    }

    useEffect(() => {
        if (window.recaptchaVerifier === undefined) {
            window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
                'size': 'invisible',
                'callback': (callback: any) => {
                    //console.log("Recaptcha Solve",callback)
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // ...
                },
                'expired-callback': () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                    //console.log("Sdfsfd")
                }
            }, fireAuth)
        }
        else {
            //console.log(window.recaptchaVerifier)
        }
    }, [])

    const goBack = () => {
        navigate(-1)
    }

    const updatePN = async () : Promise<void> => {
        const typePhone = "+82" + phone.replace(/[^0-9]/g, "").substr(1)
        const provider = new PhoneAuthProvider(fireAuth);
        const appVerifier = window.recaptchaVerifier
        console.log(appVerifier)
        window.verificationId = await provider.verifyPhoneNumber(typePhone, appVerifier)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <div className={styles.main_ctn}
                style={{ maxWidth: "1280px" }}>
                <div className={styles.header_ctn}>
                    <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                        onClick={goBack} >
                        <img
                            src={back} />
                    </div>
                    <div className={styles.header_center}>
                        <img src={ic_user} />
                        <span >{"회원정보수정"}</span>
                    </div>
                </div>
                <div className={`side_margin_5per ${styles.body_ctn}`}
                    style={{
                        overflow:"auto"
                    }}>
                    <InputJoin
                        id="company_name"
                        onChange={(e) => {
                            setCompanyName(e.target.value)
                            redOrBlack("company_name", true)
                        }}
                        value={companyName}
                        type="text"
                        title="회사명"
                        placeholder="회사명을 입력해주세요." />
                    <InputJoin
                        id="name_input"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            redOrBlack("name_input", true)
                        }}
                        type="text"
                        title="이름"
                        placeholder={"이름을 입력해주세요."} />
                    <InputJoin
                        id="phone_input"
                        value={phone}
                        onChange={(e) => {
                            if (!isConfirm) {

                                setPhone(e.target.value)
                                redOrBlack("phone_input", true)
                                redOrBlack("bn_phone_auth", true)
                                setErrorCode(0)
                            }
                        }}

                        inputStyle={{
                            color: isConfirm ? DARK_GRAY : "black"
                        }}
                        type="tel"
                        title="전화번호"
                        placeholder={"전화번호를 입력해 주세요."}
                        rightButton={!isAdmin ? () => {
                            return (
                                <div id="bn_phone_auth"
                                    style={{
                                        backgroundColor: THEME_YELLOW,
                                        borderRadius: "36px",
                                        boxSizing: "border-box",
                                        maxHeight: "100%",
                                        display: "flex", justifyContent: "center", padding: "16px",
                                        alignItems: "center"
                                    }}
                                    onClick={async () => {
                                        if (btnAuthActive) {
                                            alert("인증번호는 국제번호로 발신되니 수신이 안되는 경우 차단 목록을 확인해주세요.")
                                            setBtnAuthActive(false)
                                            redOrBlack("bn_phone_auth", true)
                                            setErrorCode(0)
                                            // const appVerifier = window.recaptchaVerifier
                                            try {
                                                await updatePN()
                                                // const typePhone = "+82" + phone.replace(/[^0-9]/g, "").substr(1)
                                                // console.log(typePhone)
                                                // const confirmationResult = await signInWithPhoneNumber(fireAuth, typePhone, appVerifier)
                                                // window.confirmationResult = confirmationResult
                                                setAuthCodeVisible(true)
                                            } catch (error) {
                                                console.log(error)
                                                if (error instanceof FirebaseError) {
                                                    switch (error.code) {
                                                        case "auth/invalid-phone-number":
                                                            setErrorCode(1)
                                                            break;
                                                        case "auth/too-many-requests":
                                                            setErrorCode(2)
                                                            break;
                                                        default:
                                                            setErrorCode(3)
                                                            break;
                                                    }
                                                } else {
                                                    setErrorCode(3)
                                                }
                                            }
                                            setBtnAuthActive(true)
                                        }
                                    }}>
                                    <span style={{
                                        wordBreak: "keep-all", fontSize: "12px",
                                        color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                                    }}>인증번호받기</span>
                                </div>
                            )
                        } : undefined} />
                    <span style={{ color: "red", fontSize: "12px" }}>{
                        errorCode > 0
                            ? errorCode > 1
                                ? errorCode > 2
                                    ? errorCode > 3
                                        ? ""
                                        : "인증 모듈에 오류가 발생하였습니다."
                                    : "너무 많이 시도되었습니다. 잠시 후에 다시 시도해주세요."
                                : "유효하지 않은 전화번호입니다."
                            : ""}</span>
                    <div id="sign-in-button" style={{}}></div>
                    {authCodeVisible && <InputJoin
                        id="auth_input"
                        value={authCode}
                        onChange={(e) => {
                            if (!isConfirm) {
                                setAuthCode(e.target.value)
                                redOrBlack("auth_input", true)
                                redOrBlack("bt_confirm", true)
                                setErrorCode(0)
                            }
                        }}
                        inputStyle={{
                            color: isConfirm ? DARK_GRAY : "black"
                        }}
                        type="number"
                        title="인증번호"
                        maxLength={6}
                        placeholder={"인증번호를 입력해 주세요."}
                        rightButton={() => {
                            return (
                                <div id="bt_confirm"
                                    style={{
                                        backgroundColor: THEME_YELLOW,
                                        borderRadius: "36px",
                                        boxSizing: "border-box",
                                        maxHeight: "100%",
                                        display: "flex", justifyContent: "center", padding: "16px",
                                        alignItems: "center"
                                    }}
                                    onClick={async () => {
                                        const user = fireAuth.currentUser
                                        if (user) {
                                            try {
                                                // Obtain the verificationCode from the user.
                                                const phoneCredential = PhoneAuthProvider.credential(window.verificationId, authCode);
                                                await updatePhoneNumber(user, phoneCredential);
                                                updatePhoneNumber(user,phoneCredential)
                                                // const confirmResult = await window.confirmationResult.confirm(authCode)
                                                setIsConfirm(true)
                                                redOrBlack("bt_confirm", true)
                                                // confirmResult.user.delete()
                                            } catch (error) {
                                                console.log(error)
                                                if (error instanceof FirebaseError) {
                                                    switch (error.code) {
                                                        case "auth/invalid-verification-code":
                                                            setErrorCode(4)
                                                            break;
                                                        case "auth/code-expired":
                                                            setErrorCode(5)
                                                            break;
                                                        case "auth/account-exists-with-different-credential":
                                                            setErrorCode(6)
                                                            break;
                                                        default:
                                                            setErrorCode(0)
                                                            break;
                                                    }
                                                }
                                            }
                                        }
                                    }}>
                                    <span style={{
                                        wordBreak: "keep-all", fontSize: "12px",
                                        color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                                    }}>인증하기</span>
                                </div>
                            )
                        }} />}
                    <span style={{ color: "red", fontSize: "12px" }}>{
                        errorCode > 3
                            ? errorCode > 4
                                ? errorCode > 5
                                    ? errorCode > 5
                                        ? "이미 가입된 전화번호입니다."
                                        : "너무 많이 시도되었습니다. 잠시 후에 다시 시도해주세요."
                                    : "만료된 인증번호입니다. 인증번호를 다시 발급받아주세요"
                                : "인증번호가 일치하지 않습니다."
                            : ""
                    }</span>
                    <InputJoin
                        id="email_input"
                        value={email}
                        readonly={true}
                        title="아이디" />
                    <InputJoin
                        id="bn_input"
                        value={bn}
                        onChange={(e) => {
                            setBn(e.target.value)
                            redOrBlack("bn_input", true)
                        }}
                        type="text"
                        title="사업자 번호"
                        placeholder={"사업자 번호를 입력해 주세요."}
                        rightButton={() => {
                            return (
                                <div id="bn_photo"
                                    style={{
                                        backgroundColor: THEME_YELLOW,
                                        borderRadius: "36px",
                                        boxSizing: "border-box",
                                        maxHeight: "100%",
                                        display: "flex", justifyContent: "center", padding: "16px",
                                        alignItems: "center"
                                    }}
                                    onClick={() => {
                                        fileInputRef.current?.click();
                                    }}>
                                    <img src={upload} />
                                    <span style={{
                                        marginInlineStart: "6px",
                                        wordBreak: "keep-all", fontSize: "12px",
                                        color: "white", fontWeight: "bold", fontFamily: "NotoSans",
                                    }}>업로드</span>
                                    <input
                                        ref={fileInputRef}
                                        style={{ flex: 0, width: "1px", height: "1px" }}
                                        type="file" accept="application/pdf,image/*"
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                redOrBlack("bn_photo", true)
                                                let file = e.target.files[0]
                                                let reader = new FileReader();
                                                reader.onloadend = () => {
                                                    const imageUrl = reader.result?.toString()
                                                    setFile(file)
                                                    setPreview(imageUrl)
                                                }
                                                reader.readAsDataURL(file)
                                            }
                                        }} />
                                </div>
                            )
                        }} />
                    <img src={preview}
                        style={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "auto"
                        }} />
                </div>
                <div className="side_margin_5per" style={{
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 24, paddingBottom: 24,
                    boxSizing: "border-box"
                }}>
                    <div style={{
                        boxSizing: "border-box",
                        width: "100%",
                        padding: "12px",
                        marginTop: "8px",
                        borderRadius: "52px",
                        backgroundColor: THEME_YELLOW,
                        display: "flex", justifyContent: "center"
                    }}
                        onClick={() => {
                            if (changeCanSignState()) {
                                updateUserInfo()
                            } else {
                                alert("모든 입력란을 알맞게 채워주세요")
                            }
                        }}>
                        <span style={{ color: "white", fontWeight: "bold", fontFamily: "NotoSans" }}>{"저장"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}