import React, {FC, MouseEventHandler, useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HTU } from "../../classes/HTU";
import { QnAItem } from "../../components/QnAItem";
import { DARK_GRAY, THEME_YELLOW } from "../../values/Colors";
import { FIVE_PER } from "../../values/strings";

export const LandingQnA: FC = () => {
    const isMobile = useSelector((state: { isMobile: boolean }) => state.isMobile)
    const navigate = useNavigate();
    const htu = useSelector((state : {htu : HTU[]})=> state.htu)
    useEffect(()=>{
        console.log(htu)
    }, [])

    return isMobile
        ? <div style={{ flex: 1, display: "flex",flexDirection:"column" }}>

            <div style={{ overflowY: "scroll", flex: 1 }}>
                {htu.map((value, index) => {
                    return (
                        <QnAItem key={`QnA_${index}`}
                            itemValue={value}
                            outerStyle={{
                                borderBottom: index === htu.length - 1 ? "1px solid #E8E8E8" : ""
                            }} />
                    )
                })}
            </div>
            <footer style={{
                width: "100%",
                padding: `12px ${FIVE_PER} 12px ${FIVE_PER}`, boxSizing: "border-box",
                backgroundColor: "rgba(245, 244, 241, 0.85)",
            }}>
                <p style={{ fontFamily: "Notosans", fontSize: "12px", color: DARK_GRAY, fontWeight: 500 }}>
                    노랑봉투<br />
                    <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                        {`주식회사 플로션`}<br />
                        {`대표자 : 조호진 | 경기도 하남시 미사강변서로 16, 에프634호(풍산동)`}<br />
                        {`1533 - 5336 | 사업자번호 : 103-86-01622`}<br />
                        {`통신판매업신고 : 제 2020 - 경기하남 - 0419호`}<br />
                    </span>
                </p>
            </footer>
        </div>
        : <div style={{
            display: "flex", alignItems: "center", flexDirection: "column",
            overflowY: "hidden"
        }}>
            <span style={{
                fontSize: "24px", fontWeight: 500, marginTop: "12px", marginBottom: "36px"
            }}>자주하는 질문</span>
            <div style={{
                width: "75%", maxWidth: "1000px",
                overflowY: "auto"
            }}>
                {htu.map((value, index) => {
                    return (
                        <QnAItem key={`QnA_${index}`}
                            isNotMobile="OK"
                            itemValue={value}
                            outerStyle={{
                                borderBottom: index === htu.length - 1 ? "1px solid #E8E8E8" : ""
                            }} />
                    )
                })}
            </div>
        </div>
}