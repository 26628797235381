import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import moment, { min, Moment } from "moment";
import React, { CSSProperties, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MonthItem } from "../../classes/Month";
import { AdditionalWorkType, EmpPs, DayPs, PayCategory, PayInputType, PayStub, payStubConverter } from "../../classes/PayStub";
import { StaffWithId } from "../../classes/Staff";
import { fireStore } from "../../fireConfig";
import { setStaffPay, updateStaffPay } from "../../redux/reducer/staff/staff";
import { THEME_YELLOW } from "../../values/Colors";
import { PayInput } from "../../components/PayInput";
import { useBlocker } from "../../components/react-router-dom-revive";
import { Dialog } from "@mui/material"
import { calIncomeTax, commaString2Number, cutOffFuntion, parseLocale } from "../../components/Tools";
import styles from '../../css/PayDayWork.module.css'
import { ic_audio_off, ic_audio_on, ic_pay, back, ic_paper, plus, ic_arrow_down, ic_arrow_up, ic_history } from "../../images/images";
import { PaymentDatePicker } from "../../components/PaymentDatePicker";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import { useMediaQuery } from "react-responsive";
import { EnrollModal } from "../../components/EnrollModal";
import { MonthlyPaymentInput } from "../../components/MonthlyPaymentInput";

export const PayDayWork: FC = () => {
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    const [enrollModal,setEnrollModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const location = useLocation()
    const { staff, payStub, targetMonth, staffIndex, readonly } =
        location.state as {
            staff: StaffWithId,
            payStub?: PayStub,
            targetMonth: MonthItem,
            staffIndex: number,
            readonly: boolean
        }
    const payStubData = payStub?.data as DayPs | undefined
    const selectMonthToPattern = targetMonth.value.year + "-" + targetMonth.value.month
    const reduxDispatch = useDispatch();
    const { id, data, payStubObj } = staff
    const sendedPayStubList = useMemo((): { payStub: PayStub, monthItem: MonthItem }[] => {
        const payStubList: { payStub: PayStub, monthItem: MonthItem }[] = []
        for (const key in payStubObj) {
            if (Object.prototype.hasOwnProperty.call(payStubObj, key)) {
                const payStubItem = payStubObj[key];
                if (payStubItem.isSend && payStubItem.type.type === 2) {
                    var eachMoment : Moment;
                    if(key.split("-")[1]!=="0"){
                        eachMoment = moment(key, "YYYY-MM").add(1, "M")
                    }else {
                        eachMoment = moment(key+"1","YYYY-MM")
                    }
                    const text = eachMoment.format("YYYY년 MM월")
                    const monthItem: MonthItem = {
                        value: {
                            year: eachMoment.get("y"),
                            month: eachMoment.get("M")
                        },
                        text: text
                    }
                    payStubList.push({
                        payStub: payStubItem,
                        monthItem: monthItem
                    })
                }
            }
        }
        payStubList.sort(({ monthItem: { value: a } }, { monthItem: { value: b } }) => {
            if (b.year !== a.year) {
                return b.year - a.year
            } else return b.month - a.month
        })
        //console.log(payStubList)
        return payStubList
    }, [])


    const cutOffUnit: number = 10


    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const listDivRef = useRef<HTMLDivElement>(null)
    const scrollTop = useState<number>();
    const needMoreScroll = useMemo(() => {
        return listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!
    }, [scrollTop[0]])

    const [itemType, setItemType] = useState<"irregular" | "deduction" | "monthly">("monthly")
    const [newTitle, setNewTitle] = useState<string>("")
    const [addVisible, setAddVisible] = useState<boolean>(false)
    const canSavePaystub = () => {
        var state = true
        // if (paymentAmount.length < 2) {
        //     state = false
        // }
        return state
    }
    const getCurrentPayStubValue = (): PayStub => {
        return {
            type: staff.data.hireType,
            staffInfo: staff.data,
            data: {
                paymentDate: new Timestamp(paymentDate.unix(), 0),
                paymentAmount: paymentAmount,//지급액계
                additionalPayments : additionalPayments,//추가 지급 계
                deductionAmount: deductionAmount,//공제액계
                amount: amount//실수령액
            } as DayPs
        }
    }

    const setPayInRedux = (payStub: PayStub) => {
        const pushIsSend: PayStub = {
            ...payStub,
            isSend: false
        }
        reduxDispatch(setStaffPay({ id: selectMonthToPattern, payStub: pushIsSend, staffIndex: staffIndex }))
    }
    const updatePayInRedux = (payStub: PayStub) => {
        reduxDispatch(updateStaffPay({ id: selectMonthToPattern, payStub: payStub, staffIndex: staffIndex }))
    }
    const savePayDoc = async () => {
        const docRef = doc(fireStore, "staffs", id, "payStub", selectMonthToPattern).withConverter(payStubConverter)
        const getPayDoc = await getDoc(docRef)
        const currentPayStub = getCurrentPayStubValue()
        if (getPayDoc.exists()) {
            //console.log("update prograssing")
            await updateDoc(docRef, currentPayStub)
            updatePayInRedux(currentPayStub)
        } else {
            //console.log("set prograssing")
            await setDoc(docRef, { ...currentPayStub, isSend: false })
            setPayInRedux(currentPayStub)
        }
        setEnrollModal(true)
    }
    const adjustValue = (prePayStub: PayStub) => {
        const { paymentDate, paymentAmount, } = prePayStub.data as DayPs
        setPaymentDate(moment.unix(paymentDate.seconds))
        setPaymentAmount(paymentAmount)
    }

    const changePaymentsValue = (type: PayCategory, value: string | { hour: string, ratio: string }, index: number) => {
        var targetCopyList: PayInputType[] | AdditionalWorkType[]
        switch (type) {
            case "monthly":
                targetCopyList = additionalPayments.slice()
                targetCopyList[index].value = value as string
                console.log(targetCopyList)
                setMonthlyPayments(targetCopyList)
                break;
            default:
                return;
        }
    }
    const addPaymentItem = (title: string, type: "irregular" | "deduction" | "monthly") => {
        if (type === "monthly") {
            const copyList = additionalPayments.slice()
            copyList.push({
                name: `monthly_${additionalPayments.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setMonthlyPayments(copyList)
        }
    }
    const deletePaymentItem = (index: number, type: "irregular" | "deduction" | "monthly") => {
        if (type === "monthly") {
            const copyList = additionalPayments.slice()
            copyList.splice(index, 1)
            setMonthlyPayments(copyList)
        }
    }
    const openDialog = () => {
        setModalVisible(!modalVisible)
    }
    const goBack = () => {
        navigate(-1)
    }
    useBlocker(() => {
        setModalVisible(false)
        setAddVisible(false)
    }, modalVisible || addVisible)

    useEffect(() => {
        console.log("PayStub", payStub)
    }, [])

    const [paymentDate, setPaymentDate] = useState<Moment>(payStubData?.paymentDate ? moment.unix(payStubData.paymentDate.seconds) : moment())

    const [paymentAmount, setPaymentAmount] = useState<string>(payStubData?.paymentAmount ?? "")
    const [additionalPayments, setMonthlyPayments] = useState<PayInputType[]>(
        payStubData?.additionalPayments ?? []
    )
    const deductionAmount = useMemo(() => {
        var addAmount = 0
        additionalPayments.forEach((value) => {
            addAmount += commaString2Number(value.value)
        })
        var allAmount = addAmount + commaString2Number(paymentAmount)
        return cutOffFuntion(3.3, allAmount.toLocaleString(), cutOffUnit)
    }, [paymentAmount,additionalPayments])

    const amount = useMemo(() => {
        var addAmount = 0
        additionalPayments.forEach((value) => {
            addAmount += commaString2Number(value.value)
        })
        var allAmount = addAmount + commaString2Number(paymentAmount)
        console.log(addAmount,allAmount)
        const p: number = allAmount
        const d: number = commaString2Number(deductionAmount)
        return (p - d).toLocaleString()
    }, [paymentAmount, deductionAmount,additionalPayments])
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}>
            <EnrollModal
                isMobile={isMobile}
                open={enrollModal}
                onClose={() => { navigate(-1) }} />
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={modalVisible}
                onClose={() => { setModalVisible(!modalVisible) }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`지난달 내역`}</span>
                    <div style={{ height: "30px" }}>
                        {(scrollTop[0] ?? 0) >= 52 && <img src={ic_arrow_up} style={{ height: "100%" }} />}
                    </div>
                    <div ref={listDivRef}
                        style={{
                            flex: 1, width: "100%",
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "flex-start",
                            overflowY: "auto", boxSizing: "border-box"
                        }}
                        onScroll={(ev) => {
                            scrollTop[1](listDivRef.current?.scrollTop!!!)
                        }}>
                        {sendedPayStubList.map((item, index) => {
                            const { monthItem, payStub } = item
                            return (
                                <div className="pointer-cursor"
                                    key={`${monthItem.text}`} style={{
                                        flex: 1,
                                        border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        boxSizing: "border-box", padding: "12px", marginBottom: index < sendedPayStubList.length - 1 ? "12px" : undefined,
                                        width: "100%"
                                    }}
                                    onClick={() => {
                                        //console.log(item)
                                        adjustValue(payStub)
                                        setModalVisible(!modalVisible)
                                    }}>{`${monthItem.text}`}</div>
                            )
                        })}
                    </div>
                    <div style={{ height: "30px" }}>
                        {needMoreScroll >= 52 && <img src={ic_arrow_down} style={{ height: "100%" }} />}
                    </div>
                </div>
            </Dialog>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        // overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={addVisible}
                onClose={() => {
                    setAddVisible(!addVisible)
                    setNewTitle("")
                }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold", alignSelf: "center" }}>{`추가 ${itemType === "deduction" ? "공제명" : "지급명"}`}</span>
                    <input
                        style={{
                            border: `2px solid ${THEME_YELLOW}`, borderRadius: "12px", height: "48px",
                            padding: "8px",
                            boxSizing: "border-box", margin: "12px 0px 12px 0px", outlineWidth: "0px"
                        }}
                        value={newTitle}
                        onChange={(e) => {
                            setNewTitle(e.target.value)
                        }} />
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            padding: "12px",
                            borderRadius: "52px",
                            backgroundColor: newTitle ? THEME_YELLOW : "#EBEBEB",
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={async () => {
                            if (newTitle) {
                                addPaymentItem(newTitle, itemType)
                                setAddVisible(!addVisible)
                                setNewTitle("")
                            } else {
                                alert("모든 입력란을 알맞게 채워주세요")
                            }
                        }}>
                        <span style={{ color: newTitle ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                    </div>
                </div>
            </Dialog>
            {isMobile
                ?
                <div className={styles.main_ctn}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <img src={ic_pay} />
                            <span>{"급여등록"}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}>
                            <span style={{ fontSize: "12px" }}>{`${targetMonth.text}분`}</span>
                        </div>
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center"
                            }}>
                            <span className="name_deco">{staff.data.name}</span>
                            <span style={{
                                marginLeft: "8px",
                                fontSize: "14px", fontWeight: 500
                            }}>{`일용직`}</span>
                        </div>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex", flexDirection: "column"
                            }} >
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                        {/* paymentAmount */}
                        <div className={`side_margin_5per`} style={{
                            display: "flex", flexDirection: "column",
                            marginBottom: "16px"
                        }}>
                            <TitleInputUnit
                                title="기본 임금 입력"
                                outerStyle={{
                                    padding: "4px 0px"
                                }}
                                titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                                value={paymentAmount}
                                unit="원"
                                readonly={readonly}
                                onChange={(event) => {
                                    const input: string = event.target.value
                                    setPaymentAmount(parseLocale(input))
                                }} />
                            <span className="name_deco" style={{
                                fontSize: "18px", fontWeight: "bold"
                            }}>{`매월지급`}</span>
                            {additionalPayments.map((value, index) => {
                                return (
                                    <MonthlyPaymentInput
                                        key={`${value.title}_${index}`}
                                        readonly={readonly}
                                        values={value}
                                        onChange={(value) => {
                                            console.log(value)
                                            changePaymentsValue("monthly", value, index)
                                        }}
                                        onClickDelete={() => {
                                            deletePaymentItem(index, "monthly")
                                        }} />
                                )
                            })}
                            {!readonly &&
                                <div className={styles.btn_add_item_ctn}
                                    onClick={() => {
                                        setItemType("monthly")
                                        setAddVisible(!addVisible)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                    <img src={plus}
                                        style={{ height: "32px", width: "auto" }} />
                                </div>}
                        </div>
                        {/* deductions */}
                        <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column" }}>
                            <span className="name_deco"
                                style={{ fontSize: "18px" }}>{`공제항목`}</span>
                            <div className={`${styles.deduct_ctn}`}>
                                <PayInput
                                    style={{ borderWidth: "0px" }}
                                    readonly={true}
                                    title={"3.3% 원천징수"}
                                    value={deductionAmount} />
                            </div>
                        </div>
                        <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                            {/* Amount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                titleStyle={{ fontWeight: 500 }}
                                readonly={true}
                                title={"실수령액"}
                                value={amount} />
                        </div>
                    </div>

                    {!readonly &&
                        <div className={styles.foo_ctn}>
                            <div className={`pointer-cursor ${styles.btn_pre_paystub_dialog}`}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <span style={{ fontSize: "12px", fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                <img src={ic_paper} style={{ height: "28px" }} />
                            </div>
                            {/* submit btn */}
                            <div className={`pointer-cursor ${styles.btn_submit}`}
                                style={{
                                    backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                }}
                                onClick={async () => {
                                    if (canSavePaystub()) {
                                        savePayDoc()
                                    } else {
                                        alert("모든 입력란을 알맞게 채워주세요")
                                    }
                                }}>
                                <span style={{ color: canSavePaystub() ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                            </div>
                        </div>}
                </div>
                :
                <div className={styles.main_ctn}
                    style={{ maxWidth: "2400px" }}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{ paddingLeft: "2.5%" }}>
                            <img src={ic_pay} />
                            <span>{"급여등록"}</span>
                        </div>
                        {!readonly &&
                            <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                                style={{
                                    flexDirection: "row", paddingRight: "2.5%"
                                }}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <div style={{
                                    border: `1px solid ${THEME_YELLOW}`, borderRadius: "6px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", height: "36px", padding: "4px 16px"
                                }}>
                                    <span style={{ fontSize: "14px", color: THEME_YELLOW, fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                    <img src={ic_history} style={{ height: "24px" }} />
                                </div>

                            </div>}
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_2_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "space-between"
                            }}>
                            <span style={{
                                fontSize: "14px", fontWeight: 500
                            }}><span className="name_deco"
                                style={{
                                    marginRight: "8px"
                                }}>{staff.data.name}</span>{`일용직`}</span>
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                style={{ rightBoxStyle: { marginLeft: "8px",cursor:"pointer" } }}
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />

                        <div style={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex", flexDirection: "column"
                        }}>
                            <div style={{
                                overflow: "hidden",
                                display: "flex", flexDirection: "row"
                            }}>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto"
                                    }}>
                                    {/* paymentAmount */}
                                    <div className={`side_margin_5per`} style={{
                                        display: "flex", flexDirection: "column",
                                        marginBottom: "16px"
                                    }}>
                                        <TitleInputUnit
                                            title="기본 임금 입력"
                                            outerStyle={{
                                                padding: "4px 0px"
                                            }}
                                            titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                                            value={paymentAmount}
                                            unit="원"
                                            readonly={readonly}
                                            onChange={(event) => {
                                                const input: string = event.target.value
                                                setPaymentAmount(parseLocale(input))
                                            }} />
                                        <span className="name_deco" style={{
                                            fontSize: "18px", fontWeight: "bold",marginTop:"16px"
                                        }}>{`추가지급`}</span>
                                        {additionalPayments.map((value, index) => {
                                            return (
                                                <MonthlyPaymentInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        changePaymentsValue("monthly", value, index)
                                                    }}
                                                    onClickDelete={() => {
                                                        deletePaymentItem(index, "monthly")
                                                    }} />
                                            )
                                        })}
                                        {!readonly &&
                                            <div className={styles.btn_add_item_ctn}
                                                onClick={() => {
                                                    setItemType("monthly")
                                                    setAddVisible(!addVisible)
                                                }}>
                                                <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                <img src={plus}
                                                    style={{ height: "32px", width: "auto" }} />
                                            </div>}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto"
                                    }}>
                                    {/* deductions */}
                                    <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column" }}>
                                        <span className="name_deco"
                                            style={{ fontSize: "18px" }}>{`공제항목`}</span>
                                        <div className={`${styles.deduct_ctn}`}>
                                            <PayInput
                                                style={{ borderWidth: "0px" }}
                                                readonly={true}
                                                title={"3.3% 원천징수"}
                                                value={deductionAmount} />
                                        </div>
                                    </div>

                                    <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                                        {/* Amount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            titleStyle={{ fontWeight: 500 }}
                                            readonly={true}
                                            title={"실수령액"}
                                            value={amount} />
                                    </div>
                                </div>
                            </div>
                            {!readonly &&
                                <div className={styles.foo_ctn}
                                    style={{
                                        flexDirection: "row", justifyContent: "flex-end"
                                    }}>
                                    {/* submit btn */}
                                    <div className="pointer-cursor" style={{
                                        border: "1px solid #767676", borderRadius: "3px",
                                        color: "#767676",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px", marginRight: "12px"
                                    }}
                                        onClick={() => { navigate(-1) }}>취소</div>
                                    <div className="pointer-cursor" style={{
                                        backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                        color: canSavePaystub() ? "white" : "#B9B9B9",
                                        borderRadius: "3px",
                                        fontWeight: 500,
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px"
                                    }}
                                        onClick={async () => {
                                            if (canSavePaystub()) {
                                                savePayDoc()
                                            } else {
                                                alert("모든 입력란을 알맞게 채워주세요")
                                            }
                                        }}>등록</div>
                                </div>}
                        </div>
                    </div>
                </div>}
        </div>
    )
}