import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import moment, { min, Moment } from "moment";
import React, { CSSProperties, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MonthItem } from "../../classes/Month";
import { AdditionalWorkType, EmpPs, PayCategory, PayInputType, PayStub, payStubConverter } from "../../classes/PayStub";
import { StaffWithId } from "../../classes/Staff";
import { fireStore } from "../../fireConfig";
import { setStaffPay, updateStaffPay } from "../../redux/reducer/staff/staff";
import { THEME_YELLOW } from "../../values/Colors";
import { PayInput } from "../../components/PayInput";
import { useBlocker } from "../../components/react-router-dom-revive";
import { Dialog } from "@mui/material"
import { calIncomeTax, commaString2Number, cutOffFuntion, parseLocale } from "../../components/Tools";
import { MonthlyPaymentInput } from "../../components/MonthlyPaymentInput";
import { AdditionalWorkInput } from "../../components/AdditionalWorkInput";
import styles from '../../css/PayEmployee.module.css'
import { ic_pay, back, ic_paper, plus, ic_arrow_down, ic_arrow_up, ic_history } from "../../images/images";
import { PaymentDatePicker } from "../../components/PaymentDatePicker";
import { HourlyWageInput } from "../../components/HourlyWageInput";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import { useMediaQuery } from "react-responsive";
import { EnrollModal } from "../../components/EnrollModal";
import { Tax } from "../../redux/reducer/tax/tax";

export const PayEmployee: FC = () => {
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    const taxState = useSelector((state : {tax : Tax}) => state.tax)
    const [enrollModal,setEnrollModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const location = useLocation()
    const { staff, payStub, targetMonth, staffIndex, readonly } =
        location.state as {
            staff: StaffWithId,
            payStub?: PayStub,
            targetMonth: MonthItem,
            staffIndex: number,
            readonly: boolean
        }
    const [isInit,setIsInit] = useState<number>(0);
    const payStubData = payStub?.data as EmpPs | undefined
    const selectMonthToPattern = targetMonth.value.year + "-" + targetMonth.value.month
    const reduxDispatch = useDispatch();
    const { id, data, payStubObj } = staff
    const sendedPayStubList = useMemo((): { payStub: PayStub, monthItem: MonthItem }[] => {
        const payStubList: { payStub: PayStub, monthItem: MonthItem }[] = []
        for (const key in payStubObj) {
            if (Object.prototype.hasOwnProperty.call(payStubObj, key)) {
                const payStubItem = payStubObj[key];
                if (payStubItem.isSend && payStubItem.type.type === 0) {
                    var eachMoment : Moment;
                    if(key.split("-")[1]!=="0"){
                        eachMoment = moment(key, "YYYY-MM").add(1, "M")
                    }else {
                        eachMoment = moment(key+"1","YYYY-MM")
                    }
                    const text = eachMoment.format("YYYY년 MM월")
                    const monthItem: MonthItem = {
                        value: {
                            year: eachMoment.get("y"),
                            month: eachMoment.get("M")
                        },
                        text: text
                    }
                    payStubList.push({
                        payStub: payStubItem,
                        monthItem: monthItem
                    })
                }
            }
        }
        payStubList.sort(({ monthItem: { value: a } }, { monthItem: { value: b } }) => {
            if (b.year !== a.year) {
                return b.year - a.year
            } else return b.month - a.month
        })
        //console.log(payStubList)
        return payStubList
    }, [])


    const cutOffUnit: number = 10


    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const listDivRef = useRef<HTMLDivElement>(null)
    const scrollTop = useState<number>();
    const needMoreScroll = useMemo(() => {
        return listDivRef.current?.scrollHeight!!! - listDivRef.current?.clientHeight!!! - listDivRef.current?.scrollTop!!!
    }, [scrollTop[0]])

    const [newTitle, setNewTitle] = useState<string>("")
    const [addVisible, setAddVisible] = useState<boolean>(false)
    const canSavePaystub = () => {
        var state = true
        if (monthlyPayments[0].value.length < 1) {
            state = false
        }
        return state
    }
    const getCurrentPayStubValue = (): PayStub => {

        return {
            type: staff.data.hireType,
            staffInfo: staff.data,
            data: {
                paymentDate: new Timestamp(paymentDate.unix(), 0),
                workingDays: workingDays,
                workingHours: workingHours,
                monthlyPayments: monthlyPayments,//매월지급
                hourlyWage: hourlyWage,//통상시급
                additionWorks: additionWorks,//추가근로시간
                additionalWorkAllowance: additionalWorkAllowance,//연장근로수당
                irregularPayments: irregularPayments,//부정기지급
                deductionItems: deductionItems,//공제항목
                paymentAmount: paymentAmount,//지급액계
                deductionAmount: deductionAmount,//공제액계
                amount: amount//실수령액
            }
        }
    }

    const setPayInRedux = (payStub: PayStub) => {
        const pushIsSend: PayStub = {
            ...payStub,
            isSend: false
        }
        reduxDispatch(setStaffPay({ id: selectMonthToPattern, payStub: pushIsSend, staffIndex: staffIndex }))
    }
    const updatePayInRedux = (payStub: PayStub) => {
        reduxDispatch(updateStaffPay({ id: selectMonthToPattern, payStub: payStub, staffIndex: staffIndex }))
    }
    const savePayDoc = async () => {
        const docRef = doc(fireStore, "staffs", id, "payStub", selectMonthToPattern).withConverter(payStubConverter)
        const getPayDoc = await getDoc(docRef)
        const currentPayStub = getCurrentPayStubValue()
        if (getPayDoc.exists()) {
            //console.log("update prograssing")
            await updateDoc(docRef, currentPayStub)
            updatePayInRedux(currentPayStub)
        } else {
            //console.log("set prograssing")
            await setDoc(docRef, { ...currentPayStub, isSend: false })
            setPayInRedux(currentPayStub)
        }
        setEnrollModal(true)
    }
    const adjustValue = (prePayStub: PayStub) => {
        const { monthlyPayments, hourlyWage, additionWorks,
            irregularPayments, deductionItems, paymentDate, workingDays, workingHours } = prePayStub.data as EmpPs
        setPaymentDate(moment.unix(paymentDate.seconds))
        setWorkingDays(workingDays)
        setWorkingHours(workingHours)
        setMonthlyPayments(monthlyPayments)
        setHourlWage(hourlyWage)
        setAdditionWorks(additionWorks)
        setIrregularPayments(irregularPayments)
        setDeductionItems(deductionItems)
    }
    const openDialog = () => {
        setModalVisible(!modalVisible)
    }
    const goBack = () => {
        navigate(-1)
    }
    useBlocker(() => {
        setModalVisible(false)
        setAddVisible(false)
    }, modalVisible || addVisible)

    useEffect(() => {
        console.log("PayStub", payStub)
    }, [])

    const changePaymentsValue = (type: PayCategory, value: string | { hour: string, ratio: string }, index: number) => {
        var targetCopyList: PayInputType[] | AdditionalWorkType[]
        switch (type) {
            case "monthly":
                targetCopyList = monthlyPayments.slice()
                targetCopyList[index].value = value as string
                setMonthlyPayments(targetCopyList)
                break;
            case "irregular":
                targetCopyList = irregularPayments.slice()
                targetCopyList[index].value = value as string
                setIrregularPayments(targetCopyList)
                break;
            case "additionalWork":
                targetCopyList = additionWorks.slice()
                targetCopyList[index].value = value as { hour: string, ratio: string }
                setAdditionWorks(targetCopyList)
                break;
            case "deduction":
                targetCopyList = deductionItems.slice()
                targetCopyList[index].value = value as string
                setDeductionItems(targetCopyList)
                break;
            default:
                return;
        }
    }
    const [paymentDate, setPaymentDate] = useState<Moment>(payStubData?.paymentDate ? moment.unix(payStubData.paymentDate.seconds) : moment())
    const [workingDays, setWorkingDays] = useState<string>(payStubData?.workingDays ?? "")
    const [workingHours, setWorkingHours] = useState<string>(payStubData?.workingHours ?? "")
    //매월지급
    const monthlyPaymentInit = [
        {
            name: "basicPay",
            title: "기본급",
            value: ""
        },
        {
            name: "meals",
            title: "식대",
            value: ""
        },
    ]
    
    const [monthlyPayments, setMonthlyPayments] = useState<PayInputType[]>(
        payStubData?.monthlyPayments ?? monthlyPaymentInit
    )

    //추가근무시간
    const [hourlyWage, setHourlWage] = useState<string>(payStubData?.hourlyWage ?? "")
    const additionWorkInit = [
        {
            name: "extendedWork",
            title: "추가연장근로",
            value: {
                hour: "",
                ratio: "1.5"
            }
        },
        {
            name: "nightWork",
            title: "추가야간근로",
            value: {
                hour: "",
                ratio: "0.5"
            }
        },
        {
            name: "holidayWork",
            title: "추가휴일근로",
            value: {
                hour: "",
                ratio: "1.5"
            }
        },
    ]
    const [additionWorks, setAdditionWorks] = useState<AdditionalWorkType[]>(payStubData?.additionWorks ?? additionWorkInit)
    const additionalWorkAllowance =
        useMemo((): PayInputType => {
            const hourlyWageNum: number = commaString2Number(hourlyWage)
            var totalAdditionalWork: number = 0
            additionWorks.forEach(({ value: { hour, ratio } }) => {
                const hourNum: number = parseFloat(hour)
                const ratioNum: number = parseFloat(ratio)
                const cal = hourNum * ratioNum
                totalAdditionalWork += !isNaN(cal) ? cal : 0
            })
            const tolocale = parseLocale(hourlyWageNum * totalAdditionalWork)
            const result = cutOffFuntion(100, tolocale, 10)
            return {
                name: "additionalWorkAllowance",
                title: "연장근로수당",
                value: result
            }
        }, [hourlyWage, additionWorks])

    //부정기지급
    const irregularPaymentsInit = [
        {
            name: "performancePay",
            title: "성과급",
            value: ""
        },
    ]
    const [irregularPayments, setIrregularPayments] = useState<PayInputType[]>(payStubData?.irregularPayments ?? irregularPaymentsInit)
    const [itemType, setItemType] = useState<"irregular" | "deduction" | "monthly">("monthly")
    const addPaymentItem = (title: string, type: "irregular" | "deduction" | "monthly") => {
        if (type === "irregular") {
            const copyList = irregularPayments.slice()
            copyList.push({
                name: `irregular_${irregularPayments.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setIrregularPayments(copyList)
        } else if (type === "deduction") {
            const copyList = deductionItems.slice()
            copyList.push({
                name: `deduction_${deductionItems.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setDeductionItems(copyList)
        } else if (type === "monthly") {
            const copyList = monthlyPayments.slice()
            copyList.push({
                name: `monthly_${monthlyPayments.length}`,
                title: title,
                value: "",
                type: "add"
            })
            setMonthlyPayments(copyList)
        }
    }
    const deletePaymentItem = (index: number, type: "irregular" | "deduction" | "monthly") => {
        if (type === "irregular") {
            const copyList = irregularPayments.slice()
            copyList.splice(index, 1)
            setIrregularPayments(copyList)
        } else if (type === "deduction") {
            const copyList = deductionItems.slice()
            copyList.splice(index, 1)
            setDeductionItems(copyList)
        } else if (type === "monthly") {
            const copyList = monthlyPayments.slice()
            copyList.splice(index, 1)
            setMonthlyPayments(copyList)
        }
    }
    const paymentAmount = useMemo(() => {
        var amount = 0
        monthlyPayments.forEach((value) => {
            amount += commaString2Number(value.value)
        })
        irregularPayments.forEach((value) => {
            amount += commaString2Number(value.value)
        })
        amount += commaString2Number(additionalWorkAllowance.value)
        return parseLocale(amount)
    }, [monthlyPayments, irregularPayments, additionalWorkAllowance])
    const deductionInit: PayInputType[] = [
        {
            name: "incomeTax",
            title: "소득세",
            value: ""
        },
        {
            name: "localeIncomeTax",
            title: "지방소득세",
            value: ""
        },
        {
            name: "nationalPension",
            title: "국민연금",
            value: ""
        },
        {
            name: "employmentInsurance",
            title: "고용보험",
            value: ""
        },
        {
            name: "healthInsurance",
            title: "건강보험",
            value: ""
        },
        {
            name: "longTermCareInsurance",
            title: "장기요양보험",
            value: ""
        },
    ]
    const [deductionItems, setDeductionItems] = useState<PayInputType[]>(payStubData?.deductionItems ?? deductionInit)

    
    const deductionAmount = useMemo(() => {
        var amount = 0
        deductionItems.forEach((value) => {
            amount += commaString2Number(value.value)
        })
        return parseLocale(amount)
    }, [deductionItems])
    const [amount, setAmount] = useState<string>("")
    const amountObserver = useMemo(() => {
        const p: number = commaString2Number(paymentAmount)
        const d: number = commaString2Number(deductionAmount)
        setAmount((p - d).toLocaleString())
    }, [paymentAmount, deductionAmount, additionalWorkAllowance])
    const normalObserver = useMemo(() => {
        const normal: number = commaString2Number(monthlyPayments[0].value)
        const meals: number = commaString2Number(monthlyPayments[1].value)
        const validMeals = meals > 200_000 ? meals - 200_000 : 0
        const awa: number = commaString2Number(additionalWorkAllowance.value)
        const sum: string = parseLocale(normal + awa + validMeals)

        //0.소득세,1.지방소득세,2.국민연금,3.고용보험,4.건강보험,5.장기요양보험
        console.log("Try",isInit)
        if(isInit < 2) {
            console.log("Stuck")
            setIsInit(isInit+1)
            // setDeductionItems(deductionInit)
            return
        }
        console.log("Pass : ",deductionItems)
        //0.소득세,1.지방소득세,2.국민연금,3.고용보험,4.건강보험,5.장기요양보험
        const deductionItemCopy = deductionItems.slice()
        //IncomeTax 소득세
        const incomeTax = calIncomeTax(sum, staff.data.deduction, cutOffUnit)
        deductionItemCopy[0].value = incomeTax
        deductionItemCopy[1].value = cutOffFuntion(10, incomeTax, cutOffUnit)
        deductionItemCopy[2].value = cutOffFuntion(taxState.nationalPension, sum, cutOffUnit)
        deductionItemCopy[3].value = cutOffFuntion(taxState.employmentInsurance, sum, cutOffUnit)
        const healthInsurance = cutOffFuntion(taxState.healthInsurance, sum, cutOffUnit)
        deductionItemCopy[4].value = healthInsurance
        deductionItemCopy[5].value = cutOffFuntion(taxState.longtermCareInsurance, healthInsurance, cutOffUnit)
        setDeductionItems(deductionItemCopy)
    }, [monthlyPayments, additionalWorkAllowance])
    const calcReverse = (amount: string) => {
        const getBasixTaxWithOutIncome = (normal: number): number => {
            const income = commaString2Number(calIncomeTax(parseLocale(normal), staff.data.deduction, cutOffUnit))
            const localIncome = Math.floor((income * 10 * 1000) / (1000 * 100) / cutOffUnit) * cutOffUnit
            const tax_1 = commaString2Number(cutOffFuntion(taxState.nationalPension, parseLocale(normal), cutOffUnit)) //
            const tax_2 = commaString2Number(cutOffFuntion(taxState.employmentInsurance, parseLocale(normal), cutOffUnit)) //
            const tax_3 = (cutOffFuntion(taxState.healthInsurance, parseLocale(normal), cutOffUnit)) //
            const tax_4 = commaString2Number(cutOffFuntion(taxState.longtermCareInsurance, tax_3, cutOffUnit)) //
            const taxSum = income + localIncome + tax_1 + tax_2 + commaString2Number(tax_3) + tax_4
            return taxSum
        }
        const amountNumb = commaString2Number(amount)
        const y: number = commaString2Number(amount) - 200_000
        var add = 0
        monthlyPayments.forEach((value, index) => {
            if (index > 1) {
                add += commaString2Number(value.value)
            }
        })
        var substract = 0
        deductionItems.forEach((value, index) => {
            if (index > 5) {
                substract += commaString2Number(value.value)
            }
        })
        //소득세가 없다고 생각하고 구한 기본급
        var normal = Math.floor((y) / 0.90875)
        if (normal > 0) {
            setIrregularPayments(irregularPaymentsInit.slice())
            setDeductionItems(deductionInit.slice())
            setHourlWage("")
            setAdditionWorks(additionWorkInit.slice())
            //위 기본급일 경우 구한 소득세 당연히 안맞음. 근사값을 구하기 위함
            //소득세 초기값
            var tempNormal = 0
            var unit = 1_000_000
            while (1) {
                // while(true){
                // console.log("--------------------------")
                const paymentSum = normal
                const taxSum = getBasixTaxWithOutIncome(paymentSum)
                const meals = 200_000
                const temptAmount = paymentSum + meals - taxSum
                // console.log("amountNumb : ", amountNumb)
                // console.log("Loop Y(temptAmount) : ", temptAmount)
                // console.log("taxSum : ", taxSum)
                tempNormal = normal
                if (temptAmount === amountNumb) {
                    break;
                }
                if (amountNumb > temptAmount) {
                    // console.log(`${amountNumb} - ${temptAmount} = ${amountNumb - temptAmount}< ${unit}`)
                    if ((amountNumb - temptAmount) < unit) {
                        // console.log(`y - amount < ${unit}`)
                        if (unit > 1)
                            unit /= 10
                        else {
                            normal += unit
                        }
                    } else
                        normal += unit
                } else {
                    // console.log(`${temptAmount} - ${amountNumb} = ${temptAmount - amountNumb} < ${unit}`)
                    if ((temptAmount - amountNumb) < unit) {
                        normal -= unit
                    } else {
                        // console.log(`temptAmount - amountNumb < ${unit}`)
                        if (unit > 1)
                            unit /= 10
                        else {
                            normal -= unit
                        }
                    }
                }
                // console.log("tempNormal : ", tempNormal, "normal : ", normal)
            }
            // console.log(normal)
            setMonthlyPayments([
                {
                    name: "basicPay",
                    title: "기본급",
                    value: parseLocale(normal)
                },
                {
                    name: "meals",
                    title: "식대",
                    value: parseLocale(200_000)
                },
            ])
        } else {
            console.log("No Tax Y : ", y, amount)
            setMonthlyPayments([
                {
                    name: "basicPay",
                    title: "기본급",
                    value: ""
                },
                {
                    name: "meals",
                    title: "식대",
                    value: parseLocale(amount)
                },
            ])
        }
        // console.log("add",add)
        // console.log("substract",substract)
        // console.log("x : ",x,"a : ",a)
        // console.log("x + a : ",x+a)
        // console.log(tt)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}>
            <EnrollModal
                isMobile={isMobile}
                open={enrollModal}
                onClose={() => { navigate(-1) }} />
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={modalVisible}
                onClose={() => { setModalVisible(!modalVisible) }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`지난달 내역`}</span>
                    <div style={{ height: "30px" }}>
                        {(scrollTop[0] ?? 0) >= 52 && <img src={ic_arrow_up} style={{ height: "100%" }} />}
                    </div>
                    <div ref={listDivRef}
                        style={{
                            flex: 1, width: "100%",
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "flex-start",
                            overflowY: "auto", boxSizing: "border-box"
                        }}
                        onScroll={(ev) => {
                            scrollTop[1](listDivRef.current?.scrollTop!!!)
                        }}>
                        {sendedPayStubList.map((item, index) => {
                            const { monthItem, payStub } = item
                            return (
                                <div className="pointer-cursor"
                                    key={`${monthItem.text}`} style={{
                                        flex: 1,
                                        border: `2px solid ${THEME_YELLOW}`, borderRadius: "8px",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        boxSizing: "border-box", padding: "12px", marginBottom: index < sendedPayStubList.length - 1 ? "12px" : undefined,
                                        width: "100%"
                                    }}
                                    onClick={() => {
                                        //console.log(item)
                                        adjustValue(payStub)
                                        setModalVisible(!modalVisible)
                                    }}>{`${monthItem.text}`}</div>
                            )
                        })}
                    </div>
                    <div style={{ height: "30px" }}>
                        {needMoreScroll >= 52 && <img src={ic_arrow_down} style={{ height: "100%" }} />}
                    </div>
                </div>
            </Dialog>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none", borderRadius: "0px",
                        flexDirection: "row", justifyContent: "center",
                        // overflowY: "auto"
                    }
                }}
                fullWidth={true}
                open={addVisible}
                onClose={() => {
                    setAddVisible(!addVisible)
                    setNewTitle("")
                }}>
                <div style={{
                    borderRadius: "24px",
                    padding: "24px",
                    width: "100%", maxWidth: "300px",
                    boxSizing: "border-box",
                    display: "flex", flexDirection: "column",
                    fontFamily: "NotoSans",
                    backgroundColor: "white"
                }}>
                    <span style={{ fontSize: "18px", fontWeight: "bold", alignSelf: "center" }}>{`추가 ${itemType === "deduction" ? "공제명" : "지급명"}`}</span>
                    <input
                        style={{
                            border: `2px solid ${THEME_YELLOW}`, borderRadius: "12px", height: "48px",
                            padding: "8px",
                            boxSizing: "border-box", margin: "12px 0px 12px 0px", outlineWidth: "0px"
                        }}
                        value={newTitle}
                        onChange={(e) => {
                            setNewTitle(e.target.value)
                        }} />
                    <div className="pointer-cursor"
                        style={{
                            boxSizing: "border-box",
                            padding: "12px",
                            borderRadius: "52px",
                            backgroundColor: newTitle ? THEME_YELLOW : "#EBEBEB",
                            display: "flex", justifyContent: "center"
                        }}
                        onClick={async () => {
                            if (newTitle) {
                                addPaymentItem(newTitle, itemType)
                                setAddVisible(!addVisible)
                                setNewTitle("")
                            } else {
                                alert("모든 입력란을 알맞게 채워주세요")
                            }
                        }}>
                        <span style={{ color: newTitle ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                    </div>
                </div>
            </Dialog>
            {isMobile
                ? <div className={styles.main_ctn}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <img src={ic_pay} />
                            <span>{"급여등록"}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}>
                            <span style={{ fontSize: "12px" }}>{`${targetMonth.text}분`}</span>
                        </div>
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center"
                            }}>
                            <span className="name_deco">{staff.data.name}</span>
                            <span style={{
                                marginLeft: "8px",
                                fontSize: "14px", fontWeight: 500
                            }}>{`직원`}</span>
                        </div>
                        <div className={`side_margin_5per`}
                            style={{
                                display: "flex", flexDirection: "column"
                            }} >
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                            {/* Working Days */}
                            <TitleInputUnit
                                title="근로일수"
                                titleStyle={{ minWidth: "100px" }}
                                value={workingDays}
                                unit="일"
                                readonly={readonly}
                                type="number"
                                onChange={(event) => {
                                    setWorkingDays(event.target.value)
                                }} />
                            {/* Working Days */}
                            <TitleInputUnit
                                title="근로시간"
                                titleStyle={{ minWidth: "100px" }}
                                value={workingHours}
                                unit="시간"
                                readonly={readonly}
                                type="number"
                                onChange={(event) => {
                                    setWorkingHours(event.target.value)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />
                        {/* monthlyPayment */}
                        <div className={`side_margin_5per`} style={{
                            display: "flex", flexDirection: "column",
                        }}>
                            <span className="name_deco" style={{
                                fontSize: "18px", fontWeight: "bold"
                            }}>{`매월지급`}</span>
                            {monthlyPayments.map((value, index) => {
                                return (
                                    <MonthlyPaymentInput
                                        key={`${value.title}_${index}`}
                                        readonly={readonly}
                                        values={value}
                                        onChange={(value) => {
                                            changePaymentsValue("monthly", value, index)
                                        }}
                                        onClickDelete={() => {
                                            deletePaymentItem(index, "monthly")
                                        }} />
                                )
                            })}
                            {!readonly &&
                                <div className={styles.btn_add_item_ctn}
                                    onClick={() => {
                                        setItemType("monthly")
                                        setAddVisible(!addVisible)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                    <img src={plus}
                                        style={{ height: "32px", width: "auto" }} />
                                </div>}
                            <PayInput
                                readonly={true}
                                title={additionalWorkAllowance.title}
                                value={additionalWorkAllowance.value} />
                            <span style={{
                                fontSize: "12px", color: THEME_YELLOW
                            }}>{`아래 추가입력에 따라 자동계산 됩니다.`}</span>
                            <span style={{
                                fontWeight: 500, marginTop: "16px"
                            }}>{`추가입력`}</span>
                            <HourlyWageInput
                                readonly={readonly}
                                value={hourlyWage}
                                onChange={(event) => {
                                    const parseInput = parseLocale(event.target.value)
                                    setHourlWage(parseInput)
                                }} />
                            {additionWorks.map((value, index) => {
                                return (
                                    <AdditionalWorkInput
                                        key={`${value.title}_${index}`}
                                        readonly={readonly}
                                        values={value}
                                        onChange={(value) => {
                                            changePaymentsValue("additionalWork", value, index)
                                        }} />
                                )
                            })}
                        </div>
                        <div className={`side_margin_5per`} style={{
                            marginTop: "16px"
                        }}>
                            <span className="name_deco" style={{
                                fontSize: "18px", fontWeight: "bold"
                            }}>{`부정기지급`}</span>
                            {irregularPayments.map((value, index) => {
                                return (
                                    <MonthlyPaymentInput
                                        key={`${value.title}_${index}`}
                                        readonly={readonly}
                                        values={value}
                                        onChange={(value) => {
                                            changePaymentsValue("irregular", value, index)
                                        }}
                                        onClickDelete={() => {
                                            deletePaymentItem(index, "irregular")
                                        }} />
                                )
                            })}
                            {!readonly &&
                                <div className={styles.btn_add_item_ctn}
                                    onClick={() => {
                                        setItemType("irregular")
                                        setAddVisible(!addVisible)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                    <img src={plus}
                                        style={{ height: "32px", width: "auto" }} />
                                </div>}
                        </div>
                        {/* deductions */}
                        <span className="name_deco side_margin_5per"
                            style={{
                                marginTop: "16px", fontSize: "18px",
                            }}>{`공제항목`}</span>
                        <div className={`side_margin_5per ${styles.deduct_ctn}`}>
                            {deductionItems.map((value, index) => {
                                return (
                                    <MonthlyPaymentInput
                                        key={`${value.title}_${index}`}
                                        readonly={readonly}
                                        values={value}
                                        onChange={(value) => {
                                            changePaymentsValue("deduction", value, index)
                                        }}
                                        onClickDelete={() => {
                                            deletePaymentItem(index, "deduction")
                                        }} />
                                )
                            })}
                            {!readonly &&
                                <div className={styles.btn_add_item_ctn}
                                    onClick={() => {
                                        setItemType("deduction")
                                        setAddVisible(!addVisible)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                    <img src={plus}
                                        style={{ height: "32px", width: "auto" }} />
                                </div>}
                        </div>
                        <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                            {/* PaymentAmount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={true}
                                title={"지급액계"}
                                value={paymentAmount}
                                onChange={() => { }} />
                            {/* DeductionAmount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={true}
                                title={"공제액계"}
                                value={deductionAmount}
                                onChange={() => { }} />
                            {/* Amount */}
                            <PayInput
                                style={{ borderWidth: "0px" }}
                                readonly={readonly}
                                title={"실수령액"}
                                value={amount}
                                onChange={(e) => {
                                    const inputValue: string = e.target.value
                                    setAmount(parseLocale(inputValue))
                                    calcReverse(inputValue)
                                }} />
                        </div>
                    </div>

                    {!readonly &&
                        <div className={styles.foo_ctn}>
                            <div className={`pointer-cursor ${styles.btn_pre_paystub_dialog}`}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <span style={{ fontSize: "12px", fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                <img src={ic_paper} style={{ height: "28px" }} />
                            </div>
                            {/* submit btn */}
                            <div className={`pointer-cursor ${styles.btn_submit}`}
                                style={{
                                    backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                }}
                                onClick={async () => {
                                    if (canSavePaystub()) {
                                        console.log(monthlyPayments)
                                        savePayDoc()
                                    } else {
                                        alert("모든 입력란을 알맞게 채워주세요")
                                    }
                                }}>
                                <span style={{ color: canSavePaystub() ? "white" : "#B9B9B9", fontWeight: "bold" }}>{"저장"}</span>
                            </div>
                        </div>}
                </div>
                :
                <div className={styles.main_ctn}
                    style={{ maxWidth: "2400px" }}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{ paddingLeft: "2.5%" }}>
                            <img src={ic_pay} />
                            <span style={{ fontWeight: "bold" }}>{"급여등록"}</span>
                        </div>
                        {!readonly &&
                            <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                                style={{
                                    flexDirection: "row", paddingRight: "2.5%"
                                }}
                                onClick={() => {
                                    if (sendedPayStubList.length)
                                        openDialog()
                                    else
                                        alert("이전 내역이 없습니다")
                                }}>
                                <div style={{
                                    border: `1px solid ${THEME_YELLOW}`, borderRadius: "6px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    boxSizing: "border-box", height: "36px", padding: "4px 16px"
                                }}>
                                    <span style={{ fontSize: "14px", color: THEME_YELLOW, fontWeight: 500 }}>{`지난달 내역 불러오기`}</span>
                                    <img src={ic_history} style={{ height: "24px" }} />
                                </div>

                            </div>}
                    </div>
                    <div className={styles.body_ctn}>
                        <div className={`side_margin_2_5per`}
                            style={{
                                display: "flex",
                                boxSizing: "border-box",
                                alignItems: "center", justifyContent: "space-between"
                            }}>
                            <span style={{
                                fontSize: "14px", fontWeight: 500
                            }}><span className="name_deco"
                                style={{
                                    marginRight: "8px"
                                }}>{staff.data.name}</span>{`직원`}</span>
                            {/* PaymentDatePicker */}
                            <PaymentDatePicker
                                style={{ rightBoxStyle: { marginLeft: "8px",cursor:"pointer" } }}
                                value={paymentDate}
                                onChange={(moment) => {
                                    setPaymentDate(moment)
                                }} />
                        </div>
                        <div style={{ minHeight: 3, backgroundColor: "#F5F5F5", margin: "16px 0px" }} />

                        <div style={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex", flexDirection: "column"
                        }}>
                            <div style={{
                                overflow: "hidden",
                                display: "flex", flexDirection: "row",
                            }}>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto",
                                        // scrollbarWidth: ""
                                    }}>
                                    <div className={`side_margin_5per`}
                                        style={{
                                            marginTop: "16px", marginBottom: "16px",
                                            display: "flex", flexDirection: "column"
                                        }} >
                                        {/* Working Days */}
                                        <TitleInputUnit
                                            title="근로일수"
                                            titleStyle={{ minWidth: "100px" }}
                                            value={workingDays}
                                            unit="일"
                                            readonly={readonly}
                                            type="number"
                                            onChange={(event) => {
                                                setWorkingDays(event.target.value)
                                            }} />
                                        {/* Working Days */}
                                        <TitleInputUnit
                                            title="근로시간"
                                            titleStyle={{ minWidth: "100px" }}
                                            value={workingHours}
                                            unit="시간"
                                            readonly={readonly}
                                            type="number"
                                            onChange={(event) => {
                                                setWorkingHours(event.target.value)
                                            }} />
                                    </div>
                                    {/* monthlyPayment */}
                                    <div className={`side_margin_5per`} style={{
                                        display: "flex", flexDirection: "column",
                                    }}>
                                        <span className="name_deco" style={{
                                            fontSize: "18px", fontWeight: "bold"
                                        }}>{`매월지급`}</span>
                                        {monthlyPayments.map((value, index) => {
                                            return (
                                                <MonthlyPaymentInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        changePaymentsValue("monthly", value, index)
                                                    }}
                                                    onClickDelete={() => {
                                                        deletePaymentItem(index, "monthly")
                                                    }} />
                                            )
                                        })}
                                        {!readonly &&
                                            <div className={styles.btn_add_item_ctn}
                                                onClick={() => {
                                                    setItemType("monthly")
                                                    setAddVisible(!addVisible)
                                                }}>
                                                <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                <img src={plus}
                                                    style={{ height: "32px", width: "auto" }} />
                                            </div>}
                                        <PayInput
                                            readonly={true}
                                            title={additionalWorkAllowance.title}
                                            value={additionalWorkAllowance.value} />
                                        <span style={{
                                            fontSize: "12px", color: THEME_YELLOW
                                        }}>{`아래 추가입력에 따라 자동계산 됩니다.`}</span>
                                        <span style={{
                                            fontWeight: 500, marginTop: "16px"
                                        }}>{`추가입력`}</span>
                                        <HourlyWageInput
                                            readonly={readonly}
                                            value={hourlyWage}
                                            onChange={(event) => {
                                                const parseInput = parseLocale(event.target.value)
                                                setHourlWage(parseInput)
                                            }} />
                                        {additionWorks.map((value, index) => {
                                            return (
                                                <AdditionalWorkInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        changePaymentsValue("additionalWork", value, index)
                                                    }} />
                                            )
                                        })}
                                    </div>

                                    <div className={`side_margin_5per`} style={{
                                        marginTop: "16px"
                                    }}>
                                        <span className="name_deco" style={{
                                            fontSize: "18px", fontWeight: "bold"
                                        }}>{`부정기지급`}</span>
                                        {irregularPayments.map((value, index) => {
                                            return (
                                                <MonthlyPaymentInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        changePaymentsValue("irregular", value, index)
                                                    }}
                                                    onClickDelete={() => {
                                                        deletePaymentItem(index, "irregular")
                                                    }} />
                                            )
                                        })}
                                        {!readonly &&
                                            <div className={styles.btn_add_item_ctn}
                                                onClick={() => {
                                                    setItemType("irregular")
                                                    setAddVisible(!addVisible)
                                                }}>
                                                <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                <img src={plus}
                                                    style={{ height: "32px", width: "auto" }} />
                                            </div>}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex", flexDirection: "column",
                                        boxSizing: "border-box", borderRight: "1px solid #E0E0E0",
                                        overflowY: "auto"
                                    }}>
                                    {/* deductions */}
                                    <span className="name_deco side_margin_5per"
                                        style={{
                                            marginTop: "16px", fontSize: "18px",
                                        }}>{`공제항목`}</span>
                                    <div className={`side_margin_5per ${styles.deduct_ctn}`}>
                                        {deductionItems.map((value, index) => {
                                            return (
                                                <MonthlyPaymentInput
                                                    key={`${value.title}_${index}`}
                                                    readonly={readonly}
                                                    values={value}
                                                    onChange={(value) => {
                                                        changePaymentsValue("deduction", value, index)
                                                    }}
                                                    onClickDelete={() => {
                                                        deletePaymentItem(index, "deduction")
                                                    }} />
                                            )
                                        })}
                                        {!readonly &&
                                            <div className={styles.btn_add_item_ctn}
                                                onClick={() => {
                                                    setItemType("deduction")
                                                    setAddVisible(!addVisible)
                                                }}>
                                                <span style={{ fontFamily: "Notosans", fontWeight: "normal", fontSize: "14px" }}>항목추가</span>
                                                <img src={plus}
                                                    style={{ height: "32px", width: "auto" }} />
                                            </div>}
                                    </div>
                                    <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                                        {/* PaymentAmount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={true}
                                            title={"지급액계"}
                                            value={paymentAmount}
                                            onChange={() => { }} />
                                        {/* DeductionAmount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={true}
                                            title={"공제액계"}
                                            value={deductionAmount}
                                            onChange={() => { }} />
                                        {/* Amount */}
                                        <PayInput
                                            style={{ borderWidth: "0px" }}
                                            readonly={readonly}
                                            title={"실수령액"}
                                            value={amount}
                                            onChange={(e) => {
                                                const inputValue: string = e.target.value
                                                setAmount(parseLocale(inputValue))
                                                calcReverse(inputValue)
                                            }} />
                                    </div>
                                </div>
                            </div>
                            {!readonly &&
                                <div className={styles.foo_ctn}
                                    style={{
                                        flexDirection: "row", justifyContent: "flex-end"
                                    }}>
                                    {/* submit btn */}
                                    <div className="pointer-cursor" style={{
                                        border: "1px solid #767676", borderRadius: "3px",
                                        color: "#767676",
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px", marginRight: "12px"
                                    }}
                                        onClick={() => { navigate(-1) }}>취소</div>
                                    <div className="pointer-cursor" style={{
                                        backgroundColor: canSavePaystub() ? THEME_YELLOW : "#EBEBEB",
                                        color: canSavePaystub() ? "white" : "#B9B9B9",
                                        borderRadius: "3px",
                                        fontWeight: 500,
                                        display: "flex", alignItems: "center", justifyContent: "center",
                                        height: "42px", width: "100px"
                                    }}
                                        onClick={async () => {
                                            if (canSavePaystub()) {
                                                console.log(monthlyPayments)
                                                savePayDoc()
                                            } else {
                                                alert("모든 입력란을 알맞게 채워주세요")
                                            }
                                        }}>등록</div>
                                </div>}
                        </div>
                    </div>
                </div>}
        </div>
    )
}