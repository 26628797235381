import { BannerWithId } from "../../../classes/Banner";
import {CHANGE_CURRENT_BANNER} from "./serveBanner.type";

export interface ServeBannerObj {
    0: BannerWithId[]
    1: BannerWithId[]
}

const initialState: ServeBannerObj = {
    0: [],
    1: [],
}

export const changeCurrentBanner = (data: ServeBannerObj) => {
    return {
        type: CHANGE_CURRENT_BANNER,
        payload: data
    }
}
export const serveBannerReducer = (state: ServeBannerObj = initialState, action: { type: string, payload?: any }): ServeBannerObj => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case CHANGE_CURRENT_BANNER:
            return action.payload;
        default:
            break;
    }
    return state
}