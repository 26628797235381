import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import React, {FC, useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styles from '../../css/AdminTerms.module.css'
import { fireStore } from "../../fireConfig"
import { ic_upload } from "../../images/images"
import { saveTaxValue, Tax } from "../../redux/reducer/tax/tax"
import { GRAY, THEME_YELLOW } from "../../values/Colors"
import ReactQuill,{Quill} from 'react-quill';
import { getConsentItem } from "../../functions/getConsentItem"

export const AdminTerms : FC = () => {
    const navigate = useNavigate()

    // const quillRef = useRef(null)
    const [term, setTerm] = useState("");
    const [privacy, setPrivacy] = useState("");

    const goBack = () =>{
        navigate(-1)
    }
    useEffect(()=>{
        getConsentItems()
    },[])

    const getConsentItems = async() => {
        setTerm(await getConsentItem("term"))
        setPrivacy(await getConsentItem("privacy"))
    }
    const updateConsentItem = async (id : "term"|"privacy") => {
        try {
            const termDocRef = doc(fireStore, "consent-items", id)
            const updateBody = {
                htmlValue: id === "term" ? term : privacy
            }
            if ((await getDoc(termDocRef)).exists()) {
                await updateDoc(termDocRef, updateBody)
            } else {
                await setDoc(termDocRef, updateBody)
            }
            alert("수정되었습니다.")
        } catch (error) {
            console.log(error)
            alert("수정 중 오류가 발생하였습니다.")
        }

    }
    return (
        <div className="parent-div-per">
            <div className={styles.main_ctn}
                style={{
                    padding: "30px 2.5%",
                    display: "flex",
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box"
                }}>
                <div style={{
                    display: "flex", flex: 1,
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box", padding: "12px 0px",
                    overflow:"hidden"
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row",
                        justifyContent: "space-between", alignItems: "center",
                        fontSize: "14px", marginBottom: "12px",
                    }}>
                        <span style={{
                            fontSize: "22px", fontWeight: 500,
                        }}>이용약관</span>
                        <div style={{
                            display: "flex", flexDirection: "row"
                        }}>
                            <div style={{
                                borderRadius: "6px",
                                padding: "10px 37px", display: "flex", alignItems: "center",
                                color: "#757575", border: "1px solid #757575",
                                marginRight: "14px",
                                cursor: "pointer"
                            }} onClick={goBack}>
                                <span >{"취소"}</span>
                            </div>
                            <div style={{
                                backgroundColor: THEME_YELLOW, borderRadius: "6px",
                                padding: "10px 37px", display: "flex", alignItems: "center",
                                color: "white", fontWeight: 500,
                                cursor: "pointer"
                            }} onClick={() => {
                                updateConsentItem("term")
                            }}>
                                <span >{"수정"}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        flex:1,
                        display:"flex",overflowY:"hidden"
                    }}>
                        <ReactQuill theme="snow"
                            // ref={quillRef}
                            style={{
                                display: "flex", flexDirection: "column",
                                flex: 1,overflowY: "hidden"
                            }}
                            placeholder="이용약관"
                            modules={{
                                toolbar: [
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                ],
                            }} value={term} onChange={(value) => {
                                setTerm(value)
                            }} />
                    </div>
                </div>
                <div style={{
                        display: "flex", flex: 1,
                    overflowY: "hidden", flexDirection: "column",
                    boxSizing: "border-box", padding: "12px 0px",
                    overflow:"hidden"
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row",
                        justifyContent: "space-between", alignItems: "center",
                        fontSize: "14px", marginBottom: "12px",
                    }}>
                        <span style={{
                            fontSize: "22px", fontWeight: 500,
                        }}>개인 정보 취급 방침</span>
                        <div style={{
                            display: "flex", flexDirection: "row"
                        }}>
                            <div style={{
                                borderRadius: "6px",
                                padding: "10px 37px", display: "flex", alignItems: "center",
                                color: "#757575", border: "1px solid #757575",
                                marginRight: "14px",
                                cursor: "pointer"
                            }} onClick={goBack}>
                                <span >{"취소"}</span>
                            </div>
                            <div style={{
                                backgroundColor: THEME_YELLOW, borderRadius: "6px",
                                padding: "10px 37px", display: "flex", alignItems: "center",
                                color: "white", fontWeight: 500,
                                cursor: "pointer"
                            }} onClick={() => {
                                updateConsentItem("privacy")
                            }}>
                                <span >{"수정"}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        flex: 1, display: "flex",
                        overflowY:"hidden"
                    }}>
                        <ReactQuill theme="snow"
                            // ref={quillRef}
                            style={{
                                display: "flex", flexDirection: "column",
                                flex:1,overflowY:"hidden"
                            }}
                            placeholder="개인 정보 취급 방침"
                            modules={{
                                toolbar: [
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                ],
                            }} value={privacy} onChange={(value) => {
                                setPrivacy(value)
                            }} />
                    </div>
                </div>
            </div>
        </div>
    )
}