import React , { FC, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { PayStub } from "../classes/PayStub";
import { StaffWithId } from "../classes/Staff";
import { DayWorkView } from "./PayViewPages/DayWorkView";
import { EmployeeView } from "./PayViewPages/EmployeeView";
import { PartTimeView } from "./PayViewPages/PartTimeView";
// import { PayPartTime } from "./PayPartTime";
export const PayViewRouter: FC = () => {
    const location = useLocation()
    const { payStub } =
        location.state as {
            payStub: PayStub,
        }
    const { type } = payStub.type
    
    if (type === 0) {
        return <EmployeeView />
    } else if (type === 1) {
        return <PartTimeView />
    } else {
        return <DayWorkView/>
    }
}
