import { addDoc, arrayUnion, collection, deleteDoc, doc, DocumentReference, DocumentSnapshot, getDocs, orderBy, query, Timestamp, updateDoc } from "firebase/firestore";
import { Dispatch } from "react";
import { QnA } from "../classes/QnA";
import { fireStore } from "../fireConfig";
import { saveQnARefList } from "../redux/reducer/qna/qna";

export const pushQnA = async (qna : QnA) => {
    const qnaCol = collection(fireStore,"qna");
    return await addDoc(qnaCol,qna) as DocumentReference<QnA> 
}

export const updateQnA = async (id : string,
    modiValue : {
    questionTitleHtml: string,
    questionContentsHtml: string, 
})=>{
    const qnaDoc = doc(fireStore,`qna/${id}`) as DocumentReference<QnA>
    const pushModiTime = arrayUnion(Timestamp.now())
    await updateDoc(qnaDoc,{
        ...modiValue,
        modifyTime : pushModiTime
    })
}
export const pushAnswer = async (id : string,
    answer : {
        answerHtml : string
        answerTime : Timestamp,
    })=>{
        const qnaDoc = doc(fireStore,`qna/${id}`) as DocumentReference<QnA>
        await updateDoc(qnaDoc,{
            answer : answer
        })
}
export const deleteAnswer = async (id: string) => {
    const qnaDoc = doc(fireStore, `qna/${id}`) as DocumentReference<QnA>
    try {
        await deleteDoc(qnaDoc)
    } catch (error) {
        return
    }
    return qnaDoc
    
}
export const getAllOfQnARef = async (reduxDispatch : Dispatch<any>) => {
    const qnaCol = collection(fireStore, "qna");
    const qnaQuery = query(qnaCol,orderBy("createTime","desc"))
    reduxDispatch(saveQnARefList((await getDocs(qnaQuery)).docs.map(snapshot=>{
        return snapshot.ref as DocumentReference<QnA>
    })))
}