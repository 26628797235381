import { collection, doc, getDoc, getDocs, orderBy, query, where } from "@firebase/firestore";
import moment from "moment";
import React, { CSSProperties, Dispatch, FC, useEffect } from "react";
import { MonthItem, MonthSet, MonthUnit } from "../classes/Month";
import { PayStub, payStubConverter } from "../classes/PayStub";
import { staffConverter, StaffWithId } from "../classes/Staff";
import { User, userConverter, UserWithId } from "../classes/User";
import { fireAuth, fireStore } from "../fireConfig";
import { back, menu } from "../images/images";
import { saveBanners } from "../redux/reducer/banners/banners";
import { saveHtu } from "../redux/reducer/howtouse/howtouse";
import { getMonthList } from "../redux/reducer/month-set/month-set";
import { getStaffList } from "../redux/reducer/staff/staff";
import { updateUser } from "../redux/reducer/user/user";
import { FIVE_PER } from "../values/strings";
import { getBannerList, getHowToUses, getNoticeList, getTaxValue } from "./Tools";
import { saveNotice } from "../redux/reducer/notice/notice";
import { saveTaxValue } from "../redux/reducer/tax/tax";
import { getAllOfQnARef } from "../functions/firebaseTools";

interface ReduxState {
    user: UserWithId;
    staff: StaffWithId[];
    monthSet: MonthSet;
}
export const GetInitValue = async (
        uid: string,
        reduxDispatch: Dispatch<any>,
        reduxState: ReduxState
    ) => {
        await reduxUpdateUser(uid, reduxDispatch)
        await saveStaffList(reduxState,reduxDispatch)
        await saveNoticeList(reduxDispatch)
        await getAllOfQnARef(reduxDispatch)
    }
const saveNoticeList = async (reduxDispatch: Dispatch<any>)=>{
    const noticeList = await getNoticeList()
    reduxDispatch(saveNotice(noticeList))
}
export const reduxUpdateUser = async (uid: string, reduxDispatch: Dispatch<any>) => {
    const userDocRef = doc(fireStore, "users", uid).withConverter(userConverter)
    const userDoc = (await getDoc(userDocRef)).data() ?? new User()
    reduxDispatch(updateUser({
        data: userDoc,
        id: uid
    }))
}
const saveStaffList = async (reduxState: ReduxState,reduxDispatch: Dispatch<any>): Promise<void> => {
    // if (reduxState.staff.length < 1) {
        const staffList: StaffWithId[] = await getStaffListFromFireStore()
        reduxDispatch(getStaffList(staffList))
    // }
    // if (reduxState.monthSet.monthList.length < 1) {
        const monthList = await getMonthListFromFirstPaymentDate()
        reduxDispatch(getMonthList(monthList))
    // }
}
const getMonthListFromFirstPaymentDate = async () : Promise<MonthItem[]>  => {
    const uid = fireAuth.currentUser!!!.uid
    const docRef = doc(fireStore,"users",uid).withConverter(userConverter)
    // const firstPaymentDate = (await getDoc(docRef)).data()!!!.firstPaymentDate?.toDate()
    // //if firstPaymentDate is undefined firstMoment get moment value of current Date
    // const firstMoment = moment(firstPaymentDate);
    const createTime = (await getDoc(docRef)).data()!!!.createTime?.toDate()
    const firstMoment = moment(createTime);
    const currentDate = moment();
    const diff = function (firstMoment,currentDate) : number {
        const firstYear = firstMoment.year(), firstMonth = firstMoment.month()
        const currentYear = currentDate.year(), currentMonth = currentDate.month()
        if(firstYear === currentYear){
            return currentMonth - firstMonth
        } else {
            const yearGap = currentYear - firstYear
            const gapToMonth = yearGap * 12
            return currentMonth + gapToMonth - firstMonth
        }
    }(firstMoment,currentDate)
    currentDate.add(1,"M")
    const monthList : MonthItem[] = []
    for(var i = 0; i <= diff ; i++){
        // const isFirst = i === 0
        const eachMoment = currentDate.subtract(1,"M")
        const text = eachMoment.format("YYYY년 MM월")
        const yandm : MonthUnit = {
            year : eachMoment.get("y"),
            month : eachMoment.get("M")
        }
        monthList.push({
            text : text,
            value : yandm
        })
    }
    return monthList
}
const getStaffListFromFireStore = async () : Promise<StaffWithId[]> => {
    const uid = fireAuth.currentUser?.uid
    const collectionRef = collection(fireStore,"staffs").withConverter(staffConverter)
    const staffQuery = query(collectionRef,where("employer","==",uid),orderBy("name","asc"))
    const querySnapshot = await getDocs(staffQuery)
    return Promise.all(
        querySnapshot.docs.map(async(doc)=>{
            const payStubObj = await getPayStubObjFromFireStore(doc.ref.path)
            return {
                data :doc.data(),
                id : doc.id,
                payStubObj : payStubObj
            }
        })
    )
}
const getPayStubObjFromFireStore = async (path:string) : Promise<{ [key: string]: PayStub }> => {
    const staffPayStubCollection = collection(fireStore,path,"payStub").withConverter(payStubConverter)
    const payStubQuery = query(staffPayStubCollection)
    const querySnapshot = await getDocs(payStubQuery)
    const payStubObject: { [key: string]: PayStub } = {}
    querySnapshot.docs.forEach(async (doc) => {
        const id: string = doc.id
        payStubObject[id] = doc.data()
    })
    return payStubObject
}