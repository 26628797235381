import React, { FC, useEffect, useState } from "react";
import { EmpPs, PartPs, PayStub } from "../../classes/PayStub";
import { MonthlyPaymentInput } from "../../components/MonthlyPaymentInput";
import { PayInput } from "../../components/PayInput";
import { TitleInputUnit } from "../../components/TitleInputUnit";
import styles from '../../css/PayViewPage.module.css'
import { back, ic_arrow_down, ic_arrow_up, logo } from "../../images/images";

export const Part: FC<{ payStubData: PartPs }> = ({ payStubData }) => {

    const [isOpenDeduct, setIsOpenDeduct] = useState<boolean>(false)

    const workHour = (parseFloat(payStubData.monthlyWorkHour !== "" ? payStubData.monthlyWorkHour : "0")
        + parseFloat(payStubData.monthlyWorkMinutes !== "" ? payStubData.monthlyWorkMinutes : "0") / 60).toLocaleString()
    const holiDayHour = (parseFloat(payStubData.monthlyHolidayHour !== "" ? payStubData.monthlyHolidayHour : "0")
        + parseFloat(payStubData.monthlyHolidayMinutes !== "" ? payStubData.monthlyHolidayMinutes : "0") / 60).toLocaleString()
    return (
        <>
            <div className={`side_margin_5per`} style={{
                display: "flex", flexDirection: "column",
                marginBottom: "16px"
            }}>
                <TitleInputUnit
                    title="시급"
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={payStubData.hourlyWage}
                    removeBorder={true}
                    unit="원"
                    readonly={true} />
                <TitleInputUnit
                    title="근로시간"
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={workHour}
                    removeBorder={true}
                    unit="시간"
                    readonly={true} />
                <TitleInputUnit
                    title="주휴"
                    titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                    value={holiDayHour}
                    removeBorder={true}
                    unit="시간"
                    readonly={true} />

            </div>

            {payStubData.additionalPayments?.length > 0 &&
                payStubData.additionalPayments.map((value) => {
                    return (
                        <div className={`side_margin_5per`} style={{
                            display: "flex", flexDirection: "column",
                            marginBottom: "16px"
                        }}>
                            <TitleInputUnit
                                title={value.title}
                                outerStyle={{
                                    padding: "4px 0px"
                                }}
                                removeBorder={true}
                                titleStyle={{ minWidth: "120px", fontWeight: 500 }}
                                value={value.value}
                                unit="원"
                                readonly={true} />
                        </div>
                    )
                })
            }
            <div className={`side_margin_5per ${styles.amount_comp_ctn}`}>
                {/* PaymentAmount */}
                <PayInput
                    style={{ borderWidth: "0px" }}
                    readonly={true}
                    title={"지급액계"}
                    value={payStubData.paymentAmount} />
                {/* DeductionAmount */}
                <PayInput
                    style={{ borderWidth: "0px" }}
                    readonly={true}
                    title={"공제액계"}
                    value={payStubData.deductionAmount}
                    onChange={() => { }} />
                {/* Amount */}
                <div style={{ minHeight: 1, backgroundColor: "#F5F5F5" }} />
                <PayInput
                    style={{ borderWidth: "0px" }}
                    titleStyle={{ fontWeight: 500 }}
                    readonly={true}
                    title={"실수령액"}
                    value={payStubData.amount} />
            </div>
            {!isOpenDeduct
                ?
                <div className="side_margin_5per"
                    style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        border: "1px solid #F5F5F5", marginBottom: "18px",
                        padding: "18px", borderRadius: 16, backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                    }}
                    onClick={() => {
                        setIsOpenDeduct(!isOpenDeduct)
                    }}>
                    <span>공제항목</span>
                    <img src={ic_arrow_down} style={{ height: "20px" }} />
                </div>
                :
                <div className="side_margin_5per" style={{ display: "flex", flexDirection: "column", }}>
                    <div className={`${styles.deduct_ctn}`}
                        style={{ paddingTop: "18px", paddingBottom: "18px", marginTop: "0px" }}>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center",
                                fontSize: "14px", fontWeight: 500, boxSizing: "border-box"
                            }}
                            onClick={() => {
                                setIsOpenDeduct(!isOpenDeduct)
                            }}>
                            <span>공제항목</span>
                            <img src={ic_arrow_up} style={{ height: "20px" }} />
                        </div>
                        {payStubData.deductionItems.map((value, index) => {
                            return (
                                <MonthlyPaymentInput
                                    key={`${value.title}_${index}_part`}
                                    styles={{
                                        titleStyle: {
                                            fontWeight: 500
                                        }
                                    }}
                                    readonly={true}
                                    values={value} />
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}