import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";
import { GRAY, THEME_YELLOW } from "../values/Colors";
import { StaffWithId } from "../classes/Staff"
import check_on from "../images/manage/check_on@2x.png"
import check_off from "../images/manage/check_off@2x.png"
import arrow_down from "../images/manage/ic_arrow_down@2x.png"
import arrow_up from "../images/manage/ic_arrow_up@2x.png"
import { btn_edit_square, edit, ic_arrow_up, ic_delete, ic_staff, logo, menu, plus } from "../images/images";
import styles from "../css/PayManage.module.css"
import { PayStub } from "../classes/PayStub";
import { useNavigate } from "react-router-dom";
import { MonthItem } from "../classes/Month";
import ic_check from "../images/manage-pay/ic_check@2x.png"
export const PayList : FC<{
    isMobile: boolean
    middleLine?: CSSProperties,
    isEmployee: number,
    list: StaffWithId[],
    headers: { title: string, flex: number }[],
    staffList : StaffWithId[],
    selectMonthToPattern : string,
    selectMonth : MonthItem | undefined,
    changeStaffState: (staff: StaffWithId) => void
}> = ({
    isMobile,
    middleLine = {},
    isEmployee,
    list,
    headers,
    staffList,
    selectMonthToPattern,
    selectMonth,
    changeStaffState
}) => {
    const navigate = useNavigate()
    const listRef = useRef<HTMLDivElement>(null)
    const keyName = isEmployee === 0 ? "employee" : "part-time-job-row"
    const title = isEmployee === 0 ? "직원" : "아르바이트/일용직"
    const [scrollState, setScrollState] = useState<number>(0)
    useEffect(() => {
        setScrollState(listRef.current?.scrollHeight!!! - listRef.current?.clientHeight!!! - listRef.current?.scrollTop!!!)
    }, [list])
    return (
        isMobile
            ?
            <div key={`${keyName}_list`}
                style={{
                    flex: 1, maxHeight: "50%",
                    display: "flex", flexDirection: "column",
                }}>
                <div className={styles.row_ctn}>
                    <span className={styles.row_cell}
                        style={{
                            flex: 1
                        }}>{title}
                        <span className={styles.row_cell}
                            style={{
                                marginLeft: "4px",
                                color: THEME_YELLOW
                            }}>{`${list.length}명`}</span></span>
                    <div style={{ flex: 19 }} />
                </div>
                <div
                    ref={listRef}
                    style={{
                        flex: 1,
                        overflowY: "auto",
                        color: "#767676",
                    }}
                    onScroll={(ev) => {
                        setScrollState(listRef.current?.scrollHeight!!! - listRef.current?.clientHeight!!! - listRef.current?.scrollTop!!!)
                    }}>
                    {list?.length
                        ? list.map((value, index1) => {
                            const { data, payStubObj } = value
                            const thisPayStub = payStubObj[selectMonthToPattern]
                            // //console.log(payStubObj,selectMonthToPattern,thisPayStub)
                            const values: [number, string, PayStub, PayStub, PayStub, boolean]
                                = [index1 + 1, data.name, thisPayStub, thisPayStub, thisPayStub, data.state]
                            const state: number = thisPayStub
                                ? thisPayStub.isSend
                                    ? 2 //"발송 완료"
                                    : 1 //"발송 전"
                                : 0 //"등록 전"
                            return (
                                <div className={styles.row_ctn} style={{
                                    borderWidth: index1 === 0
                                        ? "1px 0px 1px 0px"
                                        : "0px 0px 1px 0px",
                                    borderStyle: "solid", borderColor: "#EADFDB",
                                    backgroundColor: index1 % 2 === 0 ? "white" : "#FCFAF5",
                                    height: "44px",
                                    // minHeight: "48px",maxHeight:"48px"
                                }} key={`${keyName}-row_${index1}`}>
                                    {values.map((value2, index2) => {
                                        const { flex } = headers[index2]
                                        switch (index2) {
                                            case 0:
                                            case 1:
                                                return (
                                                    <span key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        className={styles.row_cell}
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: flex,
                                                            fontWeight: "normal"
                                                        }}>{value2}
                                                    </span>
                                                )
                                            case 2:
                                            case 3:
                                                return (
                                                    <div key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        style={{
                                                            flex: flex,
                                                            display: "flex", justifyContent: "center"
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontFamily: "Notosans", fontSize: "12px",
                                                                padding: "4px 0px", width: "52px",
                                                                borderRadius: "12px", boxSizing: "border-box",
                                                                wordBreak: "keep-all", whiteSpace: "nowrap", textAlign: "center",
                                                                ...index2 === 3
                                                                    ? state > 0 //index2 == 3 보기
                                                                        ? { color: "white", backgroundColor: THEME_YELLOW, cursor: "pointer" } //발송 전 및 완료
                                                                        : { color: "#757575", border: "1px solid #757575" } //등록 전
                                                                    :  //index2 === 2 등록
                                                                    { color: "white", backgroundColor: THEME_YELLOW, cursor: "pointer" }

                                                            }}
                                                            onClick={() => {
                                                                console.log(values[3])
                                                                if (index2 === 2) {
                                                                    //index2 === 2 등록 
                                                                    const staffIndex = staffList.indexOf(value)
                                                                    navigate("/pay", {
                                                                        state: {
                                                                            staff: value,
                                                                            payStub: values[3],
                                                                            targetMonth: selectMonth,
                                                                            staffIndex: staffIndex,
                                                                            readonly: false
                                                                        }
                                                                    })
                                                                }
                                                                if (index2 === 3 && state > 0) {
                                                                    //index2 === 3 보기

                                                                    const staffIndex = staffList.indexOf(value)
                                                                    navigate("/pay-view", {
                                                                        state: {
                                                                            staff: value,
                                                                            payStub: values[3],
                                                                            targetMonth: selectMonth,
                                                                            staffIndex: staffIndex,
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                            {index2 === 3
                                                                ? "보기"
                                                                : state > 0
                                                                    ? "수정"
                                                                    : "등록"}
                                                        </span>
                                                    </div>
                                                )
                                            case 4:
                                                return (
                                                    <span key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        className={styles.row_cell}
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: flex,
                                                            fontWeight: "normal"
                                                        }}>{state > 0
                                                            ? state > 1
                                                                ? "발송 완료" : "발송 전"
                                                            : "등록 전"}
                                                    </span>
                                                )
                                            case 5:
                                                return (
                                                    <div className={`pointer-cursor ${styles.row_cell}`}
                                                        key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        style={{
                                                            height: "100%",
                                                            flex: flex
                                                        }}>
                                                        <img
                                                            style={{ height: "100%", }}
                                                            src={data.state ? check_on : check_off}
                                                            onClick={() => {
                                                                if (state > 0) {
                                                                    changeStaffState(value)
                                                                }
                                                            }} />
                                                    </div>
                                                )
                                            default:
                                                return null
                                        }
                                    })}
                                </div>
                            )
                        })
                        :
                        <div style={{
                            display: "flex", justifyContent: "center"
                        }}>
                            <span className={styles.row_cell} style={{
                            }}>{`등록된 ${isEmployee === 0 ? "직원이" : "아르바이트가"} 없습니다.`}</span>
                        </div>}
                </div>
                {(scrollState ?? 0) >= 43 &&
                    <img src={arrow_down} style={{ width: "5%", height: "auto", alignSelf: "center", maxHeight: "36px", maxWidth: "36px" }} />}
            </div>
            :
            <div key={`${keyName}_list`}
                style={{
                    flex: 1,
                    display: "flex", flexDirection: "column",
                    boxSizing: "border-box", ...middleLine
                }}>
                <div className={styles.row_ctn}>
                    <span className={styles.row_cell}
                        style={{
                            flex: 1, fontSize: "16px"
                        }}>{title}
                        <span className={styles.row_cell}
                            style={{
                                marginLeft: "4px",
                                color: THEME_YELLOW, fontSize: "16px"
                            }}>{`${list.length}명`}</span></span>
                    <div style={{ flex: 19 }} />
                </div>
                {/* table header */}
                <div className={styles.row_ctn}
                    style={{
                        height: "48px"
                    }}>
                    {headers.map((value, index) => {
                        const { title, flex } = value
                        if (index != headers.length - 1)
                            return (
                                <div key={`header_cell_${title}_${index}`}
                                    className={`${styles.row_cell} ${styles.header_cell}`}
                                    style={{
                                        flex: flex,
                                        padding: "12px 0px",
                                        borderBottom: "0px solid white"
                                    }}>{title}</div>
                            )
                        else
                            return (
                                <div key={`header_cell_${title}_${index}`}
                                    className={`${styles.row_cell} ${styles.header_cell}`}
                                    style={{
                                        flex: flex,
                                        display: "flex", justifyContent: "center",
                                        borderBottom: "0px solid white"
                                    }}>
                                    <img src={ic_check} style={{ width: "20px" }} />
                                </div>
                            )
                    })}
                </div>
                {(listRef.current?.scrollTop ?? 0) >= 43 &&
                    <img src={ic_arrow_up} style={{ width: "5%", height: "auto", alignSelf: "center", maxHeight: "36px", maxWidth: "36px", }} />}
                <div
                    ref={listRef}
                    style={{
                        flex: 1,
                        overflowY: "auto",
                        color: "#767676",
                    }}
                    onScroll={(ev) => {
                        setScrollState(listRef.current?.scrollHeight!!! - listRef.current?.clientHeight!!! - listRef.current?.scrollTop!!!)
                    }}>
                    {list?.length
                        ? list.map((value, index1) => {
                            const { data, payStubObj } = value
                            const thisPayStub = payStubObj[selectMonthToPattern]
                            // //console.log(payStubObj,selectMonthToPattern,thisPayStub)
                            const values: [number, string, string, PayStub, PayStub, PayStub, boolean]
                                = [index1 + 1, data.name, data.rank, thisPayStub, thisPayStub, thisPayStub, data.state]
                            const state: number = thisPayStub
                                ? thisPayStub.isSend
                                    ? 2 //"발송 완료"
                                    : 1 //"발송 전"
                                : 0 //"등록 전"
                            return (
                                <div className={styles.row_ctn} style={{
                                    borderWidth: index1 === 0
                                        ? "1px 0px 1px 0px"
                                        : "0px 0px 1px 0px",
                                    borderStyle: "solid", borderColor: "#EADFDB",
                                    backgroundColor: index1 % 2 === 0 ? "white" : "#FCFAF5",
                                    height: "56px",
                                    padding: "0px", margin: "0px 5%"
                                    // minHeight: "48px",maxHeight:"48px"
                                }} key={`${keyName}-row_${index1}`}>
                                    {values.map((value2, index2) => {
                                        const { flex } = headers[index2]
                                        switch (index2) {
                                            case 0:
                                            case 1:
                                            case 2:
                                                return (
                                                    <span key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        className={styles.row_cell}
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: flex,
                                                            fontWeight: "normal"
                                                        }}>{value2}
                                                    </span>
                                                )
                                            case 3:
                                            case 4:
                                                return (
                                                    <div key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        style={{
                                                            flex: flex,
                                                            display: "flex", justifyContent: "center"
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontFamily: "Notosans", fontSize: "12px",
                                                                padding: "4px 0px", width: "52px",
                                                                borderRadius: "12px", boxSizing: "border-box",
                                                                wordBreak: "keep-all", whiteSpace: "nowrap", textAlign: "center",
                                                                ...index2 === 4
                                                                    ? state > 0 //index2 == 4 보기
                                                                        ? { color: "white", backgroundColor: THEME_YELLOW, cursor: "pointer" } //발송 전 및 완료
                                                                        : { color: "#757575", border: "1px solid #757575",cursor : "default" } //등록 전
                                                                    :  //index2 === 3 등록
                                                                    { color: "white", backgroundColor: THEME_YELLOW, cursor: "pointer" }

                                                            }}
                                                            onClick={() => {
                                                                if (index2 === 3) {
                                                                    const staffIndex = staffList.indexOf(value)
                                                                    navigate("pay", {
                                                                        state: {
                                                                            staff: value,
                                                                            payStub: values[index2],
                                                                            targetMonth: selectMonth,
                                                                            staffIndex: staffIndex,
                                                                            readonly: false
                                                                        }
                                                                    })
                                                                }
                                                                if (index2 === 4 && state > 0) {
                                                                    const staffIndex = staffList.indexOf(value)
                                                                    navigate("pay-view", {
                                                                        state: {
                                                                            staff: value,
                                                                            payStub: values[index2],
                                                                            targetMonth: selectMonth,
                                                                            staffIndex: staffIndex,
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                            {index2 === 4
                                                                ? "보기"
                                                                : state > 0
                                                                    ? "수정"
                                                                    : "등록"}
                                                        </span>
                                                    </div>
                                                )
                                            case 5:
                                                return (
                                                    <span key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        className={styles.row_cell}
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: flex,
                                                            fontWeight: "normal"
                                                        }}>{state > 0
                                                            ? state > 1
                                                                ? "발송 완료" : "발송 전"
                                                            : "등록 전"}
                                                    </span>
                                                )
                                            case 6:
                                                return (
                                                    <div className={`${styles.row_cell}`}
                                                        key={`${keyName}-row-cell_${index1}_${index2}`}
                                                        style={{
                                                            height: "100%", maxHeight: "30px",
                                                            flex: flex, boxSizing: "border-box",
                                                            display: "flex", alignItems: "center", justifyContent: "center"
                                                        }}>
                                                        <img className="pointer-cursor"
                                                            style={{ height: "80%" }}
                                                            src={data.state ? check_on : check_off}
                                                            onClick={() => {
                                                                if (state > 0) {
                                                                    changeStaffState(value)
                                                                }
                                                            }} />
                                                    </div>
                                                )
                                            default:
                                                return null
                                        }
                                    })}
                                </div>
                            )
                        })
                        :
                        <div style={{
                            display: "flex", justifyContent: "center"
                        }}>
                            <span className={styles.row_cell} style={{
                            }}>{`등록된 ${isEmployee === 0 ? "직원이" : "아르바이트가"} 없습니다.`}</span>
                        </div>}
                </div>
                {(scrollState ?? 0) >= 43 &&
                    <img src={arrow_down} style={{ width: "5%", height: "auto", alignSelf: "center", maxHeight: "36px", maxWidth: "36px" }} />}
            </div>)
}