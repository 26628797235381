// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import { ConfirmationResult, getAuth, RecaptchaVerifier } from "firebase/auth";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
    verificationId : string;
  }
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBD8Dgp_t-ZSWbENi3MzJdOfrhee0gNowU",
  authDomain: "nobong-79c64.firebaseapp.com",
  projectId: "nobong-79c64",
  storageBucket: "nobong-79c64.appspot.com",
  messagingSenderId: "736572194483",
  appId: "1:736572194483:web:519961bd2b70a270b73ac6",
  measurementId: "G-NVTYGT9XQN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const fireStore = getFirestore(app);
export const fireStorage = getStorage(app);
export const fireAuth = getAuth(app);
fireAuth.useDeviceLanguage();

export const fireFunctions = getFunctions(app
  // ,"asia-northeast3"
  );