import { CHANGE_VIEW_SIZE_STATE } from "./isMobile.type";

const initialState : boolean = false

export const changeViewSizeState = (data : boolean) => {
    return {
        type : CHANGE_VIEW_SIZE_STATE,
        payload : data
    }
}
export const isMobileReducer  = (state: boolean = initialState, action: { type: string, payload?: any }) : boolean => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case CHANGE_VIEW_SIZE_STATE:
            return action.payload;
        default:
            break;
    }
    return state
}