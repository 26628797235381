import React, { CSSProperties, Dispatch, FC, HTMLInputTypeAttribute, SetStateAction, useEffect, useMemo, useState } from "react";
import { ChangeEventHandler } from "react";
import audio_on from "../images/manage/Ic_adio_on@2x.png"
import audio_off from "../images/manage/Ic_adio_off@2x.png"
import { THEME_YELLOW } from "../values/Colors";
import { ADD_STAFF, DONE, FIVE_PER, PLEASE_INPUT_NAME, TEN_PER } from "../values/strings";
import { FullScreen } from "../components/FullScreen";
import { isValidPhone, isValidSSN } from "../components/Tools";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp, } from "firebase/firestore";
import { fireAuth, fireStore } from "../fireConfig";
import { HireType } from '../values/types'
import { Staff, staffConverter, StaffWithId } from "../classes/Staff";
import { useDispatch } from "react-redux";
import { concatStaff } from "../redux/reducer/staff/staff";
import styles from "../css/MemberAdd.module.css"
import { ic_staff } from "../images/images";
import { useMediaQuery } from "react-responsive";
export const MemberAdd: FC<{
    onDismiss : () => void
}> = (
    {
        onDismiss
    }
) => {
    const isMobile: boolean = useMediaQuery({ maxWidth: 1224 })
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();

    const hireTypeList: HireType[] = [
        {
            title: "직원",
            type: 0
        },
        {
            title: "아르바이트",
            type: 1
        },
        {
            title: "일용직",
            type: 2
        },
    ]
    const GAP: number = 5
    const buttonWidth: string = useMemo(() => {
        const listLength: number = hireTypeList.length
        return ((100 + GAP) / listLength - GAP) + "%"
    }, [hireTypeList])
    const [hireType, setHireType] = useState<HireType>(hireTypeList[0]);

    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [ssn, setSSN] = useState<string>("");
    const [deduction, setDeduction] = useState<string>("");
    const [gender, setGender] = useState<string>("")
    const [part, setPart] = useState<string>("")
    const [rank, setRank] = useState<string>("")

    const [onProcess, setOnProcess] = useState(false)

    const inputList: {
        title: string,
        placeholder: string,
        value: string | number,
        setValue: Dispatch<SetStateAction<any>>,
        type: HTMLInputTypeAttribute,
        onChange?: ChangeEventHandler<HTMLInputElement>,
        maxLength?: number
    }[] = [
            {
                title: "이름",
                placeholder: PLEASE_INPUT_NAME,
                value: name,
                setValue: setName,
                type: "text"
            },
            {
                title: "전화번호",
                placeholder: "전화번호를 입력해주세요.",
                value: phone,
                setValue: setPhone,
                type: "tel",
                onChange: (e) => {
                    //removeAll not Number
                    const nonHyphen = e.target.value.replace(/[^0-9]/g, "")
                    const betweenHyphen = nonHyphen
                        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                    setPhone(betweenHyphen)
                },
                maxLength: 14
            },
            {
                title: "주민등록번호",
                placeholder: "주민등록번호를 입력해 주세요.",
                value: ssn,
                setValue: setSSN,
                type: "tel",
                onChange: (e) => {
                    //removeAll not Number
                    const nonHyphen = e.target.value.replace(/[^0-9]/g, "")
                    const betweenHyphen = nonHyphen.replace(/([0-9]{6})([0-9]{1})$/, "$1-$2")
                    setSSN(betweenHyphen)
                },
                maxLength: 8
            },
            {
                title: "공제대상 가족 수",
                placeholder: "가족의 수를 입력해 주세요.",
                value: deduction,
                setValue: setDeduction,
                type: "number"
            },
            {
                title: "부서",
                placeholder: "부서를 입력해 주세요.",
                value: part,
                setValue: setPart,
                type: "text"
            },
            {
                title: "직급",
                placeholder: "직급을 입력해주세요.",
                value: rank,
                setValue: setRank,
                type: "text"
            },
        ]

    useEffect(() => {
        if (!fireAuth.currentUser) {
            navigate("/login", { replace: true })
        }
    }, [])

    const changeCanAddStaffState = (): boolean => {

        var state = true;

        if (name.length < 1) {
            state = false
        }
        if (!isValidPhone(phone)) {
            state = false
        }
        if (!isValidSSN(ssn)) {
            state = false
        }
        if (deduction.length < 1) {
            state = false
        }
        if (!gender) {
            state = false
        }

        return state
    }
    const addStaff = async () => {
        setOnProcess(true)
        try {
            const uid = fireAuth.currentUser!!!.uid
            const staffCollection = collection(fireStore, "staffs").withConverter(staffConverter)
            const newStaff = new Staff(
                uid, hireType, name, phone, ssn.split("-"),
                parseInt(deduction.replace(/[^0-9]/g, "")),
                gender, part, rank, Timestamp.fromDate(new Date())
            )
            const { id } = await addDoc(staffCollection, newStaff)
            const staffWithId: StaffWithId = {
                data: newStaff,
                id: id,
                payStubObj: {}
            }
            reduxDispatch(concatStaff(staffWithId))
        } catch (error) {
        }
        setOnProcess(false)
    }
    const pushDefault = (type: number) => {
        const defaultValue = 
        type > 0
            ? type > 1
                ? "일용직"
                : "아르바이트"
            : ""
        setPart(defaultValue)
            setRank(defaultValue)
    }
    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                height: !isMobile ? "100%" : undefined
            }}>
            {isMobile
                ?
                <div className={styles.main_ctn}>
                    <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            onClick={onDismiss} >
                            <span style={{ fontSize: "12px" }}>취소</span>
                        </div>
                        <div className={styles.header_center}>
                            <img src={ic_staff} />
                            <span >{ADD_STAFF}</span>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "row", height: 64,
                        fontSize: 14,
                        padding: `12px 5%`, boxSizing: "border-box",
                        justifyContent: "space-between",
                    }}>
                        {hireTypeList.map((value, index) => {
                            const titleLength = value.title.length
                            return (
                                <div className={`pointer-cursor ${styles.btn_type}`}
                                    key={`btn_select_${value.type}`}
                                    id="btn_select_staff"
                                    style={{
                                        flex: titleLength > 3 ? titleLength : 3,
                                        marginRight: index === hireTypeList.length - 1 ? 0 : "8px",
                                        // width: buttonWidth,
                                        border: `1px solid ${value.type === hireType.type ? THEME_YELLOW : "#BFBFBF"}`
                                    }}
                                    onClick={() => {
                                        setHireType(value)
                                        pushDefault(value.type)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", color: value.type === hireType.type ? "#707070" : "#B2B2B2" }}>{value.title}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{
                        flex: 1, display: "flex", flexDirection: "column", overflowY: "auto",
                        fontSize: "14px"
                    }}>
                        {inputList.map(({ value, setValue, title, placeholder, type, onChange, maxLength }, index) =>
                            <div key={`member-add-input-list_${index}`}
                                style={{
                                    padding: `8px ${FIVE_PER} 8px ${FIVE_PER}`,
                                    display: "flex", flexDirection: "column"
                                }}>
                                <span style={{ fontFamily: "Notosans", color: "#191919", marginBottom: "4px" }}>{title}</span>
                                <input className={styles.input_box}
                                    type={type}
                                    maxLength={maxLength ?? undefined}
                                    value={value}
                                    onChange={onChange ?? ((e) => {
                                        setValue(e.target.value)
                                    })}
                                    placeholder={placeholder} />
                            </div>
                        )}

                        <div style={{
                            padding: `8px ${FIVE_PER} 8px ${FIVE_PER}`,
                            display: "flex", flexDirection: "column"
                        }}>
                            <span style={{ fontFamily: "Notosans", color: "#191919", marginBottom: "4px" }}>{"성별"}</span>
                            <div style={{
                                height: "48px", boxSizing: "border-box",
                                fontFamily: "NotoSans",
                                padding: "12px 0px 12px 0px",
                                display: "flex",
                            }}>
                                <img className="pointer-cursor"
                                    src={gender === "M" ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setGender("M")
                                    }} />
                                <span style={{ color: "#191919", marginInlineEnd: "16px" }}>{"남"}</span>
                                <img className="pointer-cursor"
                                    src={gender === "F" ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setGender("F")
                                    }} />
                                <span style={{ color: "#191919" }}>{"여"}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "column",
                        alignItems: "center",
                        padding: `24px ${FIVE_PER} 24px ${FIVE_PER}`,
                        boxSizing: "border-box"
                    }}>
                        <div className="pointer-cursor"
                            style={{
                                boxSizing: "border-box",
                                width: "100%",
                                padding: "12px",
                                borderRadius: "52px",
                                backgroundColor: changeCanAddStaffState() ? THEME_YELLOW : "#EBEBEB",
                                display: "flex", justifyContent: "center"
                            }}
                            onClick={async () => {
                                if (changeCanAddStaffState()) {
                                    if (!onProcess) {
                                        await addStaff()
                                        alert("직원이 추가되었습니다.")
                                        onDismiss()
                                    }
                                } else {
                                    alert("모든 입력란을 알맞게 채워주세요")
                                }
                            }}>
                            <span style={{ color: changeCanAddStaffState() ? "white" : "#B9B9B9", fontWeight: "bold", fontFamily: "NotoSans" }}>{DONE}</span>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.main_ctn}>
                    <div className={styles.header_ctn}>

                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}>
                            <img src={ic_staff} />
                            <span style={{ fontWeight: "bold" }}>{ADD_STAFF}</span>
                        </div>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                            style={{
                                flexDirection:"row",fontSize:"14px"
                            }} >
                            <div className="pointer-cursor" style={{
                                border: "1px solid #767676", borderRadius: "3px",
                                color: "#767676",
                                display: "flex", alignItems: "center", justifyContent: "center",
                                height: "42px", width: "100px", marginRight: "12px"
                            }}
                                onClick={onDismiss}>취소</div>
                            <div className="pointer-cursor" style={{
                                backgroundColor: changeCanAddStaffState() ? THEME_YELLOW : "#EBEBEB",
                                color: changeCanAddStaffState() ? "white" : "#B9B9B9",
                                borderRadius: "3px",
                                fontWeight: 500,
                                display: "flex", alignItems: "center", justifyContent: "center",
                                height: "42px", width: "100px"
                            }}
                                onClick={async () => {
                                    if (changeCanAddStaffState()) {
                                        if (!onProcess) {
                                            await addStaff()
                                            alert("직원이 추가되었습니다.")
                                            onDismiss()
                                        }
                                    } else {
                                        alert("모든 입력란을 알맞게 채워주세요")
                                    }
                                }}>저장</div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", flexDirection: "row", height: 64,
                        fontSize: 14,
                        padding: `12px 5%`, boxSizing: "border-box",
                        justifyContent: "flex-start",
                    }}>
                        {hireTypeList.map((value, index) => {
                            return (
                                <div className={`pointer-cursor ${styles.btn_type}`}
                                    key={`btn_select_${value.type}`}
                                    id="btn_select_staff"
                                    style={{
                                        width:"180px",
                                        marginRight: index === hireTypeList.length - 1 ? 0 : "8px",
                                        // width: buttonWidth,
                                        border: `1px solid ${value.type === hireType.type ? THEME_YELLOW : "#BFBFBF"}`
                                    }}
                                    onClick={() => {
                                        setHireType(value)
                                        pushDefault(value.type)
                                    }}>
                                    <span style={{ fontFamily: "Notosans", color: value.type === hireType.type ? "#707070" : "#B2B2B2" }}>{value.title}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{
                        flex: 1, display: "flex", flexDirection: "column", overflowY: "auto",
                        fontSize: "14px"
                    }}>
                        {inputList.map((value, index) => {
                            if (index % 2 !== 0) {
                                return
                            }
                            const twoValues = [inputList[index],inputList[index+1]]
                            return (
                                <div key={`member-add-input-list_${index}`}
                                    style={{
                                        padding: `8px ${FIVE_PER} 8px ${FIVE_PER}`,
                                        display: "flex", flexDirection: "column"
                                    }}>
                                    <div style={{
                                        display: "flex", alignItems: "center"
                                    }}>
                                        {twoValues.map((value2, index) => {
                                            const { value, setValue, title, placeholder, type, onChange, maxLength } = value2
                                            return (
                                                <div style={{
                                                    display: "flex", flexDirection: "column",
                                                    marginRight : index === 0 ? "32px" : undefined
                                                }}>
                                                    <span style={{ fontFamily: "Notosans", color: "#191919", marginBottom: "8px", marginLeft: "4px" }}>{title}</span>
                                                    <input className={styles.input_box}
                                                        style={{ width: "450px" }}
                                                        type={type}
                                                        maxLength={maxLength ?? undefined}
                                                        value={value}
                                                        onChange={onChange ?? ((e) => {
                                                            setValue(e.target.value)
                                                        })}
                                                        placeholder={placeholder} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                        <div style={{
                            padding: `8px ${FIVE_PER} 8px ${FIVE_PER}`,
                            display: "flex", flexDirection: "column"
                        }}>
                            <span style={{ fontFamily: "Notosans", color: "#191919", marginBottom: "4px" }}>{"성별"}</span>
                            <div style={{
                                height: "48px", boxSizing: "border-box",
                                fontFamily: "NotoSans",
                                padding: "12px 0px 12px 0px",
                                display: "flex",
                            }}>
                                <img className="pointer-cursor"
                                    src={gender === "M" ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setGender("M")
                                    }} />
                                <span style={{ color: "#191919", marginInlineEnd: "16px" }}>{"남"}</span>
                                <img className="pointer-cursor"
                                    src={gender === "F" ? audio_on : audio_off} style={{ height: "100%" }}
                                    onClick={() => {
                                        setGender("F")
                                    }} />
                                <span style={{ color: "#191919" }}>{"여"}</span>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}