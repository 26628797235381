import { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { QnA } from '../../classes/QnA'
import { UserWithId } from '../../classes/User'
import { QnAPage } from '../../components/QnA/QnAPage'
import { ServiceBanner } from '../../components/ServiceBanner'
import styles from "../../css/QnA/QnAList.module.css"
import { arrow_back_black, arrow_back_grey, arrow_next_black, arrow_next_grey, back, ic_qna, img_qna } from '../../images/images'
import { DARK_GRAY, THEME_YELLOW } from '../../values/Colors'
import { DO_YOU_HAVE_ANY_QUESTION, QNA } from '../../values/strings'
export const QnAList: FC = () => {
    const navigate = useNavigate();
    const { page: pageFromParams = "0" } = useParams<"page">();
    const location = useLocation()
    const { isMobile, qna: qnaList ,user } = useSelector((state: { qna: DocumentReference<QnA>[], isMobile: boolean, user: UserWithId }) => state)

    const pagePerSection = 10;
    const qnaPerPage = 20;
    const page = parseInt(pageFromParams)

    const onlyMyQnA = (new URLSearchParams(location.search)).get("only") ?? "0"
    const sortedList = useMemo(()=>{
        if(onlyMyQnA === "1"){
            return qnaList.filter((value)=>{
                return user.data.qnaList?.includes(value.id )
            })}
        else return qnaList
    },[qnaList,onlyMyQnA])

    const pageArr = useMemo(() => {
        var len = sortedList.length;
        var cnt = Math.floor(len / qnaPerPage) + (Math.floor(len % qnaPerPage) > 0 ? 1 : 0);
        var tmp = []
        for (var i = 0; i < cnt; i++) {
            tmp.push(i)
        }
        return tmp
    }, [sortedList])

    const textboxRef = useRef<HTMLSpanElement>(null)

    const goBack = () => {
        navigate(-1)
    }

    const goToEdit = () => {
        navigate("../edit")
    }

    useEffect(() => {
        console.log(location.search)
        const parse = new URLSearchParams(location.search)
        console.log(parse.get("only")?.toString())
    }, [location])


    const MoreQnAComp : FC<{
        onClick? : MouseEventHandler<HTMLButtonElement>
    }> = ({
        onClick = () => {
            var path : string = isMobile ? "/how-to-use" : "/main/how-to-use"
            navigate(path)
        }
    }) => {
        return (
            <div style={{
                width:"90%",
                alignItems: "center", display: "flex", flexDirection: "column",
                padding: "8px 0px",
                borderTop:`1px solid #D3D3D3`
            }}>
                <p style={{textAlign:"center",margin:"4px"}}>
                    <p style={{
                        margin:"0px",marginBottom:"8px",
                        fontSize: "12px", fontFamily: "Notosans", fontWeight: 500, color: DARK_GRAY
                    }}> 더 정확한 정보는 이용안내를 참고해주세요!</p>
                    <button className="pointer-cursor" title="이용안내 바로가기" style={{
                        padding: "8px 24px", backgroundColor: "transparent",
                        border: `1px solid ${THEME_YELLOW}`, borderRadius: "4px",
                        color: THEME_YELLOW, fontWeight: 500, fontFamily: "Notosans"
                    }} onClick={onClick}>
                        <span>이용안내 바로가기</span>
                    </button>
                </p>
            </div>
        )
    }

    return (
        <div className={isMobile ? "parent-div" : "parent-div-per"}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
            <div className={styles.main_ctn}
                style={{ maxWidth: "2400px",overflow:"hidden",}}>
                {isMobile
                    ? <div className={styles.header_ctn}>
                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.left}`}
                            style={{paddingLeft:"5%"}}>
                            <img src={back} onClick={goBack} />
                        </div>
                        <div className={styles.header_center}>
                            <span>{QNA}</span>
                        </div>

                        <div className={`pointer-cursor ${styles.header_side_ctn} ${styles.right}`}
                            style={{ paddingRight: "5%" }}><div className={`pointer-cursor`}
                                style={{
                                    padding: "4px 12px",
                                    borderRadius: "6px",
                                    border: "1px solid #FDCA56",
                                    backgroundColor: onlyMyQnA === "1" ? THEME_YELLOW : undefined,
                                    display:"flex",
                                    alignItems:"center"
                                }}
                                onClick={() => {
                                    if (onlyMyQnA === "1")
                                        navigate(".")
                                    else
                                        navigate(".?only=1")
                                }}>
                                <span style={{ fontSize:"12px", color: onlyMyQnA === "1" ? "white" : "#767676",}}>{"내 질문"}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.header_ctn}>
                        <div className={`${styles.header_side_ctn} ${styles.left}`}>
                            <img src={ic_qna} />
                            <span style={{ fontWeight: "bold", marginLeft: 12, fontSize: 16 }}>{QNA}</span>
                            <span style={{ marginLeft: 24 }}>{DO_YOU_HAVE_ANY_QUESTION}</span>


                            <div className={`pointer-cursor`}
                                style={{
                                    marginLeft: "30px",
                                    padding: "8px 18px",
                                    borderRadius: "6px",
                                    border: "1px solid #FDCA56"
                                }}
                                onClick={goToEdit}>
                                <span style={{ color: "#767676" }}>{"질문하기"}</span>
                            </div>
                        </div>

                        <div className={`${styles.header_side_ctn} ${styles.right}`}>
                            <div className={`pointer-cursor`}
                                style={{
                                    padding: "8px 18px",
                                    borderRadius: "6px",
                                    border: "1px solid #FDCA56",
                                    backgroundColor: onlyMyQnA === "1" ? THEME_YELLOW : undefined,

                                }}
                                onClick={() => {
                                    if (onlyMyQnA === "1")
                                        navigate(".")
                                    else
                                        navigate(".?only=1")
                                }}>
                                <span style={{ color: onlyMyQnA === "1" ? "white" : "#767676" }}>{"내 질문만 보기"}</span>
                            </div>
                        </div>
                    </div>}
                {isMobile &&
                    <div style={{
                        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                        margin:"16px 0px",
                    }}>
                        <div className={`pointer-cursor`}
                            style={{
                                minWidth: textboxRef ? `${textboxRef.current?.clientWidth}px` : undefined,
                                boxSizing: "border-box", width:"65%",
                                padding:"8px 0px",
                                borderRadius: "6px", border: "1px solid #FDCA56",
                                display: "flex", justifyContent: "center"
                            }}
                            onClick={goToEdit}>
                            <span style={{ color: "#4B4B4B",fontWeight:500 }}>{"Q&A 작성"}</span>
                        </div>
                    </div>
                }
                {sortedList.length > 0
                    &&
                <QnAPage
                    qnaList={sortedList}
                    page={page}
                    qnaPerPage={qnaPerPage}
                    onClick={(ref) => {
                        navigate(`../view/${ref.id}`)
                    }} />}
                {sortedList.length > 0 ?
                    <div style={{
                        display: "flex",
                        padding: "8px",
                        justifyContent: "center", alignItems: "center",
                    }}>
                        <img className={`pointer-cursor`} src={Math.floor(page / pagePerSection) !== 0
                            ? arrow_back_black
                            : arrow_back_grey}
                            style={{ width: "42px", height: "42px", marginRight: "12px" }}
                            onClick={() => {
                                if (Math.floor(page / pagePerSection) !== 0) {
                                    const currentSection = Math.floor(page / pagePerSection)
                                    // setPage(currentSection * 10 - 1)
                                    navigate(`../${currentSection * pagePerSection - 1}`)
                                }
                            }} />
                        {pageArr.slice(Math.floor(page / pagePerSection) * pagePerSection, (Math.floor(page / pagePerSection) + 1) * pagePerSection)
                            .map((value) => {
                                const isSelected = value === page
                                return (
                                    <span key={`Section_${value}`}
                                        className={`pointer-cursor`}
                                        style={{
                                            color: isSelected ? "white" : "black",
                                            margin: "0px 4px",
                                            display: "flex", flexDirection: "row",
                                            alignItems: "center", justifyContent: "center",
                                            width: "30px", height: "30px",
                                            borderRadius: "6px",
                                            backgroundColor: isSelected ? THEME_YELLOW : undefined
                                        }} onClick={() => {
                                            console.log(value)
                                            navigate(`../${value}`)
                                            // setPage(value)
                                        }}>
                                        {value + 1}
                                    </span>
                                )
                            })}
                        <img className={`pointer-cursor`} src={
                            Math.ceil((page + 1) / pagePerSection) !== Math.ceil(pageArr.length / pagePerSection)
                                ? arrow_next_black
                                : arrow_next_grey}
                            style={{ width: "42px", height: "42px", marginLeft: "12px" }}
                            onClick={() => {
                                if (Math.ceil((page + 1) / pagePerSection) !== Math.ceil(pageArr.length / pagePerSection)) {
                                    const currentSection = Math.floor(page / pagePerSection)
                                    navigate(`../${(currentSection + 1) * pagePerSection}`)
                                }
                            }} />
                    </div>
                    : <div style={{
                        display:"flex",justifyContent:"center"
                    }}>
                        <span style={{color:"#ACACAC"}}>등록된 질문이 없습니다.</span></div>}
            </div>
            <MoreQnAComp/>
            <div style={{
                boxSizing: "border-box", display: "flex",
                width: "100%",
                borderTop: "1px solid #e4e8eb",
                justifyContent: "space-around", backgroundColor: "#fafbfc"
            }}>
                <ServiceBanner type={0} style={isMobile ? {} : { width: "45%" }} />
                {!isMobile &&
                    <ServiceBanner type={0} style={{ width: "45%" }} isRan={true} />}
            </div>
        </div>
    )
}