import { DocumentReference, FirestoreDataConverter, Timestamp } from "firebase/firestore";
import moment from "moment";
export interface UserWithId {
    id : string,
    data : User
}
export interface User {
    name? : string,
    phone?:string,
    email?:string,
    brDownloadUrl? : string,
    bn? : string,
    brRef? : DocumentReference,
    createTime? : Timestamp,
    companyName? : string,
    isApprove? : boolean,
    expirationDate? : Timestamp,
    firstPaymentDate? : Timestamp,
    qnaList? : string[]
}

export class User implements User {
    constructor(
        name?: string,
        phone?: string,
        email?: string,
        brDownloadUrl?: string,
        bn?: string,
        brRef?: DocumentReference,
        createTime?: Timestamp,
        companyName?: string,
        isApprove?: boolean,
        expirationDate?: Timestamp,
        firstPaymentDate?: Timestamp,
        qnaList? : string[]
    ) {
        this.name = name
        this.phone = phone
        this.email = email
        this.brDownloadUrl = brDownloadUrl
        this.bn =  bn
        this.brRef = brRef
        this.createTime = createTime
        this.companyName = companyName
        this.isApprove = isApprove
        this.expirationDate = expirationDate
        this.firstPaymentDate = firstPaymentDate
        this.qnaList = qnaList
    }
    /**
     * canServe
     */
    canServe(): boolean {
        if (this.createTime) {
            return true
        } else {
            return false
        }
        // if (this.isApprove && this.expirationDate) {
        //     const today = moment()
        //     const expiration = moment(this.expirationDate.toDate())
        //     return expiration.diff(today) > -1
        // } else {
        //     return false
        // }
    }
    copy() : User {
        return new User(this.name,this.phone,this.email,this.brDownloadUrl,this.bn,this.brRef,
            this.createTime,this.companyName,this.isApprove,this.expirationDate,
            this.firstPaymentDate,this.qnaList)
    }
}
export const userConverter: FirestoreDataConverter<User> = {
    toFirestore: (user: User) => {
        return {
            name: user.name,
            phone: user.phone,
            email: user.email,
            brDownloadUrl: user.brDownloadUrl,
            bn: user.bn,
            brRef: user.brRef,
            createTime : user.createTime,
            companyName : user.companyName,
            isApprove : user.isApprove,
            expirationDate : user.expirationDate,
            firstPaymentDate : user.firstPaymentDate,
            qnaList : user.qnaList
        }
    },
    fromFirestore: (snapshot, options) => {
        const user = snapshot.data(options)
        return new User(
            // name:
            user.name,
            // phone:
            user.phone,
            // email:
            user.email,
            // brDownloadUrl :
            user.brDownloadUrl,
            // bn :
            user.bn,
            // brRef :
            user.brRef,
            // createTime :
            user.createTime,
            // companyName :
            user.companyName,
            // isApprove :
            user.isApprove,
            // expirationDate :
            user.expirationDate,
            user.firstPaymentDate,
            user.qnaList
        )
    }

}