import { Timestamp } from "firebase/firestore"
import { User, UserWithId } from "../../../classes/User"
import { UPDATE_TARGET_USER, UPDATE_USERS } from "./users.type"

const initialState : UserWithId[] = []
export const updateUsers = (data : UserWithId[] | undefined) => {
    return {
        type : UPDATE_USERS,
        payload : data
    }
}
export const updateTargetUser = (data : {
    index : number,
    data : UserWithId
}) => {
    return{
        type : UPDATE_TARGET_USER,
        payload : data
    }
}
export const usersReducer = (state: UserWithId[] = initialState, action: { type: string, payload?: any }) : UserWithId[] => {
    // //console.log("userReducer",action.type, action.payload)
    switch (action.type) {
        case UPDATE_USERS:
            return action.payload;
        case UPDATE_TARGET_USER:
            var newList = state.slice(0);
            newList[action.payload.index] = action.payload.data
            return newList
        default:
            break;
    }
    return state
}